import { action, makeObservable, observable } from "mobx";

import { RecordVersion, Task, taskableStatus } from "@/api";
import { MainStore } from "@/stores/Main";

import { UserComment } from "../types/comment-types";

export default class TaskDetailStore {
  mainStore: MainStore;

  isOpen: boolean = false;
  data: Partial<Task> = { name: "", status: "Not Started" };

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      data: observable,
      isOpen: observable,
      open: action,
      openFromRecord: action,
      close: action,
    });

    this.mainStore = mainStore;
  }

  openFromRecord(recordVersion: RecordVersion) {
    this.isOpen = true;
    this.data = {
      name: `New task for ${
        recordVersion.data.name?.value || recordVersion.global_id
      }`,
      status: "Not Started",
      taskables: [
        {
          status: taskableStatus.active,
          id: 0,
          target: {
            id: recordVersion.record_id,
            identifier: recordVersion.global_id,
            relatable_type: "Record" as "record",
            name: recordVersion.data.name?.value || "No Name",
            path: "",
          },
        },
      ],
    };
  }

  openFromComment(comment: UserComment) {
    this.isOpen = true;
    this.data = {
      name: `${comment.content_human_readable}`,
      description: `@${comment.author.full_name} said:\n> ${comment.content_human_readable}\n\n${comment.details_view_url}`,
      status: "Not Started",
      assignee_id:
        comment.mentioned_ids.length === 1 ? comment.mentioned_ids[0] : null,
      taskables: [
        {
          status: taskableStatus.active,
          id: 0,
          target: {
            id: comment.record_id,
            identifier: comment.global_id,
            relatable_type: "Record" as "record",
            name: comment.record_name || "No Name",
            path: "",
          },
        },
      ],
    };
  }

  open(task?: Partial<Task>) {
    this.mainStore.notifications.setIsOpen(false);
    this.isOpen = true;
    this.data = task || { name: "", status: "Not Started" };
  }

  close() {
    this.isOpen = false;
  }
}
