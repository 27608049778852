import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "@/components/Elements";
import AddNewSectionButton from "@/components/shared/AddNewSectionButton";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import { useFilters } from "@/hooks/useFilters";
import { useLoading } from "@/hooks/useLoading";
import { useScrollToError } from "@/hooks/useScrollToError";
import { useTableData } from "@/hooks/useTableData";

import ExportBulk from "../../dashboard/ExportBulk";
import ImportBulk from "../../dashboard/ImportBulk";
import Loading from "../../Loading";
import SlideMenu from "../../slideMenu/SlideMenu";
import EmailAttest from "../policy/EmailAttest";
import CreateFirstCTA from "../shared/CreateFirstCTA";
import NewSectionTag from "../shared/NewSectionTag";
import SectionDragContext from "../shared/SectionDragContext";
import Table from "../Table";
import AttestationsList from "./AttestationsList";
import TrainingSection from "./TrainingSection";

const TABS = {
  All: {
    label: "All",
    url: "/modules/training",
    beta: false,
  },
  Published: {
    label: "Published",
    url: "/modules/training/published",
    beta: true,
  },
  Attestations: {
    label: "Attestations",
    url: "/modules/training/attestations",
    beta: true,
  },
};

type Props = {
  defaultScreen?: string;
};

function Training({ defaultScreen }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showAddNewSection, setShowAddNewSection] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(defaultScreen);
  const [showCheckbox, setShowCheckbox] = useState(false);

  // Variables
  const { isCurrentWorkspaceArchived } = mainStore.workspaces;
  const { visibleFields } = mainStore.fields;
  const recordVersions = mainStore.recordVersions.list;
  const { data } = mainStore.trainings;
  // @ts-expect-error TS(2339) FIXME: Property 'module_workspace_id' does not exist on t... Remove this comment to see the full error message
  const moduleWorkspaceID = data?.module_workspace_id;
  const sectionTags = mainStore.sectionTags.orderedList;
  const { tableName, workspaceID } = mainStore.context;
  const { canManageSections } = mainStore.userPermissions;
  const isAllScreen = currentScreen === TABS.All.label;
  const isPublishedScreen = currentScreen === TABS.Published.label;
  const isAttestationsScreen = currentScreen === TABS.Attestations.label;

  // Hooks
  const loading = useLoading(visibleFields);
  const history = useHistory();
  useScrollToError({ block: "center" });
  useTableData();

  const { filtersTrigger, filtersViewEnabled, filtersContent } = useFilters({
    fields: visibleFields,
  });

  // Effects
  useEffect(() => {
    if (
      mainStore.sectionTags.current &&
      Object.keys(mainStore.sectionTags.current).length !== 0
    ) {
      setShowAddNewSection(false);
    }
  }, [mainStore.sectionTags.current]);

  useEffect(() => {
    mainStore.trainings.setLastTab(currentScreen);
  }, [currentScreen]);

  // funcs
  // @ts-expect-error TS(7006) FIXME: Parameter 'newScreen' implicitly has an 'any' type... Remove this comment to see the full error message
  function handleScreenChange(newScreen) {
    mainStore.trainings.index({
      workspaceID,
      tab: newScreen,
    });
    setCurrentScreen(newScreen);
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    history.push(`/workspaces/${workspaceID}${TABS[newScreen].url}`);
  }

  function addNewSection() {
    setShowAddNewSection(!showAddNewSection);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionId' implicitly has an 'any... Remove this comment to see the full error message
  const onSectionChange = (recordVersionId, sectionId) => {
    mainStore.creatives.updateSection(recordVersionId, sectionId);
  };

  // elements
  // @ts-expect-error TS(7006) FIXME: Parameter 'screen' implicitly has an 'any' type.
  const renderTabButton = (screen) => (
    <button
      key={screen}
      className={classNames({ active: currentScreen === screen })}
      onClick={() => handleScreenChange(screen)}
      data-testid={`training-tab-${screen}-trigger`}
    >
      {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
      {TABS[screen].label}
      {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
      {TABS[screen].beta && <div className="tab-beta-badge">Beta</div>}
    </button>
  );

  return (
    <Table>
      {loading && <Loading loadingLayout="table" showTableHeader={false} />}

      {!loading && (
        <>
          <div className={classNames("table-header-wrap")}>
            <div className="switch-table-wrap">
              <div className="buttons-block" data-testid="training-main-tabs">
                {Object.keys(TABS).map(renderTabButton)}
              </div>
            </div>
            <div className="import-export-buttons-container">
              <ExportBulk
                onActiveStateChange={
                  isAttestationsScreen ? setShowCheckbox : undefined
                }
                // @ts-expect-error TS(2339) FIXME: Property 'specific_statuses' does not exist on typ... Remove this comment to see the full error message
                specificStatuses={data?.specific_statuses}
                isAttestation={isAttestationsScreen}
              />
              {isAllScreen && <ImportBulk topLevelSection />}
              {isPublishedScreen && (
                <SlideMenu
                  closeSlideMenu={() => setShowCheckbox(false)}
                  trigger={
                    <Button
                      label="Send Email / Attestation"
                      size="sm"
                      fontWeight="medium"
                      disabled={isCurrentWorkspaceArchived}
                      onClick={() => setShowCheckbox(true)}
                    >
                      Send Email / Attestation
                    </Button>
                  }
                >
                  <EmailAttest
                    // @ts-expect-error TS(2322) FIXME: Type '"Drafts" | "Finalized" | "Archived" | "Polic... Remove this comment to see the full error message
                    tableName={tableName}
                    moduleWorkspaceID={moduleWorkspaceID}
                    moduleIdentifier="training"
                    checkboxSelection
                  />
                </SlideMenu>
              )}
              {!isAttestationsScreen && filtersTrigger}
            </div>
          </div>
          {isAllScreen && (
            <AddRecordHeader
              recordName="Training"
              addRecord={() =>
                mainStore.trainings.create(Number(workspaceID), null)
              }
              addSection={addNewSection}
              canAddSection
            />
          )}
          {filtersViewEnabled && (
            <div className="filters-wrap">
              <div className="switch-table-wrap" />
              {filtersContent}
            </div>
          )}
          <div
            className="table-list-wrap table-training-wrap"
            data-testid={`training-table-${currentScreen}`}
          >
            {isAttestationsScreen ? (
              <AttestationsList showCheckbox={showCheckbox} />
            ) : (
              <SectionDragContext onSectionChange={onSectionChange}>
                <TrainingSection
                  archived={isPublishedScreen}
                  isDropdownOpen
                  // @ts-expect-error TS(2322) FIXME: Type '"Drafts" | "Finalized" | "Archived" | "Polic... Remove this comment to see the full error message
                  tableName={tableName}
                  fields={visibleFields}
                  recordVersions={recordVersions.filter(
                    (recordVersion) => !recordVersion.section_tag_id,
                  )}
                  moduleWorkspaceID={moduleWorkspaceID}
                  workspaceID={workspaceID}
                  isDragAndDrop
                  pageID={0}
                  showCheckbox={showCheckbox}
                  setShowCheckbox={setShowCheckbox}
                />

                {sectionTags.map((sectionTag) => {
                  const filteredRecordVersions = recordVersions.filter(
                    (recordVersion) =>
                      recordVersion.section_tag_id === sectionTag.id,
                  );
                  return (
                    <TrainingSection
                      key={sectionTag.id}
                      archived={isPublishedScreen}
                      isDropdownOpen
                      // @ts-expect-error TS(2322) FIXME: Type '"Drafts" | "Finalized" | "Archived" | "Polic... Remove this comment to see the full error message
                      tableName={tableName}
                      fields={visibleFields}
                      recordVersions={filteredRecordVersions}
                      moduleWorkspaceID={moduleWorkspaceID}
                      workspaceID={workspaceID}
                      isDragAndDrop
                      sectionTag={sectionTag}
                      pageID={sectionTag.id}
                      showCheckbox={showCheckbox}
                      setShowCheckbox={setShowCheckbox}
                    />
                  );
                })}

                {showAddNewSection && (
                  <NewSectionTag
                    moduleWorkspaceID={moduleWorkspaceID}
                    onCancel={() => setShowAddNewSection(false)}
                  />
                )}

                {canManageSections && isAllScreen && (
                  <div
                    className="tw-sticky tw-left-[25px] tw-ml-[25px]"
                    data-testid="training-add-new-section"
                  >
                    <AddNewSectionButton onClick={addNewSection} />
                  </div>
                )}
                {recordVersions.length < 3 && isAllScreen && (
                  <CreateFirstCTA subject="Training" />
                )}
              </SectionDragContext>
            )}
          </div>
          <div />
        </>
      )}
    </Table>
  );
}

Training.defaultProps = {
  defaultScreen: "All",
};

export default observer(Training);
