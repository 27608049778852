import { PreviewTable } from "@themis/ui";
import React from "react";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";

import { useAccountContracts } from "@/api/queries/contracts";
import Loading from "@/components/Loading";

import { ACCOUNT_DETAILS_PATH, ACCOUNT_ID_PARAM } from "..";
import { ErrorContainer } from "../../../../components/ErrorContainer";
import { CONTRACTS_COLUMNS } from "../../config/contractsTable";

export function AccountContracts() {
  const { accountId, workspace_id } = useParams<{
    workspace_id: string;
    [ACCOUNT_ID_PARAM]: string;
  }>();
  const { url } = useRouteMatch();

  const { data, isPending, isError } = useAccountContracts(Number(accountId));

  if (isPending) {
    return <Loading loadingLayout="table" />;
  }

  if (isError) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath(ACCOUNT_DETAILS_PATH, {
            workspace_id,
            accountId,
          }),
        }}
      >
        Could not load account contracts.
      </ErrorContainer>
    );
  }

  return (
    <PreviewTable
      rows={data.contracts}
      columns={CONTRACTS_COLUMNS}
      addRow={{
        text: "Add Contract",
        linkTo: (location) => ({
          pathname: `${url}/new`,
          state: { from: location.pathname },
        }),
      }}
    />
  );
}
