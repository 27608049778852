import { MiniTag, MiniTagProps } from "@themis/ui";
import { capitalize } from "lodash";
import React from "react";

const colorMap: Record<string, MiniTagProps["color"]> = {
  vendor: "purple",
  partner: "mint",
} as const;

export function AccountTypeTag({ type }: { type: string }) {
  return <MiniTag color={colorMap[type]}>{capitalize(type)}</MiniTag>;
}
