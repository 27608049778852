import { SelectProps } from "@themis/ui";
import React from "react";

import { StatusTag } from "../components/StatusTag";

export const STATUS_RESULT = [
  {
    label: "Unreviewed",
    value: "Unreviewed",
    id: "0",
  },
  {
    label: "False Positive",
    value: "False Positive",
    id: "1",
  },
  {
    label: "Keep Reviewing",
    value: "Keep Reviewing",
    id: "2",
  },
  {
    label: "Remediated",
    value: "Remediated",
    id: "3",
  },
];

export const ASSET_TYPE: SelectProps["items"] = [
  {
    label: "WEBSITE",
    value: "WEBSITE",
    Component: ({ value }) => <StatusTag status={value} />,
  },
  {
    label: "SOCIAL MEDIA",
    value: "SOCIAL_MEDIA",
    Component: ({ value }) => <StatusTag status={value} />,
  },
  {
    label: "ADS",
    value: "ADS",
    Component: ({ value }) => <StatusTag status={value} />,
  },
  {
    label: "REVIEWS",
    value: "REVIEWS",
    Component: ({ value }) => <StatusTag status={value} />,
  },
  {
    label: "BRAND",
    value: "BRAND",
    Component: ({ value }) => <StatusTag status={value} />,
  },
  {
    label: "WEBPAGE CONTENT CHANGE",
    value: "WEBPAGE_CONTENT_CHANGE",
    Component: ({ value }) => <StatusTag status={value} />,
  },
];

export const ENTRY_STATUS_TYPES: SelectProps["items"] = [
  {
    label: "Unreviewed",
    value: "Unreviewed",
    Component: ({ value }) => <StatusTag status={value} />,
  },
  {
    label: "False Positive",
    value: "False Positive",
    Component: ({ value }) => <StatusTag status={value} />,
  },
  {
    label: "Keep Reviewing",
    value: "Keep Reviewing",
    Component: ({ value }) => <StatusTag status={value} />,
  },
  {
    label: "Remediated",
    value: "Remediated",
    Component: ({ value }) => <StatusTag status={value} />,
  },
];

export const STATUS_TYPES: SelectProps["items"] = [
  {
    label: "ACTIVE",
    value: "ACTIVE",
    Component: ({ value }) => <StatusTag status={value} />,
  },
  {
    label: "PENDING",
    value: "PENDING",
    Component: ({ value }) => <StatusTag status={value} />,
  },
  {
    label: "PAUSED",
    value: "PAUSED",
    Component: ({ value }) => <StatusTag status={value} />,
  },
  {
    label: "INACTIVE",
    value: "INACTIVE",
    Component: ({ value }) => <StatusTag status={value} />,
  },
  {
    label: "Pending",
    value: "Pending",
    Component: ({ value }) => <StatusTag status={value} />,
  },
  {
    label: "Addressed",
    value: "Addressed",
    Component: ({ value }) => <StatusTag status={value} />,
  },
];

export const SEVERITY_TYPES: SelectProps["items"] = [
  {
    label: "HIGH",
    value: "HIGH",
    Component: ({ value }) => <StatusTag status={value} />,
  },
  {
    label: "MEDIUM",
    value: "MEDIUM",
    Component: ({ value }) => <StatusTag status={value} />,
  },
  {
    label: "LOW",
    value: "LOW",
    Component: ({ value }) => <StatusTag status={value} />,
  },
];
