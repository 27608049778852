import { cva } from "cva";
var defaultStyles = "tw-inline-flex tw-cursor-pointer tw-border-none tw-shrink-0 tw-items-center tw-justify-center tw-gap-1 tw-whitespace-nowrap tw-rounded-md tw-text-sm tw-font-semibold tw-font-sans tw-transition-colors tw-w-min";
var activeStyles = "active:tw-bg-neutral-500 active:tw-text-white";
var disabledStyles = "disabled:tw-bg-primaryDim-50 disabled:tw-text-neutral-300 disabled:tw-pointer-events-none";
var focusStyles = "focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring";
var buttonBaseVariants = cva({
    base: [defaultStyles, activeStyles, disabledStyles, focusStyles],
    variants: {
        color: {
            primary: "tw-bg-primary-300 hover:tw-bg-primary-500 tw-text-white",
            secondary: "tw-bg-secondary-300 hover:tw-bg-secondary-500 tw-text-white",
            tertiary: "tw-bg-primary-50 hover:tw-bg-primary-100 tw-text-neutral-500",
            grey: "tw-bg-neutral-50 hover:tw-bg-primary-25 tw-text-neutral-500",
            transparent: "tw-bg-transparent hover:tw-bg-primary-25 tw-text-neutral-500",
            transparentWhite: "tw-bg-transparent hover:tw-bg-primary-25 hover:tw-text-neutral-500 tw-text-neutral-25",
        },
    },
    defaultVariants: {
        color: "primary",
    },
});
export { buttonBaseVariants };
