import { Tabs, TabsList, TabsTrigger } from "@themis/ui";
import React from "react";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import { MODULE_ASSESSMENTS_LIST_PATH } from "../pages";

interface ModuleAssessmentTabsProps {
  RightComponent?: React.ReactNode;
}

export const RECORD_VERSION_LIST_PATH =
  `/workspaces/:workspace_id/modules/control-mapping/:record_version_id` as const;

export const AUDIT_LIST_PATH =
  `/workspaces/:workspace_id/modules/control-mapping/:record_version_id/audit_trail` as const;

export function ModuleAssessmentHeader({
  RightComponent,
}: ModuleAssessmentTabsProps) {
  const { workspace_id, record_version_id } = useParams<{
    workspace_id: string;
    record_version_id: string;
  }>();

  const { pathname } = useLocation();
  const history = useHistory();

  function handleTabChange(value: string) {
    history.push(value);
  }

  return (
    <Tabs
      className={"tw-flex tw-items-center tw-bg-neutral-25 tw-px-6"}
      onValueChange={handleTabChange}
      value={pathname}
    >
      <TabsList>
        <TabsTrigger
          value={generatePath(RECORD_VERSION_LIST_PATH, {
            workspace_id,
            record_version_id,
          })}
        >
          Record View
        </TabsTrigger>
        <TabsTrigger
          value={generatePath(MODULE_ASSESSMENTS_LIST_PATH, {
            workspace_id,
            record_version_id,
          })}
        >
          Control Assessment
        </TabsTrigger>
        <TabsTrigger
          value={generatePath(AUDIT_LIST_PATH, {
            workspace_id,
            record_version_id,
          })}
        >
          Activity
        </TabsTrigger>
      </TabsList>
      {RightComponent}
    </Tabs>
  );
}

export default ModuleAssessmentHeader;
