import { useToast } from "@themis/ui";
import React from "react";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import { ModifyContactRequest } from "@/api";
import { useAccount } from "@/api/queries/accounts";
import {
  useContact,
  useCreateContact,
  useUpdateContact,
} from "@/api/queries/contacts";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import Loading from "@/components/Loading";

import { ACCOUNT_DETAILS_PATH, ACCOUNT_ID_PARAM, CONTACT_ID_PARAM } from ".";
import { ErrorContainer } from "../../../components/ErrorContainer";
import {
  ContactInfoForm,
  ContactInfoSchema,
} from "../components/Contacts/ContactInfoForm";
import { Subtitle } from "../components/Subtitle";
import { AccountLocationState } from "./AccountDetails";

function FormContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-min-w-96 tw-flex-col tw-gap-2 tw-px-32">
      {children}
    </div>
  );
}

export function ContactDetails() {
  const history = useHistory();
  const location = useLocation<AccountLocationState>();
  const toast = useToast();

  const { accountId, contactId, workspace_id } = useParams<{
    workspace_id: string;
    [ACCOUNT_ID_PARAM]: string;
    [CONTACT_ID_PARAM]: string;
  }>();

  const {
    data: accountData,
    isPending: isAccountPending,
    error: accountError,
  } = useAccount({
    workspaceId: Number(workspace_id),
    accountId: Number(accountId),
  });

  const {
    data: contactData,
    isLoading: isContactLoading,
    error: contactError,
  } = useContact(Number(contactId));

  /**
   * Redirect to the previous page (account details page or contacts list page)
   * If there is no previous page, redirect to the account details page.
   */
  const fromLocation =
    location.state?.from ||
    generatePath(ACCOUNT_DETAILS_PATH, {
      workspace_id,
      accountId,
    });

  const { mutateAsync: createContact } = useCreateContact({
    accountId: Number(accountId),
  });

  const { mutateAsync: updateContact } = useUpdateContact({
    contactId: Number(contactId),
  });

  async function handleCreateContact(newContactData: ModifyContactRequest) {
    try {
      await createContact(newContactData);

      toast({
        content: `Contact "${newContactData.first_name} ${newContactData.last_name}" has been added!`,
        variant: "success",
      });

      history.push(fromLocation);
    } catch {
      toast({
        content: "Something went wrong. Could not create contact.",
        variant: "error",
      });
    }
  }

  async function handleUpdateContact(newContactData: ModifyContactRequest) {
    try {
      await updateContact(newContactData);

      toast({
        content: `Contact "${newContactData.first_name} ${newContactData.last_name}" has been updated!`,
        variant: "success",
      });

      history.push(fromLocation);
    } catch {
      toast({
        content: "Something went wrong. Could not update contact.",
        variant: "error",
      });
    }
  }

  const handleSubmitForm = async (values: ContactInfoSchema) => {
    if (!contactData) {
      await handleCreateContact(values);

      return;
    }

    await handleUpdateContact(values);
  };

  const header = (
    <Header
      backButtonLinkTo={fromLocation}
      title={
        <div className="tw-align-center tw-flex tw-gap-1">
          Accounts
          {!(isAccountPending || accountError) && (
            <Subtitle>{accountData.account.name}</Subtitle>
          )}
          {!(isContactLoading || contactError) && (
            <Subtitle>{contactData?.contact?.full_name || "Untitled"}</Subtitle>
          )}
        </div>
      }
    />
  );

  if (isContactLoading || contactError) {
    return (
      <PageLayout>
        {header}
        <PageContent>
          {isContactLoading ? (
            <Loading loadingLayout="small-table" />
          ) : (
            <ErrorContainer backButtonProps={{ linkTo: fromLocation }}>
              Could not load contact details
            </ErrorContainer>
          )}
        </PageContent>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      {header}
      <PageContent>
        <FormContainer>
          <h2 className="tw-text-lg tw-font-semibold tw-text-neutral-500">
            Contact Info
          </h2>
          <ContactInfoForm
            defaultValues={contactData?.contact}
            onSubmit={handleSubmitForm}
          />
        </FormContainer>
      </PageContent>
    </PageLayout>
  );
}
