import { zodResolver } from "@hookform/resolvers/zod";
import {
  Avatar,
  Button,
  DatePicker,
  FilePicker,
  FilePickerProps,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectProps,
  TextArea,
  TextInput,
} from "@themis/ui";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { Finding, findingRiskLevel, findingSource } from "@/api";
import { useCompanyUsers } from "@/api/queries/users";
import { useMainStore } from "@/contexts/Store";
import { zodDateCheck } from "@/features/accounts/utils";
import { RiskLevelTypeTag } from "@/features/findings/components/RiskLevelTypeTag";
import { SourceTypeTag } from "@/features/findings/components/SourceTypeTag";
import { getValues } from "@/utils/utils";

const SOURCE_TYPES: SelectProps["items"] = [
  {
    label: findingSource.Compliance,
    value: findingSource.Compliance,
    Component: ({ value }) => <SourceTypeTag type={value} />,
  },
  {
    label: findingSource.Audit,
    value: findingSource.Audit,
    Component: ({ value }) => <SourceTypeTag type={value} />,
  },
  {
    label: findingSource.Regulatory,
    value: findingSource.Regulatory,
    Component: ({ value }) => <SourceTypeTag type={value} />,
  },
];

const RISK_LEVEL_TYPES: SelectProps["items"] = [
  {
    label: findingRiskLevel.Low,
    value: findingRiskLevel.Low,
    Component: ({ value }) => <RiskLevelTypeTag type={value} />,
  },
  {
    label: findingRiskLevel.Medium,
    value: findingRiskLevel.Medium,
    Component: ({ value }) => <RiskLevelTypeTag type={value} />,
  },
  {
    label: findingRiskLevel.High,
    value: findingRiskLevel.High,
    Component: ({ value }) => <RiskLevelTypeTag type={value} />,
  },
];

const formSchema = z.object({
  name: z.string().min(1, "Title is required"),
  description: z.string().min(1, "Description is required"),
  source: z.enum(getValues(findingSource)).optional(),
  risk_level: z.enum(getValues(findingRiskLevel), {
    required_error: "Risk Level is required",
  }),
  date_identified: z
    .string({ required_error: "Date Identified is required" })
    .refine(zodDateCheck),
  due_date: z
    .string({ required_error: "Due Date is required" })
    .refine(zodDateCheck),
  owner_id: z.number().optional(),
  action_plan_title: z.string().optional(),
  supporting_evidence_attributes: z
    .object({
      id: z.number().optional(),
      file: z.string().optional(),
      _destroy: z.boolean().optional(),
    })
    .optional(),
});

type FindingInfoSchema = z.infer<typeof formSchema>;

function FindingInfoForm({
  defaultValues,
  onSubmit,
}: {
  onSubmit: (values: FindingInfoSchema) => void;
  defaultValues?: Finding;
}) {
  const mainStore = useMainStore();
  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();
  const companyID = mainStore.context.companyID!;
  const { data: activeUsers } = useCompanyUsers(companyID, {
    workspace_id: Number(workspace_id),
  });
  const evidenceFilePicker = useRef<HTMLButtonElement>(null);
  const evidenceFileToUpload = useRef<File>();

  const [evidenceFile, setEvidenceFile] = useState<FilePickerProps["file"]>(
    defaultValues?.supporting_evidence?.file
      ? {
          name: defaultValues?.supporting_evidence.file.file_name,
          url: defaultValues?.supporting_evidence.file.file_url,
          type: defaultValues?.supporting_evidence.file.content_type,
        }
      : undefined,
  );

  const form = useForm<FindingInfoSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: defaultValues?.name || "",
      description: defaultValues?.description || "",
      source: defaultValues?.source || undefined,
      risk_level: defaultValues?.risk_level || undefined,
      date_identified: defaultValues?.date_identified || undefined,
      due_date: defaultValues?.due_date || undefined,
      owner_id: defaultValues?.owner?.id || undefined,
      action_plan_title: defaultValues?.action_plan_title || undefined,
      supporting_evidence_attributes: defaultValues?.supporting_evidence?.file
        ?.signed_id
        ? { file: defaultValues.supporting_evidence.file.signed_id }
        : undefined,
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    form.handleSubmit(onSubmit)();
  };

  function handleUploadSuccess(signedIds: string[]) {
    if (!evidenceFileToUpload.current) {
      return;
    }

    evidenceFilePicker.current?.click();

    setEvidenceFile(evidenceFileToUpload.current);

    form.setValue("supporting_evidence_attributes.file", signedIds[0]);

    evidenceFileToUpload.current = undefined;
  }

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmit}
        className="tw-grid tw-grid-cols-2 tw-gap-x-6 tw-gap-y-3"
      >
        <FormField
          required
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          required
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <TextArea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="source"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Source</FormLabel>
              <FormControl>
                <Select
                  items={SOURCE_TYPES}
                  selected={field.value}
                  onSelect={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          required
          control={form.control}
          name="risk_level"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Risk Level</FormLabel>
              <FormControl>
                <Select
                  items={RISK_LEVEL_TYPES}
                  selected={field.value}
                  onSelect={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          required
          control={form.control}
          name="date_identified"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Date Identified</FormLabel>
              <FormControl>
                <DatePicker
                  closeOnDateSelect
                  calendarProps={{
                    mode: "single",
                    // Need to parseISO to avoid accidentally moving a day
                    // forward/backward because new Date() will convert to UTC timezone
                    selected: field.value ? parseISO(field.value) : undefined,
                    onSelect: (date) =>
                      date && field.onChange(format(date, "yyyy-MM-dd")),
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          required
          control={form.control}
          name="due_date"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Due Date</FormLabel>
              <FormControl>
                <DatePicker
                  closeOnDateSelect
                  calendarProps={{
                    mode: "single",
                    // Need to parseISO to avoid accidentally moving a day
                    // forward/backward because new Date() will convert to UTC timezone
                    selected: field.value ? parseISO(field.value) : undefined,
                    onSelect: (date) =>
                      date && field.onChange(format(date, "yyyy-MM-dd")),
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="owner_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Owner</FormLabel>
              <FormControl>
                <Select
                  searchable
                  items={
                    activeUsers?.data.map(
                      ({ id, full_name, initials, icon_color_index }) => ({
                        label: full_name || "",
                        value: String(id),
                        Component: () => (
                          <div className="tw-flex tw-items-center tw-gap-2">
                            <Avatar size="md" colorIndex={icon_color_index}>
                              {initials}
                            </Avatar>
                            {full_name}
                          </div>
                        ),
                      }),
                    ) || []
                  }
                  selected={field.value ? String(field.value) : undefined}
                  onSelect={(value) => field.onChange(Number(value))}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="action_plan_title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Action Plan Title</FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="supporting_evidence_attributes"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Supporting Evidence</FormLabel>
              <FormControl>
                <DirectUploadProvider
                  onSuccess={handleUploadSuccess}
                  render={({ handleUpload, uploads }) => {
                    return (
                      <FilePicker
                        ref={evidenceFilePicker}
                        className={classNames({
                          "tw-bg-warning-50 tw-text-warning-300":
                            fieldState.error,
                        })}
                        isLoading={["uploading", "waiting"].includes(
                          uploads[0]?.state,
                        )}
                        percentage={Math.round(uploads[0]?.progress) || 0}
                        onSelectFile={(selectedFile) => {
                          // DirectUploadProvider does not return file onSuccess so we have to save a ref of it to use it in handleUploadSuccess
                          evidenceFileToUpload.current = selectedFile;
                          handleUpload([selectedFile]);
                        }}
                        onRemoveFile={() => {
                          setEvidenceFile(undefined);
                          field.onChange(
                            // If file exists, API expects this request body to delete it
                            defaultValues?.supporting_evidence?.id && {
                              id: defaultValues.supporting_evidence.id,
                              _destroy: true,
                            },
                          );
                        }}
                        file={evidenceFile}
                      />
                    );
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          className="tw-col-span-2 tw-mt-5 tw-place-self-end"
          type="submit"
        >
          Save Record
        </Button>
      </form>
    </Form>
  );
}

export default observer(FindingInfoForm);
export { type FindingInfoSchema };
