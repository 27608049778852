import "./todo-items.scss";

import classNames from "classnames";
import { uniqueId } from "lodash";
import React, { useEffect, useState } from "react";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import { PiPlusBold } from "react-icons/pi";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { CreateTodoRequest, todosService, UpdateTodoRequest } from "@/api";
import { Button, Icon } from "@/components/Elements";
import LoadingWrapper from "@/components/loading-wrapper";
import ListTitleSettings from "@/components/table/settings/ListTitleSettings";
import { UsersCircle } from "@/components/users-circle";
import { useMainStore } from "@/contexts/Store";
import LongTextField from "@/features/vendor-due-diligence-contracts/components/long-text-field/LongTextField";
import { useLoadableData } from "@/hooks/useLoadableData";
import { User } from "@/stores/types/user-types";

import TodoItemsRow from "../components/TodoItemsRow";
import TodoStatusTag from "../components/TodoStatusTag";
import {
  detailFields,
  initialTodosValues,
  todoFields,
  todoItemFields,
} from "../constants";
import { TodosFormValues } from "../types";
import TodoItemsCreatedForm from "./TodoItemsCreatedForm";

function TodosDetailView() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const history = useHistory();
  const location = useLocation();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    clearErrors,
    setError,
    formState,
  } = useForm<TodosFormValues>({
    defaultValues: initialTodosValues,
  });
  const { id } = useParams<{
    id: string;
  }>();

  // Variables
  const { companyID } = mainStore.context;
  const todoID = Number(id);
  const hasCreateMatch = location.pathname.endsWith("/add-new-checklist");
  const todo = useLoadableData({
    request: () => todosService.showTodo(todoID),
    onError: () => "Error. Please refresh to try again.",
    enabled: !hasCreateMatch,
  });
  const todoData = todo.data?.data;
  const todoItems = todoData?.todo_items.sort((b, a) => a.id - b.id);
  const nameField = watch("name");

  const [newTasks, setNewTasks] = useState<string[]>([]);

  const [users, setUsers] = useState<User[]>([]);
  const [createdByUser, setCreatedByUser] = useState<User | undefined>();

  const { activeWorkspace } = mainStore.context;

  // Effects
  useEffect(() => {
    if (!hasCreateMatch) {
      reset({
        description: todoData?.description,
        name: todoData?.name,
        status: todoData?.status,
      });
    }
  }, [todo.data, hasCreateMatch]);

  useEffect(() => {
    const getUsers = async () => {
      const activeWorkspaceID = activeWorkspace?.id;
      if (activeWorkspaceID) {
        const usersData: User[] = await mainStore.users.indexForSettings({
          workspaceID: activeWorkspaceID,
        });
        setUsers(usersData);
      }
    };

    if (users.length === 0 && mainStore.users.users.length === 0) {
      getUsers();
    } else {
      setUsers(mainStore.users.users);
    }
  }, [activeWorkspace]);

  useEffect(() => {
    if (!createdByUser) {
      setCreatedByUser(users.find((u) => u.id === todoData?.created_by_id));
    }
  }, [todoData, users]);

  useEffect(() => {
    if (nameField) {
      clearErrors("name");
    }
  }, [nameField]);

  // Functions
  const onInvalid: SubmitErrorHandler<TodosFormValues> = () => {
    mainStore.toast.setErrorText(
      "There is something not right! Please check if all necessary fields have been filled properly!",
    );
  };

  function handleCreate(data: CreateTodoRequest) {
    // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    return todosService.createTodo(companyID, data);
  }

  function handleUpdate(data: UpdateTodoRequest) {
    // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    return todosService.updateTodo(todoID, data);
  }

  const onSubmit = async (data: TodosFormValues) => {
    if (!nameField.trim()) {
      setError("name", {});
      mainStore.toast.setErrorText("The name field cannot be empty!");

      return;
    }

    const serializeData = {
      todo: {
        ...data,
        description: data.description!,
        name: data.name!,
      },
    };

    try {
      const result = await (hasCreateMatch
        ? handleCreate(serializeData)
        : handleUpdate(serializeData));

      if (hasCreateMatch) {
        await history.push(`/todos/${result.data?.id}`);
      } else {
        await todo.refetch();
      }

      mainStore.toast.setInfoText(
        `Checklist was successfully ${hasCreateMatch ? "created" : "updated"}.`,
      );
    } catch (error) {
      mainStore.toast.setErrorText(
        `An error occurred. Could not ${
          hasCreateMatch ? "create" : "update"
        } checklist`,
      );
      window.console.log(error);
    }
  };

  const addNewRow = () => {
    setNewTasks((prev) => [uniqueId(), ...prev]);
  };

  const removeNewRow = (rowId: string) => {
    setNewTasks((prev) => prev.filter((i) => i !== rowId));
  };

  // Components
  function RenderHeader() {
    return (
      <div className="list-title-table">
        <div className="table-column-reorder">
          <div className="list-title-table-wrap">
            <span className="tw-mr-9" />

            {todoItemFields?.map((field) => (
              <ListTitleSettings
                key={field.field_name}
                title={field.display_name}
                width={800}
              />
            ))}
            <span className="stretch-cell" />
          </div>
          <div className="vendor-contracts__table-title-indent" />
        </div>
      </div>
    );
  }

  return (
    <LoadingWrapper
      loading={!todo.data?.data && todo.loading}
      errorText={todo.errorText}
      loadingLayout="small-table"
    >
      <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
        <div className={classNames("detail-view", "template", {})}>
          {!hasCreateMatch && (
            <span className="tw-mb-8 tw-flex tw-gap-2">
              <div className="tw-h-fit tw-w-fit tw-rounded-md tw-border tw-border-solid tw-border-neutral-50 tw-bg-neutral-50 tw-px-2.5 tw-py-1 tw-text-neutral-500">
                <p className="tw-inline tw-font-semibold">Status</p>
                <span className="tw-pl-2">
                  <TodoStatusTag value={todoData?.status || ""} />
                </span>
              </div>
              <div className="tw-flex tw-h-fit tw-w-fit tw-rounded-md tw-border tw-border-solid tw-border-neutral-50 tw-bg-neutral-50 tw-px-[10px] tw-py-[3px] tw-text-neutral-500">
                <p className="tw-mr-1 tw-inline tw-font-semibold tw-leading-relaxed">
                  Created By
                </p>
                <UsersCircle
                  initials={createdByUser?.initials}
                  iconColorIndex={createdByUser?.icon_color_index}
                />
                <p className="tw-pl-2 tw-leading-relaxed tw-text-neutral-300">
                  {createdByUser?.full_name}
                </p>
              </div>
            </span>
          )}
          <div>
            <p className="tw-text-lg tw-font-semibold tw-text-neutral-500">
              Checklist Detail
            </p>
          </div>

          <div className="section-row two-cols">
            {todoFields
              .filter((f) => detailFields.includes(f.field_name))
              .map((field) => {
                const name = field.field_name as keyof TodosFormValues;
                return (
                  <div
                    key={field.field_name}
                    className={`column column-${field.data_type}`}
                  >
                    <h4>{field.display_name}</h4>
                    <LongTextField
                      width="100%"
                      name={name}
                      control={control}
                      hasDetailView
                      twoRows
                    />
                  </div>
                );
              })}
          </div>
          <Button
            className="detail-view__button-save"
            label={hasCreateMatch ? "Save Checklist" : "Update Checklist"}
            disabled={!formState.isDirty}
          />
        </div>
      </form>

      {!hasCreateMatch && (
        <div className="detail-view">
          {todoItems && (
            <div className="tw-pt-4">
              <span className="tw-flex tw-text-lg tw-font-semibold tw-text-neutral-500">
                Related Tasks{" "}
                <p className="tw-ml-2 tw-text-sm tw-font-semibold tw-leading-[1.75rem] tw-text-neutral-300">
                  {todoItems.filter((i) => i.completed).length}/
                  {todoItems.length}
                  {` Tasks`}
                </p>
              </span>
              {todoItems?.length > 0 && <RenderHeader />}
              {newTasks.map((task) => (
                <div className="tw-mb-1 tw-ml-9 tw-flex tw-w-fit" key={task}>
                  <div className="tw-w-[800px]">
                    <TodoItemsCreatedForm
                      refetch={() => {
                        todo.refetch();
                        removeNewRow(task);
                      }}
                      todoID={todoID}
                    />
                  </div>
                  <div className="list-points tw-ml-1">
                    <button onClick={() => removeNewRow(task)}>
                      <Icon name="trash" color={"generalDark"} size="de" />
                    </button>
                  </div>
                </div>
              ))}
              {todoItems?.map((todoItem) => {
                return (
                  <TodoItemsRow
                    key={todoItem.id}
                    todoItem={todoItem}
                    refetch={todo.refetch}
                  />
                );
              })}
              <div
                className="tw-sticky tw-left-[0px] tw-ml-[0px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
                onClick={addNewRow}
                data-testid="add-new-record-button"
              >
                <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
                <div className="tw-neutral-300 tw-text-sm">Add Task...</div>
              </div>
            </div>
          )}
        </div>
      )}
    </LoadingWrapper>
  );
}

export default TodosDetailView;
