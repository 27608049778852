import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListOfAccountContactsPathParams,
  ListOfAccountContactsQueryResponse,
} from "../../models/ListOfAccountContacts";

/**
 * @description List of account contacts
 * @summary List of account contacts
 * @link /accounts/:account_id/contacts
 */
export async function listOfAccountContacts(
  accountId: ListOfAccountContactsPathParams["account_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListOfAccountContactsQueryResponse>["data"]> {
  const res = await client<ListOfAccountContactsQueryResponse>({
    method: "get",
    url: `/accounts/${accountId}/contacts`,
    ...options,
  });
  return res.data;
}
