import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { MiniTag } from "../Elements";
import ExportUsers from "./ExportUsers";
import ImportUsers from "./ImportUsers";

function CompanySettingsNavigation() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const { pathname } = useLocation();

  const { isIW, isCompanySuperAdmin, isCompanyAdmin } = mainStore.context;
  const isAllUsers = pathname === "/settings/users" && isIW;
  const isUsers = pathname.startsWith("/settings/users") || isAllUsers;
  const isContacts = pathname.startsWith("/settings/contacts");
  const displayImportButton = isContacts || isUsers;

  let tabName;

  if (isAllUsers) {
    tabName = "allUsers";
  } else if (isUsers) {
    tabName = "users";
  } else if (isContacts) {
    tabName = "contacts";
  }
  return (
    <div
      className={classNames(
        "settings-links-wrap settings-links-wrap__overflow-scroll-horizontal",
        {
          "tw-mb-0": ["allUsers", "users", "contacts"].includes(tabName || ""),
        },
      )}
      data-testid="company-settings-navigation"
    >
      <ul>
        {isIW ? (
          <>
            <li>
              <NavLink to="/settings/users-internal">Internal Users</NavLink>
            </li>
            {(isCompanySuperAdmin || isCompanyAdmin) && (
              <li>
                <NavLink to="/settings/users">All Users</NavLink>
              </li>
            )}
          </>
        ) : (
          <li>
            <NavLink to="/settings/users">Users</NavLink>
          </li>
        )}

        <li>
          <NavLink to="/settings/contacts">Contacts</NavLink>
        </li>

        {isIW && (
          <>
            <li>
              <NavLink to="/settings/workspace">Workspace</NavLink>
            </li>
            <li>
              <NavLink to="/settings/company-info">Company Info</NavLink>
            </li>
          </>
        )}

        <li>
          <NavLink to="/settings/roles">
            Roles
            <MiniTag label="Beta" className="settings-links-wrap__badge" />
          </NavLink>
        </li>
        <li data-testid="workspace-settings-modules">
          <NavLink to="/settings/modules">Modules</NavLink>
        </li>
        <li>
          <NavLink to="/settings/configuration">Configuration</NavLink>
        </li>
        <li>
          <NavLink to="/settings/integrations">Integrations</NavLink>
        </li>
        <li>
          <NavLink to="/settings/api_auth">Sei Integration</NavLink>
        </li>
      </ul>

      {displayImportButton && (
        <div className="import-export-container">
          {/* @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
          <ExportUsers filename={tabName} />
          <ImportUsers isUsers={isUsers} />
        </div>
      )}
    </div>
  );
}

export default observer(CompanySettingsNavigation);
