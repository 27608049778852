import {
  Button,
  Checkbox,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@themis/ui";
import React from "react";
import { PiFadersBold } from "react-icons/pi";

import { TASK_STATUSES } from "../config/status";
import { FilterOperand } from "../hooks/useFilterSort";
import { useMyTasksFilterSort } from "../hooks/useMyTasksFilterSort";

export default function StatusFilterButton() {
  const { filters, addFilter } = useMyTasksFilterSort();

  function handleStatusToggle(status: string, checked: boolean | string) {
    if (!filters || !filters.status) {
      throw new Error("Status filter should always be present");
    }

    if (filters?.status?.value?.length === 1 && checked === false) {
      return; // need at least one status filter in current implmementation
    }

    if (checked) {
      addFilter("status", {
        operand: FilterOperand.ANY,
        value: [...(filters.status.value as string[]), status],
      });
    } else {
      addFilter("status", {
        operand: FilterOperand.ANY,
        value: (filters.status.value as string[]).filter((v) => v !== status),
      });
    }
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button LeftIcon={PiFadersBold}>Filters</Button>
      </PopoverTrigger>
      <PopoverContent className="tw-grid tw-gap-2 tw-p-2">
        {TASK_STATUSES.map((status) => (
          <div className="tw-flex tw-items-center tw-gap-2" key={status.value}>
            <Checkbox
              checked={filters?.status?.value?.includes(status.value)}
              onCheckedChange={(checked) =>
                handleStatusToggle(status.value, checked)
              }
            />
            <Label className="tw-text-nowrap tw-text-sm tw-text-neutral-500">
              Status is {status.label}
            </Label>
          </div>
        ))}
      </PopoverContent>
    </Popover>
  );
}
