import React from "react";
import { Cell, Pie, PieChart as RechartPieChart } from "recharts";
export var PieChart = function (_a) {
    var data = _a.data, centerValue = _a.centerValue;
    return (React.createElement("div", { className: "tw-relative tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center" },
        centerValue && (React.createElement("div", { className: "tw-absolute tw-z-10 -tw-ml-[105px] tw-flex tw-h-[135px] tw-w-[135px] tw-items-center tw-justify-center tw-text-2xl tw-font-semibold tw-text-neutral-500" }, centerValue)),
        React.createElement("div", { className: "tw-relative tw-flex tw-h-full tw-items-center tw-justify-center tw-gap-5" },
            React.createElement("div", { className: "tw-relative tw-h-[135px] tw-w-[135px]" },
                React.createElement(RechartPieChart, { height: 135, width: 135, className: "tw-rounded-full tw-bg-white tw-shadow-lg" },
                    React.createElement(Pie, { data: data, innerRadius: 40, outerRadius: 64, paddingAngle: 0, dataKey: "value" }, data.map(function (entry, index) { return (React.createElement(Cell, { key: "cell-".concat(index), fill: entry.color })); })))),
            React.createElement("div", { className: "tw-flex tw-flex-col tw-gap-2" }, data.map(function (entry, index) { return (React.createElement("div", { key: "legend-".concat(index), className: "tw-flex tw-items-center tw-gap-[6px]" },
                React.createElement("div", { style: { backgroundColor: entry.color }, className: "tw-h-2 tw-w-2 tw-rounded-full" }),
                React.createElement("span", { className: "tw-max-w-[100px] tw-truncate tw-text-xs tw-font-medium tw-text-neutral-300" }, entry.name))); })))));
};
