import "./text-field.scss";

import classNames from "classnames";
import React, { useRef, useState } from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

import { COLORS } from "../../constants";

type Props<T extends FieldValues> = UseControllerProps<T> & {
  hasErrors?: boolean;
  width: number | string;
  disabled?: boolean;
  hasErrorClass?: string;
  locked?: boolean;
  pinned?: boolean;
  readOnly?: boolean;
  hasDetailView?: boolean;
  keyForColor?: string;
  autoFocus?: boolean;
};

function TextField<T extends FieldValues>({
  disabled,
  hasErrorClass = "has-errors",
  hasErrors = false,
  locked,
  pinned,
  readOnly,
  width,
  hasDetailView,
  keyForColor = "",
  autoFocus,
  ...controller
}: Props<T>) {
  // State
  const [isActive, setIsActive] = useState(false);

  // Constants
  const { field, fieldState } = useController(controller);
  const text = useRef<HTMLInputElement>(null);
  const cellRef = useRef<HTMLLIElement>(null);
  const cellTextRef = useRef<HTMLDivElement>(null);

  // Functions
  const onBlur = () => {
    setIsActive(false);
  };

  const handleClick = () => {
    if (!disabled) {
      setIsActive(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event.target.value);
  };

  // Elements
  const liClassNames = classNames("cell input-cell", {
    active: isActive,
    [hasErrorClass]: hasErrors || !!fieldState.error,
    "read-only": readOnly,
    "locked-cell": locked,
    "pointer-events-none": locked || readOnly,
    "active-outline": isActive,
    pinned,
  });

  const inputPlaceholder = locked ? "N/A" : "Type here";
  const statusColors = COLORS[keyForColor];
  const styleStatus = {
    color: statusColors?.color,
    backgroundColor: statusColors?.background,
    borderRadius: "24px",
    padding: "2px 8px",
    fontWeight: 400,
  };

  if ((locked || !isActive) && !hasDetailView) {
    return (
      <li
        style={{ width }}
        className={liClassNames}
        ref={cellRef}
        onClick={handleClick}
      >
        <div className="cell-content status-color">
          <div
            className={classNames({
              placeholder: !field.value,
            })}
            ref={cellTextRef}
          >
            <span style={keyForColor ? styleStatus : {}}>{field.value}</span>
          </div>
        </div>
      </li>
    );
  }

  return (
    <li style={{ width }} className={liClassNames} onBlur={onBlur}>
      <div className="cell-content">
        <input
          ref={text}
          readOnly={readOnly || locked}
          className={classNames("cell-text", {
            "cell-locked": locked,
          })}
          disabled={disabled}
          value={field.value}
          onChange={handleChange}
          placeholder={inputPlaceholder}
          autoFocus={autoFocus}
        />
      </div>
    </li>
  );
}

export default TextField;
