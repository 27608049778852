import React from "react";

import { useTasks } from "@/api/queries/tasks";
import { useMainStore } from "@/contexts/Store";

export function TaskCountBadge() {
  const { context } = useMainStore();
  const {
    data: tasksData,
    isPending,
    error,
  } = useTasks(Number(context.companyID));

  if (isPending) {
    return null;
  }

  if (error) {
    return null;
  }

  if (!tasksData || !tasksData.data) {
    return null;
  }

  const incompleteTaskCount = tasksData.data.filter(
    (task) =>
      !task.archived_at &&
      task.status !== "Done" &&
      context.mainStore.users.user.id === task.assignee_id,
  ).length;

  if (!incompleteTaskCount) {
    return null;
  }

  return (
    <span className="tw-h-[15px] tw-rounded-full tw-bg-warning-300 tw-px-1.5 tw-text-center tw-text-[11px] tw-text-white">
      {incompleteTaskCount}
    </span>
  );
}
