import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListThemisRecordColumnsPathParams,
  ListThemisRecordColumnsQueryResponse,
} from "../../models/ListThemisRecordColumns";

/**
 * @description Fetch all columns for specific ThemisRecord in Workspace
 * @summary Fetch columns for ThemisRecord
 * @link /workspaces/:workspace_id/themis_records/:themis_record_class/columns
 */
export async function listThemisRecordColumns(
  workspaceId: ListThemisRecordColumnsPathParams["workspace_id"],
  themisRecordClass: ListThemisRecordColumnsPathParams["themis_record_class"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListThemisRecordColumnsQueryResponse>["data"]> {
  const res = await client<ListThemisRecordColumnsQueryResponse>({
    method: "get",
    url: `/workspaces/${workspaceId}/themis_records/${themisRecordClass}/columns`,
    ...options,
  });
  return res.data;
}
