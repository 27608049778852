import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import { attachmentFileType, RecordVersion } from "@/api";
import {
  getAllAttachments,
  getFirstAttachment,
  getLatestAttachment,
  hasFigmaAttachment,
  maxAttachmentVersion,
} from "@/components/helpers/AttachmentGroupsHelper";
import { useMainStore } from "@/contexts/Store";

import arrowDownBlack from "../../../../../images/table-image/icon/arrow-down-black.svg";
import arrowDown from "../../../../../images/table-image/icon/arrow-down-light-blue.svg";
import commentActiveIcon from "../../../../../images/table-image/icon/comment-white.svg";
import { formatDate, stringToDate } from "../../../../helpers/DateFormatters";
import { getApproversForCurrentStep } from "../../helpers/approvers";
import UploadDate from "../../UploadDate";
import UsersCircle from "../../UsersCircle";
import MultipleApproversPopup from "./MultipleApproversPopup";

type Props = {
  commentsMode?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchFile?: (...args: any[]) => any;
  handleChangeVersion: (
    newAttachmentID: number,
    newCommentsMode?: boolean,
  ) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleCommentsToggle?: (...args: any[]) => any;

  recordVersion: RecordVersion;
  renderBackToSubmiter?: React.ReactNode;
  renderCommentsFigmaSwitch?: React.ReactNode;
  renderPushToApprovers?: React.ReactNode;
  renderTakeScreenshot?: React.ReactNode;
};

function MultipleAttachmentsToolbar({
  recordVersion,
  fetchFile,
  handleChangeVersion,
  commentsMode,
  handleCommentsToggle,
  renderCommentsFigmaSwitch,
  renderBackToSubmiter,
  renderPushToApprovers,
  renderTakeScreenshot,
}: Props) {
  // States
  const [isAttachmentSelectionOpen, setIsAttachmentSelectionOpen] =
    useState(false);
  const [isVersionsPopUpOpen, setIsVersionsPopUpOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [denyReason, setDenyReason] = useState("");
  const [showMessagePopup, setShowMessagePopup] = useState(false);

  // Import MobX stores
  const mainStore = useMainStore();
  const {
    attachmentID: currentAttachmentID,
    attachmentGroupID: currentAttachmentGroupID,
  } = mainStore.files;
  const { list: attachmentGroups } = mainStore.attachmentGroups;
  const { themisModuleIdentifier, workspaceID } = mainStore.context;
  const { hasModuleWriteAccess } = mainStore.userPermissions;

  // Variables
  const history = useHistory();
  const allAttachments = getAllAttachments(attachmentGroups);
  const currentAttachment = allAttachments.find(
    (attachment) => attachment.id === currentAttachmentID,
  );
  const currentAttachmentGroup = attachmentGroups.find(
    (attachmentGroup) => attachmentGroup.id === currentAttachmentGroupID,
  );
  const latestAttachmentVersion = maxAttachmentVersion(
    currentAttachmentGroup?.attachments,
  );
  const isLatestVersion =
    currentAttachment?.version === latestAttachmentVersion;

  const hasFigmaAttachmentGroup = hasFigmaAttachment(attachmentGroups);
  const isFigmaActiveFile =
    currentAttachment?.file_type === attachmentFileType.figma;
  const isMarketingModule = themisModuleIdentifier === "marketing";
  const isVDD = themisModuleIdentifier === "vendor_due_diligence";
  const shouldShowVersions = [
    "policy",
    "procedures",
    "marketing",
    "documents",
    "training",
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  ].includes(themisModuleIdentifier);

  // This logic is specific to the marketing module and if we have other modules that use this component, we will need to change this logic
  const currentUserID = mainStore.users.user.id;
  const currentUserRecordVersionReview = recordVersion?.reviews?.find(
    (item) => item.user_id === currentUserID,
  );
  const approversIDs = mainStore.avroSchemas.valueForField(
    "approvers",
    recordVersion.data,
  );
  const currentStepAproversIDs = getApproversForCurrentStep(recordVersion);

  const isApprover =
    approversIDs?.includes(currentUserID) ||
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    currentStepAproversIDs.includes(currentUserID);

  const ownersIDs = mainStore.avroSchemas.valueForField(
    "owner",
    recordVersion.data,
  );
  const isOwner = ownersIDs?.includes(currentUserID);

  const canSeePushToApproversButton =
    isMarketingModule &&
    recordVersion.status &&
    ["new", "request_change"].includes(recordVersion.status);
  const canSeeBackToSubmitterButton =
    isMarketingModule && isApprover && recordVersion.status === "reviewing";
  const canSeeApproveButton =
    !currentUserRecordVersionReview &&
    isLatestVersion &&
    isApprover &&
    recordVersion.status &&
    ["reviewing", "approved"].includes(recordVersion.status);
  const canSeeUnapproveButton =
    Boolean(currentUserRecordVersionReview?.id) &&
    (isApprover || isOwner) &&
    recordVersion.status === "approved";
  const canSeeDenyButton =
    canSeeApproveButton && recordVersion.status === "reviewing";
  const canSeeDenyReopenButton =
    isLatestVersion && isApprover && recordVersion.status === "rejected";
  const canSeeApprovedState = Boolean(
    recordVersion?.reviews?.find(
      (review) =>
        review.user_id === currentUserID && review.review_type === "approval",
    ),
  );

  const creativeComments = classNames(
    "creative-view-comments-icon no-mr-multiple",
    { active: commentsMode && !showMessagePopup },
  );
  const { contentType } = mainStore.files;

  const isDoc = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
  ].includes(contentType);
  const isPptx =
    contentType ===
    "application/vnd.openxmlformats-officedocument.presentationml.presentation";
  const isMicrosoftPackage = isDoc || isPptx;

  // @ts-expect-error TS(7006) FIXME: Parameter 'attachmentID' implicitly has an 'any' t... Remove this comment to see the full error message
  const fetchFileHandler = async (attachmentID) => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    await fetchFile(attachmentID);
    setIsAttachmentSelectionOpen(false);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'updatedRecordVersion' implicitly has an... Remove this comment to see the full error message
  const handleRedirect = (updatedRecordVersion) => {
    const approvedMessage =
      "Approved - Marketing material moved to the Completed Tab!";
    const rejectedMessage =
      "Marketing material moved to the Completed Tab as Denied!";
    const newStatus = mainStore.avroSchemas.firstValueForField(
      "status",
      updatedRecordVersion?.data,
      // @ts-expect-error TS(2345) FIXME: Argument of type 'Field[]' is not assignable to pa... Remove this comment to see the full error message
      mainStore.creatives.fields,
    );

    switch (newStatus) {
      case "approved":
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ showThumbsUp: boolean; }' is n... Remove this comment to see the full error message
        mainStore.toast.setInfoText(approvedMessage, { showThumbsUp: true });
        mainStore.creatives.index({
          workspaceID,
          tab: "Completed",
        });
        history.push(`/workspaces/${workspaceID}/modules/marketing/completed`);
        break;

      case "rejected":
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ showThumbsUp: boolean; }' is n... Remove this comment to see the full error message
        mainStore.toast.setInfoText(rejectedMessage, { showThumbsUp: true });
        mainStore.creatives.index({
          workspaceID,
          tab: "Completed",
        });
        history.push(`/workspaces/${workspaceID}/modules/marketing/completed`);
        break;

      case "reviewing":
        mainStore.creatives.index({
          workspaceID,
          tab: "Active",
        });
        history.push(`/workspaces/${workspaceID}/modules/marketing`);
        break;

      default:
        break;
    }

    mainStore.pageLoading.endLoading();
  };

  const handleApprove = async () => {
    const approvedRecordVersion = await mainStore.creatives.approve(
      recordVersion.id,
    );
    handleRedirect(approvedRecordVersion);
  };

  const handleUnlock = () => {
    mainStore.creatives.unlock(recordVersion.id);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const changeDenyReason = (event) => {
    setDenyReason(event.target.value);
  };

  const updateDenyReason = async () => {
    if (recordVersion.id) {
      await mainStore.recordVersions.update({
        fieldName: "deny_reason",
        recordVersionID: recordVersion.id,
        value: mainStore.avroSchemas.serializeValue("deny_reason", denyReason),
      });

      const rejectedRecordVersion = await mainStore.creatives.deny(
        recordVersion.id,
      );
      handleRedirect(rejectedRecordVersion);
    }

    mainStore.pageLoading.endLoading();
  };

  const handleVersionClick = async (attachmentId: number) => {
    await handleChangeVersion(attachmentId);
    setIsVersionsPopUpOpen(false);
  };

  const renderToolbarVersions = attachmentGroups && (
    <Popup
      trigger={
        <div
          data-testid="cv-toolbar-multiple-versions-popup-trigger"
          className="version"
        >
          <span>{`V${currentAttachment?.version}${
            isLatestVersion ? " (Current)" : ""
          }`}</span>
          <img src={arrowDown} alt="arrow-down" />
        </div>
      }
      open={isVersionsPopUpOpen}
      onOpen={() => setIsVersionsPopUpOpen(true)}
      onClose={() => setIsVersionsPopUpOpen(false)}
      keepTooltipInside
    >
      <div
        className="versions-popup"
        data-testid="cv-toolbar-multiple-versions-popup-container"
      >
        {currentAttachmentGroup &&
          currentAttachmentGroup.attachments.map((attachment) => {
            const isActive = attachment.version === currentAttachment?.version;
            const isLatestAttachmentVersion =
              attachment.version === latestAttachmentVersion;

            return (
              <div
                key={attachment.id}
                data-testid="cv-toolbar-multiple-versions-popup-item"
                className={classNames("item", { active: isActive })}
                onClick={() => handleVersionClick(attachment.id)}
              >
                {`Version ${attachment.version}${
                  isLatestAttachmentVersion ? " (Current)" : ""
                }`}
              </div>
            );
          })}
      </div>
    </Popup>
  );

  const renderToolbarApproval = (
    <>
      {currentUserRecordVersionReview && (
        <>
          <div
            className="approved-by"
            data-testid="cv-toolbar-multiple-approved-by"
          >
            {approversIDs?.length === 1 ? (
              <UsersCircle
                user={mainStore.users.user}
                // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
                additionalText={formatDate(
                  stringToDate(currentUserRecordVersionReview?.approved_at),
                )}
              />
            ) : (
              <MultipleApproversPopup recordVersion={recordVersion} />
            )}
          </div>
          {canSeeUnapproveButton && (
            <button
              onClick={handleUnlock}
              type="button"
              className="unapprove"
              data-testid="comment-sidebar-trigger-unapprove"
            >
              Reopen
            </button>
          )}
        </>
      )}

      {canSeeApproveButton && (
        <button
          type="button"
          className={classNames("approve", {
            "mr-l":
              !currentUserRecordVersionReview &&
              recordVersion.status === "Approved",
          })}
          onClick={handleApprove}
          data-testid="cv-toolbar-multiple-approve"
        >
          Approve
        </button>
      )}
    </>
  );

  const renderToolbarDeniedLabel = (
    <div
      className="denied-label"
      data-testid="cv-toolbar-multiple-rejected-label"
    >
      This creative was denied!
    </div>
  );

  const renderToolbarDeny = (
    <>
      {canSeeDenyReopenButton && (
        <button
          onClick={handleUnlock}
          type="button"
          className="unapprove mr-l"
          data-testid="comment-sidebar-trigger-reopen"
        >
          Reopen
        </button>
      )}

      {canSeeDenyButton && (
        <div>
          <Popup
            position="bottom right"
            trigger={() => (
              <button
                type="button"
                className={classNames("deny", { active: showPopup })}
                data-testid="cv-toolbar-multiple-deny-popup"
              >
                Deny
              </button>
            )}
            keepTooltipInside
            open={showPopup}
            onOpen={() => setShowPopup(true)}
            onClose={() => setShowPopup(false)}
          >
            <div className="table-dropdown file-changes ">
              <h4>Deny Reason</h4>
              <p>
                Describe why this creative has been denied. This will be saved
                within the record.
              </p>
              <textarea
                placeholder="Please type your denial reason"
                value={denyReason}
                onChange={changeDenyReason}
                data-testid="cv-toolbar-multiple-deny-textarea"
              />
              <div className="confirmation deny-confirnation">
                <button
                  type="button"
                  className="deny-confirnation"
                  data-testid="cv-toolbar-multiple-deny-confirm"
                  disabled={!denyReason || !hasModuleWriteAccess}
                  onClick={() => {
                    updateDenyReason();
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Popup>
        </div>
      )}
    </>
  );

  const renderToolbarComments = (
    <div
      className={creativeComments}
      onClick={handleCommentsToggle}
      data-testid="comment-sidebar-trigger"
    >
      <img src={commentActiveIcon} className="comments-icon" alt="comments" />
      Comment
    </div>
  );

  const renderMessageForNotSupported = (
    <Popup
      trigger={renderToolbarComments}
      position="bottom center"
      open={showMessagePopup}
      onOpen={() => setShowMessagePopup(true)}
      onClose={() => setShowMessagePopup(false)}
      keepTooltipInside
    >
      <div className="table-dropdown dark-mode">
        <span>Not available for doc / docx file types</span>
      </div>
    </Popup>
  );

  if (!attachmentGroups?.length) {
    return null;
  }

  const documentUploadDate = currentAttachment?.created_at;

  return (
    <div
      className="creative-toolbar creative-toolbar-multiple"
      data-testid="cv-toolbar-multiple-container"
    >
      <div
        className="creative-toolbar-left"
        data-testid="cv-toolbar-multiple-left"
      >
        {/* is this really what we want to do? show the dropdown when you're on a "screenshot, but not if you're on the first figma file? */}
        {!isFigmaActiveFile && (
          <>
            <Popup
              trigger={() => (
                <div>
                  {attachmentGroups.length === 1 && (
                    <div className="filename" data-testid="filename-container">
                      {currentAttachment?.original?.filename}
                    </div>
                  )}
                  {attachmentGroups.length > 1 && (
                    <div
                      data-testid="cv-toolbar-multiple-attachments-popup-trigger"
                      className={classNames("attachment-selection", {
                        "attachment-active-selection":
                          isAttachmentSelectionOpen,
                      })}
                    >
                      <div
                        className="filename-container"
                        data-testid="filename-container"
                      >
                        {" "}
                        {currentAttachment?.original?.filename}
                      </div>
                      <img src={arrowDownBlack} alt="arrow down" />
                    </div>
                  )}
                </div>
              )}
              open={isAttachmentSelectionOpen}
              onOpen={() => setIsAttachmentSelectionOpen(true)}
              onClose={() => setIsAttachmentSelectionOpen(false)}
              keepTooltipInside
              disabled={attachmentGroups.length === 1}
            >
              <div
                className="attachment-popup"
                data-testid="cv-toolbar-multiple-attachments-popup-container"
              >
                {attachmentGroups.map((attachmentGroup) => {
                  const latestAttachment = getLatestAttachment(attachmentGroup);
                  const firstAttachment = getFirstAttachment(attachmentGroup);
                  const attachmentID =
                    attachmentGroup.id === currentAttachmentGroupID
                      ? currentAttachmentID
                      : latestAttachment?.id;

                  return (
                    <div
                      key={`creative-${attachmentGroup.id}`}
                      data-testid="cv-toolbar-multiple-attachments-popup-item"
                      className={classNames("attachment-item", {
                        "attachment-item-selected":
                          attachmentID === currentAttachmentID,
                      })}
                      onClick={() => fetchFileHandler(attachmentID)}
                    >
                      {firstAttachment?.original?.filename}
                    </div>
                  );
                })}
              </div>
            </Popup>
            {shouldShowVersions && renderToolbarVersions}
          </>
        )}
      </div>

      <div
        className="creative-toolbar-center"
        data-testid="cv-toolbar-multiple-center"
      >
        {isMarketingModule &&
          hasFigmaAttachmentGroup &&
          renderCommentsFigmaSwitch}
      </div>

      <div
        className="creative-toolbar-right"
        data-testid="cv-toolbar-multiple-right"
      >
        {/* @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
        <UploadDate date={documentUploadDate} />
        {isMarketingModule &&
          recordVersion.status === "Rejected" &&
          renderToolbarDeniedLabel}
        {isMarketingModule && canSeeApprovedState && (
          <div
            className="current-label"
            data-testid="cv-toolbar-multiple-current-label-approve"
          >
            You&#39;ve approved this creative!
          </div>
        )}
        {!isVDD &&
          isLatestVersion &&
          !isFigmaActiveFile &&
          (isMicrosoftPackage
            ? renderMessageForNotSupported
            : renderToolbarComments)}
        {isFigmaActiveFile && !commentsMode && renderTakeScreenshot}
        {canSeePushToApproversButton && renderPushToApprovers}
        {canSeeBackToSubmitterButton && renderBackToSubmiter}
        {isMarketingModule && renderToolbarApproval}
        {isMarketingModule && renderToolbarDeny}
      </div>
    </div>
  );
}

export default observer(MultipleAttachmentsToolbar);
