import { ToggleButtonGroup, ToggleButtonGroupItem } from "@themis/ui";
import classNames from "classnames";
import React from "react";
import { PiGridFourFill, PiListBulletsBold } from "react-icons/pi";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";

import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import { useMainStore } from "@/contexts/Store";
import AccountImport from "@/features/accounts/components/AccountImport";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import FavoriteButton from "@/features/misc/favorites/FavoriteButton";
import { useSearchParams } from "@/hooks/useSearchParams";

import { AccountList } from "../components/AccountsList";
import { ACCOUNT_ROUTES } from "../pages";
import AccountsHeader from "./AccountsHeader";

export type AccountSearchParams = {
  view?: AccountListView;
};

type AccountListView = "grid" | "table";

function AccountsContainer({ children }: { children: React.ReactNode }) {
  const [{ view }] = useSearchParams<AccountSearchParams>();

  return (
    <div
      className={classNames(
        "tw-flex tw-min-w-96 tw-flex-col tw-gap-4 tw-overflow-auto",
        {
          "tw-px-16": view === "grid",
        },
      )}
    >
      {children}
    </div>
  );
}

function MessageContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-self-stretch tw-rounded-md tw-border tw-border-dashed tw-border-primaryDim-300 tw-px-3 tw-py-2">
      {children}
    </div>
  );
}

export function Accounts() {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [{ view }, setSearchParams] = useSearchParams<AccountSearchParams>();

  const accountsListView = view || "table";
  const mainStore = useMainStore();

  function handleViewChange(newView: AccountListView) {
    setSearchParams({ view: newView }, true);
  }

  return (
    <PageLayout>
      <Header
        title={
          <>
            Accounts
            <FavoriteButton
              passedModuleWorkspaceID={
                mainStore.moduleWorkspaces.list.find(
                  (mw) => mw.themis_module.identifier === "accounts",
                )?.id
              }
            />
          </>
        }
        HeaderOptions={
          <ToggleButtonGroup
            size="lg"
            type="single"
            value={accountsListView}
            onValueChange={handleViewChange}
          >
            <ToggleButtonGroupItem aria-label="Grid View" value="grid">
              <PiGridFourFill className="tw-h-[18px] tw-w-[18px]" />
            </ToggleButtonGroupItem>
            <ToggleButtonGroupItem aria-label="Table View" value="table">
              <PiListBulletsBold className="tw-h-[18px] tw-w-[18px]" />
            </ToggleButtonGroupItem>
          </ToggleButtonGroup>
        }
      />
      <AccountsHeader RightComponent={<AccountImport />} />
      <AddRecordHeader
        addRecord={() => {
          history.push(
            generatePath(`${url}${ACCOUNT_ROUTES.details}`, {
              accountId: "new",
            }),
          );
        }}
        recordName="Account"
        wrapperClassNames="!tw-h-11 !tw-bg-white"
      />
      <PageContent>
        <AccountsContainer>
          <MessageContainer>
            <p className="tw-text-sm tw-font-semibold tw-text-primaryDim-300">
              Accounts are business entities - Vendor or Partners - used in
              modules like Vendor Due Diligence and Questionnaires.
            </p>
          </MessageContainer>
          <AccountList />
        </AccountsContainer>
      </PageContent>
    </PageLayout>
  );
}
