import { useQueryClient } from "@tanstack/react-query";
import {
  ConfirmationPopup,
  IconButton,
  LinkIconButton,
  useToast,
} from "@themis/ui";
import React, { useState } from "react";
import { PiCaretRightBold, PiTrashSimpleBold } from "react-icons/pi";
import { useParams } from "react-router-dom";

import { useDeleteIssueManagementRecord } from "@/api/queries/issueManagementRecords";
import { QA_TESTS_QUERY_KEY } from "@/api/queries/qaTests";

export function RelatedIssueActionsCell({
  id,
  recordVersionId,
  name,
  url,
  isIssueManagementModuleAdded,
}: {
  id: number;
  recordVersionId: number;
  name: string | null;
  url: string | null;
  isIssueManagementModuleAdded: boolean;
}) {
  const { findingId } = useParams<{
    findingId: string;
  }>();
  const toast = useToast();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const queryClient = useQueryClient();

  const { mutate: deleteIssue } = useDeleteIssueManagementRecord({
    findingId: Number(findingId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QA_TESTS_QUERY_KEY] });

      toast({
        content: "Related issue has been removed!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to remove related issue!",
        variant: "error",
      });
    },
  });

  async function handleDelete() {
    setIsConfirmationOpen(false);
    deleteIssue(recordVersionId);
  }

  function handleClickDelete() {
    setIsConfirmationOpen(true);
  }

  function handleCancelDelete() {
    setIsConfirmationOpen(false);
  }

  return (
    <>
      {url && isIssueManagementModuleAdded && (
        <LinkIconButton
          aria-label="Edit Related Issue"
          aria-describedby={`related-issue-id-${id}`}
          to={url}
          Icon={PiCaretRightBold}
          size="lg"
          color="tertiary"
        />
      )}
      <span id={`related-issue-id-${id}`} className="tw-hidden">
        Related Issue Name {name}
      </span>
      <ConfirmationPopup
        anchor
        align="end"
        title="Delete Related Issue"
        content="Actions are non-reversible. Are you sure you want to proceed?"
        open={isConfirmationOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
      >
        <IconButton
          aria-label="Delete Related Issue"
          aria-describedby={`related-issue-id-${id}`}
          Icon={PiTrashSimpleBold}
          disabled={!isIssueManagementModuleAdded}
          size="lg"
          color="tertiary"
          onClick={handleClickDelete}
        />
      </ConfirmationPopup>
    </>
  );
}
