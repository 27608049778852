import { StatusColors } from "./types";

export enum TodosTabs {
  TODOS = "Checklists",
  TODO_ITEMS = "Checklist Items",
}

export const todoFields = [
  {
    field_name: "status",
    display_name: "Status",
    data_type: "text",
  },
  {
    field_name: "name",
    display_name: "Checklist Name",
    data_type: "textarea",
  },
  {
    field_name: "description",
    display_name: "Description",
    data_type: "textarea",
  },
  {
    field_name: "created_by",
    display_name: "Created By",
    data_type: "textarea",
  },
  {
    field_name: "related_tasks",
    display_name: "Related Tasks",
    data_type: "textarea",
  },
];

export const todoItemFields = [
  {
    field_name: "name",
    display_name: "Task Title",
    data_type: "text",
  },
];

export const initialTodosValues = {
  description: "",
  name: "",
  status: "New",
};

export const initialTodoItemsValues = {
  name: "",
  completed: false,
};

export const detailFields = ["name", "description"];

// Colors
export const COLORS: { [key: string]: StatusColors } = {
  new: {
    background: "#ED21E5",
    color: "#fae5ff",
  },
  completed: {
    background: "#C5EEFA",
    color: "#21A9D3",
  },
  started: {
    background: "#E3E3FD",
    color: "#5C5FC8",
  },
};
