import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import { UseFiltersReturn } from "@/hooks/useFilters";
import { patternToRoute } from "@/utils/routing";

import ExportBulk from "../../dashboard/ExportBulk";
import { VDDTabs } from "./types";
import {
  activeVDDUrl,
  inactiveVDDUrl,
  RATemplatesVDDUrl,
  templatesVDDUrl,
} from "./urlPaths";

export const SCREENS = (name: string | undefined) => ({
  [VDDTabs.ACTIVE_VENDORS]: {
    label: `Active ${name}`,
    url: activeVDDUrl,
  },
  [VDDTabs.TEMPLATES]: {
    label: "Questionnaires",
    url: templatesVDDUrl,
  },
  [VDDTabs.RISK_ASSESSMENT_TEMPLATES]: {
    label: "Risk Assessment Templates",
    url: RATemplatesVDDUrl,
  },
  [VDDTabs.INACTIVE_VENDORS]: {
    label: `Inactive ${name}`,
    url: inactiveVDDUrl,
  },
});

type VendorsHeaderProps = {
  activeScreen?: string;
  filtersData?: UseFiltersReturn | Record<string, never>;
  recordName?: string;
  disableCreationForLimit: boolean;
};

function VendorsHeader({
  recordName,
  activeScreen,
  filtersData = {},
  disableCreationForLimit,
}: VendorsHeaderProps) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const history = useHistory();

  const handleAddNewVendor = () => {
    history.push(`/workspaces/${workspaceID}/modules/add-new-vendor`);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'newScreen' implicitly has an 'any' type... Remove this comment to see the full error message
  const handleScreenChange = (newScreen) => {
    history.push(
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      patternToRoute(SCREENS(recordName)[newScreen].url, {
        workspace_id: workspaceID,
      }),
    );
  };

  return (
    <>
      <div
        className={classNames("table-header-wrap", {
          "header-with-filters": filtersData.filtersViewEnabled,
        })}
      >
        <div className="buttons-block" data-testid="vdd-main-tabs">
          {Object.keys(SCREENS(recordName)).map((screen) => (
            <button
              key={screen}
              className={classNames({ active: activeScreen === screen })}
              onClick={() => handleScreenChange(screen)}
              data-testid={`vdd-tab-${screen}-trigger`}
            >
              {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
              {SCREENS(recordName)[screen].label}
            </button>
          ))}
        </div>
        <div className="add-new-block">
          {activeScreen === "ActiveVendors" && <ExportBulk />}
          {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}
          {["ActiveVendors", "InactiveVendors"].includes(activeScreen) && (
            <div className="vendor-heading-actions">
              <button
                type="button"
                className="table-button add-new-vendor-button"
                onClick={handleAddNewVendor}
                data-testid="vdd-add-new"
                disabled={disableCreationForLimit}
              >
                Add New Vendor
              </button>
              {activeScreen === "ActiveVendors" && filtersData.filtersTrigger}
            </div>
          )}
        </div>
      </div>
      {filtersData.filtersViewEnabled && (
        <div className="filters-wrap">
          <div className="switch-table-wrap" />
          {filtersData.filtersContent}
        </div>
      )}
    </>
  );
}

VendorsHeader.defaultProps = {
  recordName: "Vendors",
};

export default observer(VendorsHeader);
