import * as Sentry from "@sentry/react";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import CustomerSupportCreativePage from "@/features/customer-support/pages/CustomerSupportCreativePage";
import FinraComplaintsCreativePage from "@/features/finra/pages/FinraComplaintsCreativePage";
import IssueManagementRouter from "@/features/issue-management/pages";
import KeyRiskIndicatorsRouter from "@/features/key-risk-indicators/KeyRiskIndicatorsRouter";
import { kriUrl } from "@/features/key-risk-indicators/urlPaths";
import AuditTrailPage from "@/features/misc/pages/AuditTrailPage";
import VersionedDocumentPreviewPage from "@/features/misc/pages/VersionedDocumentPreviewPage";
import { ModuleNotificationsRouter } from "@/features/notifications";
import { MODULE_NOTIFICATIONS_BASE_PATH } from "@/features/notifications/pages/constants";
import PoliciesPage from "@/features/policy/pages/PoliciesPage";
import { RiskAssessmentRouter } from "@/features/risk-assessment";
import TrainingCreativePage from "@/features/training/pages/training/TrainingCreativeViewPage";

import ErrorBoundaryFallback from "../errors/ErrorBoundaryFallback";
import HeadSelect from "../table/shared/HeadSelect";
import DashboardContent from "./DashboardContent";

function Dashboard() {
  // store
  const mainStore = useMainStore();

  // Variables
  const history = useHistory();
  const { search, pathname } = useLocation();

  // effects
  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(search);
      const openTaskRecordId = parseInt(searchParams.get("hRID") || String(-1));

      openTaskRecordId !== -1 &&
        mainStore.context.setHighlightedRecordId(openTaskRecordId);

      const workspaceId = searchParams.get("workspace");

      // hRID and workspace came from url and they are packed together now
      if (workspaceId) {
        await mainStore.workspaces.switch(workspaceId);
        searchParams.delete("workspace");
        history.replace(`${pathname}?${searchParams.toString()}`);
      }
    })();
  }, [search]);

  return (
    <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
      <Switch>
        <Route path={kriUrl}>
          <KeyRiskIndicatorsRouter />
        </Route>
        <Route
          exact
          path="/workspaces/:workspace_id/modules/:moduleIdentifier/audit_trail"
        >
          <AuditTrailPage RightActionComponent={HeadSelect} />
        </Route>
        <Route path="/workspaces/:workspace_id/modules/:moduleIdentifier/document_preview/:record_version_id/:file_id">
          <VersionedDocumentPreviewPage />
        </Route>

        {mainStore.userPermissions.canSeeNotifications && (
          <Route path={MODULE_NOTIFICATIONS_BASE_PATH}>
            <ModuleNotificationsRouter />
          </Route>
        )}
        <Route
          key="/modules/training/attachment_view"
          path="/workspaces/:workspace_id/modules/training/attachment_view/:record_version_id/:file_id"
        >
          <TrainingCreativePage />
        </Route>
        <Route
          key="/modules/customer-support/attachment_view"
          path="/workspaces/:workspace_id/modules/customer-support/attachment_view/:record_version_id/:file_id"
        >
          <CustomerSupportCreativePage />
        </Route>
        <Route
          key="/modules/finra/attachment_view"
          path="/workspaces/:workspace_id/modules/finra/attachment_view/:record_version_id/:file_id"
        >
          <FinraComplaintsCreativePage />
        </Route>
        <Route path="/workspaces/:workspace_id/modules/issue-management/">
          <IssueManagementRouter />
        </Route>
        <Route path="/workspaces/:workspace_id/modules/policy/">
          <PoliciesPage />
        </Route>
        <Route path="/workspaces/:workspace_id/modules/risk-assessment">
          <RiskAssessmentRouter />
        </Route>
        <Route path="*">
          <DashboardContent />
        </Route>
      </Switch>
    </Sentry.ErrorBoundary>
  );
}

export default observer(Dashboard);
