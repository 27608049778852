import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import { Link, NavLink, useHistory, useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";

import { useLoading } from "../../../hooks/useLoading";
import { Icon } from "../../Elements";
import Loading from "../../Loading";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";

function IMOperationalControls() {
  // Import MobX stores
  const mainStore = useMainStore();
  const history = useHistory();

  // State
  const [fields, setFields] = useState([]);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const { company } = mainStore.companies;

  // Variables
  const { workspaceID } = mainStore.context;
  // @ts-expect-error TS(2339) FIXME: Property 'record_version_id' does not exist on typ... Remove this comment to see the full error message
  const { record_version_id: recordVersionID } = useParams();
  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === Number(recordVersionID),
  );
  const status = mainStore.avroSchemas.firstValueForField(
    "status",
    recordVersion?.data,
  );
  const isLocked = status === "closed";
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const filteredRows = rows.filter((row) => {
    return fields.find((field) => {
      // @ts-expect-error TS(2339) FIXME: Property 'computed_column_identifier' does not exi... Remove this comment to see the full error message
      if (field.computed_column_identifier === "policies_records") {
        return Boolean(
          // @ts-expect-error TS(2339) FIXME: Property 'modules_records' does not exist on type ... Remove this comment to see the full error message
          row.modules_records
            // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
            ?.find((item) => item.identifier === "policy")
            ?.records?.find(
              // @ts-expect-error TS(7006) FIXME: Parameter 'r' implicitly has an 'any' type.
              (r) => r.title?.toLowerCase()?.includes(search.toLowerCase()),
            ),
        );
      }

      // @ts-expect-error TS(2339) FIXME: Property 'computed_column_identifier' does not exi... Remove this comment to see the full error message
      if (field.computed_column_identifier === "procedures_records") {
        return Boolean(
          // @ts-expect-error TS(2339) FIXME: Property 'modules_records' does not exist on type ... Remove this comment to see the full error message
          row.modules_records
            // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
            ?.find((item) => item.identifier === "procedures")
            ?.records?.find(
              // @ts-expect-error TS(7006) FIXME: Parameter 'r' implicitly has an 'any' type.
              (r) => r.title?.toLowerCase()?.includes(search.toLowerCase()),
            ),
        );
      }

      const value = mainStore.avroSchemas.valueForField(
        // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
        field.name,
        // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type 'never'.
        row.data,
        // @ts-expect-error TS(2345) FIXME: Argument of type 'never[]' is not assignable to pa... Remove this comment to see the full error message
        fields,
      );
      if (!value) {
        return false;
      }

      // @ts-expect-error TS(2339) FIXME: Property 'data_type' does not exist on type 'never... Remove this comment to see the full error message
      if (field.data_type === "com.askthemis.types.v1.option") {
        const firstOption = mainStore.avroSchemas.firstValueForField(
          // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
          field.name,
          // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type 'never'.
          row.data,
          // @ts-expect-error TS(2345) FIXME: Argument of type 'never[]' is not assignable to pa... Remove this comment to see the full error message
          fields,
        );
        return Boolean(
          firstOption?.toLowerCase()?.includes(search.toLowerCase()),
        );
      }

      // @ts-expect-error TS(2339) FIXME: Property 'data_type' does not exist on type 'never... Remove this comment to see the full error message
      if (field.data_type === "com.askthemis.types.v1.tag_department") {
        const departments_ids = mainStore.avroSchemas.valueForField(
          // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
          field.name,
          // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type 'never'.
          row.data,
          // @ts-expect-error TS(2345) FIXME: Argument of type 'never[]' is not assignable to pa... Remove this comment to see the full error message
          fields,
        );
        return Boolean(
          departments_ids.find(
            // @ts-expect-error TS(7006) FIXME: Parameter 't' implicitly has an 'any' type.
            (t) =>
              mainStore.departments.departments
                .find((o) => o.id === t)
                ?.title?.toLowerCase()
                ?.includes(search.toLowerCase()),
          ),
        );
      }

      // @ts-expect-error TS(2339) FIXME: Property 'data_type' does not exist on type 'never... Remove this comment to see the full error message
      if (field.data_type === "com.askthemis.types.v1.tag_user") {
        const users_ids = mainStore.avroSchemas.valueForField(
          // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
          field.name,
          // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type 'never'.
          row.data,
          // @ts-expect-error TS(2345) FIXME: Argument of type 'never[]' is not assignable to pa... Remove this comment to see the full error message
          fields,
        );
        return Boolean(
          users_ids.find(
            // @ts-expect-error TS(7006) FIXME: Parameter 't' implicitly has an 'any' type.
            (t) =>
              mainStore.users.allUsers
                .find((o) => o.id === t)
                ?.full_name?.toLowerCase()
                ?.includes(search.toLowerCase()),
          ),
        );
      }

      return value?.toString()?.toLowerCase()?.includes(search.toLowerCase());
    });
  });

  // Hooks
  const loading = useLoading(fields);
  // @ts-expect-error TS(2339) FIXME: Property 'is_hidden' does not exist on type 'never... Remove this comment to see the full error message
  const onlyVisibleFields = fields.filter((field) => !field.is_hidden);

  // effects
  useEffect(() => {
    if (!workspaceID || !company?.id) {
      return;
    }

    const fetchData = async () => {
      if (!recordVersion) {
        await mainStore.issueManagement.index({ workspaceID });
      }
      const allStatus = "all";
      const data = await mainStore.issueManagement.fetchOperationalControlsData(
        recordVersionID,
        // @ts-expect-error TS(2345) FIXME: Argument of type '"all"' is not assignable to para... Remove this comment to see the full error message
        allStatus,
      );

      mainStore.controlCategories.index(company.id);
      mainStore.controlMappingTypes.index(company.id);

      setFields(data.cm_fields);

      setRows(
        // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
        data.cm_record_versions.filter((item) =>
          data.selected_record_versions_ids.includes(item.id),
        ),
      );

      mainStore.issueManagement.setOperationalControlsData(
        data.selected_record_versions_ids,
        false,
      );
      mainStore.fieldOptions.setAll(data.cm_field_options);
    };

    fetchData();
  }, [company, workspaceID]);

  useEffect(() => {
    if (!rows.length) {
      return;
    }

    setRows(
      rows.filter((item) =>
        mainStore.issueManagement.operationalControlsData.selectedIDs.includes(
          // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
          item.id,
        ),
      ),
    );
  }, [mainStore.issueManagement.operationalControlsData.selectedIDs]);

  // Rendering
  const renderSearch = (
    <div className="rr-operational-controls-search-container">
      <input
        type="text"
        placeholder="Search Control"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Icon name="search" color="generalDark" size="de" />
    </div>
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  const renderField = (field) => (
    <ModuleTableColumn key={field.name} field={field} />
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'rv' implicitly has an 'any' type.
  const renderRow = (rv) => (
    <div
      className="rr-operational-controls-row-container tw-mr-[70px]"
      key={rv.id}
    >
      <ModuleRecordVersion
        fields={onlyVisibleFields}
        isLockedRow
        recordVersion={rv}
        tableID={rv.table_id}
        tableName={rv.table_name}
        currentTableName="OperationalControls"
        moduleIdentifier="control_mapping"
      />
    </div>
  );

  const renderAddNewButton = (
    <div
      className="tw-sticky tw-left-[0px] tw-ml-[0px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
      onClick={() =>
        history.push(
          `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/operational-controls-add-new`,
        )
      }
      data-testid="add-new-record-button"
    >
      <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
      <div className="tw-neutral-300 tw-text-sm">
        Add Operational Control...
      </div>
    </div>
  );

  return (
    <div className="settings-wrap company-users-settings-container detail-view-documents-wrap">
      <div className="settings-content-wrap">
        <div className="settings-links-wrap audit-trail-record-wrap !tw-mb-0">
          <ul>
            <li data-testid="im-controls-tab-item">
              <Link
                to={`/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}`}
              >
                Record View
              </Link>
            </li>
            <li data-testid="im-controls-tab-item">
              <Link
                to={`/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/action-plans`}
              >
                Action Plans
              </Link>
            </li>
            <li data-testid="im-controls-tab-item">
              <Link
                to={`/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/controls`}
              >
                Module Controls
              </Link>
            </li>
            <li data-testid="im-controls-tab-item">
              <NavLink to="">Operational Controls</NavLink>
            </li>
            <li data-testid="im-controls-tab-item">
              <Link
                to={`/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/related-risks`}
              >
                Related Risks
              </Link>
            </li>
            <li data-testid="im-controls-tab-item">
              <Link
                to={`/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/audit_trail`}
              >
                Activity
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {!loading && hasModuleWriteAccess && (
        <AddRecordHeader
          recordName="Operational Control"
          addRecord={() =>
            history.push(
              `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/operational-controls-add-new`,
            )
          }
        />
      )}
      {loading ? (
        <Loading loadingLayout="table" showTableHeader={false} />
      ) : (
        <div
          className={classNames(
            "rr-operational-controls-wrapper rr-library-wrapper",
            { locked: isLocked },
          )}
        >
          <div
            className="rr-library-container"
            data-testid="im-operational-controls-container"
          >
            {renderSearch}
            <div
              className="rr-library-table-container"
              data-testid="im-operational-controls-table"
            >
              <div className="rr-library-table-columns">
                {fields.map(renderField)}
                <div className="rr-library-columns-end-filler" />
              </div>
              <div className="rr-library-table-rows">
                {filteredRows.map(renderRow)}
              </div>
            </div>
            {hasModuleWriteAccess && renderAddNewButton}
          </div>
        </div>
      )}
    </div>
  );
}

export default observer(IMOperationalControls);
