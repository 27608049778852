import { PreviewTable, PreviewTableColumn } from "@themis/ui";
import React from "react";
import { generatePath } from "react-router-dom";

import { Task } from "@/api";
import { useTasks } from "@/api/queries/tasks";
import { useCompanyUsers } from "@/api/queries/users";
import { ErrorContainer } from "@/components/ErrorContainer";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";

import { useMyTasksFilterSort } from "../../hooks/useMyTasksFilterSort";
import { TasksEmptyState } from "../TasksEmptyState";

type Props = {
  tableColumns: PreviewTableColumn<Task>[];
  responsive?: boolean;
  projectId?: number;
};

export function TasksTable({
  tableColumns,
  responsive = false,
  projectId,
}: Props) {
  const { context } = useMainStore();
  const { listRequestQueryParams, sorting, setSorting } =
    useMyTasksFilterSort();

  const {
    data: tasksData,
    isPending,
    isError,
  } = useTasks(Number(context.companyID), listRequestQueryParams);

  const { isPending: isUserPending } = useCompanyUsers(
    Number(context.companyID),
    { view: "all" },
  );

  if (isError) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath("/workspaces/:workspace_id/home", {
            workspace_id: context.workspaceID!,
          }),
        }}
      >
        Could not load Tasks.
      </ErrorContainer>
    );
  }

  if ((!tasksData && isPending) || isUserPending) {
    return <Loading loadingLayout="table-no-add-new" />;
  }

  // Temporary for APP-289: filter tasks by projectId if this is rendered by ProjectTasks component
  // other task tables use url param filtering
  const filteredTasksWithProjectId: Task[] = [];
  if (projectId) {
    tasksData.data.forEach((task: Task) => {
      if (task.parent_id === projectId) {
        filteredTasksWithProjectId.push(task);
      }
    });
  }

  return tasksData.data.length ? (
    <PreviewTable
      responsive={responsive}
      columns={tableColumns}
      rows={projectId ? filteredTasksWithProjectId : tasksData.data}
      sort={sorting}
      onSortChange={setSorting}
    />
  ) : (
    // Temporary for APP-289: Don't show the empty state because the add new button in it doesn't create a task with parent id
    !projectId && <TasksEmptyState />
  );
}
