import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  GetDashboardComponentPathParams,
  GetDashboardComponentQueryResponse,
} from "../../models/GetDashboardComponent";

/**
 * @description Get the dashboard component data and metadata
 * @summary Get dashboard component's data
 * @link /workspaces/:workspace_id/components/:system_name
 */
export async function getDashboardComponent(
  systemName: GetDashboardComponentPathParams["system_name"],
  workspaceId: GetDashboardComponentPathParams["workspace_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<GetDashboardComponentQueryResponse>["data"]> {
  const res = await client<GetDashboardComponentQueryResponse>({
    method: "get",
    url: `/workspaces/${workspaceId}/components/${systemName}`,
    ...options,
  });
  return res.data;
}
