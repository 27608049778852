import classNames from "classnames";
import React, { useEffect, useState } from "react";

type statuses = "Not Started" | "In Progress" | "Completed";

type Props = {
  value: string;
  keyForColor?: string;
};

function TodoStatusTag({ value }: Props) {
  const [status, setStatus] = useState<statuses>("Not Started");

  const styleStatus = {
    color: "white",
    borderRadius: "24px",
    padding: "2px 8px",
  };

  useEffect(() => {
    const getStatus = () => {
      switch (value) {
        case "Completed":
          setStatus("Completed");
          break;
        case "Started":
          setStatus("In Progress");
          break;
        default:
          setStatus("Not Started");
      }
    };

    if (value) {
      getStatus();
    }
  }, [value]);

  return (
    <span
      className={classNames("tw-border-radius tw-px-8 tw-py-2", {
        "tw-bg-fuchsia-3": status === "Not Started",
        "tw-bg-primaryDim-400": status === "In Progress",
        "tw-bg-secondary-400": status === "Completed",
      })}
      style={styleStatus}
    >
      {status}
    </span>
  );
}

export default TodoStatusTag;
