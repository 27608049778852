import { PreviewTable } from "@themis/ui";
import React from "react";
import { PiChartBarBold, PiGlobeBold, PiTextTBold } from "react-icons/pi";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";

import { useColumns } from "@/api/queries/columns";
import { useListModuleAssessments } from "@/api/queries/moduleAssessments";
import { ErrorContainer } from "@/components/ErrorContainer";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import Loading from "@/components/Loading";
import CreateFirstCTA from "@/components/table/shared/CreateFirstCTA";
import { fieldConfigToColumnsMap } from "@/utils/tableUtils";

import { MODULE_ASSESSMENT_ROUTES } from ".";
import { ModuleAssessmentHeader } from "../components/ModuleAssessmentHeader";
import { ModuleAssessmentsActionsCell } from "../components/ModuleAssessmentTable/ModuleAssessmentActionsCell";
import { ModuleAssessmentIdLinkCell } from "../components/ModuleAssessmentTable/ModuleAssessmentIdLinkCell";
import { MODULE_ASSESSMENT_TABLE_COMPONENT_OVERRIDES } from "../config/moduleAssessmentsTable";

export type SendModuleAssessmentSearchParams = {
  send_issue?: string;
};

export function ModuleAssessmentsList({
  recordVersionId,
}: {
  recordVersionId?: number;
}) {
  const { url } = useRouteMatch();

  const { workspace_id } = useParams<{ workspace_id: string }>();

  const {
    data: moduleAssessmentColumns,
    isPending: isColumnsPending,
    isError: isColumnsError,
  } = useColumns({
    workspaceId: Number(workspace_id),
    recordClass: "ModuleAssessment",
  });

  const {
    data: moduleAssessments,
    isPending: isModuleAssessmentsPending,
    isError: isModuleAssessmentsError,
  } = useListModuleAssessments(Number(recordVersionId));

  if (isModuleAssessmentsPending || isColumnsPending) {
    return <Loading loadingLayout="table" />;
  }

  if (isModuleAssessmentsError || isColumnsError) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath(
            "/workspaces/:workspace_id/modules/control-mapping",
            {
              workspace_id,
            },
          ),
        }}
      >
        Could not load Control Assessment.
      </ErrorContainer>
    );
  }

  return (
    <PageLayout>
      <ModuleAssessmentHeader />
      <PageContent>
        <PreviewTable
          rows={moduleAssessments?.data}
          columns={[
            {
              title: "ID",
              type: "id",
              width: 160,
              Component: (moduleAssessmentId) => (
                <ModuleAssessmentIdLinkCell
                  moduleAssessmentId={moduleAssessmentId.id}
                />
              ),
              Icon: PiGlobeBold,
            },
            // @ts-expect-error TS(7008) FIXME: Member 'accounts' implicitly has an 'any' typ... Remove this comment to see the full error message
            ...fieldConfigToColumnsMap(moduleAssessmentColumns?.data),
          ]}
          columnIconOverrides={{
            status: PiChartBarBold,
            compliance_status: PiChartBarBold,
            control_effectiveness_rating: PiTextTBold,
          }}
          componentOverrides={MODULE_ASSESSMENT_TABLE_COMPONENT_OVERRIDES}
          rowActions={(moduleAssessment) => (
            <ModuleAssessmentsActionsCell
              moduleAssessment={moduleAssessment}
              recordVersionId={Number(recordVersionId)}
            />
          )}
          addRow={{
            text: "Add Control Assessment",
            linkTo: generatePath(`${url}${MODULE_ASSESSMENT_ROUTES.details}`, {
              moduleAssessmentId: "new",
            }),
          }}
        />

        {moduleAssessments?.data.length < 2 && (
          <CreateFirstCTA subject="ModuleAssessment" />
        )}
      </PageContent>
    </PageLayout>
  );
}
