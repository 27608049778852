import { PreviewTable } from "@themis/ui";
import React from "react";
import { useParams, useRouteMatch } from "react-router-dom";

import { useChildAccounts } from "@/api/queries/childAccounts";
import Loading from "@/components/Loading";
import LoadingError from "@/components/loading-error";

import { ACCOUNT_ID_PARAM } from "..";
import { ACCOUNT_COLUMNS } from "../../config/accountsTable";

export function ChildAccounts() {
  const { accountId } = useParams<{
    [ACCOUNT_ID_PARAM]: string;
  }>();
  const { url } = useRouteMatch();

  const { data, isPending, isError } = useChildAccounts(Number(accountId));

  if (isPending) {
    return <Loading loadingLayout="table" />;
  }

  if (isError) {
    return (
      <LoadingError loadingError="There was an issue loading the sub accounts." />
    );
  }

  return (
    <PreviewTable
      rows={data.accounts}
      columns={ACCOUNT_COLUMNS}
      addRow={{
        text: "Add Account",
        linkTo: (location) => ({
          pathname: `${url}/new`,
          state: { from: location.pathname },
        }),
      }}
    />
  );
}
