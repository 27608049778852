import { LinkButton, LinkIconButton } from "@themis/ui";
import React from "react";
import { PiArchiveBold } from "react-icons/pi";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";

import { Project } from "@/api";
import { useCreateProject, useProjects } from "@/api/queries/projects";
import { ErrorContainer } from "@/components/ErrorContainer";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import Loading from "@/components/Loading";
import ImportButton from "@/features/misc/ImportButton";
import mainStore from "@/stores/Main";

import ProjectsEmptyState from "../components/ProjectsEmptyState";
import ProjectsTable from "../components/ProjectsTable/ProjectsTable";
import { PROJECTS_ARCHIVE_PATH, PROJECTS_ROUTES } from "./ProjectRoutes";

export default function Projects() {
  const { url } = useRouteMatch();
  const companyId = mainStore.companies.company?.id;
  const { workspace_id } = useParams<{ workspace_id: string }>();

  const { data: projects, isPending, isError } = useProjects(Number(companyId));
  const { mutateAsync: createProject } = useCreateProject({
    companyId: Number(companyId),
  });

  if (isPending) {
    return <Loading loadingLayout="table" />;
  }

  if (isError) {
    return (
      <PageContent>
        <ErrorContainer
          backButtonProps={{
            linkTo: generatePath("/workspaces/:workspace_id/home", {
              workspace_id,
            }),
          }}
        >
          Could not load projects.
        </ErrorContainer>
      </PageContent>
    );
  }

  const unarchivedProjects: Project[] =
    projects?.data.filter((project: Project) => !project.archived_at) ?? [];

  return (
    <PageLayout>
      <Header
        title={<>Projects</>}
        HeaderOptions={
          <>
            <LinkButton
              size="sm"
              to={(location) => ({
                pathname: generatePath(`${url}${PROJECTS_ROUTES.details}`, {
                  project_id: "new",
                }),
                state: { from: location.pathname },
              })}
            >
              Add Project
            </LinkButton>
            <ImportButton referenceType={"projects"} />
            <LinkIconButton
              className="tw-text-neutral-500"
              to={(location) => ({
                pathname: generatePath(PROJECTS_ARCHIVE_PATH, {
                  workspace_id,
                }),
                state: { from: location.pathname },
              })}
              Icon={PiArchiveBold}
              color="tertiary"
              data-tooltip-id="tooltip"
              data-tooltip-place="bottom-end"
              data-tooltip-content="View archived projects"
            />
          </>
        }
      />
      {unarchivedProjects.length ? (
        <ProjectsTable
          projects={unarchivedProjects}
          createProject={createProject}
          workspace_id={workspace_id}
          companyId={companyId}
        />
      ) : (
        <ProjectsEmptyState />
      )}
    </PageLayout>
  );
}
