import React, { useEffect } from "react";
import { PiPlusBold } from "react-icons/pi";

import { Project } from "@/api";
import { PageContent } from "@/components/Layout/PageContent";
import { useMainStore } from "@/contexts/Store";
import { TasksTable } from "@/features/tasks/components/table/TasksTable";
import { projectTasksTableColumns } from "@/features/tasks/config/myTasksTableConfig";
import { useMyTasksFilterSort } from "@/features/tasks/hooks/useMyTasksFilterSort";

export default function ProjectTasks({
  projectData,
}: {
  projectData: Project | undefined;
}) {
  const { taskDetail } = useMainStore();

  const { sorting, setSorting } = useMyTasksFilterSort();

  useEffect(() => {
    if (!sorting) {
      setSorting({
        columnKey: "due_date",
        direction: "asc",
      });
    }
  }, [location.pathname]);

  return (
    <PageContent>
      <TasksTable
        tableColumns={projectTasksTableColumns}
        projectId={projectData?.id}
      />
      <div
        className="tw-my-4 tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-4 tw-px-[7px] tw-py-[6px]"
        onClick={() =>
          taskDetail.open({
            parent_id: projectData?.id,
            name: `New Task for ${projectData?.name}`,
            workspace_id: projectData?.workspace_id,
          })
        }
      >
        <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
        <div className="tw-neutral-300 tw-text-sm">Add Task</div>
      </div>
    </PageContent>
  );
}
