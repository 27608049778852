import React from "react";
import { NavLink } from "react-router-dom";

function CompanyTagsNavigation() {
  return (
    <div className="settings-links-wrap tw-mb-0">
      <ul>
        <li>
          <NavLink to="/tags/departments">Departments</NavLink>
        </li>
        <li>
          <NavLink to="/tags/frameworks">Frameworks</NavLink>
        </li>
        <li>
          <NavLink to="/tags/products">Products</NavLink>
        </li>
      </ul>
    </div>
  );
}

export default CompanyTagsNavigation;
