import React from "react";
import { generatePath, Link, useRouteMatch } from "react-router-dom";

import { WEBSITE_MONITORING_ROUTES } from "../pages";

export default function NameCell({
  title,
  id,
  type,
}: {
  title: string;
  id: number;
  type: string;
}) {
  const { url } = useRouteMatch();
  const monitoringGroupAssetId = id;
  const monitoringGroupAssetChildId = id;

  return (
    <Link
      className="tw-flex tw-cursor-pointer tw-items-center tw-px-2.5 tw-py-1 tw-text-neutral-300 hover:tw-underline"
      to={
        type === "Asset"
          ? generatePath(
              `${url}${WEBSITE_MONITORING_ROUTES.monitoringGroupAssetChildren}`,
              {
                monitoringGroupAssetId,
              },
            )
          : generatePath(
              `${WEBSITE_MONITORING_ROUTES.base}${WEBSITE_MONITORING_ROUTES.monitoringGroups}${WEBSITE_MONITORING_ROUTES.monitoringGroupAssetChildResults}`,
              {
                monitoringGroupAssetChildId,
              },
            )
      }
    >
      {title}
    </Link>
  );
}
