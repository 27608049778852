import {
  PreviewTableColumn,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@themis/ui";
import React from "react";

import { MonitoringGroupAssetChildResult } from "@/api";

import { StatusTag } from "../components/StatusTag";
import { ThumbsStatus } from "../components/ThumbsStatus";
import ViewSlideButton from "../components/ViewSlideButton";

export const MONITORING_GROUP_ASSET_CHILD_RESULTS_COLUMNS: PreviewTableColumn<MonitoringGroupAssetChildResult>[] =
  [
    {
      isRowStart: true,
      key: "rule",
      title: "Rule",
      type: "locked",
      width: 250,
    },
    {
      title: "Reason",
      type: "locked",
      width: 450,
      Component: (monitoring_group_result) => (
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <div className="w-w-full tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-sm tw-font-semibold">
              {monitoring_group_result.reason}
            </div>
          </TooltipTrigger>
          <TooltipContent
            align="start"
            side="bottom"
            className="tw-max-h-48 tw-overflow-y-auto tw-font-semibold"
          >
            {monitoring_group_result.reason}
          </TooltipContent>
        </Tooltip>
      ),
    },
    {
      title: "Severity",
      type: "locked",
      width: 150,
      Component: (monitoring_group_result) => (
        <StatusTag status={monitoring_group_result.severity || ""} />
      ),
    },
    {
      title: "Status",
      type: "locked",
      isRowEnd: true,
      width: 150,
      Component: (monitoring_group_result) => (
        <StatusTag status={monitoring_group_result.status || ""} />
      ),
    },
    {
      type: "actions",
      Component: (monitoring_group_result) => (
        <ThumbsStatus
          monitoringGroupResultId={monitoring_group_result.id}
          disable={monitoring_group_result.status !== "Unreviewed"}
        />
      ),
    },
    {
      type: "actions",
      Component: (monitoring_group_result) => (
        <ViewSlideButton monitoringGroupResultId={monitoring_group_result.id} />
      ),
    },
  ] as const;

export const MONITORING_GROUP_ASSET_CHILD_RESULTS_MISSING_COLUMNS: PreviewTableColumn<MonitoringGroupAssetChildResult>[] =
  [
    {
      isRowStart: true,
      title: "Copy",
      type: "locked",
      width: 850,
      Component: (monitoring_group_result) => (
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <div className="w-w-full tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-sm tw-font-semibold">
              {monitoring_group_result.copy_title_data}
            </div>
          </TooltipTrigger>
          <TooltipContent
            align="start"
            side="bottom"
            className="tw-max-h-48 tw-max-w-96 tw-overflow-y-auto tw-font-semibold"
          >
            {monitoring_group_result.copy_title_data}
          </TooltipContent>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      type: "locked",
      isRowEnd: true,
      width: 140,
      Component: (monitoring_group_result) => (
        <StatusTag status={monitoring_group_result.status || ""} />
      ),
    },
    {
      type: "actions",
      Component: (monitoring_group_result) => (
        <ThumbsStatus
          monitoringGroupResultId={monitoring_group_result.id}
          disable={monitoring_group_result.status !== "Unreviewed"}
        />
      ),
    },
    {
      type: "actions",
      Component: (monitoring_group_result) => (
        <ViewSlideButton monitoringGroupResultId={monitoring_group_result.id} />
      ),
    },
  ] as const;
