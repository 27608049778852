import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Button } from "@/components/Elements";
import CommentsSlideMenu from "@/components/table/shared/comments/CommentsSlideMenu";
import { VendorContracts } from "@/features/vendor-due-diligence-contracts";
import { Vendor } from "@/stores/types/vendor-types";

import { useMainStore } from "../../../contexts/Store";
import responseIcon from "../../../images/table-image/icon/response.svg";
import { LOADING_DELAY } from "../../constants";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import Loading from "../../Loading";
import RecordNotFound from "../../shared/RecordNotFound";
import Table from "../Table";
import BuildVendor from "./BuildVendor";
import RiskAssessments from "./RiskAssessments";
import VendorQuestionnaires from "./VendorQuestionnaires";

const SAVED_MSG_TIMEOUT = 2000;

type Props = {
  defaultScreen?: string;
};

function ViewVendor({ defaultScreen }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  // State
  const [initialLoad, setInitialLoad] = useState(true);
  const [activeScreen, setActiveScreen] = useState(defaultScreen);
  const [loading, setLoading] = useState(true);
  const [showAutosave, setShowAutosave] = useState(false);

  // Hooks
  const { vendor_id } = useParams<{ vendor_id: string }>();

  // Variables
  const { moduleWorkspaceID, workspaceID } = mainStore.context;
  const { currentVendor, data } = mainStore.vendors || {};
  const { active_workspace: workspace } = mainStore.users.user;
  const { canManageAccounts } = mainStore.userPermissions;
  // @ts-expect-error TS(2339) FIXME: Property 'risk_assessment_templates' does not exis... Remove this comment to see the full error message
  const riskAssessmentTemplates = data?.risk_assessment_templates || [];
  // @ts-expect-error TS(2339) FIXME: Property 'record' does not exist on type 'object |... Remove this comment to see the full error message
  const questionnaires = currentVendor?.record?.vendor_checklists || [];
  // @ts-expect-error TS(2339) FIXME: Property 'record' does not exist on type 'object |... Remove this comment to see the full error message
  const riskAssessments = currentVendor?.record?.risk_assessments || [];
  const history = useHistory();
  const hasData = activeScreen === "Documents" || currentVendor?.id;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const recordName = getRecordName(
    "vendor_due_diligence",
    moduleWorkspaces,
    true,
  );
  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === (currentVendor as Vendor).id,
  );

  const screens = {
    ActiveVendors: {
      label: `${recordName} Info`,
      url: `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendor_id}`,
    },
    Contracts: {
      label: "Contracts",
      url: `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendor_id}/contracts`,
    },
    Questionnaires: {
      label: "Questionnaires",
      url: `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendor_id}/questionnaires`,
    },
    RiskAssessment: {
      label: "Risk Assessment",
      url: `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendor_id}/risk_assessment`,
    },
    Documents: {
      label: "Documents",
      url: `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendor_id}/documents`,
    },
  };

  // Effects
  useEffect(() => {
    mainStore.vendors.setCurrentVendor({});
    vendor_id && mainStore.vendors.getVendorById(vendor_id);
  }, [vendor_id]);

  useEffect(() => {
    if (!workspace?.id) {
      return;
    }

    !mainStore.users.users?.length &&
      mainStore.users.indexForModules({ workspaceID: workspace.id });
    // @ts-expect-error TS(2339) FIXME: Property 'module_workspace_id' does not exist on t... Remove this comment to see the full error message
    !mainStore.vendors.data?.module_workspace_id &&
      mainStore.vendors.index({ workspaceID: workspace.id });
  }, [workspace?.id]);

  useEffect(() => {
    setActiveScreen(defaultScreen);
  }, [defaultScreen]);

  useEffect(() => {
    setInitialLoad(false);
  }, []);

  useEffect(() => {
    if (initialLoad) {
      return;
    }

    setTimeout(() => {
      setLoading(Boolean(!moduleWorkspaceID));
    }, LOADING_DELAY);
  }, [initialLoad, moduleWorkspaceID]);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'newScreen' implicitly has an 'any' type... Remove this comment to see the full error message
  function handleScreenChange(newScreen) {
    setActiveScreen(newScreen);
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    history.push(screens[newScreen].url);
  }

  function handleGeneratePDFReport() {
    mainStore.vendors.generateAndOpenPDFReport(vendor_id);
  }

  function autoSave() {
    setShowAutosave(true);
    setTimeout(() => setShowAutosave(false), SAVED_MSG_TIMEOUT);
  }

  // Elements
  const renderTabButton = (screen: string) => (
    <button
      className={classNames({ active: activeScreen === screen })}
      onClick={() => handleScreenChange(screen)}
      data-testid={`vdd-view-vendor-tab-${screen}`}
    >
      {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
      {screens[screen].label}
    </button>
  );

  return (
    <Table>
      {loading && activeScreen === "ActiveVendors" && (
        <Loading loadingLayout="small-table" showTableHeader />
      )}
      {loading &&
        (activeScreen === "Questionnaires" ||
          activeScreen === "RiskAssessment" ||
          activeScreen === "Documents" ||
          activeScreen === "Contracts") && (
          <Loading loadingLayout="table" showTableHeader />
        )}
      {!loading && !hasData && <RecordNotFound />}
      {!loading && hasData && (
        <>
          <div
            className="table-header-wrap"
            data-testid="vdd-view-vendor-header"
          >
            <div className="buttons-block" data-testid="vdd-view-vendor-tabs">
              {renderTabButton("ActiveVendors")}
              {canManageAccounts && renderTabButton("Contracts")}
              {renderTabButton("Questionnaires")}
              {renderTabButton("RiskAssessment")}
            </div>
            <div className="add-new-block">
              <div
                className={classNames("saved-success-msg", {
                  active: showAutosave,
                })}
                data-testid="vdd-saved-success-msg"
              >
                <img src={responseIcon} alt="response-icon" />
                <span>Vendor Auto Saved</span>
              </div>
              <Button
                type="button"
                size="sm"
                onClick={handleGeneratePDFReport}
                label={`Export ${recordName} to PDF`}
              >
                Export {recordName} to PDF
              </Button>
              {activeScreen === "ActiveVendors" && recordVersion && (
                <CommentsSlideMenu
                  renderCommentsButton
                  recordID={recordVersion.record_id}
                  globalID={recordVersion.global_id}
                  uncompletedCommentsCount={
                    recordVersion.uncompleted_comments_count
                  }
                  taskableType="Record"
                />
              )}
            </div>
          </div>
          <div
            className="view-vendor-content"
            data-testid="vdd-view-vendor-content"
          >
            {activeScreen === "ActiveVendors" && (
              <BuildVendor vendor={currentVendor} autoSave={autoSave} />
            )}
            {activeScreen === "Contracts" && canManageAccounts && (
              <VendorContracts accountId={currentVendor.record?.account_id} />
            )}
            {activeScreen === "Questionnaires" && (
              <VendorQuestionnaires questionnaires={questionnaires} />
            )}
            {activeScreen === "RiskAssessment" && (
              <RiskAssessments
                vendorID={vendor_id}
                templates={riskAssessmentTemplates}
                riskAssessments={riskAssessments}
              />
            )}
          </div>
        </>
      )}
    </Table>
  );
}

ViewVendor.defaultProps = {
  defaultScreen: "ActiveVendors",
};

export default observer(ViewVendor);
