import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListAccountsPathParams,
  ListAccountsQueryResponse,
} from "../../models/ListAccounts";

/**
 * @description List accounts
 * @summary List accounts
 * @link /workspaces/:workspace_id/accounts
 */
export async function listAccounts(
  workspaceId: ListAccountsPathParams["workspace_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListAccountsQueryResponse>["data"]> {
  const res = await client<ListAccountsQueryResponse>({
    method: "get",
    url: `/workspaces/${workspaceId}/accounts`,
    ...options,
  });
  return res.data;
}
