import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useAccounts, useAccountsList } from "@/api/queries/accounts";
import VendorAccountSelect from "@/components/table/vendor-due-diligence/VendorAccountSelect";
import { useMainStore } from "@/contexts/Store";
import { VendorDetailAccountInfo } from "@/features/vendor-due-diligence/components/VendorDetailAccountInfo";
import { VendorDetailAllContracts } from "@/features/vendor-due-diligence/components/VendorDetailAllContracts";

import { Button } from "../../Elements";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import CustomOptionsSelect from "../shared/CustomOptionsSelect";
import Textarea from "../shared/dynamic-form/view-textarea/Textarea";
import ViewChecklist from "../shared/dynamic-form/ViewChecklist";
import ViewDateSelect from "../shared/dynamic-form/ViewDateSelect";
import ViewFileSelect from "../shared/dynamic-form/ViewFileSelect";
import ViewInputCell from "../shared/dynamic-form/ViewInputCell";
import ViewLinkCell from "../shared/dynamic-form/ViewLinkCell";
import ViewSelect from "../shared/dynamic-form/ViewSelect";
import ViewUsersSelect from "../shared/dynamic-form/ViewUsersSelect";
import Switch from "../shared/Switch";

const CUSTOM_COLUMN_INFO = {
  "com.askthemis.types.v1.option": {
    component: ViewSelect,
    type: "select",
  },
  "com.askthemis.types.v1.text": {
    component: ViewInputCell,
    type: "input",
  },
  "com.askthemis.types.v1.link": {
    component: ViewLinkCell,
    type: "link",
  },
  "com.askthemis.types.v1.attachment": {
    component: ViewFileSelect,
    type: "input",
  },
  "com.askthemis.types.v1.date": {
    component: ViewDateSelect,
    type: "input",
  },
  "com.askthemis.types.v1.checklist": {
    component: ViewChecklist,
    type: "checklist",
  },
  "com.askthemis.types.v1.tag_user": {
    component: ViewUsersSelect,
    type: "select",
  },
};

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  autoSave?: (...args: any[]) => any;
  isNew?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  vendor?: any; // TODO: PropTypes.instanceOf(Object)
};

function BuildVendor({ vendor, isNew, autoSave }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const accountId = searchParams.get("account_id");
  const { moduleWorkspaceID } = mainStore.context;

  // State
  const [riskLevel, setRiskLevel] = useState(null);
  const [PIIProvided, setPIIProvided] = useState([]);
  const [PIICategories, setPIICategories] = useState([]);
  const [PIINotes, setPIINotes] = useState("");
  const [summary, setSummary] = useState("");
  const [otherInfo, setOtherInfo] = useState("");
  const [grouping, setGrouping] = useState([]);
  const [materialActivity, setMaterialActivity] = useState([]);

  const [customData, setCustomData] = useState({});
  const [initialLoad, setInitialLoad] = useState(true);
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(
    Number(accountId) || vendor?.record?.account_id || null,
  );

  // Variables
  const { users } = mainStore.users;
  const { isCurrentWorkspaceArchived } = mainStore.workspaces;
  const { hasModuleWriteAccess, canManageAccounts } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;
  const { activeWorkspace, workspaceID } = mainStore.context;
  const { currentVendor } = mainStore.vendors;
  const status = mainStore.avroSchemas.valueForField("status", vendor?.data);
  const isDisabled = status === "disabled" || !!isCurrentWorkspaceArchived;
  const customFields = mainStore.fields.list.filter(
    (field) => field.is_custom_field,
  );

  const rvFromList = mainStore.recordVersions.list?.find(
    (recordVersion) => recordVersion.id === vendor?.id,
  );
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const recordName = getRecordName(
    "vendor_due_diligence",
    moduleWorkspaces,
    true,
  );
  const { data: accountData } = useAccounts(Number(workspaceID));
  const { data: accountsList } = useAccountsList(Number(moduleWorkspaceID));
  const selectedAccount = accountData?.accounts?.find(
    (account) => selectedAccountId === account.id,
  );
  const newVendorParams = new URLSearchParams({
    redirectTo: `/workspaces/${workspaceID}/modules/add-new-vendor`,
  }).toString();

  // Effects
  useEffect(() => {
    if (!isNew) {
      return;
    }

    if (!Object.keys(currentVendor).length) {
      return setInitialLoad(false);
    }

    setRiskLevel(
      mainStore.avroSchemas.valueForField("risk_level", currentVendor),
    );
    setPIIProvided(
      mainStore.avroSchemas.valueForField("pii_provided", currentVendor) || [],
    );
    setPIICategories(
      mainStore.avroSchemas.valueForField("pii_categories", currentVendor),
    );
    setPIINotes(
      mainStore.avroSchemas.valueForField("pii_notes", currentVendor),
    );
    setSummary(
      mainStore.avroSchemas.valueForField(
        "summary_of_activity_outsourced",
        currentVendor,
      ),
    );
    setOtherInfo(
      mainStore.avroSchemas.valueForField("other_info", currentVendor),
    );
    setGrouping(mainStore.avroSchemas.valueForField("grouping", currentVendor));
    setMaterialActivity(
      mainStore.avroSchemas.valueForField("material", currentVendor),
    );

    setTimeout(() => setInitialLoad(false));
    setTimeout(handleAutoSave);
  }, []);

  useEffect(() => {
    if (!isNew) {
      return;
    }

    handleSubmit();
  }, [customData]);

  useEffect(() => {
    if (isNew) {
      return;
    }
    if (!(users && vendor && initialLoad)) {
      return;
    }

    setRiskLevel(
      mainStore.avroSchemas.valueForField("risk_level", vendor.data),
    );
    setPIIProvided(
      mainStore.avroSchemas.valueForField("pii_provided", vendor.data),
    );
    setPIICategories(
      mainStore.avroSchemas.valueForField("pii_categories", vendor.data),
    );
    setPIINotes(mainStore.avroSchemas.valueForField("pii_notes", vendor.data));
    setSummary(
      mainStore.avroSchemas.valueForField(
        "summary_of_activity_outsourced",
        vendor.data,
      ),
    );
    setOtherInfo(
      mainStore.avroSchemas.valueForField("other_info", vendor.data),
    );
    setGrouping(mainStore.avroSchemas.valueForField("grouping", vendor.data));
    setMaterialActivity(
      mainStore.avroSchemas.valueForField("material", vendor.data),
    );

    setTimeout(() => setInitialLoad(false));
  }, [vendor, users, initialLoad]);

  useEffect(() => {
    if (!vendor?.id || isNew) {
      return;
    }

    setInitialLoad(true);
  }, [vendor?.id]);

  useEffect(() => {
    if (!activeWorkspace) {
      return;
    }

    const fetchVendors = async () => {
      if (!mainStore.fields.list?.length) {
        await mainStore.vendors.index({ workspaceID: activeWorkspace.id });
      }
    };

    fetchVendors();
  }, [activeWorkspace]);

  useEffect(handleAutoSave, [
    grouping,
    materialActivity,
    PIICategories,
    PIIProvided,
    riskLevel,
    selectedAccountId,
  ]);

  // Functions
  function addDisabledClass(actualClasses: string) {
    return classNames(actualClasses, {
      "vendor-view-disabled": isDisabled,
      "table-cell--disabled": isReadOnly,
    });
  }

  function handleAutoSave() {
    if (initialLoad) {
      return;
    }

    handleSubmit();
  }

  async function handleSubmit() {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    const piiCategoriesValue = PIIProvided?.includes("yes")
      ? PIICategories
      : [];
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    const piiNotesValue = PIIProvided?.includes("yes") ? PIINotes : "";

    const data = {
      risk_level: mainStore.avroSchemas.serializeValue("risk_level", riskLevel),
      pii_provided: mainStore.avroSchemas.serializeValue(
        "pii_provided",
        PIIProvided,
      ),
      pii_categories: mainStore.avroSchemas.serializeValue(
        "pii_categories",
        piiCategoriesValue,
      ),
      pii_notes: mainStore.avroSchemas.serializeValue(
        "pii_notes",
        piiNotesValue,
      ),
      material: mainStore.avroSchemas.serializeValue(
        "material",
        materialActivity,
      ),
      summary_of_activity_outsourced: mainStore.avroSchemas.serializeValue(
        "summary_of_activity_outsourced",
        summary,
      ),
      other_info: mainStore.avroSchemas.serializeValue("other_info", otherInfo),
      grouping: mainStore.avroSchemas.serializeValue("grouping", grouping),
    };

    Object.keys(customData).forEach((key) => {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (Array.isArray(customData[key]?.files)) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        data[key] = { fileObj: customData[key], customFile: true };
      } else {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        data[key] = mainStore.avroSchemas.serializeValue(key, customData[key]);
      }
    });

    if (isNew) {
      return mainStore.vendors.setCurrentVendor({
        ...mainStore.vendors.currentVendor,
        ...data,
        account_id: selectedAccountId,
      });
    }

    await mainStore.vendors.update(vendor.id, data);

    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    autoSave();
  }

  // Elements
  const renderAccountSelect = (
    <div className="build-vendor-block">
      <div className="row">
        <VendorAccountSelect
          label="Account Name"
          isNew={isNew}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          placeholder="– Select existing account –"
          accounts={accountsList?.data}
          selectedAccountId={selectedAccountId}
          setSelectedAccountId={setSelectedAccountId}
        />
        {!isDisabled && isNew && canManageAccounts && (
          <div className="determine-risk-rating-container stretch">
            <p>No Existing Account?</p>
            <Link
              to={`/workspaces/${workspaceID}/accounts/new?${newVendorParams}`}
            >
              <Button size="sm" label="Create New Account" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );

  const renderSummaryArea = (
    <div className="input-block">
      <label>Summary</label>
      <Textarea
        name="details"
        dataTestID="build-vendor-input-summary"
        placeholder="Type here"
        value={summary}
        disabled={isDisabled}
        className={addDisabledClass("")}
        onChange={(event) => setSummary(event.target.value)}
        onBlur={handleAutoSave}
      />
    </div>
  );

  const renderOtherArea = (
    <div className="input-block">
      <label>Other Info</label>
      <Textarea
        name="other_info"
        dataTestID="build-vendor-input-other-info"
        placeholder="Type here (Optional)"
        value={otherInfo}
        disabled={isDisabled}
        className={addDisabledClass("")}
        onChange={(event) => setOtherInfo(event.target.value)}
        onBlur={handleAutoSave}
      />
    </div>
  );

  const renderGrouping = (
    <div>
      <label>Grouping Section</label>
      <div
        className={classNames("custom-column-option-container", {
          "vdd-filled-input": grouping?.length,
          "vendor-view-disabled": isDisabled,
          "table-cell--disabled": isReadOnly,
        })}
      >
        <CustomOptionsSelect
          fieldName="grouping"
          selectedOptions={grouping || []}
          handleSelect={setGrouping}
          titlePlaceholder="- Select -"
          enableAdd
        />
      </div>
    </div>
  );

  const vendorInfoBlock = (
    <div
      className="build-vendor-block company-info-block"
      data-testid="vdd-vendor-info-section"
    >
      <div className="title">Vendor Info</div>
      <div className="row">
        {renderSummaryArea}
        {renderOtherArea}
      </div>
      <div className="row">{renderGrouping}</div>
    </div>
  );

  const privacyQuestionBlock = (
    <div className="build-vendor-block privacy-question-block">
      <div className="title" data-testid="vdd-privacy-question-header">
        Privacy Question
      </div>
      <div className="row no-allign">
        <div>
          <div
            className="new-vendor-switch-container"
            data-testid="build-vendor-switch-pii-provided-container"
          >
            <Switch
              active={!isDisabled}
              indeterminate={false}
              // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              checked={PIIProvided?.includes("yes")}
              onChange={(event) =>
                // @ts-expect-error TS(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
                setPIIProvided(event.target.checked ? ["yes"] : ["no"])
              }
            />
            <p>is {recordName} receiving PII or sensitive data?</p>
          </div>
          {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message */}
          {PIIProvided?.includes("yes") && (
            <div className="vdd-pii-additional-fields">
              <div
                data-testid="build-vendor-pii-categories"
                className={classNames("custom-column-option-container", {
                  "vdd-filled-input": !isDisabled && PIICategories?.length,
                  "vendor-disabled-view": isDisabled,
                })}
              >
                <CustomOptionsSelect
                  fieldName="pii_categories"
                  selectedOptions={PIICategories || []}
                  handleSelect={setPIICategories}
                  titlePlaceholder="- Select PII Category -"
                  enableAdd
                  isMultiSelect
                  disabled={isDisabled}
                />
              </div>
              <div className="input-block">
                <input
                  type="text"
                  data-testid="build-vendor-input-pii-notes"
                  name="pii_notes"
                  placeholder="Add your notes (Optional)"
                  value={PIINotes}
                  disabled={isDisabled}
                  className={addDisabledClass("")}
                  onChange={(e) => setPIINotes(e.target.value)}
                  onBlur={handleAutoSave}
                />
              </div>
            </div>
          )}
          <div
            className="new-vendor-switch-container"
            data-testid="build-vendor-switch-material-container"
          >
            <Switch
              active={!isDisabled}
              indeterminate={false}
              // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              checked={materialActivity?.includes("yes")}
              onChange={(event) =>
                // @ts-expect-error TS(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
                setMaterialActivity(event.target.checked ? ["yes"] : ["no"])
              }
            />
            <p>
              Is the outsourced activity considered to be &quot;material?&quot;
            </p>
          </div>
        </div>
        <div className="tips">
          <p>Materially Outsourced Factors:</p>
          <p>
            - The degree to which the outsourced business functions are critical
            to business strategy;
          </p>
          <p>
            - The degree to which the outsourced business functions are central
            to fulfillment of obligations to customers;
          </p>
          <p>
            - The extent of reputational impact that success or failure in
            performing the business functions would have;
          </p>
          <p>- The cost to perform the business functions.</p>
        </div>
      </div>
    </div>
  );

  const riskRatingBlock = (
    <div
      className="build-vendor-block risk-rating-section"
      data-testid="vdd-risk-rating-section"
    >
      <div className="title" data-testid="vdd-risk-rating-header">
        Risk Rating
      </div>
      <div className="row no-allign">
        <div
          className={classNames("custom-column-option-container stretch", {
            // @ts-expect-error TS(2339) FIXME: Property 'length' does not exist on type 'never'.
            "vdd-filled-input": !isDisabled && riskLevel?.length,
            "vendor-view-disabled": isDisabled,
            "table-cell--disabled": isReadOnly,
          })}
        >
          <CustomOptionsSelect
            fieldName="risk_level"
            selectedOptions={riskLevel || []}
            handleSelect={setRiskLevel}
            titlePlaceholder="Select Risk Rating"
            enableAdd
            disabled={isDisabled}
          />
        </div>

        {!isDisabled && (
          <div className="determine-risk-rating-container stretch">
            <p>If you&lsquo;re not sure with the risk rating ...?</p>
            <Link
              to={
                isNew
                  ? `/workspaces/${workspaceID}/modules/vendor-due-diligence/risk-assessment-templates`
                  : `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendor?.id}/risk_assessment`
              }
            >
              <Button size="sm" label="Determine Risk Rating" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div
      className={classNames("new-vendor-container", { "create-mode": isNew })}
      data-testid="vdd-build-vendor-container"
    >
      <div className="forms-container">
        {renderAccountSelect}
        {!!selectedAccount && (
          <VendorDetailAccountInfo
            canManageAccounts={canManageAccounts}
            isNew={!!isNew}
            isReadOnly={!!isReadOnly}
            selectedAccount={selectedAccount}
          />
        )}
        {vendorInfoBlock}
        <VendorDetailAllContracts
          contracts={currentVendor.contracts}
          canManageAccounts={canManageAccounts}
        />
        {privacyQuestionBlock}
        {riskRatingBlock}

        {customFields?.length > 0 && (
          <div className="build-vendor-custom-columns detail-view-container">
            <div className="title" data-testid="custom-columns-title">
              Custom Columns
            </div>
            {customFields.map((field) => {
              // @ts-expect-error TS(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
              const saveData = async (val) => {
                if (val === undefined) {
                  return;
                }
                if (!isNew) {
                  return;
                }

                setCustomData((prev) => ({ ...prev, [field.name]: val }));
              };
              // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              const columnInfo = CUSTOM_COLUMN_INFO[field.data_type];
              if (!columnInfo) {
                return null;
              }

              const { component: Component, type } = columnInfo;
              const title = field.display_name;
              let initialValue = undefined;
              if (isNew) {
                // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                initialValue = customData[field.name];
                if (initialValue?.replaced_attachment_id) {
                  initialValue = [
                    {
                      attachment_id: "-1",
                      field_name: field.name,
                      file_type: "direct_upload",
                      filename: `File uploaded - it will be available after creation of the ${recordName}`,
                      id: "",
                      url: "",
                    },
                  ];
                }
              }

              return (
                <div key={field.name} className={`column column-${type} mb-5`}>
                  <h4>{title}</h4>
                  <Component
                    fieldName={field.name}
                    recordVersion={rvFromList}
                    title={title}
                    recordVersionID={vendor?.id}
                    hasErrorClass="detail-view-has-errors"
                    exposeData={saveData}
                    selectedOptionsParent={initialValue}
                    initialValue={initialValue}
                    isMultiple={field.is_multiselect}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

BuildVendor.defaultProps = {
  vendor: null,
  isNew: false,
};

export default observer(BuildVendor);
