import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiPlusBold } from "react-icons/pi";

import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";

import Loading from "../Loading";
import ListSettings from "../table/settings/ListSettings";
import ListTitleSettings from "../table/settings/ListTitleSettings";
import Table from "../table/Table";
import CompanyTagsNavigation from "./CompanyTagsNavigation";

export type TagModel = "Tag" | "Department" | "Product";
export type TagItem = "tags" | "departments" | "products";

const headers = [
  {
    field_name: "title",
    title: "Title",
    width: 100,
  },
];

type Props = {
  items: TagItem;
  model: TagModel;
};

function CompanyTags({ items, model }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [newRecord, setNewRecord] = useState<object | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { company } = mainStore.companies;
  let fields;
  switch (items) {
    case "tags":
      fields = mainStore.tags.tags;
      break;
    case "departments":
      fields = mainStore.departments.departments;
      break;
    case "products":
      fields = mainStore.products.products;
      break;
    default:
      throw new Error(`unexpected case ${items}`);
  }

  useEffect(() => {
    (async () => {
      if (company?.id) {
        await mainStore[items].index(company.id);
        setLoading(false);
      }
    })();
  }, [company]);

  function addNewItem() {
    if (!newRecord) {
      setNewRecord({});
    }
  }

  function removeNewItem() {
    setNewRecord(null);
  }

  return (
    <Table>
      <div className="settings-wrap">
        <CompanyTagsNavigation />
        <AddRecordHeader
          recordName={items[0].toUpperCase() + items.slice(1, items.length - 1)}
          addRecord={addNewItem}
        />
        <div className="settings-content-wrap">
          <div className="settings-explainer">
            What are tags? <br />
            Tags can be used to categorize your records. They are company
            specific and shared across all of a company&apos;s internal and
            collaborative workspaces.
          </div>
          <div className="list-title-table">
            <ul>
              <div className="list-title-table-wrap">
                {headers.map((header) => (
                  <ListTitleSettings
                    key={header.field_name}
                    width="calc(100% - 7px)"
                    title={header.title}
                  />
                ))}
              </div>
              <div className="list-title-table-block" />
            </ul>
          </div>
          {newRecord && (
            <ListSettings
              key="new_record"
              headers={headers}
              data={newRecord}
              model={model}
              isNewRecord
              removeNewEntry={removeNewItem}
            />
          )}
          {loading && (
            <Loading loadingLayout="small-table" showTableHeader={false} />
          )}
          {!loading && (
            <>
              {fields.map((field) => (
                <ListSettings
                  inTag
                  key={field.id}
                  headers={headers}
                  data={field}
                  model={model}
                />
              ))}
            </>
          )}
        </div>
        <div
          className="tw-ml-4 tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
          onClick={addNewItem}
          data-testid="new-tag-button"
        >
          <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
          <div
            aria-label={`Add ${model}...`}
            className="tw-neutral-300 tw-text-sm"
          >
            Add {model}...
          </div>
        </div>
      </div>
    </Table>
  );
}

export default observer(CompanyTags);
