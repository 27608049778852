import React, { ReactNode } from "react";
import { Control } from "react-hook-form";

import { TodoItemsFormValues, TodosFieldsTypes } from "../types";
import TextField from "./text-field/TextField";

type Props = {
  control: Control<TodoItemsFormValues>;
  fieldData: TodosFieldsTypes;
  locked?: boolean;
};

function TodoItemsDetailFieldRenderer({ control, fieldData, locked }: Props) {
  const fieldComponents: { [key: string]: ReactNode } = {
    name: (
      <TextField
        width="100%"
        name="name"
        control={control}
        hasDetailView
        locked={locked}
        autoFocus
      />
    ),
  };

  const selectedComponent = fieldComponents[fieldData.field_name];

  return selectedComponent || null;
}

export default TodoItemsDetailFieldRenderer;
