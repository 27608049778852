import React from "react";
import { useHistory } from "react-router-dom";

import { Todo } from "@/api";
import { User } from "@/stores/types/user-types";

import { todoFields } from "../constants";
import TodosContextMenu from "./TodosContextMenu";
import TodosRowFieldRenderer from "./TodosRowFieldRenderer";

type TodosRowProps = {
  todo: Todo;
  refetch: VoidFunction;
  users: User[];
};

function TodosRow({ todo, refetch, users }: TodosRowProps) {
  // Hooks
  const history = useHistory();

  function onRowClick() {
    history.push(`/todos/${todo.id}`);
  }

  return (
    <form onClick={onRowClick} className="todo-row">
      <div className="list-table">
        <ul>
          <div className="list-table-wrap">
            <div className="list-table-block">
              {todoFields.map((field) => {
                const createdBy = users.find(
                  (u) => u.id === todo.created_by_id,
                );
                return (
                  <TodosRowFieldRenderer
                    todo={todo}
                    key={field.field_name}
                    fieldData={field}
                    keyForColor={todo?.status?.toLocaleLowerCase()}
                    createdBy={createdBy}
                  />
                );
              })}
              <span className="stretch-cell" />
            </div>
          </div>
          <TodosContextMenu todoID={todo.id} refetch={refetch} />
        </ul>
      </div>
    </form>
  );
}

export default TodosRow;
