import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateTodoMutationRequest,
  UpdateTodoMutationResponse,
  UpdateTodoPathParams,
} from "../../models/UpdateTodo";

/**
 * @description Update todo
 * @summary Update todo
 * @link /todos/:id
 */
export async function updateTodo(
  id: UpdateTodoPathParams["id"],
  data: UpdateTodoMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateTodoMutationResponse>["data"]> {
  const res = await client<
    UpdateTodoMutationResponse,
    UpdateTodoMutationRequest
  >({
    method: "put",
    url: `/todos/${id}`,
    data,
    ...options,
  });
  return res.data;
}
