import React from "react";
import { generatePath, useLocation, useParams } from "react-router-dom";

import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";

import { ACCOUNT_DETAILS_PATH, ACCOUNT_ID_PARAM } from "..";
import { Subtitle } from "../../components/Subtitle";
import { AccountLocationState } from "../AccountDetails";
import { NewChildAccountInfo } from "./NewChildAccountInfo";

export function NewChildAccountDetails() {
  const location = useLocation<AccountLocationState>();

  const { workspace_id, accountId } = useParams<{
    workspace_id: string;
    [ACCOUNT_ID_PARAM]: string;
  }>();

  return (
    <PageLayout>
      <Header
        backButtonLinkTo={
          location.state?.from ||
          generatePath(ACCOUNT_DETAILS_PATH, { workspace_id, accountId })
        }
        title={
          <div className="tw-align-center tw-flex tw-gap-1">
            Accounts
            <Subtitle>Untitled</Subtitle>
          </div>
        }
      />
      <PageContent>
        <NewChildAccountInfo />
      </PageContent>
    </PageLayout>
  );
}
