import "./styles.scss";

import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import * as EventNotificationsAPI from "@/api/legacy/event-notifications";
import LoadingWrapper from "@/components/loading-wrapper";
import DashboardContent from "@/components/shared/DashboardContent";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import { NavTabContainer } from "@/components/shared/nav-tab-container";
import HeadSelect from "@/components/table/shared/HeadSelect";
import { useMainStore } from "@/contexts/Store";
import { useLoadableData } from "@/hooks/useLoadableData";
import { useLoading } from "@/hooks/useLoading";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";
import { NOTIFICATION_TYPE } from "@/stores/types/notification-rule-types";

import { MessageGroup } from "../../../../components/shared/message-group";
import AutoUnlockNotificationRow from "../../components/auto-unlock-notification-row";
import { DateNotificationRow } from "../../components/date-notification-row";
import EventNotificationRow from "../../components/event-notification-row";
import { ModuleNotificationParams } from "../../types/types";
import {
  DATE_TAB,
  EVENT_TAB,
  MODULE_NOTIFICATIONS_BASE_PATH,
  MODULE_NOTIFICATIONS_CREATE_PATH,
  MODULE_NOTIFICATIONS_TAB_PATH,
  UNLOCK_TASK_TAB,
} from "../constants";

function ModuleNotificationsListPage({
  moduleWorkspaceID,
}: {
  moduleWorkspaceID: number;
}) {
  const mainStore = useMainStore();
  const history = useHistory();
  const params = useParams<ModuleNotificationParams>();

  const isAllTab = !params.tab;
  const isDateTab = params.tab === DATE_TAB;
  const isEventTab = params.tab === EVENT_TAB;
  const isUnlockTaskTab = params.tab === UNLOCK_TASK_TAB;
  const autoUnlockEnabled =
    params.moduleIdentifier === "policy" &&
    mainStore.featureFlags.getIsEnabled(FEATURE_FLAG_ID.SELF_AUTO_UNLOCK);

  const { themisModuleIdentifier: moduleIdentifier, workspaceID } =
    mainStore.context;

  const {
    data: { notification_rules: generalDateNotificationRules },
    fieldsForSelectedModule,
  } = mainStore.notificationsRules;
  const dateNotificationRules = (generalDateNotificationRules || []).filter(
    (rule) =>
      rule.data.notification_type.options.includes(
        NOTIFICATION_TYPE.REMINDER,
      ) ||
      rule.data.notification_type.options.includes(NOTIFICATION_TYPE.OVERDUE),
  );
  const autoUnlockNotificationRules = (
    generalDateNotificationRules || []
  ).filter((rule) =>
    rule.data.notification_type.options.includes(NOTIFICATION_TYPE.AUTO_UNLOCK),
  );

  const tabs = useMemo(() => {
    const initialTabs = [
      {
        label: "All",
        link: generatePath(MODULE_NOTIFICATIONS_BASE_PATH, {
          ...params,
          workspace_id: workspaceID!,
        }),
      },
      {
        label: "Date Based",
        link: generatePath(MODULE_NOTIFICATIONS_TAB_PATH, {
          ...params,
          tab: DATE_TAB,
        }),
      },
      {
        label: "Event Based",
        link: generatePath(MODULE_NOTIFICATIONS_TAB_PATH, {
          ...params,
          tab: EVENT_TAB,
        }),
      },
    ];
    if (autoUnlockEnabled) {
      initialTabs.push({
        label: "Unlock Task Based",
        link: generatePath(MODULE_NOTIFICATIONS_TAB_PATH, {
          ...params,
          tab: UNLOCK_TASK_TAB,
        }),
      });
    }

    return initialTabs;
  }, [workspaceID, autoUnlockEnabled]);

  useEffect(() => {
    if (moduleWorkspaceID) {
      mainStore.notificationsRules.index(moduleWorkspaceID);
      mainStore.notificationsRules.getFields(moduleWorkspaceID);
    }
  }, [moduleWorkspaceID]);

  useEffect(
    () => () => {
      mainStore.notificationsRules.cleanup();
    },
    [],
  );

  const loading = useLoading(fieldsForSelectedModule);

  const eventNotifications = useLoadableData({
    request: () =>
      EventNotificationsAPI.getEventNotifications(moduleWorkspaceID),
    enabled: !!moduleWorkspaceID,
    onError: () =>
      "There was an issue loading the notifications data. Please refresh to try again.",
  });

  const data = dateNotificationRules && eventNotifications.data;

  return (
    <DashboardContent>
      <DashboardHeader
        title="All Module Notifications"
        onBackClick={() => {
          history.push(
            // @ts-expect-error TS(2538) FIXME: Type 'null' cannot be used as an index type.
            mainStore.moduleWorkspaces.moduleInfo[moduleIdentifier].url,
          );
        }}
        RightActionBar={<HeadSelect />}
        subHeader={<NavTabContainer tabs={tabs} />}
      />
      <DashboardContentWrapper>
        <LoadingWrapper
          loading={loading || eventNotifications.loading}
          errorText={eventNotifications.errorText}
          loadingLayout="table"
        >
          {data && (
            <div className="notifications-container tw-flex tw-flex-col tw-content-center tw-justify-center tw-gap-6">
              {(isAllTab || isDateTab) && (
                <MessageGroup
                  title="Date Based Notifications"
                  description="Customize your email type, frequency, time range etc."
                  addButtonText="Add Date Based Notification"
                  addButtonLink={generatePath(
                    MODULE_NOTIFICATIONS_CREATE_PATH,
                    { ...params, tab: DATE_TAB },
                  )}
                  initialStateDescription="No Notifications yet. Click “Add Date Based Notification” to get started."
                >
                  {dateNotificationRules.length && (
                    <div className="notifications-container__list tw-flex tw-flex-col">
                      {dateNotificationRules.map((notificationRule) => (
                        <DateNotificationRow
                          key={notificationRule.global_id}
                          notificationRule={notificationRule}
                          fieldsForSelectedModule={fieldsForSelectedModule}
                        />
                      ))}
                    </div>
                  )}
                </MessageGroup>
              )}
              {(isAllTab || isEventTab) && (
                <MessageGroup
                  title="Event Based Notifications"
                  description="Select module events to trigger email notifications"
                  addButtonText="Add Event Based Notification"
                  addButtonLink={generatePath(
                    MODULE_NOTIFICATIONS_CREATE_PATH,
                    { ...params, tab: EVENT_TAB },
                  )}
                  initialStateDescription="No Notifications yet. Click “Add Event Based Notification” to get started."
                >
                  {eventNotifications.data.event_notification_rules.length && (
                    <div className="notifications-container__list tw-flex tw-flex-col">
                      {eventNotifications.data.event_notification_rules.map(
                        (eventNotification) => (
                          <EventNotificationRow
                            key={eventNotification.id}
                            eventNotification={eventNotification}
                            columns={eventNotifications.data.recipients.columns}
                            events={eventNotifications.data.events}
                          />
                        ),
                      )}
                    </div>
                  )}
                </MessageGroup>
              )}
              {autoUnlockEnabled && (isAllTab || isUnlockTaskTab) && (
                <MessageGroup
                  title="Unlock Task Notifications"
                  description="Auto unlock tasks"
                  addButtonText="Add Unlock Task Notification"
                  addButtonLink={generatePath(
                    MODULE_NOTIFICATIONS_CREATE_PATH,
                    { ...params, tab: UNLOCK_TASK_TAB },
                  )}
                  initialStateDescription="No Notifications yet. Click “Add Unlock Task Notification” to get started."
                >
                  {autoUnlockNotificationRules.length && (
                    <div className="notifications-container__list tw-flex tw-flex-col">
                      {autoUnlockNotificationRules.map((notificationRule) => (
                        <AutoUnlockNotificationRow
                          key={notificationRule.id}
                          notificationRule={notificationRule}
                          fieldsForSelectedModule={fieldsForSelectedModule}
                        />
                      ))}
                    </div>
                  )}
                </MessageGroup>
              )}
            </div>
          )}
        </LoadingWrapper>
      </DashboardContentWrapper>
    </DashboardContent>
  );
}

export default observer(ModuleNotificationsListPage);
