import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import arrowRightIcon from "../../../images/table-image/icon/arrow-right.svg";
import resyncIcon from "../../../images/table-image/icon/resync.svg";

function MarketingCreativeViewStages() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const location = useLocation();
  const status = mainStore.recordVersions.current?.status;
  const isOnAudit = location.pathname.endsWith("audit_trail");

  return (
    <section className="creative-stages">
      {!isOnAudit && (
        <>
          <span>Creative Stage:</span>
          <div className={classNames("new", { active: status === "new" })}>
            New
          </div>
          <img src={arrowRightIcon} alt="arrow-right" />
          <div
            className={classNames("reviewing", {
              active: status === "reviewing",
            })}
          >
            Reviewing
          </div>
          <img src={resyncIcon} alt="resync" />
          <div
            className={classNames("request-change", {
              active: status === "request_change",
            })}
          >
            Request Change
          </div>
          <img src={arrowRightIcon} alt="arrow-right" />
          {status !== "Rejected" && (
            <div
              className={classNames("approved", {
                active: status === "Approved",
              })}
            >
              Approved
            </div>
          )}
          {status === "Rejected" && (
            <div
              className={classNames("denied", {
                active: status === "Rejected",
              })}
            >
              Denied
            </div>
          )}
        </>
      )}
    </section>
  );
}

export default observer(MarketingCreativeViewStages);
