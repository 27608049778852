import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import AddNewSectionButton from "@/components/shared/AddNewSectionButton";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import { useFilters } from "@/hooks/useFilters";
import { useLoading } from "@/hooks/useLoading";
import { useTableData } from "@/hooks/useTableData";

import { useScrollToError } from "../../../hooks/useScrollToError";
import ExportBulk from "../../dashboard/ExportBulk";
import ImportBulk from "../../dashboard/ImportBulk";
import Loading from "../../Loading";
import CreateCTACW from "../shared/CreateCTACW";
import CreateFirstCTA from "../shared/CreateFirstCTA";
import NewSectionTag from "../shared/NewSectionTag";
import SectionDragContext from "../shared/SectionDragContext";
import Table from "../Table";
import MarketingSection from "./MarketingSection";

type Props = {
  defaultScreen?: string;
  isDragAndDrop?: boolean;
};

const SCREENS = {
  Active: {
    label: "Active",
    url: "/modules/marketing",
  },
  Completed: {
    label: "Completed",
    url: "/modules/marketing/completed",
  },
};

function Marketing({ isDragAndDrop, defaultScreen }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [currentScreen, setCurrentScreen] = useState(defaultScreen);
  const [showAddNewSection, setShowAddNewSection] = useState(false);

  // Variables
  const { data } = mainStore.creatives;
  // @ts-expect-error TS(2339) FIXME: Property 'module_workspace_id' does not exist on t... Remove this comment to see the full error message
  const moduleWorkspaceID = data?.module_workspace_id;
  const fields = mainStore.fields.visibleFields;
  const recordVersions = mainStore.recordVersions.list;
  const sectionTags = mainStore.sectionTags.orderedList;
  const { isIW, tableName, workspaceID } = mainStore.context;
  const { canManageSections } = mainStore.userPermissions;
  const isActiveScreen = currentScreen === "Active";
  const isCompletedScreen = currentScreen === "Completed";
  const isReviewScreen = currentScreen === "ReviewProcess";
  const recordVersionsNoSections = recordVersions.filter(
    (recordVersion) => !recordVersion.section_tag_id,
  );

  // Hooks
  const loading = useLoading(fields);
  const history = useHistory();
  useTableData();
  useScrollToError();

  const { filtersTrigger, filtersViewEnabled, filtersContent } = useFilters({
    fields,
  });

  // Effects
  useEffect(() => {
    if (
      mainStore.sectionTags.current &&
      Object.keys(mainStore.sectionTags.current).length !== 0
    ) {
      setShowAddNewSection(false);
    }
  }, [mainStore.sectionTags.current]);

  useEffect(() => {
    mainStore.creatives.setDefaultPages(sectionTags);
  }, [sectionTags]);

  useEffect(() => {
    mainStore.creatives.setLastTab(currentScreen);
  }, [currentScreen]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'newScreen' implicitly has an 'any' type... Remove this comment to see the full error message
  function handleScreenChange(newScreen) {
    mainStore.creatives.index({
      workspaceID,
      tab: newScreen,
    });
    setCurrentScreen(newScreen);
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    history.push(`/workspaces/${workspaceID}${SCREENS[newScreen].url}`);
  }

  function addNewSection() {
    setShowAddNewSection(!showAddNewSection);
  }

  function renderCTA() {
    return isIW ? (
      <CreateFirstCTA subject="Creative" />
    ) : (
      <CreateCTACW subject="Creative" />
    );
  }

  function getFilteredFields() {
    let items = fields;

    if (isReviewScreen) {
      items = items.filter(
        (field) =>
          !(
            [
              "status",
              "submission_date",
              "name",
              "type",
              "owner",
              "product",
            ].includes(field.name) ||
            field.computed_column_identifier === "record_version"
          ),
      );
    } else if (isActiveScreen) {
      items = items.filter((field) => field.name !== "deny_reason");
    }

    return items;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionId' implicitly has an 'any... Remove this comment to see the full error message
  const onSectionChange = (recordVersionId, sectionId) => {
    mainStore.creatives.updateSection(recordVersionId, sectionId);
  };

  // elements
  // @ts-expect-error TS(7006) FIXME: Parameter 'screen' implicitly has an 'any' type.
  const renderTabButton = (screen) => (
    <button
      key={screen}
      className={classNames({ active: currentScreen === screen })}
      onClick={() => handleScreenChange(screen)}
      data-testid={`marketing-tab-${screen}-trigger`}
    >
      {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
      {SCREENS[screen].label}
    </button>
  );

  return (
    <Table>
      {loading && <Loading loadingLayout="table" showTableHeader={false} />}

      {!loading && (
        <>
          <div className={classNames("table-header-wrap")}>
            <div className="switch-table-wrap">
              <div className="buttons-block" data-testid="marketing-main-tabs">
                {Object.keys(SCREENS).map(renderTabButton)}
              </div>
            </div>
            {isReviewScreen && (
              <div className="marketing-header-statuses">
                <div className="marketing-status-letter reviewing">R</div>
                <span>Reviewing</span>
                <div className="marketing-status-letter request_change">C</div>
                <span>Request Change</span>
              </div>
            )}
            <div
              className="import-export-buttons-container"
              data-testid="import-export-container"
            >
              <ExportBulk isExportFilesEnabled />
              {!isCompletedScreen && <ImportBulk topLevelSection />}
              {filtersTrigger}
            </div>
          </div>
          {isActiveScreen && (
            <AddRecordHeader
              addSection={addNewSection}
              addRecord={() =>
                mainStore.creatives.create(Number(workspaceID), null)
              }
              recordName="Creative"
              canAddSection={fields.length > 0}
            />
          )}
          {filtersViewEnabled && (
            <div className="filters-wrap">
              <div className="switch-table-wrap" />
              {filtersContent}
            </div>
          )}
          <div
            className="table-list-wrap marketing-content"
            data-testid="marketing-table"
          >
            <SectionDragContext onSectionChange={onSectionChange}>
              <MarketingSection
                isDropdownOpen
                // @ts-expect-error TS(2322) FIXME: Type '"Drafts" | "Finalized" | "Archived" | "Polic... Remove this comment to see the full error message
                tableName={tableName}
                fields={getFilteredFields()}
                recordVersions={recordVersionsNoSections}
                moduleWorkspaceID={moduleWorkspaceID}
                isDragAndDrop={isDragAndDrop}
                currentScreen={currentScreen}
                pageID={0}
              />

              {sectionTags.map((sectionTag) => {
                const filteredRecordVersions = recordVersions.filter(
                  (recordVersion) =>
                    recordVersion.section_tag_id === sectionTag.id,
                );
                if (filteredRecordVersions.length === 0 && !isActiveScreen) {
                  return null;
                }

                return (
                  <MarketingSection
                    key={sectionTag.id}
                    isDropdownOpen
                    // @ts-expect-error TS(2322) FIXME: Type '"Drafts" | "Finalized" | "Archived" | "Polic... Remove this comment to see the full error message
                    tableName={tableName}
                    fields={getFilteredFields()}
                    recordVersions={filteredRecordVersions}
                    moduleWorkspaceID={moduleWorkspaceID}
                    isDragAndDrop={isDragAndDrop}
                    sectionTag={sectionTag}
                    currentScreen={currentScreen}
                    pageID={sectionTag.id}
                  />
                );
              })}

              {showAddNewSection && (
                <NewSectionTag
                  moduleWorkspaceID={moduleWorkspaceID}
                  onCancel={() => setShowAddNewSection(false)}
                />
              )}

              {fields.length > 0 && isActiveScreen && canManageSections && (
                <div className="tw-sticky tw-left-[25px] tw-ml-[25px]">
                  <AddNewSectionButton onClick={addNewSection} />
                </div>
              )}

              {recordVersions.length < 3 && renderCTA()}
            </SectionDragContext>
          </div>
        </>
      )}
    </Table>
  );
}

Marketing.defaultProps = {
  defaultScreen: "Active",
};

export default observer(Marketing);
