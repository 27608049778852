import { zodResolver } from "@hookform/resolvers/zod";
import {
  Avatar,
  Button,
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectProps,
  TextArea,
  TextInput,
} from "@themis/ui";
import { format, parseISO } from "date-fns";
import React, {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { ControllerRenderProps, useForm } from "react-hook-form";
import { z } from "zod";

import { CompanyUser, Relatable, Task, UpdateTaskRequest } from "@/api";
import { getWorkspaceLogo } from "@/components/helpers/getWorkspaceLogo";
import { Workspace } from "@/stores/types/workspace-types";

import { TASK_STATUSES } from "../config/status";
import { AssociatedRecordRow } from "./AssociatedRecordRow";
import MultiUserSelect from "./MultiuserSelect";
import RecordSelect from "./Select/RecordSelect";

const formSchema = z.object({
  name: z.string().min(1, "Task name is required"),
  assignee_id: z.number().nullable().optional(),
  description: z.string().optional(),
  due_date: z.string().nullable().optional(),
  status: z.enum(["Not Started", "In Progress", "Done"]),
  workspace_id: z.number().nullable().optional(),
});

export type TaskDetailFormSchema = z.infer<typeof formSchema>;

type TaskDetailFormProps = {
  workspaces: Workspace[];
  users: CompanyUser[];
  currentWorkspaceId: number | null;
  defaultValues?: Partial<Task>;
  onSubmit: (
    formValues: TaskDetailFormSchema,
    taskables?: Relatable[],
    collaboratorIds?: number[],
  ) => void;
  onClickArchive: MouseEventHandler<HTMLButtonElement>;
  onFieldChange: (updatedValue: Partial<UpdateTaskRequest["task"]>) => void;
  formDidChange: boolean;
  setFormDidChange: Dispatch<SetStateAction<boolean>>;
  onRecordSelect: (record: Relatable) => void;
  onRecordDelete: (id: number) => void;
};

export default function TaskDetailForm({
  users,
  workspaces,
  currentWorkspaceId,
  defaultValues,
  onSubmit,
  onClickArchive,
  onFieldChange,
  formDidChange,
  setFormDidChange,
  onRecordSelect,
  onRecordDelete,
}: TaskDetailFormProps) {
  const isNew = !defaultValues?.id;
  const isArchived = Boolean(defaultValues?.archived_at);
  const isProjectTask = Boolean(defaultValues?.parent_id);

  const [newSelectedCollaboratorIds, setNewSelectedCollaboratorIds] = useState<
    number[]
  >([]);
  const [newSelectedTaskables, setNewSelectedTaskables] = useState<Relatable[]>(
    () => {
      if (!defaultValues?.taskables) {
        return [];
      }
      return defaultValues.taskables
        .map((taskable) => taskable.target)
        ?.filter(Boolean) as Relatable[];
    },
  );

  const form = useForm<TaskDetailFormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: defaultValues?.name || "",
      assignee_id: defaultValues?.assignee_id || null,
      description: defaultValues?.description || "",
      due_date: defaultValues?.due_date ? defaultValues?.due_date : null,
      status: defaultValues?.status || "Not Started",
      workspace_id: isProjectTask
        ? null
        : defaultValues?.workspace_id || currentWorkspaceId,
    },
    mode: "onBlur",
  });

  const usersForSelect: SelectProps["items"] = users
    .filter((user) => {
      return user.is_active || user.id === defaultValues?.assignee_id;
    })
    .map((user: CompanyUser) => {
      return {
        label: user.full_name as string,
        value: user.id.toString(),
        Component: ({ label }) => (
          <span className="tw-flex tw-space-x-2">
            <Avatar
              size="sm"
              colorIndex={user.icon_color_index}
              variant={!user.is_active ? "pending" : undefined}
            >
              {user.initials}
            </Avatar>
            <span>{label}</span>
          </span>
        ),
      };
    });
  const workspaceItems: SelectProps["items"] = workspaces.map((workspace) => ({
    label: workspace.name,
    value: String(workspace.id),
    Component: () => (
      <div className="tw-flex tw-items-center tw-gap-2">
        <img
          className="tw-w-[20px] tw-rounded"
          src={getWorkspaceLogo(workspace).logo}
        />
        <span className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
          {workspace.name}
        </span>
      </div>
    ),
  }));

  useEffect(() => {
    if (Object.keys(form.formState.dirtyFields).length > 0) {
      setFormDidChange(true);
    }
  }, [Object.keys(form.formState.dirtyFields).length]);

  useEffect(() => {
    form.reset({}, { keepValues: true });
  }, [form.formState.isSubmitSuccessful]);

  // Handles submit onclick of Create Task button, which only appears if the task is new
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!isNew) {
      return;
    }

    form.handleSubmit((formData) =>
      onSubmit(formData, newSelectedTaskables, newSelectedCollaboratorIds),
    )();
  }

  function handleSubmitOnBlur(updatedValue: Partial<TaskDetailFormSchema>) {
    if (isNew || !formDidChange) {
      return;
    }

    form.handleSubmit(() => onFieldChange(updatedValue))();
  }

  async function handleSubmitOnChange(
    updatedValue: Partial<TaskDetailFormSchema>,
  ) {
    if (isNew) {
      return;
    }
    form.handleSubmit(() => onFieldChange(updatedValue))();
  }

  function handleAssigneeSelect(
    value: number,
    field: ControllerRenderProps<TaskDetailFormSchema, "assignee_id">,
  ) {
    if (field.value === value) {
      field.onChange(null);
      handleSubmitOnChange({ assignee_id: null });
    } else {
      field.onChange(value);
      handleSubmitOnChange({ assignee_id: value });
    }
  }

  function handleRecordSelect(record: Relatable) {
    if (isNew) {
      setNewSelectedTaskables((prevTaskables) => [...prevTaskables, record]);
    } else {
      onRecordSelect(record);
    }
  }

  function handleCollaboratorSelect(collaboratorIds: number[]) {
    if (isNew) {
      setNewSelectedCollaboratorIds(collaboratorIds);
    } else {
      onFieldChange({ collaborator_ids: collaboratorIds });
    }
  }

  function handleDeleteNewTaskable(id: number) {
    setNewSelectedTaskables((prevTaskables) =>
      prevTaskables.filter((taskable) => taskable.id !== id),
    );
  }

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="tw-p tw-space-y-4">
        <FormField
          required
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Task Name</FormLabel>
              <FormControl>
                <TextInput
                  {...field}
                  onBlur={() => handleSubmitOnBlur({ name: field.value })}
                  readOnly={isArchived}
                  autoComplete="off"
                  autoFocus={isNew}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="assignee_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Assigned to</FormLabel>
              <FormControl>
                <Select
                  readOnly={isArchived}
                  searchable
                  items={usersForSelect}
                  selected={field.value ? String(field.value) : null}
                  onSelect={(value: string) =>
                    handleAssigneeSelect(Number(value), field)
                  }
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div>
          <label className="tw-font-sans tw-text-xs tw-font-semibold tw-leading-none tw-tracking-wide tw-text-neutral-300">
            Collaborators
          </label>
          <MultiUserSelect
            users={users}
            selectedUserIds={
              isNew
                ? newSelectedCollaboratorIds
                : defaultValues?.collaborator_ids || []
            }
            onSelect={(collaborator_ids) =>
              handleCollaboratorSelect(collaborator_ids)
            }
          />
        </div>
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <TextArea
                  {...field}
                  onBlur={() =>
                    handleSubmitOnBlur({ description: field.value })
                  }
                  readOnly={isArchived}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="due_date"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Due Date</FormLabel>
              <FormControl>
                <DatePicker
                  readOnly={isArchived}
                  dateFormat="MM/dd/yyyy"
                  calendarProps={{
                    mode: "single",
                    selected: field.value ? parseISO(field.value) : undefined,
                    onSelect: (value) => {
                      if (value) {
                        const formattedDate = format(value, "yyyy-MM-dd");
                        field.onChange(formattedDate);
                        handleSubmitOnChange({ due_date: formattedDate });
                      }
                    },
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="status"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Status</FormLabel>
              <FormControl>
                <Select
                  readOnly={isArchived}
                  items={TASK_STATUSES}
                  selected={field.value}
                  onSelect={(value: string) => {
                    field.onChange(value);
                    handleSubmitOnChange({
                      status: value as "Not Started" | "In Progress" | "Done",
                    });
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="workspace_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Workspace</FormLabel>
              <FormControl>
                <Select
                  readOnly={isArchived || isProjectTask}
                  items={workspaceItems}
                  selected={field.value ? String(field.value) : null}
                  onSelect={(value) => {
                    field.onChange(Number(value));
                    handleSubmitOnChange({
                      workspace_id: Number(value),
                    });
                  }}
                />
              </FormControl>
              <FormMessage>
                {isProjectTask && (
                  <span className="tw-text-neutral-300">
                    Workspace for project tasks is managed at the project level
                  </span>
                )}
              </FormMessage>
            </FormItem>
          )}
        />
        <div className="tw-grid tw-gap-2">
          <div className="tw-flex tw-items-center tw-justify-between">
            <h3 className="tw-text-base tw-font-semibold tw-text-neutral-500">
              Associated Records
            </h3>
            <RecordSelect
              ignoredRecordIds={
                isNew
                  ? newSelectedTaskables.map((taskable) => taskable.id)
                  : defaultValues?.taskables?.map(
                      (taskable) => taskable.target?.id || 0,
                    )
              }
              isDisabled={isArchived}
              onRecordSelect={handleRecordSelect}
            />
          </div>
          <div>
            {isNew
              ? newSelectedTaskables.map((taskable) => (
                  <AssociatedRecordRow
                    key={taskable.id}
                    relatedRecord={taskable}
                    onClickDelete={handleDeleteNewTaskable}
                  />
                ))
              : defaultValues?.taskables?.map((relatedTask) => (
                  <AssociatedRecordRow
                    taskable={relatedTask}
                    key={relatedTask.id}
                    relatedRecord={relatedTask.target}
                    onClickDelete={isArchived ? undefined : onRecordDelete}
                  />
                ))}
          </div>
        </div>
        {isNew ? (
          <Button className="tw-mt-6" type="submit">
            Create Task
          </Button>
        ) : (
          <div className="tw-flex tw-justify-center ">
            {isArchived ? (
              <Button
                color="transparent"
                type="button"
                onClick={onClickArchive}
              >
                Unarchive Task
              </Button>
            ) : (
              <Button
                color="transparent"
                type="button"
                className="tw-text-[#EB2E4E]"
                onClick={onClickArchive}
              >
                Archive Task
              </Button>
            )}
          </div>
        )}
      </form>
    </Form>
  );
}
