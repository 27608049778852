import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

import ExportBulk from "@/components/dashboard/ExportBulk";
import {
  formatTimeAfterDate,
  stringToDateAndTime,
} from "@/components/helpers/DateFormatters";
import Loading from "@/components/Loading";
import { NavTabs } from "@/components/nav-tab";
import CreateFirstCTA from "@/components/table/shared/CreateFirstCTA";
import NewSectionTag from "@/components/table/shared/NewSectionTag";
import SectionDragContext from "@/components/table/shared/SectionDragContext";
import Table from "@/components/table/Table";
import ZendeskSection from "@/components/table/zendesk/ZendeskSection";
import { useMainStore } from "@/contexts/Store";
import { useFilters } from "@/hooks/useFilters";
import { useLoading } from "@/hooks/useLoading";
import { useTableData } from "@/hooks/useTableData";

import Button from "../../Elements/Button";
import { DisconnectedWelcome } from "./components/disconnected-welcome";

function Zendesk() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showAddNewSection, setShowAddNewSection] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);

  // Variables
  const { visibleFields } = mainStore.fields;
  const { tableName, workspaceID } = mainStore.context;
  const recordVersions = mainStore.recordVersions.list?.filter(
    (recordVersion) => recordVersion.table_name === tableName,
  );
  const { data } = mainStore.zendesk;
  const zendeskConfigurationId = data?.zendesk_configuration_id;
  const latestSyncDate = data?.latest_sync_date;
  const moduleWorkspaceID = data?.module_workspace_id;
  const sectionTags = mainStore.sectionTags.orderedList;
  const { canManageSections, hasModuleWriteAccess } = mainStore.userPermissions;
  const isActiveMatch = useRouteMatch({
    path: "/workspaces/:workspace_id/modules/zendesk",
    exact: true,
  });

  const tabs = [
    {
      label: "Active",
      link: `/workspaces/${workspaceID}/modules/zendesk`,
    },
    {
      label: "Completed",
      link: `/workspaces/${workspaceID}/modules/zendesk/completed`,
    },
  ];

  // Hooks
  const loading = useLoading(visibleFields);
  useTableData();

  const { filtersTrigger, filtersViewEnabled, filtersContent } = useFilters({
    fields: visibleFields,
  });

  // Effects
  useEffect(() => {
    if (
      mainStore.sectionTags.current &&
      Object.keys(mainStore.sectionTags.current).length !== 0
    ) {
      setShowAddNewSection(false);
    }
  }, [mainStore.sectionTags.current]);

  // Functions
  function addNewSection() {
    setShowAddNewSection(!showAddNewSection);
  }

  async function synchronize() {
    if (zendeskConfigurationId) {
      await mainStore.zendesk.synchronize(zendeskConfigurationId);
    }
  }

  return (
    <Table>
      {loading && !mainStore.zendesk.disconnected && (
        <Loading loadingLayout="table" showTableHeader={false} />
      )}

      {mainStore.zendesk.disconnected && <DisconnectedWelcome />}

      {!loading && !mainStore.zendesk.disconnected && (
        <>
          <div
            className={classNames("table-header-wrap", {
              "header-with-filters": filtersViewEnabled,
            })}
          >
            {filtersViewEnabled ? (
              <>
                {filtersContent}
                <ExportBulk />
              </>
            ) : (
              <>
                <div className="switch-table-wrap">
                  <div className="buttons-block">
                    <NavTabs tabs={tabs} />
                  </div>
                </div>
                <div className="import-export-buttons-container">
                  {!!latestSyncDate && (
                    <div className="table-header-wrap__last-synchronization">
                      Date of last synchronization:{" "}
                      {formatTimeAfterDate(stringToDateAndTime(latestSyncDate))}
                    </div>
                  )}
                  <Button
                    label="Sync"
                    icon="sync"
                    onClick={synchronize}
                    className="table-header-wrap__synchronize"
                    data-testid="sync-button"
                    disabled={!hasModuleWriteAccess}
                  />
                  <ExportBulk />
                  {filtersTrigger}
                </div>
              </>
            )}
          </div>

          <div className="table-list-wrap table-training-wrap">
            <SectionDragContext>
              <ZendeskSection
                isDropdownOpen
                // @ts-expect-error TS(2322) FIXME: Type '"Active" | "Completed" | "Drafts" | "Finaliz... Remove this comment to see the full error message
                tableName={tableName}
                fields={visibleFields}
                recordVersions={recordVersions.filter(
                  (recordVersion) => !recordVersion.section_tag_id,
                )}
                moduleWorkspaceID={moduleWorkspaceID}
                showCheckbox={showCheckbox}
                setShowCheckbox={setShowCheckbox}
              />

              {sectionTags.map((sectionTag) => {
                const filteredRecordVersions = recordVersions.filter(
                  (recordVersion) =>
                    recordVersion.section_tag_id === sectionTag.id,
                );
                return (
                  <ZendeskSection
                    key={sectionTag.id}
                    isDropdownOpen
                    // @ts-expect-error TS(2322) FIXME: Type '"Active" | "Completed" | "Drafts" | "Finaliz... Remove this comment to see the full error message
                    tableName={tableName}
                    fields={visibleFields}
                    recordVersions={filteredRecordVersions}
                    moduleWorkspaceID={moduleWorkspaceID}
                    sectionTag={sectionTag}
                    showCheckbox={showCheckbox}
                    setShowCheckbox={setShowCheckbox}
                  />
                );
              })}

              {showAddNewSection && (
                <NewSectionTag
                  moduleWorkspaceID={moduleWorkspaceID}
                  onCancel={() => setShowAddNewSection(false)}
                />
              )}

              {canManageSections && isActiveMatch && (
                <div className="create-new-record add-section">
                  <button onClick={addNewSection}>Add New Section +</button>
                </div>
              )}
              {recordVersions.length === 0 && isActiveMatch && (
                <CreateFirstCTA subject="Zendesk" />
              )}
            </SectionDragContext>
          </div>
        </>
      )}
    </Table>
  );
}

export default observer(Zendesk);
