import { useQuery } from "@tanstack/react-query";

import { ListThemisRecordColumnsPathParamsThemisRecordClass } from "../gen";
import { listThemisRecordColumns } from "../gen/axios/columnsController";

export const COLUMNS_QUERY_KEY = "columns";

export function useColumns({
  workspaceId,
  recordClass,
}: {
  workspaceId: number;
  recordClass: ListThemisRecordColumnsPathParamsThemisRecordClass;
}) {
  return useQuery({
    queryKey: [COLUMNS_QUERY_KEY, recordClass, workspaceId],
    queryFn: () => listThemisRecordColumns(workspaceId, recordClass),
    enabled: !!workspaceId && !!recordClass,
  });
}
