import React, { useState } from "react";
import Popup from "reactjs-popup";

import closeIcon from "../../images/table-image/icon/close-black-icon.svg";

function LeaveWorkspacePopup() {
  // State
  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  return (
    <Popup
      trigger={
        <div
          className="edit-users-close"
          data-tooltip-id="tooltip"
          data-tooltip-content="Leave workspace"
          data-tooltip-place="right"
        >
          <img src={closeIcon} alt="close-icon" />
        </div>
      }
      position="bottom right"
      open={showPopup}
      onOpen={handleOpenPopup}
      onClose={() => setShowPopup(false)}
      keepTooltipInside
    >
      <div className="table-dropdown">
        <div>
          <h4>Leave Workspace</h4>
          <p>
            Action is non-reversable. You will lose all access to this
            workspace. Are you sure you want to proceed?
          </p>
          <div className="confirmation">
            <button onClick={() => setShowPopup(false)}>Yes</button>
            <button onClick={() => setShowPopup(false)}>No</button>
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default LeaveWorkspacePopup;
