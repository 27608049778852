import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateTodoMutationRequest,
  CreateTodoMutationResponse,
  CreateTodoPathParams,
} from "../../models/CreateTodo";

/**
 * @description Create todo
 * @summary Create todo
 * @link /companies/:company_id/todos
 */
export async function createTodo(
  companyId: CreateTodoPathParams["company_id"],
  data: CreateTodoMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateTodoMutationResponse>["data"]> {
  const res = await client<
    CreateTodoMutationResponse,
    CreateTodoMutationRequest
  >({
    method: "post",
    url: `/companies/${companyId}/todos`,
    data,
    ...options,
  });
  return res.data;
}
