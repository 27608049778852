import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import ControlLibraryApplyButton from "@/components/dashboard/actions/ControlLibraryApplyButton";
import ControlMappingLibraryTemplateButton from "@/components/table/control-mapping/ControlMappingLibraryTemplateButton";
import { useMainStore } from "@/contexts/Store";
import { useTableData } from "@/hooks/useTableData";
import { pathWithoutWorkspacePrefix } from "@/utils/routing";

import Table from "../Table";
import ControlMappingTable from "./ControlMappingTable";
import Library from "./Library";
import LibraryImport from "./LibraryImport";

type Props = {
  defaultScreen?: string;
  tableName?: string;
};

const SCREENS = {
  Active: {
    label: "Active",
    url: "/modules/control-mapping",
  },
  Completed: {
    label: "Completed",
    url: "/modules/control-mapping/completed",
  },
  ControlLibrary: {
    label: "Company Library",
    url: "/modules/control-mapping/templates",
  },
  ThemisControlLibrary: {
    label: "Themis Control Library",
    url: "/modules/control-mapping/themis-control-library",
  },
};

function ControlMapping({ defaultScreen }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  useTableData();

  // State
  const [activeScreen, setActiveScreen] = useState(defaultScreen);

  // Variables
  const history = useHistory();
  const location = useLocation();
  const { company } = mainStore.companies;

  // @ts-expect-error TS(2339) FIXME: Property 'module_workspace_id' does not exist on t... Remove this comment to see the full error message
  const moduleWorkspaceID = mainStore.controlMappings.data?.module_workspace_id;
  const { workspaceID, isIW: isInternalWorkspace } = mainStore.context;

  // effects
  useEffect(() => {
    if (!company?.id || mainStore.controlCategories.controlCategories?.length) {
      return;
    }

    mainStore.controlCategories.index(company.id);
  }, [company]);

  useEffect(() => {
    if (
      !company?.id ||
      mainStore.controlMappingTypes.controlMappingTypes?.length
    ) {
      return;
    }

    mainStore.controlMappingTypes.index(company.id);
  }, [company]);

  useEffect(() => {
    const screenKeys = Object.keys(SCREENS);
    const screensValues = Object.values(SCREENS);
    const index = screensValues.findIndex(
      (item) => item.url === pathWithoutWorkspacePrefix(location.pathname),
    );

    if (index < 0) {
      return;
    }

    const newScreen = screenKeys[index];

    if (activeScreen === newScreen) {
      return;
    }

    setActiveScreen(newScreen);
  }, [location.pathname]);

  // funcs
  // @ts-expect-error TS(7006) FIXME: Parameter 'newScreen' implicitly has an 'any' type... Remove this comment to see the full error message
  function handleScreenChange(newScreen) {
    mainStore.controlMappings.index({
      workspaceID,
      tab: newScreen,
    });

    setActiveScreen(newScreen);

    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    history.push(`/workspaces/${workspaceID}${SCREENS[newScreen].url}`);
  }

  // render
  // @ts-expect-error TS(7006) FIXME: Parameter 'screen' implicitly has an 'any' type.
  const renderTabButton = (screen) => (
    <button
      key={screen}
      className={classNames({ active: activeScreen === screen })}
      onClick={() => handleScreenChange(screen)}
      data-testid={`cm-tab-${screen}-trigger`}
    >
      {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
      {SCREENS[screen].label}
    </button>
  );

  const tabs = (
    <div className="buttons-block" data-testid="cm-main-tabs">
      {Object.keys(SCREENS).map(renderTabButton)}
    </div>
  );

  const renderHeader = (
    <div className="table-header-wrap">
      {tabs}
      <div className="import-export-buttons-container">
        {activeScreen === "ControlLibrary" && (
          <>
            {isInternalWorkspace && (
              <LibraryImport moduleWorkspaceID={moduleWorkspaceID} />
            )}
            <ControlLibraryApplyButton
              libraryType="company"
              handleScreenChange={handleScreenChange}
            />
          </>
        )}
        {activeScreen === "ThemisControlLibrary" && (
          <>
            <ControlLibraryApplyButton
              libraryType="themis"
              handleScreenChange={handleScreenChange}
            />
            {isInternalWorkspace && (
              <ControlMappingLibraryTemplateButton
                handleScreenChange={handleScreenChange}
              />
            )}
          </>
        )}
      </div>
    </div>
  );

  return (
    <Table>
      {activeScreen !== "Active" &&
        activeScreen !== "Completed" &&
        renderHeader}
      {activeScreen === "Active" && (
        <ControlMappingTable tabs={tabs} completed={false} lockTab={false} />
      )}
      {activeScreen === "Completed" && (
        <ControlMappingTable tabs={tabs} completed lockTab />
      )}
      {/* { activeScreen === 'Status' && <CMAttestationsList group='CM' moduleWorkspaceID={ moduleWorkspaceID } title='controls' /> } */}
      {activeScreen === "ThemisControlLibrary" && (
        <Library moduleWorkspaceID={moduleWorkspaceID} libraryType="themis" />
      )}
      {activeScreen === "ControlLibrary" && (
        <Library moduleWorkspaceID={moduleWorkspaceID} libraryType="company" />
      )}
      {(activeScreen === "ControlLibrary" ||
        activeScreen === "ThemisControlLibrary") && (
        <>
          {/* @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message */}
          {activeScreen === "Templates" && isInternalWorkspace && (
            <LibraryImport moduleWorkspaceID={moduleWorkspaceID} />
          )}
        </>
      )}
    </Table>
  );
}

ControlMapping.defaultProps = {
  defaultScreen: "Active",
  tableName: "Default",
};

export default observer(ControlMapping);
