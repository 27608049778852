import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { WebsiteMonitoringGroupResultUpdateMutationRequest } from "app/javascript/api/gen/models/WebsiteMonitoringGroupResultUpdate";

import { WebsiteMonitoringGroupChildQueryParams } from "../gen";
import {
  websiteMonitoringGroupAsset,
  websiteMonitoringGroupChild,
  websiteMonitoringGroupChildResults,
  websiteMonitoringGroupChildResultsUpdate,
  websiteMonitoringGroupResult,
  websiteMonitoringGroupResults,
  websiteMonitoringGroupResultUpdate,
  websiteMonitoringGroups,
} from "../gen/axios/websiteMonitoringController";

export const WEBSITE_MONITORING_GROUPS_QUERY_KEY = "website_monitoring_groups";
export const WEBSITE_MONITORING_RESULTS_QUERY_KEY =
  "website_monitoring_results";
export const WEBSITE_MONITORING_CHILDREN_QUERY_KEY =
  "website_monitoring_children";

export function useMonitoringGroups(companyId: number) {
  return useQuery({
    queryKey: [WEBSITE_MONITORING_GROUPS_QUERY_KEY, companyId],
    queryFn: () => websiteMonitoringGroups(companyId),
    enabled: !!companyId,
  });
}

export function useMonitoringGroupAsset(monitoringGroupAssetId: number) {
  return useQuery({
    queryKey: [WEBSITE_MONITORING_GROUPS_QUERY_KEY, monitoringGroupAssetId],
    queryFn: () => websiteMonitoringGroupAsset(monitoringGroupAssetId),
    enabled: !!monitoringGroupAssetId,
  });
}

export function useMonitoringGroupChildren(
  monitoringGroupAssetId: number,
  queryParams: WebsiteMonitoringGroupChildQueryParams = {},
) {
  return useQuery({
    queryKey: [
      WEBSITE_MONITORING_CHILDREN_QUERY_KEY,
      monitoringGroupAssetId,
      queryParams,
    ],
    queryFn: () =>
      websiteMonitoringGroupChild(monitoringGroupAssetId, queryParams),
    enabled: !!monitoringGroupAssetId && !!queryParams,
    placeholderData: (prevData) => prevData,
  });
}

export function useMonitoringGroupChildResults(
  monitoringGroupAssetChildId: number,
) {
  return useQuery({
    queryKey: [
      WEBSITE_MONITORING_RESULTS_QUERY_KEY,
      monitoringGroupAssetChildId,
    ],
    queryFn: () =>
      websiteMonitoringGroupChildResults(monitoringGroupAssetChildId),
    enabled: !!monitoringGroupAssetChildId,
  });
}

export function useMonitoringGroupResults(monitoringGroupAssetId: number) {
  return useQuery({
    queryKey: [WEBSITE_MONITORING_RESULTS_QUERY_KEY, monitoringGroupAssetId],
    queryFn: () => websiteMonitoringGroupResults(monitoringGroupAssetId),
    enabled: !!monitoringGroupAssetId,
  });
}

export function useMonitoringGroupResult(
  monitoringGroupResultId: number,
  monitoringGroupAssetId: number,
) {
  return useQuery({
    queryKey: [
      WEBSITE_MONITORING_RESULTS_QUERY_KEY,
      monitoringGroupAssetId,
      monitoringGroupResultId,
    ],
    queryFn: () => websiteMonitoringGroupResult(monitoringGroupResultId),
    enabled: !!monitoringGroupResultId,
  });
}

export function useMonitoringGroupChildResultsUpdate({
  monitoringGroupAssetChildId,
  monitoringGroupAssetId,
  onSuccess = () => {},
  onError = () => {},
}: {
  monitoringGroupAssetChildId: number;
  monitoringGroupAssetId?: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      websiteMonitoringGroupChildResultsUpdate(monitoringGroupAssetChildId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          WEBSITE_MONITORING_RESULTS_QUERY_KEY,
          monitoringGroupAssetId,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          WEBSITE_MONITORING_RESULTS_QUERY_KEY,
          monitoringGroupAssetChildId,
        ],
      });
      onSuccess();
    },
    onError,
  });
}

export function useMonitoringGroupResultUpdate({
  monitoringGroupResultId,
  monitoringGroupAssetChildId,
  monitoringGroupAssetId,
  onSuccess = () => {},
  onError = () => {},
}: {
  monitoringGroupResultId: number;
  monitoringGroupAssetChildId?: number;
  monitoringGroupAssetId?: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: WebsiteMonitoringGroupResultUpdateMutationRequest) =>
      websiteMonitoringGroupResultUpdate(monitoringGroupResultId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          WEBSITE_MONITORING_RESULTS_QUERY_KEY,
          monitoringGroupAssetId,
          monitoringGroupResultId,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          WEBSITE_MONITORING_RESULTS_QUERY_KEY,
          monitoringGroupAssetId,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          WEBSITE_MONITORING_RESULTS_QUERY_KEY,
          monitoringGroupAssetChildId,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          WEBSITE_MONITORING_RESULTS_QUERY_KEY,
          monitoringGroupResultId,
        ],
      });
      onSuccess();
    },
    onError,
  });
}
