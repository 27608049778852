var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { PiArrowsOutSimpleBold } from "react-icons/pi";
import { filterCellProps } from "../../../lib/utils";
import { Button } from "../../Button/Button";
import { LinkButton } from "../../Button/LinkButton";
import { TextInput } from "../../Form/TextInput";
function IdentifierCellEditor(_a) {
    var value = _a.value, onValueChange = _a.onValueChange, to = _a.to, onClick = _a.onClick, props = __rest(_a, ["value", "onValueChange", "to", "onClick"]);
    var filteredProps = filterCellProps(__assign({ value: value, onValueChange: onValueChange }, props));
    return (React.createElement("div", { className: "tw-group/parent tw-flex tw-h-full tw-w-full tw-items-center tw-px-2 tw-font-sans tw-font-medium tw-text-neutral-500" },
        React.createElement("div", { className: "tw-group/cell tw-flex tw-h-full tw-w-full tw-items-center tw-justify-between" },
            React.createElement(TextInput, __assign({ className: "tw-mr-1.5 tw-h-5 tw-rounded-[4px] tw-border-0 tw-px-0.5 focus-visible:!tw-border focus-visible:!tw-border-neutral-100 focus-visible:tw-ring-0 group-hover/cell:!tw-bg-white", onChange: function (e) { return onValueChange(e.target.value); }, placeholder: "Add Text", onBlur: function () { return props.stopEditing && props.stopEditing(); }, value: value, autoFocus: true }, filteredProps)),
            to ? (React.createElement(LinkButton, { className: "tw-mx-1 tw-hidden tw-h-7 tw-rounded-none tw-p-0 tw-px-2 tw-font-medium group-hover/cell:tw-flex", color: "transparent", to: to, RightIcon: PiArrowsOutSimpleBold }, "Open")) : (React.createElement(Button, { className: "tw-mx-1 tw-flex tw-h-7 tw-rounded-none tw-p-0 tw-px-2 tw-font-medium", color: "transparent", RightIcon: PiArrowsOutSimpleBold, onClick: onClick, onMouseDown: function (event) {
                    event.stopPropagation();
                    event.preventDefault();
                } }, "Open")))));
}
export default IdentifierCellEditor;
