import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  WebsiteMonitoringGroupChildResultsUpdateMutationResponse,
  WebsiteMonitoringGroupChildResultsUpdatePathParams,
} from "../../models/WebsiteMonitoringGroupChildResultsUpdate";

/**
 * @description Update Website Monitoring - Group result
 * @summary Update Website Monitoring group result
 * @link /website_monitoring/:monitoring_group_asset_child_id/monitoring_group_child_results_marked_resolved
 */
export async function websiteMonitoringGroupChildResultsUpdate(
  monitoringGroupAssetChildId: WebsiteMonitoringGroupChildResultsUpdatePathParams["monitoring_group_asset_child_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<WebsiteMonitoringGroupChildResultsUpdateMutationResponse>["data"]
> {
  const res =
    await client<WebsiteMonitoringGroupChildResultsUpdateMutationResponse>({
      method: "put",
      url: `/website_monitoring/${monitoringGroupAssetChildId}/monitoring_group_child_results_marked_resolved`,
      ...options,
    });
  return res.data;
}
