import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router-dom";

import { RecordVersion } from "@/api";
import AddNewSectionButton from "@/components/shared/AddNewSectionButton";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import { useFilters } from "@/hooks/useFilters";
import { useTableData } from "@/hooks/useTableData";

import { useLoading } from "../../../hooks/useLoading";
import ExportBulk from "../../dashboard/ExportBulk";
import Loading from "../../Loading";
import CreateFirstCTA from "../shared/CreateFirstCTA";
import SectionDragContext from "../shared/SectionDragContext";
import Table from "../Table";
import ChangeManagementSectionTag from "./ChangeManagementSectionTag";

function ChangeManagement() {
  // Import MobX stores
  const mainStore = useMainStore();

  useTableData();

  // Variables
  const { isCurrentWorkspaceActive } = mainStore.workspaces;
  const fields = mainStore.fields.visibleFields;
  const recordVersions = mainStore.recordVersions.list;
  const sectionTags = mainStore.sectionTags.orderedList;
  const { workspaceID } = mainStore.context;

  // Hooks
  const loading = useLoading(fields);
  const history = useHistory();

  const { filtersTrigger, filtersViewEnabled, filtersContent } = useFilters({
    fields: fields.filter((item) => !["checklist"].includes(item.name)),
  });

  const onSectionChange = (
    recordVersionId: RecordVersion,
    sectionId: number,
  ) => {
    mainStore.changeManagements.updateSection(recordVersionId, sectionId);
  };

  const addNewSection = () => {
    history.push(
      `/workspaces/${workspaceID}/modules/change-management/add-new-approval`,
    );
  };

  return (
    <Table>
      {loading && <Loading loadingLayout="sections" showTableHeader={false} />}

      {!loading && (
        <>
          <div className={classNames("table-header-wrap")}>
            <div className="switch-table-wrap" />
            <div className="import-export-buttons-container">
              <ExportBulk />
              {filtersTrigger}
            </div>
          </div>
          {filtersViewEnabled && (
            <div className="filters-wrap">
              <div className="switch-table-wrap" />
              {filtersContent}
            </div>
          )}

          <AddRecordHeader
            recordName="Reviewer"
            addRecord={() =>
              mainStore.changeManagements.create(
                Number(workspaceID),
                sectionTags[0].id,
              )
            }
            addSection={addNewSection}
            canAddSection
          />
          <div className="table-list-wrap cm-content" data-testid="cm-table">
            <SectionDragContext onSectionChange={onSectionChange}>
              {sectionTags.map((sectionTag) => (
                <ChangeManagementSectionTag
                  key={sectionTag.id}
                  sectionTag={sectionTag}
                  fields={fields}
                  recordVersions={recordVersions?.filter(
                    (recordVersion) =>
                      recordVersion?.section_tag_id === sectionTag.id,
                  )}
                />
              ))}
              {isCurrentWorkspaceActive && recordVersions.length < 3 && (
                <CreateFirstCTA subject="Change Management" />
              )}
            </SectionDragContext>
            <div
              className="tw-sticky tw-left-[25px] tw-ml-[25px]"
              data-testid="cm-add-new-product"
            >
              <AddNewSectionButton onClick={addNewSection} title="Add Change" />
            </div>
          </div>
        </>
      )}
    </Table>
  );
}

export default observer(ChangeManagement);
