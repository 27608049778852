import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { useMainStore } from "@/contexts/Store";

import PieChart from "../sharedGraphs/pie-chart";
import RecordsByMonthStackedBar from "../sharedGraphs/records-by-month-stacked-bar";
import RecordsByMonthStackedBarWorkspaces from "../sharedGraphs/records-by-month-stacked-bar-workspaces";
import WorkspaceSelection from "../workspaceSelection/WorkspaceSelection";

const ReportsPage = ({
  identifier,
  defaultDateColumn,
  defaultGroupColumn,
  tableName,
  showPieChart = true,
}: {
  identifier: string;
  defaultDateColumn: string;
  defaultGroupColumn: string;
  showPieChart?: boolean;
  tableName?: string;
}) => {
  const mainStore = useMainStore();

  const workspaceIDs = mainStore.reports.selectedWorkspaceIDs;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      mainStore.fields.getAllFieldsByDataType({
        workspaceIDs: workspaceIDs.map((id) => parseInt(id.toString())),
        identifier,
      });
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [workspaceIDs]);

  return (
    <div className="report-dashboard-wrap">
      <WorkspaceSelection />
      <RecordsByMonthStackedBarWorkspaces
        identifier={identifier}
        dateColumnDefault={defaultDateColumn}
      />
      <RecordsByMonthStackedBar
        identifier={identifier}
        dateColumnDefault={defaultDateColumn}
        groupColumnDefault={defaultGroupColumn}
        tableName={tableName}
      />
      {showPieChart && (
        <PieChart identifier={identifier} columnDefault={defaultGroupColumn} />
      )}
    </div>
  );
};

export default observer(ReportsPage);
