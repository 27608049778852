import { MiniTag, MiniTagProps } from "@themis/ui";
import React from "react";

const FINDING_ACTIVE_COLOR_MAP: Record<string, MiniTagProps["color"]> = {
  Open: "grey",
  Sent: "yellow",
  Closed: "primary",
} as const;

export function FindingActiveTag({ status = "Open" }: { status?: string }) {
  return <MiniTag color={FINDING_ACTIVE_COLOR_MAP[status]}>{status}</MiniTag>;
}
