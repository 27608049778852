import { AxiosResponse } from "axios";

import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";
import { ModuleWorkspace, Workspace } from "@/stores/types/workspace-types";

import api from "../api";

type WorkspacesResponse = {
  inbound_ids: number[];
  workspaces: Workspace[];
};

export type WorkspaceCreate = Pick<Workspace, "icon_index" | "name"> & {
  suggested_logo?: string;
  user_ids: number[];
};

export type WorkspaceCreateRequest = {
  workspace: WorkspaceCreate;
  themis_module_identifiers: ModuleIdentifier[];
};

type WorkspaceRequest = {
  workspace: Partial<Workspace>;
};

type WorkspaceResponse = {
  workspace: Workspace;
};

export async function getWorkspaces() {
  const response = await api.get<WorkspacesResponse>("/workspaces");

  return response.data;
}

export async function createWorkspace(
  companyID: number,
  data: WorkspaceCreateRequest,
) {
  const response = await api.post<
    WorkspaceResponse,
    AxiosResponse<WorkspaceResponse>,
    WorkspaceCreateRequest
  >(`/companies/${companyID}/workspaces`, data);

  return response.data;
}

export async function updateWorkspace(
  workspaceId: number,
  data: Partial<Workspace>,
) {
  const response = await api.patch<
    WorkspaceResponse,
    AxiosResponse<WorkspaceResponse>,
    WorkspaceRequest
  >(`/workspaces/${workspaceId}`, { workspace: data });

  return response.data;
}

export async function getModuleWorkspaces(
  workspaceId: number,
): Promise<ModuleWorkspace[]> {
  const response = await api.get<{ module_workspaces: ModuleWorkspace[] }>(
    `/workspaces/${workspaceId}/module_workspaces`,
  );

  return response.data.module_workspaces;
}
