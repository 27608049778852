import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  CreateTaskableMutationRequest,
  CreateTaskMutationRequest,
  ListTasksQueryParams,
  UpdateTaskMutationRequest,
} from "../gen";
import {
  createTask,
  createTaskable,
  deleteTaskable,
  getTask,
  listTasks,
  updateTask,
} from "../gen/axios/tasksController";

export const TASKS_QUERY_KEY = "tasks";

export function useTasks(
  companyId: number,
  queryParams: ListTasksQueryParams = {},
) {
  return useQuery({
    queryKey: [TASKS_QUERY_KEY, companyId, queryParams],
    queryFn: () => listTasks(companyId, queryParams),
    enabled: !!companyId && !!queryParams,
    placeholderData: (prevData) => prevData,
  });
}

export function useTask(companyId: number, taskId: number) {
  return useQuery({
    queryKey: [TASKS_QUERY_KEY, companyId, taskId],
    queryFn: () => getTask(taskId),
    enabled: !!companyId && !!taskId,
  });
}

export function useCreateTask({
  companyId,
  onSuccess = () => {},
  onError = () => {},
}: {
  companyId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (task: CreateTaskMutationRequest) =>
      createTask(companyId, task),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TASKS_QUERY_KEY, companyId],
      });
      onSuccess();
    },
    onError,
  });
}

export function useUpdateTask({
  companyId,
  taskId,
  onSuccess = () => {},
  onError = () => {},
}: {
  companyId: number;
  taskId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (task: UpdateTaskMutationRequest) => updateTask(taskId, task),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TASKS_QUERY_KEY, companyId],
      });
      onSuccess();
    },
    onError,
  });
}

export function useCreateTaskable({
  companyId,
  taskId,
  onSuccess = () => {},
  onError = () => {},
}: {
  companyId: number;
  taskId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (taskable: CreateTaskableMutationRequest) =>
      createTaskable(taskId, taskable),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TASKS_QUERY_KEY, companyId],
      });
      onSuccess();
    },
    onError,
  });
}

export function useDeleteTaskable({
  companyId,
  taskId,
  onSuccess = () => {},
  onError = () => {},
}: {
  companyId: number;
  taskId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (taskableId: number) => deleteTaskable(taskId, taskableId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TASKS_QUERY_KEY, companyId],
      });
      onSuccess();
    },
    onError,
  });
}
