import { observer } from "mobx-react";
import React from "react";
import { NavLink } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

type Props = {
  moduleIdentifier: string;
};

function ManageApprovalsStepsNavigation({ moduleIdentifier }: Props) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  return (
    <div
      className="settings-links-wrap"
      data-testid="stepped-approval-navigation"
    >
      <ul>
        <li>
          <NavLink
            to={`/workspaces/${workspaceID}/modules/${moduleIdentifier}/manage-approvals-steps`}
          >
            Stepped Approval
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default observer(ManageApprovalsStepsNavigation);
