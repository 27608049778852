import { ControlEffectivenessRating } from "./ControlEffectivenessRating";
import type { ThemisDocument } from "./ThemisDocument";
import type { User } from "./User";

export const moduleAssessmentComplianceStatus = {
  Compliant: "Compliant",
  Non_Compliant: "Non_Compliant",
} as const;
export type ModuleAssessmentComplianceStatus =
  (typeof moduleAssessmentComplianceStatus)[keyof typeof moduleAssessmentComplianceStatus];
export type ModuleAssessment = {
  /**
   * @description ID
   * @type integer
   */
  id: number;
  /**
   * @description Status
   * @type string
   */
  status: string;
  /**
   * @description Compliance Status
   * @type string
   */
  compliance_status: ModuleAssessmentComplianceStatus | null;
  /**
   * @description Summary
   * @type string
   */
  summary: string | null;
  /**
   * @description Due Date
   * @type string, date
   */
  due_date: string | null;
  /**
   * @description Assessment Date
   * @type string, date
   */
  assessment_date: string | null;
  control_effectiveness_rating: ControlEffectivenessRating;
  /**
   * @type array
   */
  evidences: ThemisDocument[] | null;
  assignee: User | null;
  /**
   * @description User ID
   * @type integer
   */
  assignee_id: number | null;
  /**
   * @description Record ID
   * @type integer
   */
  record_id: number | null;
  /**
   * @description Module Workspace ID
   * @type integer
   */
  module_workspace_id: number | null;
  /**
   * @description Record Version ID
   * @type integer
   */
  record_version_id: number | null;
  /**
   * @description Record Name
   * @type string
   */
  record_name: string | null;
  /**
   * @description Created At timestamp
   * @type string, date-time
   */
  created_at: string;
  /**
   * @description Updated At timestamp
   * @type string, date-time
   */
  updated_at: string;
};
