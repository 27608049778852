import * as Sentry from "@sentry/react";
import classNames from "classnames";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import Popup from "reactjs-popup";

import { attachmentFileType } from "@/api";
import Icon from "@/components/Elements/Icon";
import { buildUploadPayload } from "@/components/helpers/AttachmentGroupsHelper";
import { useMainStore } from "@/contexts/Store";

import { LINK_EXTENSION_STORE } from "../../../constants";
import Button from "../../../Elements/Button";
import UsersSelect from "../cell-type/users-select/UsersSelect";
import { getApproversForCurrentStep } from "../helpers/approvers";
import Switch from "../Switch";
import MultipleAttachmentsToolbar from "./toolbar/MultipleAttachmentsToolbar";

type Props = {
  commentsMode: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchFile: (...args: any[]) => any;
  handleChangeVersion: (
    newAttachmentID: number,
    newCommentsMode?: boolean,
  ) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCommentsMode: (...args: any[]) => any;
  fieldName?: string;
  mainFileID?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion?: any;
};

function CreativeViewToolbar({
  recordVersion,
  fieldName,
  commentsMode,
  setCommentsMode,
  mainFileID,
  fetchFile,
  handleChangeVersion,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [showExtensionPopup, setShowExtensionPopup] = useState(false);
  const [stepExtensionPopup, setStepExtensionPopup] = useState(
    "extension-notification-view",
  );

  // Variables
  const { attachmentID } = mainStore.files;
  const recordVersionID = recordVersion.id;
  const approversIDs = mainStore.avroSchemas.valueForField(
    "approvers",
    recordVersion.data,
  );
  const currentStepAproversIDs = getApproversForCurrentStep(recordVersion);

  // Refs
  const uploaderRef = useRef();

  // Effects
  useEffect(() => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
    const handler = (event) => {
      if (
        event.data.name !== "screenshot:done" ||
        event.data.recordVersionID !== recordVersion.id
      ) {
        return;
      }
      if (event.data.missedElement) {
        Sentry.captureMessage(
          "Figma iframe structure change - check figma screenshots for artifacts",
        );
      }
      const { data } = event.data;
      mainStore.pageLoading.startLoading();
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(data);
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      uploaderRef.current(dataTransfer.files);
    };

    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, [recordVersion.id]);

  autorun(() => {
    const { users } = mainStore.users;
    const activeWorkspaceID = mainStore.users.user.active_workspace?.id;

    if (activeWorkspaceID && !users.length) {
      mainStore.users.indexForModules({ workspaceID: activeWorkspaceID });
    }
  });

  // functions
  async function handleFigmaCommentsToggle() {
    if (commentsMode && mainFileID !== attachmentID) {
      await fetchFile(mainFileID);
    }

    handleCommentsToggle();
  }

  function handleCommentsToggle() {
    setCommentsMode(!commentsMode);
  }

  async function checkExtension() {
    // this has been changed due to the fact that the extension is not working and blocking the screenshot feature in figma. It'll be reverted, please blame it to understand the change.
    const extensionInstalled = true;

    if (extensionInstalled) {
      setShowExtensionPopup(false); // hide popup before screenshot is taken
      handleScreenshot();
    } else {
      setShowExtensionPopup(true);
      setStepExtensionPopup("extension-check");
    }
  }

  function openExtensionPopup() {
    setShowExtensionPopup(true);
  }

  function closeExtensionPopup() {
    setShowExtensionPopup(false);
    setStepExtensionPopup("extension-notification-view");
  }

  function handleScreenshot() {
    window.postMessage(
      { name: "screenshot:capture", recordVersionID: recordVersion.id },
      document.location.origin,
    );
  }

  function redirectToStoreExtension() {
    window.open(LINK_EXTENSION_STORE);
  }

  function handlePushToApprovers() {
    mainStore.creatives.pushToApprovers(recordVersion.id);
  }

  async function handleBackToSubmitter() {
    await mainStore.creatives.backToSubmitter(recordVersion.id);
    setShowPopup(false);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'signedIDs' implicitly has an 'any' type... Remove this comment to see the full error message
  async function handleFinraScreenshot(signedIDs) {
    const fileType = attachmentFileType.direct_upload;
    const [signedID] = signedIDs;
    const url = null;
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const payload = buildUploadPayload(fieldName, fileType, signedID, url);
    if (recordVersionID && payload) {
      const attachmentGroup = await mainStore.attachmentGroups.create({
        recordVersionID,
        payload,
      });
      const latestAttachmentID = attachmentGroup?.attachments?.[0].id;
      if (latestAttachmentID) {
        await handleChangeVersion(latestAttachmentID, true);
      }
    }

    mainStore.pageLoading.endLoading();
  }

  // elements
  const renderCommentsFigmaSwitch = (
    <div
      className="figma-mode-switch-container"
      data-testid="cv-figma-mode-switch-container"
    >
      <span>Explore Mode</span>
      <Switch
        active
        indeterminate={false}
        checked={commentsMode}
        onChange={handleFigmaCommentsToggle}
      />
      <span>Comments Mode</span>
    </div>
  );

  const takeScreenshotClasses = classNames("take-screenshot-button", {
    active: stepExtensionPopup === "extension-check",
  });

  const renderTakeScreenshot = (
    <Popup
      trigger={
        <div>
          <Button
            label="Take Screenshot"
            onClick={checkExtension}
            className={takeScreenshotClasses}
            size="sm"
            data-testid="cv-take-screenshot"
          />
        </div>
      }
      position="bottom center"
      on="hover"
      open={showExtensionPopup}
      onOpen={openExtensionPopup}
      onClose={closeExtensionPopup}
      keepTooltipInside
    >
      {stepExtensionPopup === "extension-check" && (
        <div
          className="extension-popup table-dropdown"
          data-testid="extension-popup"
        >
          <h4>No figma extension found</h4>
          <p>
            Oops! Looks like you haven’t installed the figma browser extension
            yet.
          </p>
          <p>
            To utilize this button, please visit the link below and install the
            extension to your browser.
          </p>
          <Button
            label="Visit figma extension page"
            onClick={redirectToStoreExtension}
            size="sm"
          />
        </div>
      )}
      {stepExtensionPopup === "extension-notification-view" && (
        <div
          className="extension-notification-popup"
          data-testid="extension-notification-popup"
        >
          A new snapshot will be added to &quot;Comments Mode&quot; for
          commenting
        </div>
      )}
    </Popup>
  );

  const renderPushToApproversButton = () => (
    <button
      type="button"
      className="push-to-approvers"
      onClick={handlePushToApprovers}
      data-testid="push-to-approvers"
    >
      <Icon name="upload" color="accentsSalmon" />
      Push to Approvers
    </button>
  );

  const renderPushToApproversWithPopup = (
    <UsersSelect
      openOnHover={false}
      tableName="Default"
      columnTitle="Approvers"
      fieldName="approvers"
      recordVersionID={recordVersion?.id}
      selectedIDs={approversIDs}
      singleSelection={false}
      width={200}
      hasErrors={false}
      reviews={recordVersion?.reviews}
      isUserEditable
      customTrigger={renderPushToApproversButton()}
    />
  );

  const renderPushToApprovers =
    approversIDs?.length || currentStepAproversIDs.length
      ? // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
        renderPushToApproversButton(handlePushToApprovers)
      : renderPushToApproversWithPopup;

  const renderBackToSubmiter = (
    <button
      type="button"
      className="back-to-submitter"
      data-testid="back-to-submitter"
    >
      <Icon name="refresh" color="generalDark" />
      Back to Submitter
    </button>
  );

  const backToSubmitter = (
    <Popup
      trigger={renderBackToSubmiter}
      position="bottom right"
      open={showPopup}
      onOpen={() => setShowPopup(true)}
      onClose={() => setShowPopup(false)}
      keepTooltipInside
    >
      <div
        className="table-dropdown"
        data-testid="confirmation-dialog-back-to-submitter"
      >
        <h4>Back to Submitter</h4>
        <p>Are you sure you want to send this back to submitter?</p>
        <div className="confirmation">
          <button
            onClick={handleBackToSubmitter}
            data-testid="confirmation-back-to-submitter"
          >
            Yes
          </button>
          <button
            onClick={() => setShowPopup(false)}
            data-testid="cancel-confirmation-back-to-submitter"
          >
            No
          </button>
        </div>
      </div>
    </Popup>
  );

  const directUploadProvider = (
    <DirectUploadProvider
      onSuccess={handleFinraScreenshot}
      render={({ handleUpload }) => {
        uploaderRef.current = handleUpload;
        return null;
      }}
    />
  );

  return (
    <>
      <MultipleAttachmentsToolbar
        fetchFile={fetchFile}
        // @ts-expect-error TS(2322) FIXME: Type '{ fetchFile: (...args: any[]) => any; fieldN... Remove this comment to see the full error message
        fieldName={fieldName}
        handleChangeVersion={handleChangeVersion}
        commentsMode={commentsMode}
        handleCommentsToggle={handleCommentsToggle}
        recordVersion={recordVersion}
        renderCommentsFigmaSwitch={renderCommentsFigmaSwitch}
        renderBackToSubmiter={backToSubmitter}
        renderPushToApprovers={renderPushToApprovers}
        renderTakeScreenshot={renderTakeScreenshot}
      />
      {directUploadProvider}
    </>
  );
}

CreativeViewToolbar.defaultProps = {
  recordVersion: {},
};

export default observer(CreativeViewToolbar);
