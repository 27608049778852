import { Tabs, TabsList, TabsTrigger } from "@themis/ui";
import React from "react";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import {
  PROJECT_DETAILS_PATH,
  PROJECT_TASKS_PATH,
} from "@/features/projects/pages/ProjectRoutes";

function ProjectHeaderTabs() {
  const { workspace_id, project_id } = useParams<{
    workspace_id: string;
    project_id?: string;
  }>();
  const { pathname } = useLocation();
  const history = useHistory();
  const isNewProject = !Number.isInteger(Number(project_id));

  function handleTabChange(value: string) {
    history.push(value);
  }

  return (
    <Tabs
      className="tw-flex tw-items-center tw-bg-neutral-25 tw-px-6"
      onValueChange={handleTabChange}
      value={pathname}
    >
      <TabsList>
        {project_id && (
          <>
            <TabsTrigger
              value={generatePath(PROJECT_DETAILS_PATH, {
                workspace_id,
                project_id,
              })}
            >
              Project Info
            </TabsTrigger>
            {!isNewProject && (
              <TabsTrigger
                value={generatePath(PROJECT_TASKS_PATH, {
                  workspace_id,
                  project_id,
                })}
                disabled={isNewProject}
              >
                <span>Tasks</span>
              </TabsTrigger>
            )}
          </>
        )}
      </TabsList>
    </Tabs>
  );
}

export default ProjectHeaderTabs;
