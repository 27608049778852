import {
  Button,
  IconButton,
  ImagePicker,
  PreviewTable,
  Select,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@themis/ui";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { PiLinkSimpleBold } from "react-icons/pi";
import { generatePath, useParams } from "react-router-dom";

import { MonitoringGroupAssetChildResult } from "@/api";
import type { WebsiteMonitoringGroupChildResultsQueryResponse } from "@/api/gen/models/WebsiteMonitoringGroupChildResults";
import { useMonitoringGroupChildResults } from "@/api/queries/websiteMonitoring";
import { ErrorContainer } from "@/components/ErrorContainer";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";
import FavoriteButton from "@/features/misc/favorites/FavoriteButton";

import { WEBSITE_MONITORING_ROUTES } from "..";
import { MarkAllStatus } from "../../components/MarkAllStatus";
import { StatusTag } from "../../components/StatusTag";
import { Subtitle } from "../../components/Subtitle";
import {
  MONITORING_GROUP_ASSET_CHILD_RESULTS_COLUMNS,
  MONITORING_GROUP_ASSET_CHILD_RESULTS_MISSING_COLUMNS,
} from "../../config/monitoringGroupAssetChildResultsTable";
import { MonitoringSidebarProvider } from "../../providers/MonitoringSidebarProvider";

export function MonitoringGroupChildResults() {
  const { monitoringGroupAssetChildId } = useParams<{
    monitoringGroupAssetChildId: string;
  }>();

  const mainStore = useMainStore();

  const { data, isLoading, error } = useMonitoringGroupChildResults(
    Number(monitoringGroupAssetChildId),
  );

  const monitoringGroupAssetId = data?.data?.asset_id || "";

  const groupDate = Object.keys(data?.data?.group_by_date || []);
  const groupDocsByDate = data?.data?.docs_group_by_date;
  const groupImagesByDate = data?.data?.images_group_by_date;
  const groupMetadataByDate = data?.data?.metadata_group_by_date;

  const [isDoc, setIsDoc] = useState(true);
  const [isImage, setIsImage] = useState(false);
  const [isMetadata, setIsMetadata] = useState(false);
  const [selectedDate, setSelectedDate] = useState<null | string>(null);
  const [stateData, setStateData] =
    useState<WebsiteMonitoringGroupChildResultsQueryResponse>();

  const [groupDateDoc, setGroupDateDoc] = useState<
    MonitoringGroupAssetChildResult[]
  >([]);
  const [groupDateImage, setGroupDateImage] = useState<
    MonitoringGroupAssetChildResult[]
  >([]);
  const [groupDateMetadata, setGroupDateMetadata] = useState<
    MonitoringGroupAssetChildResult[]
  >([]);

  const activeClass = "tw-bg-primary-300";
  const inActiveClass = "tw-bg-neutral-200";

  function setSubTypeValues(valueType: string) {
    if (valueType === "Doc") {
      setIsDoc(Boolean(true));
      setIsImage(Boolean(false));
      setIsMetadata(Boolean(false));
    } else if (valueType === "Image") {
      setIsDoc(Boolean(false));
      setIsImage(Boolean(true));
      setIsMetadata(Boolean(false));
    } else if (valueType === "Metadata") {
      setIsDoc(Boolean(false));
      setIsImage(Boolean(false));
      setIsMetadata(Boolean(true));
    }
  }

  useEffect(() => {
    // First time we load OR if we do a click on Thumbs up/down to change status on table
    if (!selectedDate && groupDate) {
      setStateData(data);
      setSelectedDate(groupDate[0]);

      setGroupDateDoc(filterByDate(groupDocsByDate || [], groupDate[0]) || []);
      setGroupDateImage(
        filterByDate(groupImagesByDate || [], groupDate[0]) || [],
      );
      setGroupDateMetadata(
        filterByDate(groupMetadataByDate || [], groupDate[0]) || [],
      );
    } else if (data && data !== stateData && selectedDate) {
      setStateData(data);

      setGroupDateDoc(filterByDate(groupDocsByDate || [], selectedDate) || []);
      setGroupDateImage(
        filterByDate(groupImagesByDate || [], selectedDate) || [],
      );
      setGroupDateMetadata(
        filterByDate(groupMetadataByDate || [], selectedDate) || [],
      );
    }
  }, [data]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function filterByDate(hashData: { [key: string]: any }, value: string) {
    return hashData[value];
  }

  function handleDateSelect(value: string) {
    setGroupDateDoc(filterByDate(groupDocsByDate || [], value));
    setGroupDateImage(filterByDate(groupImagesByDate || [], value));
    setGroupDateMetadata(filterByDate(groupMetadataByDate || [], value));
  }

  if (isLoading) {
    return <Loading loadingLayout="table" />;
  }

  if (error) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath(`${WEBSITE_MONITORING_ROUTES.monitoringGroups}`),
        }}
      >
        Could not load Asset Child Results.
      </ErrorContainer>
    );
  }

  return (
    <PageLayout>
      <Header
        backButtonLinkTo={generatePath(
          `${WEBSITE_MONITORING_ROUTES.base}${WEBSITE_MONITORING_ROUTES.monitoringGroups}${WEBSITE_MONITORING_ROUTES.monitoringGroupAssetChildren}`,
          { monitoringGroupAssetId },
        )}
        title={
          <div className="tw-align-center tw-flex tw-gap-1">
            Website Monitoring
            {!(isLoading || error) && (
              <>
                <Subtitle>{data?.data?.group_name || ""}</Subtitle>
                <Subtitle>{data?.data?.asset_name || ""}</Subtitle>
              </>
            )}
            <FavoriteButton
              passedModuleWorkspaceID={
                mainStore.moduleWorkspaces.list.find(
                  (mw) => mw.themis_module.identifier === "website_monitoring",
                )?.id
              }
            />
          </div>
        }
      />
      <PageContent>
        <MonitoringSidebarProvider>
          <>
            <div className="tw-items-left tw-col-span-1 tw-flex tw-w-full tw-justify-start tw-rounded-md tw-pb-4 tw-pt-2">
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                  <div className="tw-w-full tw-content-center">
                    <a
                      href={data?.data?.data}
                      target="_blank"
                      className="tw-content-center tw-gap-4 
                    tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap
                    tw-font-semibold
                    tw-text-secondary-300"
                      data-testid={`group-child-${data?.data?.id}`}
                      rel="noreferrer"
                    >
                      {data?.data?.data}
                      <IconButton
                        Icon={PiLinkSimpleBold}
                        size="lg"
                        color="transparent"
                        variant="vertical"
                        className="tw-ml-4 tw-content-center"
                      />
                    </a>
                  </div>
                </TooltipTrigger>
                <TooltipContent
                  align="start"
                  side="bottom"
                  className="tw-max-h-48 tw-overflow-y-auto tw-font-semibold"
                >
                  {data?.data?.data}
                </TooltipContent>
              </Tooltip>
            </div>

            <div className="dropdown-title-block tw-items-center tw-pb-4 tw-pt-4">
              <div className="dropdown-title-click tw-flex tw-flex-row tw-items-center tw-justify-start">
                <div
                  className="tw-border-1px tw-h-min-28 tw-box-border tw-flex tw-w-80 tw-shrink-0 
                  tw-flex-row tw-items-center tw-justify-start
                  tw-overflow-hidden tw-rounded-md tw-pr-2"
                >
                  <Select
                    items={groupDate?.map((dateGrouped) => ({
                      label: dateGrouped,
                      value: dateGrouped,
                    }))}
                    selected={selectedDate || groupDate[0]}
                    onSelect={(value: string) => {
                      value === selectedDate
                        ? setSelectedDate(null)
                        : setSelectedDate(value);
                      handleDateSelect(value);
                    }}
                  />
                </div>
                <div
                  className="tw-box-border tw-flex tw-flex-row tw-items-center tw-justify-center tw-rounded-md
                     tw-bg-primary-50 tw-text-center"
                >
                  <MarkAllStatus
                    monitoringGroupAssetChildId={Number(
                      monitoringGroupAssetChildId,
                    )}
                    monitoringGroupAssetId={Number(monitoringGroupAssetId)}
                  />
                </div>
                <div
                  className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-justify-end tw-rounded-md
                      tw-text-center tw-text-neutral-300"
                >
                  <div
                    className="tw-flex tw-cursor-not-allowed tw-flex-row tw-items-center tw-justify-start tw-justify-end tw-rounded-md
                      tw-pl-28 tw-text-center"
                  >
                    <Button
                      onClick={() => setSubTypeValues("Doc")}
                      disabled={
                        !groupDateDoc ||
                        (Object.keys(groupDateDoc).length || 0) === 0
                      }
                      className={classNames("tw-mx-0.5 tw-w-[100px]", {
                        [activeClass]: isDoc,
                        [inActiveClass]: !isDoc,
                      })}
                    >
                      Doc
                    </Button>
                  </div>

                  <div
                    className="tw-flex tw-cursor-not-allowed tw-flex-row tw-items-center tw-justify-start tw-justify-end tw-rounded-md
                      tw-text-center"
                  >
                    <Button
                      onClick={() => setSubTypeValues("Image")}
                      disabled={
                        !groupDateImage || (groupDateImage?.length || 0) === 0
                      }
                      className={classNames("tw-mx-0.5 tw-w-[100px] ", {
                        [activeClass]: isImage,
                        [inActiveClass]: !isImage,
                      })}
                    >
                      Image
                    </Button>
                  </div>

                  <div
                    className="tw-flex tw-cursor-not-allowed tw-flex-row tw-items-center tw-justify-start tw-justify-end tw-rounded-md
                      tw-text-center"
                  >
                    <Button
                      onClick={() => setSubTypeValues("Metadata")}
                      disabled={
                        !groupDateMetadata ||
                        (groupDateMetadata?.length || 0) === 0
                      }
                      className={classNames("tw-mx-0.5 tw-w-[100px]", {
                        [activeClass]: isMetadata,
                        [inActiveClass]: !isMetadata,
                      })}
                    >
                      Screenshot
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {isImage && groupDateImage && groupDateImage?.length > 0 && (
                <PreviewTable
                  rows={groupDateImage}
                  columns={MONITORING_GROUP_ASSET_CHILD_RESULTS_COLUMNS}
                  customCellClassName="tw-box-border tw-border-y tw-bg-neutral-25 tw-text-neutral-300 tw-border-r-0 tw-border-solid tw-border-neutral-100 tw-px-2.5 tw-py-1"
                />
              )}

              {isDoc &&
                groupDateDoc &&
                Object.entries(groupDateDoc).map(
                  ([documentTypeKey, documentTypeValue]) => (
                    <div
                      className="tw-flex  tw-flex-col tw-pt-4"
                      key={documentTypeKey}
                    >
                      <div className="tw-flex tw-pt-4 ">
                        <StatusTag status={documentTypeKey} />
                      </div>
                      {Object.entries(documentTypeValue).map(
                        ([complianceHashKey, complianceHashValue]) => (
                          <div
                            className="tw-flex tw-flex-row tw-gap-2 tw-pt-10"
                            key={complianceHashKey}
                          >
                            {
                              // @ts-expect-error TS(2339) FIXME: Property 'category' does not exist on type 'never'... Remove this comment to see the full error message
                              Object.entries(complianceHashValue).map(
                                ([complianceCopyKey, complianceCopyValue]) => (
                                  <div
                                    className="tw-flex tw-flex-col tw-gap-2"
                                    key={complianceCopyKey}
                                  >
                                    {complianceCopyKey && (
                                      <div className="tw-flex tw-content-center tw-items-center tw-pb-2 tw-pt-2 tw-font-semibold tw-text-neutral-500">
                                        <p className="tw-text-lg">Copy:</p>

                                        <p className="tw-text-sl tw-pl-4">
                                          {complianceCopyKey}
                                        </p>
                                      </div>
                                    )}

                                    <PreviewTable
                                      rows={complianceCopyValue}
                                      columns={
                                        documentTypeKey === "MissingDisclosures"
                                          ? MONITORING_GROUP_ASSET_CHILD_RESULTS_MISSING_COLUMNS
                                          : MONITORING_GROUP_ASSET_CHILD_RESULTS_COLUMNS
                                      }
                                      customCellClassName="tw-box-border tw-border-y tw-bg-neutral-25 tw-text-neutral-300 tw-border-r-0 tw-border-solid tw-border-neutral-100 tw-px-2.5 tw-py-1"
                                    />
                                  </div>
                                ),
                              )
                            }
                          </div>
                        ),
                      )}
                    </div>
                  ),
                )}

              {isMetadata &&
                groupDateMetadata &&
                (groupDateMetadata?.length || 0) > 0 &&
                groupDateMetadata[0]?.screenshots_attachments?.map(
                  (screenshots_attachment) => {
                    return (
                      screenshots_attachment?.file_url && (
                        <div className="tw-pb-4 tw-pt-4">
                          <ImagePicker
                            readOnly
                            className="tw-h-100 "
                            defaultUrl={screenshots_attachment?.file_url}
                          />
                        </div>
                      )
                    );
                  },
                )}
            </div>
          </>
        </MonitoringSidebarProvider>
      </PageContent>
    </PageLayout>
  );
}
