import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Link, NavLink, useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { useLoading } from "../../../hooks/useLoading";
import Loading from "../../Loading";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";
import VersionHistoryExport from "./VersionHistoryExport";

function RiskRegisterVersionHistory() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  // @ts-expect-error TS(2339) FIXME: Property 'record_version_id' does not exist on typ... Remove this comment to see the full error message
  const { record_version_id: recordVersionID } = useParams();
  const { company } = mainStore.companies;
  const { data, versionHistoryRecords } = mainStore.riskRegisters;
  const rv = mainStore.recordVersions.list.find(
    (recordVersion) => recordVersion.id === recordVersionID,
  );
  const moduleWorkspaceID = data?.module_workspace_id;
  const { visibleFields } = mainStore.fields;
  const { workspaceID } = mainStore.context;

  // hooks
  const loading = useLoading(versionHistoryRecords);

  // Effects
  useEffect(() => {
    mainStore.riskRegisters.setVersionHistoryRecords([]);
  }, []);

  useEffect(() => {
    if (!company?.id || !moduleWorkspaceID) {
      return;
    }

    mainStore.riskRegisters.fetchVersionHistory(recordVersionID);
  }, [company, moduleWorkspaceID]);

  useEffect(() => {
    if (!workspaceID) {
      return;
    }

    if (Object.keys(mainStore.riskRegisters.data).length === 0) {
      mainStore.riskRegisters.index({ workspaceID });
    }
  }, [workspaceID]);

  // elements
  const renderTabs = (
    <div
      className="settings-content-wrap"
      data-testid="rr-version-history-tabs"
    >
      <div className="settings-links-wrap audit-trail-record-wrap">
        <ul>
          <li>
            <Link
              to={`/workspaces/${workspaceID}/modules/risk-register/${recordVersionID}`}
            >
              Record View
            </Link>
          </li>
          <li>
            <NavLink to="">Version History</NavLink>
          </li>
          <li>
            <Link
              to={`/workspaces/${workspaceID}/modules/risk-register/${recordVersionID}/controls`}
            >
              Module Controls
            </Link>
          </li>
          <li>
            <Link
              to={`/workspaces/${workspaceID}/modules/risk-register/${recordVersionID}/audit_trail`}
            >
              Activity
            </Link>
          </li>
        </ul>

        {rv && (
          <VersionHistoryExport tableID={rv.table_id} recordID={rv.record.id} />
        )}
      </div>
    </div>
  );

  const renderLoading = (
    <Loading loadingLayout="table" showTableHeader={false} />
  );

  const renderColumns = visibleFields.map((field) => (
    <ModuleTableColumn key={field.name} field={field} />
  ));

  const renderRows = versionHistoryRecords.map((record_version) => (
    <ModuleRecordVersion
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
      key={record_version.id}
      moduleWorkspaceID={moduleWorkspaceID}
      fields={visibleFields}
      recordVersion={record_version}
      // @ts-expect-error TS(2339) FIXME: Property 'table_id' does not exist on type 'never'... Remove this comment to see the full error message
      tableID={record_version.table_id}
      // @ts-expect-error TS(2339) FIXME: Property 'table_name' does not exist on type 'neve... Remove this comment to see the full error message
      tableName={record_version.table_name}
      moduleIdentifier="risk_register"
      // @ts-expect-error TS(2339) FIXME: Property 'table_name' does not exist on type 'neve... Remove this comment to see the full error message
      currentTableName={record_version.table_name}
      isArchivedRow
      archived
    />
  ));

  const renderTable = (
    <div
      className="table-list-wrap risk-register-content history"
      data-testid="rr-version-history-table-container"
    >
      <div className="list-title-table">
        <ul>
          <div className="list-column-wrap">
            <div className="list-title-table-wrap">{renderColumns}</div>
          </div>
          <div className="risk-register-header-ending" />
        </ul>
      </div>
      {renderRows}
    </div>
  );

  const renderMainContent = () => {
    if (loading) {
      return renderLoading;
    }

    return renderTable;
  };

  return (
    <div
      className="settings-wrap company-users-settings-container detail-view-documents-wrap"
      data-testid="rr-version-history-container"
    >
      {renderTabs}
      {renderMainContent()}
    </div>
  );
}

export default observer(RiskRegisterVersionHistory);
