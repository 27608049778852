import classNames from "classnames";
import React from "react";

import { useMonitoringGroups } from "@/api/queries/websiteMonitoring";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import LoadingWrapper from "@/components/loading-wrapper";
import { useMainStore } from "@/contexts/Store";
import FavoriteButton from "@/features/misc/favorites/FavoriteButton";

import MonitoringGroupSection from "../components/MonitoringGroupSection";
import { MONITORING_GROUP_ASSET_COLUMNS } from "../config/monitoringGroupAssetsTable";

function WebsiteMonitoringContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div
      className={classNames(
        "tw-flex tw-h-full tw-min-w-96 tw-flex-col tw-gap-4",
      )}
    >
      {children}
    </div>
  );
}

export function MonitoringGroups() {
  const mainStore = useMainStore();
  const { companyID } = mainStore.context;
  const { isLoading, data, error } = useMonitoringGroups(Number(companyID));

  return (
    <PageLayout>
      <Header
        title={
          <>
            Website Monitoring
            <FavoriteButton
              passedModuleWorkspaceID={
                mainStore.moduleWorkspaces.list.find(
                  (mw) => mw.themis_module.identifier === "website_monitoring",
                )?.id
              }
            />
          </>
        }
      />
      <PageContent>
        <LoadingWrapper
          loadingLayout={"table"}
          loading={isLoading}
          errorText={
            error ? "There was an issue loading the monitoring group data." : ""
          }
        >
          <WebsiteMonitoringContainer>
            {data?.data?.map((monitoring_group) => {
              return (
                <MonitoringGroupSection
                  isDropdownOpen
                  key={monitoring_group.id}
                  monitoringGroup={monitoring_group}
                  rows={monitoring_group?.assets || []}
                  columns={MONITORING_GROUP_ASSET_COLUMNS}
                />
              );
            })}
          </WebsiteMonitoringContainer>
        </LoadingWrapper>
      </PageContent>
    </PageLayout>
  );
}
