import { Button, useToast } from "@themis/ui";
import classNames from "classnames";
import dayjs from "dayjs";
import { debounce, isEqual, pickBy } from "lodash";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { PiDownloadSimple } from "react-icons/pi";
import { useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import Loading from "@/components/Loading";
import AddNewButtonPopup from "@/components/table/risk-register/AddNewButtonPopup";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import { exportRiskRegisterGroups } from "@/features/risk-register/hooks";
import GroupScoringPage from "@/features/risk-register/pages/GroupScoringPage";
import { useFilters } from "@/hooks/useFilters";
import { useLoading } from "@/hooks/useLoading";
import { usePreventUnsavedChanges } from "@/hooks/usePreventUnsavedChanges";
import { useTableData } from "@/hooks/useTableData";
import {
  RiskRegisterScoringMatrix,
  ScoringMatrixRating,
} from "@/stores/types/risk-register-types";
import { Section } from "@/stores/types/section-tags";

import ExportBulk from "../../dashboard/ExportBulk";
import ImportBulk from "../../dashboard/ImportBulk";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import RegularDateSelect from "../shared/RegularDateSelect";
import Switch from "../shared/Switch";
import Table from "../Table";
import AllRiskPopup from "./AllRiskPopup";
import { INHERENT_MATRIX_TITLE, RESIDUAL_MATRIX_TITLE } from "./Constants";
import Library from "./Library";
import LibraryImport from "./LibraryImport";
import RiskRegisterLibraryApplyButton from "./RiskRegisterLibraryApplyButton";
import RiskRegisterLibraryTemplateButton from "./RiskRegisterLibraryTemplateButton";
import RiskRegisterScoringModal from "./RiskRegisterScoringModal";
import RiskRegistersTable from "./RiskRegistersTable";
import Scoring from "./Scoring";

interface Screen {
  label: string;
  url: string;
  menu: "primary" | "secondary";
}

const ALL_SCREENS: { [key: string]: Screen } = {
  AllRisk: {
    label: "All Risks",
    url: "/modules/risk-register",
    menu: "primary",
  },
  Inherent: {
    label: "Inherent",
    url: "/modules/risk-register-scoring-inherent",
    menu: "primary",
  },
  Residual: {
    label: "Residual",
    url: "/modules/risk-register-scoring-residual",
    menu: "primary",
  },
  Scoring: {
    label: "Scoring Group",
    url: "/modules/risk-register/scoring",
    menu: "primary",
  },
  Templates: {
    label: "Company Library",
    url: "/modules/risk-register/templates",
    menu: "primary",
  },
  ThemisRiskLibrary: {
    label: "Themis Risk Library",
    url: "/modules/risk-register/themis-risk-library",
    menu: "primary",
  },
  All: {
    label: "All",
    url: "/modules/risk-register",
    menu: "secondary",
  },
  Active: {
    label: "Active",
    url: "/modules/risk-register/active",
    menu: "secondary",
  },
  Completed: {
    label: "Completed",
    url: "/modules/risk-register/completed",
    menu: "secondary",
  },
};

function RiskRegister() {
  // Import MobX stores
  const mainStore = useMainStore();

  useTableData();
  const toast = useToast();

  // State
  const { setActiveScreen, activeScreen: activeScreenKey } =
    mainStore.riskRegisters;
  const [lastSecondaryScreenKey, setLastSecondaryScreenKey] = useState("All");
  const [editingMatrix, setEditingMatrixHelper] =
    useState<RiskRegisterScoringMatrix | null>(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [editingMatrixChanged, setEditingMatrixChanged] = useState(false);
  const [isMatrixSwitchPopupOpened, setIsMatrixSwitchPopupOpened] =
    useState(false);
  const [tabSwitchPopupOpened, setTabSwitchPopupOpened] = useState<
    string | null
  >(null);
  const [riskRatings, setRiskRatings] = useState<ScoringMatrixRating[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showNewSection, setShowNewSection] = useState(false);

  const [mode, setMode] = useState("review");
  const [initialSection, setInitialSection] = useState<null | Section>(null);
  const [completeDate, setCompleteDate] = useState(dayjs().toDate());
  const [rangeModalOpen, setRangeModalOpen] = useState(false);

  // Variables
  const { isCurrentWorkspaceActive } = mainStore.workspaces;
  const fields = mainStore.fields.visibleFields;
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialSectionID = queryParams.get("initialSectionID");

  const loading = useLoading(fields) || mainStore.riskRegisters.loading;

  const { company } = mainStore.companies;
  const { data } = mainStore.riskRegisters;
  const { moduleWorkspaceID, workspaceID, isIW } = mainStore.context;
  const moduleWorkspace = mainStore.moduleWorkspaces.list.find(
    (item) => item.id === moduleWorkspaceID,
  );
  const isLibraryEnabled = isIW || moduleWorkspace?.library_enabled;
  const recordVersions = mainStore.recordVersions.list;
  const riskTableScreens = ["AllRisk", "Active", "Completed", "All"];
  const matrices = data?.scoring_matrices || [];
  const rangeRatings = data?.scoring_matrix_range_ratings || [];

  const debouncedHandleSave = useCallback(
    debounce(handleEditMatrixSubmit, 500),
    [moduleWorkspaceID],
  );

  const primaryScreens = pickBy(
    ALL_SCREENS,
    (value) => value.menu === "primary",
  );
  const secondaryScreens = pickBy(
    ALL_SCREENS,
    (value) => value.menu === "secondary",
  );

  // hooks
  const { filtersTrigger, filtersViewEnabled, filtersContent } = useFilters({
    fields: fields.filter(
      (item) =>
        !["inherent_risk_rating", "residual_risk_rating"].includes(item.name),
    ),
  });

  // Effects
  useEffect(() => {
    const companyId = company?.id;
    if (companyId) {
      mainStore.riskTypes.index(companyId);
      mainStore.mainCategories.index(companyId);
    }
  }, [company]);

  useEffect(() => {
    if (
      workspaceID &&
      mainStore.riskRegisters.data &&
      Object.keys(mainStore.riskRegisters.data).length === 0 &&
      !loading
    ) {
      mainStore.riskRegisters.index({ workspaceID });
    }
  }, [workspaceID, mainStore.riskRegisters.data, loading]);

  useEffect(() => {
    const newScreenKey = Object.entries(ALL_SCREENS).find(
      ([, value]) =>
        `/workspaces/${workspaceID}${value.url}` === location.pathname,
    )?.[0];
    if (newScreenKey && activeScreenKey !== newScreenKey) {
      setActiveScreen(newScreenKey);
    }
    setIsUpdated(false);
  }, [location.pathname, activeScreenKey]);

  useEffect(() => {
    if (initialSectionID) {
      const section =
        mainStore.sectionTags.list.find(
          (item) => item.id === Number(initialSectionID),
        ) || null;
      setInitialSection(section);
      mainStore.riskRegisters.setCurrentSection(section);
    } else {
      setInitialSection(null);
    }
  }, [initialSectionID]);

  useEffect(() => {
    if (editingMatrix) {
      const currentMatrix = matrices.find(
        (matrix) => matrix.id === editingMatrix.id,
      );
      if (currentMatrix && !isEqual(currentMatrix, editingMatrix)) {
        setEditingMatrix(currentMatrix, false);
        setEditingMatrixChanged(false);
      }
    }
  }, [matrices]);

  useEffect(() => {
    setRiskRatings(
      mainStore.riskRegisters.scoringMatrixRatings.filter(
        (matrixRating) =>
          matrixRating.risk_type === activeScreenKey.toLowerCase(),
      ),
    );
  }, [mainStore.riskRegisters.scoringMatrixRatings, activeScreenKey]);

  function setEditingMatrix(
    newMatrix: RiskRegisterScoringMatrix | null,
    isUpdatedByUser = true,
  ) {
    setEditingMatrixHelper(newMatrix);
    isUpdatedByUser && setIsUpdated(true);
  }

  function setRiskRatingsHelper(
    newRiskRatings: ScoringMatrixRating[],
    isUpdatedByUser = true,
  ) {
    setRiskRatings(newRiskRatings);
    isUpdatedByUser && setIsUpdated(true);
  }

  function handleResetMatrixMode() {
    setMode("review");
    setEditingMatrixChanged(false);
  }

  async function handleEditMatrixSubmit(newMatrix?: RiskRegisterScoringMatrix) {
    if ((newMatrix && !isEqual(newMatrix, editingMatrix)) || !newMatrix) {
      const updatedMatrix = newMatrix || editingMatrix;
      if (updatedMatrix) {
        updatedMatrix.scoring_matrix_rows =
          updatedMatrix.scoring_matrix_rows.map((row) => {
            row.scoring_matrix_cells = row.scoring_matrix_cells.map((cell) => {
              if (Number.isNaN(cell.rating_id)) {
                cell.rating_id =
                  mainStore.riskRegisters.scoringMatrixRatings.find(
                    (matrixRating) => matrixRating.title === cell.title,
                  )?.id;
              }
              return cell;
            });
            return row;
          });
      }

      await mainStore.riskRegisters.updateScoringMatrix(
        moduleWorkspaceID,
        updatedMatrix,
      );

      setIsUpdated(false);
    }
  }

  function handleScreenChange(newScreen: string, fromDialog = false) {
    const oldScreen = activeScreenKey;
    if (mode === "edit" && editingMatrixChanged && !fromDialog) {
      return setTabSwitchPopupOpened(newScreen);
    }
    if (ALL_SCREENS[newScreen].menu === "secondary") {
      setLastSecondaryScreenKey(newScreen);
    }
    setTabSwitchPopupOpened(null);
    setActiveScreen(newScreen);

    if (
      Object.keys(secondaryScreens).includes(newScreen) &&
      newScreen !== lastSecondaryScreenKey
    ) {
      mainStore.riskRegisters.index({ workspaceID });
      setCompleteDate(dayjs().toDate());
    }
    handleResetMatrixMode();
    setEditingMatrix(null, false);
    mainStore.riskRegisters.setLibraryRecords([]);
    history.push(`/workspaces/${workspaceID}${ALL_SCREENS[newScreen].url}`, {
      from: oldScreen,
    });
  }

  function handleDateChange(date: string) {
    const dateObject = dayjs(date).toDate();
    setCompleteDate(dateObject);
    mainStore.riskRegisters.completedOnDate({ date });
  }

  function handleMatrixModeSwitchClick() {
    if (mode === "edit") {
      return handleResetMatrixMode();
    }

    const matrixName =
      activeScreenKey === "Inherent"
        ? INHERENT_MATRIX_TITLE
        : RESIDUAL_MATRIX_TITLE;
    setEditingMatrix(
      matrices.find((item) => item.name === matrixName) || null,
      false,
    );
    setMode("edit");
    setEditingMatrixChanged(false);
  }

  function handleChangeMatrix(
    newData: RiskRegisterScoringMatrix,
    saveChanges = false,
  ) {
    setEditingMatrix(newData);

    if (saveChanges) {
      debouncedHandleSave(newData);
    } else {
      setEditingMatrixChanged(true);
    }
  }

  function handleMatrixModeSwitchPopupReject() {
    setIsMatrixSwitchPopupOpened(false);
    handleResetMatrixMode();
  }

  async function handleMatrixModeSwitchPopupConfirm() {
    const riskType = activeScreenKey === "Inherent" ? "inherent" : "residual";
    await mainStore.riskRegisters.updateMatrixRatings(
      moduleWorkspaceID,
      riskRatings,
      riskType,
      false,
    );
    await mainStore.riskRegisters.updateScoringMatrix(
      moduleWorkspaceID,
      editingMatrix,
    );
    handleMatrixModeSwitchPopupReject();
  }

  async function handleTabSwitchPopupConfirm() {
    setIsUpdated(false);
    const riskType = activeScreenKey === "Inherent" ? "inherent" : "residual";
    await mainStore.riskRegisters.updateMatrixRatings(
      moduleWorkspaceID,
      riskRatings,
      riskType,
      false,
    );
    await mainStore.riskRegisters.updateScoringMatrix(
      moduleWorkspaceID,
      editingMatrix,
    );
    handleScreenChange(tabSwitchPopupOpened!, true);
  }

  function handleTabSwitchPopupReject(screen: string) {
    setTabSwitchPopupOpened(null);
    mainStore.riskRegisters.index({ workspaceID });
    handleScreenChange(screen, true);
  }

  // Elements
  const renderTabButton = (screenKey: string) => {
    const popupEnabled =
      mode === "edit" &&
      editingMatrixChanged &&
      tabSwitchPopupOpened === screenKey;

    const { label } = ALL_SCREENS[screenKey];
    const showActive =
      ALL_SCREENS[activeScreenKey]?.url === ALL_SCREENS[screenKey]?.url;
    const renderTabSwitchTrigger = (
      <div key={`rr-tab-button-${screenKey}`}>
        <button
          className={classNames({ active: showActive })}
          onClick={() => handleScreenChange(screenKey, false)}
          data-testid={`risk-register-tab-${screenKey}-trigger`}
        >
          {label}
        </button>
      </div>
    );

    const renderTabSwitchConfirmation = (
      <ConfirmationDialog
        heading="Save Now?"
        content="Do you want to save changes?"
        handleConfirm={handleTabSwitchPopupConfirm}
        handleReject={() => handleTabSwitchPopupReject(screenKey)}
      />
    );

    if (!popupEnabled) {
      return renderTabSwitchTrigger;
    }

    return (
      <Popup
        position="bottom center"
        key={screenKey}
        trigger={renderTabSwitchTrigger}
        open={popupEnabled}
      >
        <div
          className="table-dropdown success-dropdown"
          data-testid="rr-tab-switch-confirmation-popup"
        >
          {renderTabSwitchConfirmation}
        </div>
      </Popup>
    );
  };

  const renderMatrixModeSwitchTrigger = (
    <div>
      <Switch
        active
        indeterminate={false}
        checked={mode === "edit"}
        disabled={mode === "edit" && editingMatrixChanged}
        onChange={handleMatrixModeSwitchClick}
      />
    </div>
  );

  const renderMatrixModeSwitchConfirmation = (
    <ConfirmationDialog
      heading="Save Now?"
      content="Do you want to save changes?"
      handleConfirm={handleMatrixModeSwitchPopupConfirm}
      handleReject={handleMatrixModeSwitchPopupReject}
    />
  );

  const renderMatrixModeSwitch = (
    <div className="rr-mode-switch" data-testid="rr-mode-switch">
      <span className={classNames({ active: mode === "review" })}>Review</span>
      <Popup
        position="bottom center"
        trigger={renderMatrixModeSwitchTrigger}
        open={
          mode === "edit" && editingMatrixChanged && isMatrixSwitchPopupOpened
        }
        onOpen={() => setIsMatrixSwitchPopupOpened(true)}
        onClose={() => setIsMatrixSwitchPopupOpened(false)}
      >
        <div className="table-dropdown success-dropdown">
          {renderMatrixModeSwitchConfirmation}
        </div>
      </Popup>
      <span className={classNames({ active: mode === "edit" })}>Modify</span>
    </div>
  );

  const onModalClose = () => {
    setRangeModalOpen(false);
  };

  usePreventUnsavedChanges([isUpdated], [isUpdated]);

  const handleRiskRegisterAddNewGroup = () => {
    document.getElementById("add-new-risk-register-scoring-group-btn")?.click();
  };

  if (loading) {
    return <Loading loadingLayout="table" />;
  }

  return (
    <Table>
      <RiskRegisterScoringModal
        open={rangeModalOpen}
        moduleWorkspaceID={moduleWorkspaceID}
        onClose={onModalClose}
        rangeRatings={rangeRatings}
      />
      <div className="table-header-wrap" data-testid="risk-register-header">
        <div className="buttons-block" data-testid="risk-register-main-tabs">
          <AllRiskPopup
            setActiveScreen={setActiveScreen}
            lastSecondaryScreenKey={lastSecondaryScreenKey}
            activeScreen={activeScreenKey}
            onClick={handleScreenChange}
            showConfirmation={
              mode === "edit" &&
              editingMatrixChanged &&
              tabSwitchPopupOpened === "All"
            }
            onConfirmYes={handleTabSwitchPopupConfirm}
            onConfirmNo={handleTabSwitchPopupReject}
            moduleWorkspaceID={Number(moduleWorkspaceID)}
            initialSection={initialSection}
          />
          {Object.keys(primaryScreens)
            .filter((screen) => screen !== "AllRisk")
            .map(renderTabButton)}
        </div>
        <div className="import-export-buttons-container">
          {["All", "AllRisk"].includes(activeScreenKey) && (
            <>
              <button
                className="risk-rating-top-button gray-button"
                onClick={() => setRangeModalOpen(true)}
              >
                Edit Scoring
              </button>
              <ExportBulk />
              <ImportBulk />
            </>
          )}
          {activeScreenKey === "Scoring" && (
            <>
              <Button onClick={handleRiskRegisterAddNewGroup}>
                Add New Group
              </Button>
              <Button
                color="tertiary"
                LeftIcon={PiDownloadSimple}
                onClick={() => {
                  toast({
                    content: "The excel spreadsheet is downloading",
                    variant: "download",
                    hideCloseButton: true,
                  });
                  exportRiskRegisterGroups(workspaceID!);
                }}
              >
                Export
              </Button>
            </>
          )}
          {activeScreenKey === "Active" && (
            <ExportBulk
              specificStatuses={["new", "in_review", "ready_to_finalize"]}
            />
          )}
          {activeScreenKey === "Completed" && (
            <ExportBulk specificStatuses={["completed"]} />
          )}

          {(activeScreenKey === "Templates" ||
            activeScreenKey === "ThemisRiskLibrary") && (
            <>
              {activeScreenKey === "Templates" && isLibraryEnabled && (
                <>
                  <LibraryImport moduleWorkspaceID={moduleWorkspaceID} />
                  <RiskRegisterLibraryApplyButton libraryType="user_generated" />
                </>
              )}
              {activeScreenKey === "ThemisRiskLibrary" && isLibraryEnabled && (
                <>
                  {isIW && (
                    <RiskRegisterLibraryTemplateButton
                      handleScreenChange={handleScreenChange}
                    />
                  )}
                  <RiskRegisterLibraryApplyButton libraryType="themis_generated" />
                </>
              )}
            </>
          )}
        </div>
        <div className="add-new-block">
          {riskTableScreens.includes(activeScreenKey) && (
            <div className="vendor-heading-actions">{filtersTrigger}</div>
          )}

          {(activeScreenKey === "Inherent" || activeScreenKey === "Residual") &&
            isCurrentWorkspaceActive && (
              <div className="vendor-heading-actions">
                {renderMatrixModeSwitch}
              </div>
            )}
        </div>
      </div>
      {["All", "AllRisk", "Active", "Completed"].includes(activeScreenKey) && (
        <div
          className={classNames("table-header-wrap", {
            "header-with-filters": filtersViewEnabled,
          })}
          data-testid="risk-register-header"
        >
          <div
            className="buttons-block small"
            data-testid="risk-register-main-tabs"
          >
            {Object.keys(secondaryScreens).map(renderTabButton)}
          </div>
          {activeScreenKey === "Completed" && (
            <div className="date-block">
              <span data-tooltip-left='Provides a historical snapshot. This will show you what the "completed" tab would have looked like on the selected date.'>
                Show Completed Records As Of:
              </span>
              <RegularDateSelect
                initialValue={completeDate}
                handleUpdateDate={handleDateChange}
              />
            </div>
          )}
        </div>
      )}
      {filtersViewEnabled && (
        <div className="filters-wrap">
          <div className="switch-table-wrap" />
          {filtersContent}
        </div>
      )}
      {["AllRisk", "Active"].includes(activeScreenKey) && (
        <AddRecordHeader
          addRecord={() => setShowPopup(!showPopup)}
          recordName="Risk Event"
          canAddSection
          addSection={() => setShowNewSection(true)}
        />
      )}
      <AddNewButtonPopup
        sectionTagID={mainStore.sectionTags.orderedList[0]?.id}
        workspaceID={workspaceID}
        showPopupParent={showPopup}
      />
      {riskTableScreens.includes(activeScreenKey) && (
        <RiskRegistersTable
          recordVersions={recordVersions}
          fields={fields}
          loading={loading}
          moduleWorkspaceID={Number(moduleWorkspaceID)}
          initialSection={initialSection}
          forcedShowNewSectionDetails={showNewSection}
        />
      )}
      {!loading && activeScreenKey === "Inherent" && (
        <Scoring
          editingMode={mode === "edit"}
          matrix={
            editingMatrix ||
            matrices.find((item) => item.name === INHERENT_MATRIX_TITLE)
          }
          setMatrix={handleChangeMatrix}
          riskRatings={riskRatings}
          setRiskRatings={setRiskRatingsHelper}
          handleEditSubmit={handleEditMatrixSubmit}
          setEditingMatrixChanged={setEditingMatrixChanged}
        />
      )}
      {!loading && activeScreenKey === "Residual" && (
        <Scoring
          editingMode={mode === "edit"}
          matrix={
            editingMatrix ||
            matrices.find((item) => item.name === RESIDUAL_MATRIX_TITLE)
          }
          setMatrix={handleChangeMatrix}
          riskRatings={riskRatings}
          setRiskRatings={setRiskRatingsHelper}
          handleEditSubmit={handleEditMatrixSubmit}
          setEditingMatrixChanged={setEditingMatrixChanged}
        />
      )}
      {!loading && activeScreenKey === "Templates" && (
        <Library
          moduleWorkspaceID={Number(moduleWorkspaceID)}
          libraryType="user_generated"
        />
      )}
      {!loading && activeScreenKey === "ThemisRiskLibrary" && (
        <Library
          moduleWorkspaceID={Number(moduleWorkspaceID)}
          libraryType="themis_generated"
        />
      )}
      {activeScreenKey === "Scoring" && <GroupScoringPage />}
    </Table>
  );
}
export default observer(RiskRegister);
