import {
  Button,
  ConfirmationPopup,
  Tabs,
  TabsList,
  TabsTrigger,
  useToast,
} from "@themis/ui";
import React, { useEffect, useState } from "react";
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { useAccount, useUpdateAccount } from "@/api/queries/accounts";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";

import { ACCOUNTS_LIST_PATH } from "..";
import { Subtitle } from "../../components/Subtitle";
import { AccountContacts } from "./AccountContacts";
import { AccountContracts } from "./AccountContracts";
import { AccountInfo } from "./AccountInfo";
import { ChildAccounts } from "./ChildAccounts";

export const ACCOUNT_DETAILS_ROUTES = {
  contacts: "/contacts",
  contracts: "/contracts",
  childAccounts: "/sub-accounts",
} as const;

export type AccountLocationState =
  | {
      from?: string;
    }
  | undefined;

export function AccountDetails() {
  const toast = useToast();
  const history = useHistory();
  const { url, path } = useRouteMatch();

  const pageContentRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    pageContentRef.current?.scrollTo({
      top: 0,
    });
  }, [url]);

  const { pathname, search } = useLocation();

  const { workspace_id, accountId } = useParams<{
    workspace_id: string;
    accountId: string;
  }>();

  const searchParams = new URLSearchParams(search);
  const redirectTo = searchParams.get("redirectTo");

  const isNewAccount = !Number.isInteger(Number(accountId));

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const {
    data: accountData,
    isLoading,
    error,
  } = useAccount({
    workspaceId: Number(workspace_id),
    accountId: Number(accountId),
  });

  const { mutateAsync: updateAccount } = useUpdateAccount({
    accountId: Number(accountId),
    workspaceId: Number(workspace_id),
  });

  async function handleUpdateStatus() {
    setIsConfirmationOpen(false);

    try {
      const response = await updateAccount({
        name: accountData?.account.name || "",
        is_active: !accountData?.account.is_active,
        website: accountData?.account.website || "",
      });

      toast({
        content: `Account "${accountData?.account.name}" has been ${
          response.account?.is_active ? "activated" : "deactivated"
        }!`,
        variant: "success",
      });

      if (redirectTo) {
        history.push(redirectTo);
      }
    } catch {
      toast({
        content: "Something went wrong. Could not update account status.",
        variant: "error",
      });
    }
  }

  function handleClickActivate() {
    setIsConfirmationOpen(true);
  }

  function handleCancelStatusUpdate() {
    setIsConfirmationOpen(false);
  }

  const handleTabChange = (value: string) => {
    history.push(value);
  };

  return (
    <PageLayout>
      <Header
        backButtonLinkTo={generatePath(ACCOUNTS_LIST_PATH, {
          workspace_id,
        })}
        HeaderOptions={
          !isNewAccount &&
          pathname === url && (
            <ConfirmationPopup
              anchor
              align="end"
              title={
                accountData?.account.is_active
                  ? "Deactivate Account"
                  : "Activate Account"
              }
              content="Are you sure you want to proceed?"
              open={isConfirmationOpen}
              onCancel={handleCancelStatusUpdate}
              onConfirm={handleUpdateStatus}
            >
              <Button color="tertiary" onClick={handleClickActivate}>
                {accountData?.account.is_active ? "Deactivate" : "Activate"}
              </Button>
            </ConfirmationPopup>
          )
        }
        title={
          <div className="tw-align-center tw-flex tw-gap-1">
            Accounts
            {!(isLoading || error) && (
              <Subtitle>
                {isNewAccount ? "Untitled" : accountData?.account.name}
              </Subtitle>
            )}
          </div>
        }
      />
      {!isNewAccount && (
        <Tabs onValueChange={handleTabChange} value={pathname}>
          <TabsList className="tw-px-6">
            <TabsTrigger
              value={generatePath(`${url}`, {
                accountId,
              })}
            >
              Account Info
            </TabsTrigger>
            <TabsTrigger
              value={generatePath(`${url}${ACCOUNT_DETAILS_ROUTES.contracts}`)}
            >
              Contracts
            </TabsTrigger>
            <TabsTrigger
              value={generatePath(
                `${url}${ACCOUNT_DETAILS_ROUTES.childAccounts}`,
              )}
            >
              Sub Accounts
            </TabsTrigger>
            <TabsTrigger
              value={generatePath(`${url}${ACCOUNT_DETAILS_ROUTES.contacts}`, {
                accountId,
              })}
            >
              Contacts
            </TabsTrigger>
          </TabsList>
        </Tabs>
      )}
      <PageContent ref={pageContentRef}>
        {isNewAccount ? (
          <AccountInfo />
        ) : (
          <Switch>
            <Route path={`${path}${ACCOUNT_DETAILS_ROUTES.contracts}`}>
              <AccountContracts />
            </Route>
            <Route path={`${path}${ACCOUNT_DETAILS_ROUTES.childAccounts}`}>
              <ChildAccounts />
            </Route>
            <Route path={`${path}${ACCOUNT_DETAILS_ROUTES.contacts}`}>
              <AccountContacts />
            </Route>
            <Route path="">
              <AccountInfo />
            </Route>
          </Switch>
        )}
      </PageContent>
    </PageLayout>
  );
}
