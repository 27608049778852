import { capitalize } from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";

import VendorsTable from "./VendorsTable";

type Props = {
  isActiveScreen?: boolean;
  canCreateNewVendors: boolean;
};

function VendorsScreen({ isActiveScreen, canCreateNewVendors }: Props) {
  const mainStore = useMainStore();
  const history = useHistory();
  const { moduleWorkspaceID, workspaceID } = mainStore.context;
  const { visibleFields } = mainStore.fields;
  const recordVersions = mainStore.recordVersions.list;

  const vendors = recordVersions.filter((vendor) => {
    if (isActiveScreen) {
      return vendor.record.account?.is_active === true;
    }

    return vendor.record.account?.is_active === false;
  });

  const vendorsWithoutGroup = vendors.filter((rv) => {
    const groupingValue = mainStore.avroSchemas.valueForField(
      "grouping",
      rv?.data,
    );
    return !groupingValue || groupingValue?.length === 0;
  });

  const getGroupsWithVendors = () =>
    mainStore.fieldOptions
      .optionsForField("grouping")
      .map((option) => ({
        ...option,
        vendors: vendors.filter(
          (rv) =>
            mainStore.avroSchemas.firstValueForField("grouping", rv?.data) ===
            option.name,
        ),
      }))
      .filter((group) => group.vendors.length > 0);

  const groupsWithVendors = getGroupsWithVendors();

  return (
    <>
      {isActiveScreen && (
        <>
          <AddRecordHeader
            addRecord={() =>
              history.push(`/workspaces/${workspaceID}/modules/add-new-vendor`)
            }
            recordName="Vendor"
          />
          <div className="vdd-page-content table-list-wrap">
            <VendorsTable
              vendors={vendorsWithoutGroup}
              fields={visibleFields}
              // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
              moduleWorkspaceID={moduleWorkspaceID}
              isActiveScreen
              canCreateNewVendors={canCreateNewVendors}
            />

            {groupsWithVendors.length > 0 && (
              <>
                <div className="vdd-divider table-header-wrap" />
                <div className="vdd-groups">
                  {groupsWithVendors.map((group) => (
                    <VendorsTable
                      key={group.name}
                      sectionId={group.name}
                      title={capitalize(group.name)}
                      vendors={group.vendors}
                      fields={visibleFields}
                      // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
                      moduleWorkspaceID={moduleWorkspaceID}
                      isActiveScreen
                      canCreateNewVendors={canCreateNewVendors}
                    />
                  ))}
                </div>
              </>
            )}

            {isActiveScreen && !canCreateNewVendors && (
              <div className="vendor-payment-alert">
                {" "}
                You can test the Vendor Due Diligence platform by profiling and
                scoring 3 of your vendors. To learn more about how you can use
                this module for all of your vendors, please contact Themis at
                hello@themis.com{" "}
              </div>
            )}
          </div>
        </>
      )}

      {!isActiveScreen && (
        <div className="vdd-page-content table-list-wrap">
          <VendorsTable
            vendors={vendors}
            fields={visibleFields}
            // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
            moduleWorkspaceID={moduleWorkspaceID}
            isActiveScreen={false}
            canCreateNewVendors={canCreateNewVendors}
          />
        </div>
      )}
    </>
  );
}

VendorsScreen.defaultProps = {
  isActiveScreen: true,
};

export default observer(VendorsScreen);
