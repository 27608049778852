import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateTodoItemMutationRequest,
  CreateTodoItemMutationResponse,
  CreateTodoItemPathParams,
} from "../../models/CreateTodoItem";

/**
 * @description Create todo item
 * @summary Create todo item
 * @link /todos/:id/todo_items
 */
export async function createTodoItem(
  id: CreateTodoItemPathParams["id"],
  data: CreateTodoItemMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateTodoItemMutationResponse>["data"]> {
  const res = await client<
    CreateTodoItemMutationResponse,
    CreateTodoItemMutationRequest
  >({
    method: "post",
    url: `/todos/${id}/todo_items`,
    data,
    ...options,
  });
  return res.data;
}
