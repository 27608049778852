import "../../../stylesheets/dashboard/home/header/announcement-header.scss";

import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { Button, Icon, Typography } from "../../Elements";

const AnnouncementHeader = () => {
  const mainStore = useMainStore();
  const announcement = mainStore.announcements.announcement.forHeader;
  const history = useHistory();

  const handleShowMeClick = () => {
    // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
    if (announcement.pathToContent) {
      // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
      history.push(announcement.pathToContent);
    }
    // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
    mainStore.announcements.dismissAnnouncement(announcement.localStorageKey);
  };

  return (
    <div className="announcement-header">
      <div className="announcement-header__title">
        <Icon
          className="announcement-header__icon"
          name="themisLogo"
          color="generalWhite"
          size="md"
        />
        <Typography
          // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
          label={announcement.title}
          color="generalWhite"
          size="lg"
          weight="semiBold"
        />
      </div>
      <div className="announcement-header__content">
        <Typography
          // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
          label={announcement.textContent}
          color="generalWhite"
          className="announcement-header__content-title"
        />
        <Button
          theme="secondary"
          // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
          label={announcement.pathToContent ? "Show Me" : "Dismiss"}
          onClick={handleShowMeClick}
        />
      </div>
    </div>
  );
};

export default observer(AnnouncementHeader);
