import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  DatePicker,
  FilePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  IconButton,
  Label,
  Select,
  SelectProps,
  TextArea,
  TextInput,
  useToast,
} from "@themis/ui";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { useForm } from "react-hook-form";
import { PiTrashSimpleBold, PiXBold } from "react-icons/pi";
import { useParams, useRouteMatch } from "react-router-dom";
import { z } from "zod";

import {
  FindingRiskLevel,
  FindingSource,
  IssueManagementRecordRiskLevel,
  issueManagementRecordRiskLevel,
  IssueManagementRecordSource,
  issueManagementRecordSource,
} from "@/api";
import { useFinding } from "@/api/queries/findings";
import { useCreateIssueManagementRecord } from "@/api/queries/issueManagementRecords";
import { ErrorContainer } from "@/components/ErrorContainer";
import Loading from "@/components/Loading";
import { zodDateCheck } from "@/features/accounts/utils";
import { SendFindingSearchParams } from "@/features/findings/components/FindingsList";
import { IssueManagementRiskLevelTag } from "@/features/issue-management/components/IssueManagementRiskLevelTag";
import {
  IssueManagementSourceTag,
  SOURCE_LABEL_MAP,
} from "@/features/issue-management/components/IssueManagementSourceTag";
import { useSearchParams } from "@/hooks/useSearchParams";
import { getValues } from "@/utils/utils";

import { FindingActiveTag } from "./FindingActiveTag";

const formSchema = z.object({
  title: z.string().min(1, "Title is required"),
  description: z.string(),
  risk_level: z.enum(getValues(issueManagementRecordRiskLevel)).optional(),
  source: z.enum(getValues(issueManagementRecordSource)).optional(),
  date_identified: z.string().refine(zodDateCheck).optional(),
  due_date: z.string().refine(zodDateCheck).optional(),
  supporting_evidence: z.array(
    z.object({
      signedId: z.string(),
      name: z.string(),
    }),
  ),
});

type SendToIssueManagementSchema = z.infer<typeof formSchema>;

const RISK_LEVELS: SelectProps["items"] = [
  {
    label: capitalize(issueManagementRecordRiskLevel.low),
    value: issueManagementRecordRiskLevel.low,
    Component: ({ value }) => <IssueManagementRiskLevelTag riskLevel={value} />,
  },
  {
    label: capitalize(issueManagementRecordRiskLevel.medium),
    value: issueManagementRecordRiskLevel.medium,
    Component: ({ value }) => <IssueManagementRiskLevelTag riskLevel={value} />,
  },
  {
    label: capitalize(issueManagementRecordRiskLevel.high),
    value: issueManagementRecordRiskLevel.high,
    Component: ({ value }) => <IssueManagementRiskLevelTag riskLevel={value} />,
  },
];

const SOURCE_OPTIONS: SelectProps["items"] = [
  {
    label: SOURCE_LABEL_MAP[issueManagementRecordSource.compliance],
    value: issueManagementRecordSource.compliance,
    Component: ({ value }) => <IssueManagementSourceTag source={value} />,
  },
  {
    label: SOURCE_LABEL_MAP[issueManagementRecordSource.audit],
    value: issueManagementRecordSource.audit,
    Component: ({ value }) => <IssueManagementSourceTag source={value} />,
  },
  {
    label: SOURCE_LABEL_MAP[issueManagementRecordSource.regulatory],
    value: issueManagementRecordSource.regulatory,
    Component: ({ value }) => <IssueManagementSourceTag source={value} />,
  },
  {
    label: SOURCE_LABEL_MAP[issueManagementRecordSource.internal],
    value: issueManagementRecordSource.internal,
    Component: ({ value }) => <IssueManagementSourceTag source={value} />,
  },
  {
    label: SOURCE_LABEL_MAP[issueManagementRecordSource.it_incident],
    value: issueManagementRecordSource.it_incident,
    Component: ({ value }) => <IssueManagementSourceTag source={value} />,
  },
];

const issueRiskLevelToIssueManagementRecordRiskLevelMap: Record<
  FindingRiskLevel,
  IssueManagementRecordRiskLevel
> = {
  Low: issueManagementRecordRiskLevel.low,
  Medium: issueManagementRecordRiskLevel.medium,
  High: issueManagementRecordRiskLevel.high,
};

const issueSourceToIssueManagementRecordSourceMap: Record<
  FindingSource,
  IssueManagementRecordSource
> = {
  Compliance: issueManagementRecordSource.compliance,
  Audit: issueManagementRecordSource.audit,
  Regulatory: issueManagementRecordSource.regulatory,
};

function SlideOutContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-fixed tw-inset-y-0 tw-right-0 tw-z-[100] tw-flex tw-w-[500px] tw-flex-col tw-divide-x-0 tw-divide-y tw-divide-solid tw-divide-neutral-100 tw-bg-white tw-shadow-slideout">
      {children}
    </div>
  );
}

function Header({ onClose }: { onClose(): void }) {
  return (
    <header className="tw-flex tw-h-12 tw-shrink-0 tw-items-center tw-justify-between tw-px-5">
      <h1 className="tw-text-base tw-font-semibold tw-text-neutral-500">
        Send To Issue Management
      </h1>
      <IconButton
        aria-label="Close"
        color="transparent"
        Icon={PiXBold}
        onClick={onClose}
      />
    </header>
  );
}

function ReferenceContainer({ children }: { children: React.ReactNode }) {
  return (
    <article className="tw-box-border tw-flex tw-h-8 tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-bg-neutral-50 tw-px-2.5 tw-py-1.5">
      {children}
    </article>
  );
}

function SubHeaderContainer({ children }: { children: React.ReactNode }) {
  return (
    <section className="tw-flex tw-content-center tw-items-center tw-gap-2 tw-px-5 tw-py-2">
      {children}
    </section>
  );
}

export function SendToIssueManagementSlideOut({
  recordId,
}: {
  recordId: number;
}) {
  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();
  const { url } = useRouteMatch();

  const [searchParams, setSearchParams] =
    useSearchParams<SendFindingSearchParams>();

  const toast = useToast();

  const [fileName, setFileName] = useState("");

  const {
    data: finding,
    isPending,
    isError,
  } = useFinding({
    findingId: Number(searchParams.send_finding),
  });

  const { mutate: sendFinding } = useCreateIssueManagementRecord({
    workspaceId: Number(workspace_id),
    recordId,
    onSuccess() {
      toast({
        content: "Finding has been sent to issue management!",
        variant: "success",
      });

      setSearchParams({ ...searchParams, send_finding: undefined });
    },
    onError() {
      toast({
        content: "Failed to send finding to issue management!",
        variant: "error",
      });
    },
  });

  function getDefaultValues() {
    return {
      title: finding?.data.name || "",
      description: finding?.data.description || "",
      risk_level:
        (finding?.data.risk_level &&
          issueRiskLevelToIssueManagementRecordRiskLevelMap[
            finding.data.risk_level
          ]) ||
        undefined,
      source:
        (finding?.data.source &&
          issueSourceToIssueManagementRecordSourceMap[finding.data.source]) ||
        undefined,
      date_identified: finding?.data.date_identified || undefined,
      due_date: finding?.data.due_date || undefined,
      supporting_evidence: [],
    };
  }

  const form = useForm<SendToIssueManagementSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    if (!finding) {
      return;
    }

    // Reset form values when finding data loads
    // This is necessary as the default values are cached
    form.reset(getDefaultValues());
  }, [finding?.data]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    form.handleSubmit(
      ({ supporting_evidence, ...values }: SendToIssueManagementSchema) => {
        sendFinding({
          ...values,
          supporting_evidence: supporting_evidence.map(
            ({ signedId }) => signedId,
          ),
          related_finding_id: finding?.data.id,
        });
      },
    )();
  }

  function handleClickClose() {
    setSearchParams({ ...searchParams, send_finding: undefined });
  }

  if (isPending) {
    return (
      <SlideOutContainer>
        <Header onClose={handleClickClose} />
        <Loading loadingLayout="single-file-form" />
      </SlideOutContainer>
    );
  }

  if (isError) {
    return (
      <SlideOutContainer>
        <Header onClose={handleClickClose} />
        <ErrorContainer backButtonProps={{ linkTo: url, replaceHistory: true }}>
          Could not load finding data.
        </ErrorContainer>
      </SlideOutContainer>
    );
  }

  return (
    <SlideOutContainer>
      <Header onClose={handleClickClose} />
      <SubHeaderContainer>
        <ReferenceContainer>
          <Label className="tw-font-semibold tw-text-neutral-500">Status</Label>
          <FindingActiveTag status={finding.data.status} />
        </ReferenceContainer>
        <ReferenceContainer>
          <Label className="tw-font-semibold tw-text-neutral-500">
            Link to
          </Label>
          <p className="tw-text-secondary-300">{finding.data.name}</p>
        </ReferenceContainer>
      </SubHeaderContainer>
      <Form {...form}>
        <form
          className="tw-flex tw-flex-col tw-overflow-hidden"
          onSubmit={handleSubmit}
        >
          <section className="tw-flex tw-flex-col tw-gap-3 tw-self-stretch tw-overflow-y-auto tw-px-5 tw-py-4">
            <FormField
              required
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <TextInput {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <TextArea {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="risk_level"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Risk Level</FormLabel>
                  <FormControl>
                    <Select
                      items={RISK_LEVELS}
                      selected={field.value}
                      onSelect={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="source"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Source</FormLabel>
                  <FormControl>
                    <Select
                      items={SOURCE_OPTIONS}
                      selected={field.value}
                      onSelect={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="date_identified"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Date Identified</FormLabel>
                  <FormControl>
                    <DatePicker
                      closeOnDateSelect
                      calendarProps={{
                        mode: "single",
                        // Need to parseISO to avoid accidentally moving a day
                        // forward/backward because new Date() will convert to UTC timezone
                        selected: field.value
                          ? parseISO(field.value)
                          : undefined,
                        onSelect: (date) =>
                          date && field.onChange(format(date, "yyyy-MM-dd")),
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="due_date"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Due Date</FormLabel>
                  <FormControl>
                    <DatePicker
                      closeOnDateSelect
                      calendarProps={{
                        mode: "single",
                        // Need to parseISO to avoid accidentally moving a day
                        // forward/backward because new Date() will convert to UTC timezone
                        selected: field.value
                          ? parseISO(field.value)
                          : undefined,
                        onSelect: (date) =>
                          date && field.onChange(format(date, "yyyy-MM-dd")),
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="supporting_evidence"
              render={({ field, fieldState }) => (
                <FormItem className="tw-space-2">
                  <FormLabel className="tw-flex tw-items-center tw-justify-between tw-self-stretch tw-pb-1 tw-text-sm tw-font-semibold tw-text-neutral-500">
                    Supporting Documents
                    <DirectUploadProvider
                      onSuccess={(signedIds) => {
                        field.onChange(
                          field.value.concat({
                            signedId: signedIds[0],
                            name: fileName,
                          }),
                        );

                        setFileName("");
                      }}
                      render={({ handleUpload, ready, uploads }) => {
                        if (uploads[0]?.file.name && !fileName) {
                          setFileName(uploads[0]?.file.name);
                        }

                        return (
                          <FilePicker
                            className={classNames({
                              "tw-bg-warning-50 tw-text-warning-300":
                                fieldState.error,
                            })}
                            isLoading={["uploading", "waiting"].includes(
                              uploads[0]?.state,
                            )}
                            percentage={Math.round(uploads[0]?.progress) || 0}
                            readOnly={!ready}
                            onSelectFile={(selectedFile) =>
                              handleUpload([selectedFile])
                            }
                            trigger={
                              <Button size="sm" color="tertiary">
                                Add
                              </Button>
                            }
                          />
                        );
                      }}
                    />
                  </FormLabel>
                  <FormControl>
                    {field.value.length && (
                      <ul className="tw-flex tw-flex-col tw-items-start tw-divide-x-0 tw-divide-y tw-divide-solid tw-divide-neutral-100 tw-self-stretch tw-border-x-0 tw-border-y tw-border-solid tw-border-neutral-100">
                        {field.value.map(({ name, signedId }) => (
                          <li
                            key={signedId}
                            className="tw-flex tw-min-h-10 tw-items-center tw-gap-2 tw-self-stretch"
                          >
                            <p className="tw-flex-grow tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-sm tw-font-semibold tw-text-secondary-300">
                              {name}
                            </p>
                            <IconButton
                              size="sm"
                              color="transparent"
                              Icon={PiTrashSimpleBold}
                              onClick={() => {
                                field.onChange(
                                  field.value.filter(
                                    (doc) => doc.signedId !== signedId,
                                  ),
                                );
                              }}
                            />
                          </li>
                        ))}
                      </ul>
                    )}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>
          <section className="tw-flex tw-flex-col tw-items-start tw-gap-2.5 tw-self-stretch tw-border-x-0 tw-border-y tw-border-solid tw-border-neutral-100 tw-px-5 tw-py-2">
            <Button type="submit" color="primary" size="lg">
              Send
            </Button>
          </section>
        </form>
      </Form>
    </SlideOutContainer>
  );
}
