import { PublicClientApplication } from "@azure/msal-browser";
import { AxiosResponse } from "axios";
import { observer } from "mobx-react";
import React, { useState } from "react";

import { IntegrationsAPI } from "@/api/legacy/integrations";
import legacyApi from "@/api/legacy/legacy-api";
import { API_URL } from "@/components/constants";
import CurrentUserSettingsNavigation from "@/components/settings/CurrentUserSettingsNavigation";
import RenderIntegrationItem from "@/components/settings/integrations/components/integration-item";
import { msalConfig } from "@/components/table/shared/cell-type/file-select/SharePointFilePicker/helpers";
import { useMainStore } from "@/contexts/Store";
import { useLoadableData } from "@/hooks/useLoadableData";
import figmaIcon from "@/images/table-image/icon/figma.webp";
import googleDriveIcon from "@/images/table-image/icon/logo_drive.webp";
import sharePointIcon from "@/images/table-image/icon/sharepoint.webp";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

const integrationNames = {
  google: "Google Drive",
  figma: "Figma",
  share_point: "Share Point",
};

function UserIntegrations() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [loadingDisconnect, setLoadingDisconnect] = useState({
    google: false,
    figma: false,
    share_point: false,
  });

  // Variables
  const { workspaceID } = mainStore.context;
  const sharepointEnabled = mainStore.featureFlags.getIsEnabled(
    FEATURE_FLAG_ID.COM_SHARE_POINT,
  );

  // Hooks
  const { data: integrationData, refetch } = useLoadableData({
    request: () => IntegrationsAPI.getIntegrations(workspaceID as number),
    onError: () =>
      "There was an issue loading integrations data. Please refresh to try again.",
  });

  // Functions
  async function clearMsalCache() {
    const msalInstance = new PublicClientApplication(msalConfig(""));
    await msalInstance.clearCache();
  }

  const handleConnect = async (type: string, id?: number) => {
    if (type === "share_point") {
      await clearMsalCache();
    }
    const params = id ? { workspace_id: id } : null;
    const response: AxiosResponse<{ url: string }> = await legacyApi({
      method: "GET",
      url: `${API_URL}/integrations/${type}/oauth/generate_redirect_url`,
      headers: mainStore.getHeaders(),
      params,
    });
    window.location.href = response.data.url;
  };

  const handleDisconnect = async (
    type: keyof typeof integrationNames,
    id?: number,
  ) => {
    if (type === "share_point") {
      await clearMsalCache();
    }

    setLoadingDisconnect((prevLoadingDisconnect) => ({
      ...prevLoadingDisconnect,
      [type]: true,
    }));

    try {
      await IntegrationsAPI.disconnectIntegrationAuth(type, id);

      if (type === "share_point") {
        await refetch();
      }

      mainStore.toast.setText(
        `Successfully disconnected ${integrationNames[type]} Integration.`,
      );
    } catch (e) {
      mainStore.toast.setErrorText(
        `There was an issue disconnecting  ${integrationNames[type]} Integration, please try again.`,
      );
    } finally {
      setLoadingDisconnect((prevLoadingDisconnect) => ({
        ...prevLoadingDisconnect,
        [type]: false,
      }));
    }
  };

  return (
    <div className="settings-wrap company-info-settings-container">
      <CurrentUserSettingsNavigation />
      <div className="workspace-dropdown-wrap workspace-dropdown-integration">
        <div className="workspace-dropdown">
          <RenderIntegrationItem
            icon={googleDriveIcon}
            title="Google Drive™"
            subtitle="Upload policies and procedures with Google Docs"
            enabled={mainStore?.users?.user?.google_integration_enabled}
            onConnect={() => handleConnect("google")}
            onDisconnect={() => handleDisconnect("google")}
            loadingDisconnect={loadingDisconnect.google}
          />
          <RenderIntegrationItem
            icon={figmaIcon}
            title="Figma"
            subtitle="Upload creatives with Figma"
            enabled={mainStore?.users?.user?.figma_integration_enabled}
            onConnect={() => handleConnect("figma")}
            onDisconnect={() => handleDisconnect("figma")}
            loadingDisconnect={loadingDisconnect.figma}
          />
          {sharepointEnabled && (
            <RenderIntegrationItem
              icon={sharePointIcon}
              title="Microsoft SharePoint"
              subtitle="Upload and sync documents from Microsoft SharePoint to Themis using your Microsoft 365 account."
              enabled={Boolean(integrationData?.integrations.share_point.id)}
              onConnect={() =>
                handleConnect("share_point", workspaceID as number)
              }
              onDisconnect={() =>
                handleDisconnect("share_point", workspaceID as number)
              }
              loadingDisconnect={loadingDisconnect.share_point}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(UserIntegrations);
