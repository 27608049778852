import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory, useParams } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import { iconForThemisModuleIdentifier } from "../../helpers/iconForThemisModuleIdentifier";
import RecordSelect from "../shared/cell-type/RecordSelect";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import Switch from "../shared/Switch";

function IMControls() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [loaded, setLoaded] = useState(false);
  const [tabPopupOpened, setTabPopupOpened] = useState("");

  // Variables
  // @ts-expect-error TS(2339) FIXME: Property 'record_version_id' does not exist on typ... Remove this comment to see the full error message
  const { record_version_id: recordVersionID } = useParams();
  const { modules, changed } = mainStore.issueManagement.controlsData;
  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === Number(recordVersionID),
  );
  const { workspaceID } = mainStore.context;
  const status = mainStore.avroSchemas.firstValueForField(
    "status",
    recordVersion?.data,
  );
  const isLocked = status === "closed";
  const { hasModuleWriteAccess } = mainStore.userPermissions;

  // hooks
  const history = useHistory();

  // Effects
  useEffect(() => {
    if (!recordVersionID) {
      return;
    }

    const fetchControlData = async () => {
      mainStore.issueManagement.resetControlsData();
      await mainStore.issueManagement.fetchControlsData(recordVersionID);
      setLoaded(true);
    };

    fetchControlData();
  }, [recordVersionID]);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'module' implicitly has an 'any' type.
  function handleSelectModuleControls(module, ids) {
    mainStore.issueManagement.setControlsData({
      ...mainStore.issueManagement.controlsData,
      modules: modules.map((item) =>
        // @ts-expect-error TS(2339) FIXME: Property 'identifier' does not exist on type 'neve... Remove this comment to see the full error message
        item.identifier === module.identifier
          ? // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
            { ...item, selected_ids: ids }
          : item,
      ),
    });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'module' implicitly has an 'any' type.
  function handleModuleSwitchClick(module, e) {
    const { checked } = e.target;

    mainStore.issueManagement.setControlsData({
      ...mainStore.issueManagement.controlsData,
      modules: modules.map((item) =>
        // @ts-expect-error TS(2339) FIXME: Property 'identifier' does not exist on type 'neve... Remove this comment to see the full error message
        item.identifier === module.identifier ? { ...item, checked } : item,
      ),
    });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'link' implicitly has an 'any' type.
  async function handleSaveAndRedirect(link) {
    await mainStore.issueManagement.saveControlsData(recordVersionID);

    handleRedirect(link);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'link' implicitly has an 'any' type.
  function handleRedirect(link) {
    history.push(link);
  }

  // elements -> top
  // @ts-expect-error TS(7006) FIXME: Parameter 'link' implicitly has an 'any' type.
  const renderTabItemWithValidation = (link, label) => {
    if (!changed) {
      return (
        <li>
          <Link to={link}>{label}</Link>
        </li>
      );
    }

    return (
      <Popup
        position="bottom left"
        trigger={
          <li>
            <Link to="#">{label}</Link>
          </li>
        }
        open={tabPopupOpened === label}
        onOpen={() => setTabPopupOpened(label)}
        onClose={() => setTabPopupOpened("")}
        keepTooltipInside
      >
        <div className="table-dropdown success-dropdown back-btn-confirm-popup">
          <ConfirmationDialog
            handleConfirm={() => handleSaveAndRedirect(link)}
            handleReject={() => handleRedirect(link)}
            heading="Save Now?"
            content="Do you want to save changes?"
          />
        </div>
      </Popup>
    );
  };

  const renderTabs = (
    <div className="settings-content-wrap">
      <div className="settings-links-wrap audit-trail-record-wrap">
        <ul>
          {renderTabItemWithValidation(
            `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}`,
            "Record View",
          )}
          {renderTabItemWithValidation(
            `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/action-plans`,
            "Action Plans",
          )}
          <li>
            <NavLink to="#">Module Controls</NavLink>
          </li>
          {renderTabItemWithValidation(
            `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/operational-controls`,
            "Operational Controls",
          )}
          {renderTabItemWithValidation(
            `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/related-risks`,
            "Related Risks",
          )}
          {renderTabItemWithValidation(
            `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/audit_trail`,
            "Activity",
          )}
        </ul>
      </div>
    </div>
  );

  // elements -> left side
  const leftHeaderBlock = (
    <div
      className="rr-controls-block left-header"
      data-testid="im-controls-left-header"
    >
      <h4>Add Controls to Mitigate Identified Risk:</h4>
      <div className="rr-controls-helper-block">
        Toggle modules below to add more control documents to the register.
      </div>
    </div>
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'module' implicitly has an 'any' type.
  const renderModuleItem = (module) => (
    <div
      key={module.identifier}
      className="rr-module-item"
      data-testid="im-controls-module-item"
    >
      <div className="rr-module-item-heading">
        <Switch
          active={hasModuleWriteAccess}
          indeterminate={false}
          checked={Boolean(module.checked)}
          onChange={(e) => handleModuleSwitchClick(module, e)}
          disabled={!hasModuleWriteAccess}
        />
        <img
          src={iconForThemisModuleIdentifier(module.identifier)}
          alt="module-icon"
        />
        <h5>{module.title}</h5>
      </div>

      {module.checked && (
        <div
          className={classNames(
            "rr-module-item-content custom-column-option-container",
            { "rr-filled-input": module.selected_ids.length > 0 },
          )}
        >
          <RecordSelect
            dataType={module.data_type}
            selectedIDs={module.selected_ids}
            fetchOnInit
            singleSelection={false}
            handleSelect={(ids) => handleSelectModuleControls(module, ids)}
          />
        </div>
      )}
    </div>
  );

  return (
    <div className="settings-wrap company-users-settings-container detail-view-documents-wrap">
      {renderTabs}

      {loaded && (
        <div
          className={classNames("rr-controls-container", { locked: isLocked })}
          data-testid="im-controls-container"
        >
          <div
            className="rr-controls-heading"
            data-testid="im-controls-heading"
          >
            <p>Associated Record:</p>
            <span>{recordVersion?.meta?.name}</span>
          </div>
          <div
            className="rr-controls-content"
            data-testid="im-controls-content"
          >
            <div className="rr-controls-side">
              {leftHeaderBlock}
              {modules.map(renderModuleItem)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default observer(IMControls);
