import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { ConfirmPopup } from "@/components/confirm-popup";
import { Button, Flex, Typography } from "@/components/Elements";
import {
  formatTimeAfterLongDate,
  stringToDateAndTimeSSSZ,
} from "@/components/helpers/DateFormatters";
import { Divider, IconMenu, IconMenuItem } from "@/components/IconMenu";
import Checkbox from "@/components/table/shared/Checkbox";
import UserMentionsInput from "@/components/table/shared/UserMentionsInput";
import { UsersCircle } from "@/components/users-circle";
import { useMainStore } from "@/contexts/Store";
import { UserComment } from "@/stores/types/comment-types";

function CreativeViewFilePositionComment(props: {
  reply?: boolean;
  comment: UserComment;
  handlePopupClose(): void;
}) {
  const mainStore = useMainStore();
  const history = useHistory();
  const [showDeleteCommentPopup, setShowDeleteCommentPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editValue, setEditValue] = useState(props.comment.content);

  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;

  const formattedCreateDate = formatTimeAfterLongDate(
    stringToDateAndTimeSSSZ(props.comment.created_at),
    { removeAt: true },
  );

  const subscribeLabel = props.comment.is_subscribed_to_thread
    ? "Unsubscribe"
    : "Subscribe";

  const currentUserID = mainStore.users.user.id;
  const isUserAuthor = props.comment.author.id === currentUserID;

  const showViewTask =
    !!currentUserID &&
    !!props.comment.assignees_ids.length &&
    (isUserAuthor || props.comment.assignees_ids.includes(currentUserID));

  function handleShowDeleteCommentPopup() {
    setShowDeleteCommentPopup(true);
  }

  function handleToggleEditMode() {
    setEditMode((value) => !value);
  }

  function handleResolveCommentToggle() {
    mainStore.comments.update(props.comment.id, {
      resolved: !props.comment.resolved,
    });
  }

  function handleDeleteComment() {
    if (!props.reply) {
      props.handlePopupClose();
    }
    mainStore.comments.delete(props.comment.id);
  }

  function handleUpdateComment() {
    mainStore.comments.update(props.comment.id, { content: editValue });
    handleToggleEditMode();
  }

  function handleCancelUpdateComment() {
    setEditValue(props.comment.content);
    handleToggleEditMode();
  }

  function handleUpdateSubscription() {
    mainStore.comments.updateSubscription(
      props.comment.id,
      !props.comment.is_subscribed_to_thread,
    );
  }

  function handleCopyLink() {
    mainStore.comments.copyCommentLink(props.comment);
  }

  function handleViewTask() {
    history.push(`/notifications/tasks/${props.comment.id}/edit`);
  }

  return (
    <div
      key={props.comment.id}
      className={classNames("creative-popup-show-item", {
        ["comment-reply"]: props.reply,
      })}
    >
      <div className="heading">
        <Flex alignCenter columnGap={8}>
          <UsersCircle
            small={props.reply}
            initials={props.comment.author.initials}
            iconColorIndex={props.comment.author.icon_color_index}
          />
          <Flex column>
            <Typography
              label={props.comment.author.full_name}
              weight="semiBold"
              color="generalMidnightDark"
              size="sm"
            />
            <Typography
              label={formattedCreateDate}
              weight="semiBold"
              color="extrasBlueGrayDarker"
              size="xs"
            />
          </Flex>
        </Flex>
        <div className="comment-actions">
          {!props.reply && (
            <Checkbox
              small
              padding
              disabled={
                isCurrentWorkspaceArchived || !props.comment.user_can_update
              }
              checked={props.comment.resolved}
              onChange={handleResolveCommentToggle}
            />
          )}
          {isCurrentWorkspaceActive && (
            <IconMenu
              nested
              icon="moreHorizontal"
              triggerTestId="cv-popup-show-context-menu-trigger"
            >
              {props.comment.user_can_update_content && (
                <IconMenuItem label="Edit" onClick={handleToggleEditMode} />
              )}
              {showViewTask && (
                <IconMenuItem label="View Comment" onClick={handleViewTask} />
              )}
              <IconMenuItem label="Copy Link" onClick={handleCopyLink} />
              {!props.reply && (
                <>
                  <Divider />
                  <IconMenuItem
                    label={subscribeLabel}
                    onClick={handleUpdateSubscription}
                  />
                </>
              )}
              {props.comment.user_can_destroy && (
                <>
                  <Divider />
                  <IconMenuItem
                    label="Delete"
                    onClick={handleShowDeleteCommentPopup}
                  />
                </>
              )}
            </IconMenu>
          )}
          <ConfirmPopup
            title="Delete Comment"
            content="Are you sure you want to remove this comment?"
            showPopup={showDeleteCommentPopup}
            setShowPopup={setShowDeleteCommentPopup}
            onConfirmClick={handleDeleteComment}
          />
        </div>
      </div>
      {mainStore.userPermissions.canAddComments && (
        <UserMentionsInput
          placeholder={
            props.reply ? "Reply" : "Add a comment. Use @ to mention a user."
          }
          singleLine={false}
          editable={editMode}
          content={editValue}
          onChange={(event) => {
            setEditValue(event.target.value);
          }}
        />
      )}
      {editMode && (
        <Flex columnGap={8}>
          <Button onClick={handleUpdateComment} label="Update" size="sm" />
          <Button
            onClick={handleCancelUpdateComment}
            label="Cancel"
            theme="tertiary"
            size="sm"
          />
        </Flex>
      )}
    </div>
  );
}

export default observer(CreativeViewFilePositionComment);
