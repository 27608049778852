import React from "react";
import { Bar, ComposedChart, LabelList, Line, ResponsiveContainer, Text, XAxis, } from "recharts";
var labelWidth = 32;
var labelHeight = 24;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var CustomAxisTick = function (props) {
    var x = props.x, y = props.y, payload = props.payload;
    return (React.createElement(Text, { x: x, y: y + 8, width: 124, textAnchor: "middle", verticalAnchor: "middle", fill: "#353549", fontFamily: "Poppins", fontSize: 12, fontWeight: 600 }, payload.value));
};
export var StackedBarChart = function (_a) {
    var _b = _a.wireKey, wireKey = _b === void 0 ? "none" : _b, data = _a.data, keys = _a.keys, colors = _a.colors;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var FloatingValueLabel = function (props) {
        var _a;
        var x = props.x, y = props.y, width = props.width, name = props.name;
        var barValue = (_a = data.find(function (d) { return d.name === name; })) === null || _a === void 0 ? void 0 : _a.total;
        if (!barValue) {
            return null;
        }
        return (React.createElement("g", null,
            React.createElement("rect", { x: x - 4, y: y - labelWidth, rx: 4, ry: 4, width: labelWidth, height: labelHeight, fill: "#F6F6F9" }),
            React.createElement("text", { x: x + width / 2, y: y - labelHeight / 2 - 4, fill: "#353549", textAnchor: "middle", fontFamily: "Poppins", fontWeight: 600, fontSize: 12 }, barValue)));
    };
    return (React.createElement(ResponsiveContainer, { width: "100%", height: "100%" },
        React.createElement(ComposedChart, { data: data, margin: {
                top: 40,
            } },
            React.createElement(XAxis, { interval: 0, dataKey: "name", tickLine: false, tick: CustomAxisTick, axisLine: {
                    stroke: "#E4E4EB",
                    strokeWidth: 1,
                } }),
            wireKey !== "none" && (React.createElement(Line, { type: "linear", dataKey: wireKey, stroke: "#313181", strokeWidth: 2, dot: false })),
            keys
                .map(function (key, index) {
                var _a, _b;
                return index === 0 ? (React.createElement(Bar, { key: "bar-".concat(key), dataKey: key, stackId: "a", barSize: 24, fill: (_a = colors.find(function (c) { return c.key === key; })) === null || _a === void 0 ? void 0 : _a.color },
                    React.createElement(LabelList, { dataKey: "total", content: FloatingValueLabel }))) : (React.createElement(Bar, { key: "bar-".concat(key), dataKey: key, stackId: "a", barSize: 24, fill: (_b = colors.find(function (c) { return c.key === key; })) === null || _b === void 0 ? void 0 : _b.color }));
            })
                .reverse())));
};
