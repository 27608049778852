import { PreviewTableColumn } from "@themis/ui";
import React from "react";
import {
  PiBookOpenBold,
  PiGlobeBold,
  PiHashStraightBold,
  PiLinkSimpleBold,
  PiListBulletsBold,
  PiTagBold,
  PiTextAlignLeftBold,
  PiTextTBold,
  PiUserBold,
} from "react-icons/pi";

import { Account, Contact } from "@/api";

import { AccountActionsCell } from "../components/AccountsTable/AccountActionsCell";
import { AccountContactCell } from "../components/AccountsTable/AccountContactCell";
import { AccountIdLinkCell } from "../components/AccountsTable/AccountIdLinkCell";
import { AccountTypeTag } from "../components/AccountTypeTag";
import { ActiveTag } from "../components/ActiveTag";

export const ACCOUNT_COLUMNS: PreviewTableColumn<
  Account & {
    main_contact: Contact | null;
  }
>[] = [
  {
    title: "ID",
    type: "id",
    width: 160,
    Component: (account) => <AccountIdLinkCell accountId={account.id} />,
    Icon: PiGlobeBold,
  },
  {
    title: "Status",
    type: "locked",
    width: 200,
    Component: (account) => <ActiveTag isActive={account.is_active} />,
    Icon: PiBookOpenBold,
  },
  {
    key: "name",
    title: "Account Name",
    type: "locked",
    width: 200,
    Icon: PiTextTBold,
  },
  {
    title: "Parent Account",
    type: "locked",
    width: 200,
    Icon: PiListBulletsBold,
    Component: ({ parent_account_name }) => parent_account_name || "N/A",
  },
  {
    key: "legal_name",
    title: "Legal Entity Name",
    type: "locked",
    width: 200,
    Icon: PiTextTBold,
  },
  {
    title: "Account Type",
    Component: (account) =>
      account.account_types.length ? (
        <AccountTypeTag type={account.account_types[0]} />
      ) : null,
    type: "locked",
    width: 200,
    Icon: PiTagBold,
  },
  {
    key: "website",
    title: "Company Website",
    type: "locked",
    width: 200,
    Icon: PiLinkSimpleBold,
  },
  {
    key: "duns_number",
    title: "DUNS Number",
    type: "locked",
    width: 200,
    Icon: PiTextTBold,
  },
  {
    title: "Main Contact",
    type: "locked",
    width: 200,
    Component: ({ main_contact }) =>
      main_contact ? (
        <AccountContactCell contact={main_contact} display="name" />
      ) : null,
    Icon: PiUserBold,
  },
  {
    title: "Contact Email",
    type: "locked",
    width: 200,
    Component: ({ main_contact }) =>
      main_contact ? (
        <AccountContactCell contact={main_contact} display="email" />
      ) : null,
    Icon: PiTextTBold,
  },
  {
    key: "phone",
    title: "Company Phone",
    type: "locked",
    width: 200,
    Icon: PiTextTBold,
  },
  {
    key: "description",
    title: "Description",
    type: "locked",
    width: 200,
    Icon: PiTextAlignLeftBold,
  },
  {
    key: "mailing_street",
    title: "Street",
    type: "locked",
    width: 200,
    Icon: PiTextTBold,
  },
  {
    key: "mailing_city",
    title: "City",
    type: "locked",
    width: 200,
    Icon: PiTagBold,
  },
  {
    key: "mailing_state",
    title: "State",
    type: "locked",
    width: 200,
    Icon: PiTagBold,
  },
  {
    key: "mailing_postal_code",
    title: "Postal Code",
    type: "locked",
    width: 200,
    Icon: PiHashStraightBold,
  },
  {
    key: "mailing_country",
    title: "Country",
    type: "locked",
    width: 200,
    Icon: PiTagBold,
  },
  {
    type: "actions",
    Component: (account) => (
      <AccountActionsCell accountId={account.id} name={account.name} />
    ),
  },
] as const;

export const PREVIEW_CHILD_ACCOUNTS_COLUMNS: PreviewTableColumn<Account>[] = [
  {
    title: "ID",
    type: "id",
    width: 160,
    Icon: PiGlobeBold,
    Component: (account) => <AccountIdLinkCell accountId={account.id} />,
  },
  {
    key: "name",
    title: "Name",
    type: "locked",
    width: 676,
    isRowStart: true,
    isRowEnd: true,
    Icon: PiTextTBold,
  },
] as const;
