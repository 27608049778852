import "../pages/todo-items.scss";

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { TodoItem, todosService, UpdateTodoItemRequest } from "@/api";
import Checkbox from "@/components/table/shared/Checkbox";
import mainStore from "@/stores/Main";

import { todoItemFields } from "../constants";
import { TodoItemsFormValues } from "../types";
import TodoItemsContextMenu from "./TodoItemsContextMenu";
import TodoItemsRowFieldRenderer from "./TodoItemsRowFieldRenderer";

type TodoItemsRowProps = {
  todoItem: TodoItem;
  refetch: VoidFunction;
};

function TodoItemsRow({ todoItem, refetch }: TodoItemsRowProps) {
  // Constants
  const { control, handleSubmit, setError, watch, clearErrors } =
    useForm<TodoItemsFormValues>({
      defaultValues: {
        name: todoItem.name,
        completed: todoItem.completed,
      },
    });
  const isCompleted = todoItem.completed;
  const nameField = watch("name");

  useEffect(() => {
    if (nameField) {
      clearErrors("name");
    }
  }, [nameField]);

  // Functions
  async function handleResolveCheckboxToggle(data: TodoItemsFormValues) {
    const serializeData = {
      todo_item: {
        ...data,
        completed: !isCompleted!,
      },
    };

    try {
      await handleUpdate(serializeData);
      refetch();

      mainStore.toast.setInfoText(
        `Checklist Item was successfully ${
          todoItem.completed ? "uncompleted" : "completed"
        }.`,
      );
    } catch (error) {
      mainStore.toast.setInfoText(
        `An error occurred. Could not ${
          todoItem.completed ? "uncompleted" : "completed"
        } checklist item.`,
      );
      window.console.log(error);
    }
  }

  async function updateItemName(data: TodoItemsFormValues) {
    if (!nameField.trim()) {
      setError("name", {});
      mainStore.toast.setErrorText("The name field cannot be empty!");

      return;
    }

    const serializeData = {
      todo_item: {
        ...data,
        name: data.name!,
      },
    };

    try {
      await handleUpdate(serializeData);
      refetch();

      mainStore.toast.setInfoText("Checklist Item was successfully renamed");
    } catch (error) {
      mainStore.toast.setErrorText(
        "An error occurred. Could not rename checklist item",
      );
      window.console.log(error);
    }
  }

  function handleUpdate(data: UpdateTodoItemRequest) {
    // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    return todosService.updateTodoItem(todoItem.id, data);
  }

  return (
    <div className="list-table">
      <ul>
        <div className="tw-mr-[2px] tw-px-2 tw-py-1">
          <Checkbox
            marginTop
            rounded
            checked={todoItem.completed}
            onChange={() => handleResolveCheckboxToggle(todoItem)}
          />
        </div>
        <div className="list-table-wrap">
          <div className="list-table-block">
            {todoItemFields.map((field) => {
              return (
                <form
                  onSubmit={handleSubmit(updateItemName)}
                  onBlur={handleSubmit(updateItemName)}
                  key={field.field_name}
                >
                  <TodoItemsRowFieldRenderer
                    key={field.field_name}
                    fieldData={field}
                    control={control}
                    isCompleted={isCompleted}
                  />
                </form>
              );
            })}
            <span className="stretch-cell" />
          </div>
        </div>
        <TodoItemsContextMenu todoItemID={todoItem.id} refetch={refetch} />
      </ul>
    </div>
  );
}

export default TodoItemsRow;
