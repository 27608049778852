import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ShowTodoPathParams,
  ShowTodoQueryResponse,
} from "../../models/ShowTodo";

/**
 * @description Todo data
 * @summary todo
 * @link /todos/:id
 */
export async function showTodo(
  id: ShowTodoPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ShowTodoQueryResponse>["data"]> {
  const res = await client<ShowTodoQueryResponse>({
    method: "get",
    url: `/todos/${id}`,
    ...options,
  });
  return res.data;
}
