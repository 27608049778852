import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import infoIcon from "../../../../images/table-image/icon/smart-assistance-blue.svg";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion?: any;
};

function CreativeViewTips({ recordVersion }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // vars
  const currentUserID = mainStore.users.user.id;
  const { status } = recordVersion;

  const approversIDs = mainStore.avroSchemas.valueForField(
    "approvers",
    recordVersion.data,
  );
  const isApprover = approversIDs?.includes(currentUserID);
  const submittersIDs = mainStore.avroSchemas.valueForField(
    "submitter",
    recordVersion.data,
  );
  const isSubmitter = submittersIDs?.includes(currentUserID);

  // msg
  let msg = null;
  if (isSubmitter && !isApprover && status === "reviewing") {
    msg =
      "When creative is being reviewed, you (as a submitter) cannot modify the creative.";
  } else if (isSubmitter && status === "request_change") {
    msg =
      "You have a change request. Please update the creative before pushing back to reviewers.";
  } else if (isApprover && status === "reviewing") {
    msg =
      "Please review the attachment and decide whether to approve, deny or send request back to submitter.";
  } else if (isApprover && !isSubmitter && status === "request_change") {
    msg =
      "Submitter is working on the changes, please wait until they push you back an update.";
  } else if (
    !isSubmitter &&
    (status === "new" || status === "request_change")
  ) {
    msg = "Only the submitter can modify the attached files.";
  }
  if (!msg) {
    return null;
  }

  return (
    <section className="creative-view-tips" data-testid="creative-view-tips">
      <img src={infoIcon} alt="info" />
      <span>{msg}</span>
    </section>
  );
}

CreativeViewTips.defaultProps = {
  recordVersion: {},
};

export default observer(CreativeViewTips);
