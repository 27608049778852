import { Label } from "@themis/ui";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useMonitoringGroupAsset } from "@/api/queries/websiteMonitoring";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import { useMainStore } from "@/contexts/Store";
import FavoriteButton from "@/features/misc/favorites/FavoriteButton";

import { MonitoringGroupChildrenTable } from "../../components/MonitoringGroupChildrenTable";
import StatusFilterButton from "../../components/StatusFilterButton";
import { Subtitle } from "../../components/Subtitle";
import { ChildStatus } from "../../config/status";
import { useChildrenFilterSort } from "../../hooks/useChildrenFilterSort";
import { FilterOperand } from "../../hooks/useFilterSort";
import { WEBSITE_MONITORING_ROUTES } from "../../pages";

function FormContainer({ children }: { children: React.ReactNode }) {
  return (
    <div
      className="tw-flex tw-w-full tw-flex-row tw-flex-wrap tw-items-center tw-justify-between tw-rounded-md tw-px-6 tw-py-3 tw-text-left"
      style={{ backgroundColor: "white", overflow: "auto" }}
    >
      {children}
    </div>
  );
}

function StatusContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-8 tw-rounded-md">
      {children}
    </div>
  );
}
export function MonitoringGroupChildrenDetails() {
  const { monitoringGroupAssetId } = useParams<{
    monitoringGroupAssetId: string;
  }>();

  const mainStore = useMainStore();

  const { filters, addFilter, sorting, setSorting } = useChildrenFilterSort();
  const { data, isLoading, error } = useMonitoringGroupAsset(
    Number(monitoringGroupAssetId),
  );

  useEffect(() => {
    if (!filters?.status) {
      addFilter("child_status", {
        operand: FilterOperand.ANY,
        value: [ChildStatus["Need Review"].value, ChildStatus.Resolved.value],
      });
    }

    if (!sorting) {
      setSorting({
        columnKey: "monitoring_group_asset_child_updated_at",
        direction: "desc",
      });
    }
  }, []);

  return (
    <PageLayout>
      <Header
        backButtonLinkTo={`${WEBSITE_MONITORING_ROUTES.base}${WEBSITE_MONITORING_ROUTES.monitoringGroups}`}
        title={
          <div className="tw-align-center tw-flex tw-gap-1">
            Website Monitoring
            {!(isLoading || error) && (
              <>
                <Subtitle>{data?.data?.group?.group_name || ""}</Subtitle>
                <Subtitle>{data?.data?.asset_name || ""}</Subtitle>
              </>
            )}
            <FavoriteButton
              passedModuleWorkspaceID={
                mainStore.moduleWorkspaces.list.find(
                  (mw) => mw.themis_module.identifier === "website_monitoring",
                )?.id
              }
            />
          </div>
        }
      />

      <FormContainer>
        <StatusContainer>
          <Label className="tw-h-8 tw-content-center tw-rounded-md tw-bg-neutral-50 tw-py-1.5 tw-pl-2 tw-pr-2.5 tw-text-sm tw-font-semibold tw-text-neutral-500">
            Parent URL
            <span className="tw-pl-4 tw-text-neutral-300">
              {data?.data?.data}
            </span>
          </Label>
          <Label className="tw-h-8 tw-content-center tw-rounded-md tw-bg-neutral-50 tw-px-2.5 tw-py-1.5 tw-text-sm tw-font-semibold tw-text-neutral-500">
            Asset Type
            <span className="tw-pl-4  tw-text-neutral-300">
              {data?.data?.asset_type}
            </span>
          </Label>
        </StatusContainer>
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-4 tw-rounded-md tw-text-center">
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-4 tw-rounded-md tw-py-8 tw-text-center">
            <StatusFilterButton />
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-4 tw-rounded-md tw-py-8 tw-text-center" />
        </div>
      </FormContainer>

      <PageContent>
        <MonitoringGroupChildrenTable
          monitoringGroupAssetId={Number(monitoringGroupAssetId)}
        />
      </PageContent>
    </PageLayout>
  );
}
