var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { PiLinkSimpleBold } from "react-icons/pi";
import { cn } from "../../../lib/utils";
import { IconButton } from "../../Button/IconButton";
import { TextInput } from "../../Form/TextInput";
import CustomLink from "./CustomLink";
function LinkRenderCell(_a) {
    var _b;
    var value = _a.value, props = __rest(_a, ["value"]);
    var editable = Boolean((_b = props.colDef) === null || _b === void 0 ? void 0 : _b.editable);
    return (React.createElement("div", { className: "tw-relative tw-h-full tw-w-full" },
        editable ? (React.createElement(TextInput, { className: "tw-h-full tw-rounded-none tw-border-0 tw-bg-transparent tw-text-secondary-300 !tw-placeholder-transparent group-hover/cell:!tw-placeholder-neutral-200", placeholder: props.placeholder || "Add Link", value: value, onChange: function () { } })) : (React.createElement("span", { className: "tw-flex tw-h-full tw-items-center tw-rounded-none tw-border-0 tw-bg-transparent tw-px-3 tw-font-medium tw-text-secondary-300 !tw-placeholder-transparent group-hover/cell:!tw-placeholder-neutral-200" }, value)),
        React.createElement(CustomLink, { to: value, className: "tw-absolute tw-right-0 tw-top-1 tw-mr-2" },
            React.createElement(IconButton, { onClick: function () { }, Icon: PiLinkSimpleBold, color: "transparent", size: "lg", className: cn({
                    "[&>svg]:tw-fill-transparent": !value,
                    "group-hover/cell:[&>svg]:tw-fill-neutral-500": value,
                    "group-hover/cell:[&>svg]:tw-fill-neutral-200": !value,
                }) }))));
}
export default LinkRenderCell;
