import { Button, ConfirmationPopup, IconButton, useToast } from "@themis/ui";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { PiXBold } from "react-icons/pi";

import { Relatable, UpdateTaskRequest } from "@/api";
import {
  useCreateTask,
  useCreateTaskable,
  useDeleteTaskable,
  useTask,
  useUpdateTask,
} from "@/api/queries/tasks";
import { useCompanyUsers } from "@/api/queries/users";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";

import UserBadge from "../../../components/shared/UserBadge";
import TaskDetailForm, { TaskDetailFormSchema } from "./TaskDetailForm";

const SLIDEOUT_WIDTH = "500px";

function HeaderContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-px-7 tw-py-2.5">
      {children}
    </div>
  );
}
function SectionContainer({ children }: { children: React.ReactNode }) {
  return <div className="tw-px-7 tw-py-3">{children}</div>;
}

function TaskDetail() {
  const mainStore = useMainStore();
  const { taskDetail } = mainStore;
  const { workspaceID } = mainStore.context;
  const { list: workspaces } = mainStore.workspaces;
  const activeWorkspaces = workspaces.filter(
    (workspace) => !workspace.is_archived,
  );
  const companyId = mainStore.companies.company?.id;
  const isNew = !taskDetail.data.id;
  const toast = useToast();
  const isArchived = Boolean(taskDetail.data.archived_at);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const taskDetailContainer = useRef<HTMLDivElement>(null);
  const [formDidChange, setFormDidChange] = useState(false);

  const { data, isSuccess: isUsersSuccess } = useCompanyUsers(
    Number(companyId),
    { view: "all" },
  );
  const users = data?.data;
  const { data: task, isSuccess: isTaskSuccess } = useTask(
    Number(companyId),
    Number(taskDetail.data.id),
  );

  const isSuccess = isUsersSuccess && (isNew || isTaskSuccess);
  const createdByUser = isNew
    ? mainStore.users.user
    : users?.find((user) => user.id === task?.data.created_by_id) || {
        full_name: "Not Found",
        initials: "NF",
        icon_color_index: 0,
      };

  const { mutateAsync: updateTask } = useUpdateTask({
    companyId: Number(companyId),
    taskId: Number(taskDetail.data.id),
  });
  const { mutateAsync: createTask } = useCreateTask({
    companyId: Number(companyId),
  });
  const { mutateAsync: createTaskable } = useCreateTaskable({
    companyId: Number(companyId),
    taskId: Number(taskDetail.data.id),
  });
  const { mutateAsync: deleteTaskable } = useDeleteTaskable({
    companyId: Number(companyId),
    taskId: Number(taskDetail.data.id),
  });

  const handleClickClose = () => {
    formDidChange && isNew ? setIsConfirmationOpen(true) : handleClose();
  };

  const handleCancelClose = (
    event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent,
  ) => {
    event.stopPropagation();
    setIsConfirmationOpen(false);
  };

  const handleCreateTask = async (
    values: TaskDetailFormSchema,
    taskables?: Relatable[],
    collaboratorIds?: number[],
  ) => {
    const newTaskData = {
      name: values.name,
      status: values.status || null,
      description: values.description || null,
      due_date: values.due_date || null, // Value comes from form input as a Date obj but server expects a string
      assignee_id: Number(values.assignee_id) || null, // Value comes from form input, which enforces a string type on the user name, so we have to lookup the id and turn it into a number
      workspace_id: Number(values.workspace_id) || null,
      collaborator_ids: collaboratorIds,
      parent_id: taskDetail.data.parent_id || null,
      taskables: taskables?.map((taskable) => ({
        taskable_id: taskable.id,
        taskable_type: "Record",
      })),
    };

    try {
      const { data: updatedTask } = await createTask({
        task: { ...newTaskData },
      });
      toast({
        content: "Task created successfully!",
        variant: "success",
        onClick: () => taskDetail.open(updatedTask),
      });
      taskDetail.close();
    } catch {
      toast({
        content: "Something went wrong. Could not create task.",
        variant: "error",
      });
    }
  };

  const handleClose = () => {
    taskDetail.close();
  };

  const handleUpdateTask = async (
    updatedValue: Partial<UpdateTaskRequest["task"]>,
  ) => {
    try {
      await updateTask({
        task: {
          ...updatedValue,
        },
      });

      toast({ content: "Task updated successfully!", variant: "success" });
    } catch {
      toast({
        content: "Something went wrong. Could not update task.",
        variant: "error",
      });
    }
  };

  async function handleRecordSelect(record: Relatable) {
    try {
      await createTaskable({
        taskable: {
          target_id: record.id,
          target_type: "Record",
        },
      });
      toast({
        content: "Associated Record added to Task successfully!",
        variant: "success",
      });
    } catch {
      toast({
        content: "Something went wrong. Could not update task.",
        variant: "error",
      });
    }
  }

  async function handleRecordDelete(id: number) {
    try {
      await deleteTaskable(id);
      toast({
        content: "Associated Record deleted successfully!",
        variant: "success",
      });
    } catch {
      toast({
        content: "Something went wrong. Could not update task.",
        variant: "error",
      });
    }
  }

  const handleToggleArchive = async () => {
    try {
      await updateTask({
        task: {
          archived_at: isArchived ? null : new Date().toISOString(),
        },
      });
      toast({
        content: isArchived
          ? "Task unarchived successfully!"
          : "Task archived successfully!",
        variant: "success",
      });
      taskDetail.close();
    } catch {
      toast({
        content: isArchived
          ? "Something went wrong. Could not unarchive task."
          : "Something went wrong. Could not archive task.",
        variant: "error",
      });
    }
  };

  const handleMarkComplete = async () => {
    try {
      await updateTask({
        task: {
          status: "Done",
        },
      });
      toast({ content: "Task completed!", variant: "success" });
      taskDetail.close();
    } catch {
      toast({
        content: "Something went wrong. Could not update task.",
        variant: "error",
      });
    }
  };

  return (
    <div
      ref={taskDetailContainer}
      className="tw-absolute tw-inset-y-0 tw-right-0 tw-z-[101] tw-flex tw-flex-col tw-divide-x-0 tw-divide-y tw-divide-solid tw-divide-neutral-100 tw-overflow-y-auto tw-bg-white tw-shadow-slideout"
      style={{ width: SLIDEOUT_WIDTH }}
    >
      <HeaderContainer>
        <h3 className="tw-text-base tw-font-semibold tw-text-neutral-500">
          Task Detail
        </h3>
        <ConfirmationPopup
          title="Unsaved New Task"
          content="Your task has not been created. Are you sure you want to close this form?"
          open={isConfirmationOpen}
          onCancel={handleCancelClose}
          onConfirm={handleClose}
          align="end"
          anchor
        >
          <IconButton
            onClick={handleClickClose}
            color="transparent"
            Icon={PiXBold}
          />
        </ConfirmationPopup>
      </HeaderContainer>

      <SectionContainer>
        <div className="tw-flex tw-items-center tw-justify-between ">
          {!isNew && !isArchived && (
            <Button
              color="tertiary"
              size="md"
              disabled={task?.data.status === "Done"}
              onClick={handleMarkComplete}
            >
              Mark Complete
            </Button>
          )}
          <UserBadge text="Created by" user={createdByUser} />
        </div>
      </SectionContainer>

      <SectionContainer>
        {isSuccess ? (
          <TaskDetailForm
            workspaces={activeWorkspaces}
            currentWorkspaceId={workspaceID}
            users={users || []}
            onSubmit={handleCreateTask}
            onFieldChange={handleUpdateTask}
            onClickArchive={handleToggleArchive}
            defaultValues={isNew ? taskDetail.data : task?.data}
            formDidChange={formDidChange}
            setFormDidChange={setFormDidChange}
            onRecordSelect={handleRecordSelect}
            onRecordDelete={handleRecordDelete}
          />
        ) : (
          <Loading loadingLayout="single-file-form" />
        )}
      </SectionContainer>
    </div>
  );
}
export { SLIDEOUT_WIDTH };

export default observer(TaskDetail);
