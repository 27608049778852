import "../pages/todo-items.scss";

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { todosService } from "@/api";
import { Icon } from "@/components/Elements";
import ConfirmationDialog from "@/components/table/shared/ConfirmationDialog";
import { useMainStore } from "@/contexts/Store";

type Props = {
  todoItemID: number;
  refetch: VoidFunction;
};

function TodoItemsContextMenu({ todoItemID, refetch }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);

  // Functions
  async function handleDeleteRow() {
    await todosService.deleteTodoItem(todoItemID);
    refetch();
    mainStore.toast.setInfoText("The checklist item was successfully deleted.");

    setShowPopup(false);
  }

  const renderTrigger = (
    <button
      type="button"
      className={classNames({
        "big-active": showPopup,
      })}
    >
      <Icon
        name="trash"
        color={showPopup ? "generalWhite" : "generalDark"}
        size="de"
      />
    </button>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Checklist Item"
      content="Are you sure you want to delete this checklist item? Actions are not reversible."
      handleConfirm={handleDeleteRow}
      handleReject={() => setShowPopup(false)}
    />
  );

  return (
    <div className="list-points">
      <Popup
        position="bottom right"
        trigger={renderTrigger}
        open={showPopup}
        onOpen={() => setShowPopup(true)}
        onClose={() => setShowPopup(false)}
        keepTooltipInside
      >
        <div className="table-dropdown success-dropdown">
          {renderDeleteConfirmation}
        </div>
      </Popup>
    </div>
  );
}

export default observer(TodoItemsContextMenu);
