import classNames from "classnames";
import React from "react";

type TableCellProps = {
  children: React.ReactNode;
  width: number | string;
  minWidth?: number | string;
  disabled?: boolean;
  hasErrorClass?: string;
  locked?: boolean;
  pinned?: boolean;
  readOnly?: boolean;
  hasDetailView?: boolean;
  hasErrors?: boolean;
};

function TableCell({
  children,
  width,
  minWidth,
  hasErrorClass = "has-errors",
  hasErrors,
  readOnly,
  locked,
  pinned,
}: TableCellProps) {
  const liClassNames = classNames("cell input-cell", {
    [hasErrorClass]: hasErrors,
    "read-only": readOnly,
    "locked-cell": locked,
    "pointer-events-none": locked || readOnly,
    pinned,
  });

  return (
    <li className={liClassNames} style={{ width, minWidth }}>
      <div className="cell-content status-color">{children}</div>
    </li>
  );
}

export default TableCell;
