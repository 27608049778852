import { Button } from "@themis/ui";
import React from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import { MY_TASKS_ROUTES } from "@/features/tasks/pages/index";

import moduleIcons from "../../../images/modules.webp";

export function TasksEmptyState() {
  const {
    taskDetail,
    users: { user },
  } = useMainStore();
  const { pathname } = useLocation();

  const isAssignedToMeView = pathname === MY_TASKS_ROUTES.assignedToMeView;
  return (
    <div className="tw--my-[25px] tw-flex tw-flex-col tw-items-center tw-gap-3 tw-text-center tw-font-semibold tw-text-neutral-500">
      <img src={moduleIcons} />
      <p>Tasks are core items that users complete to achieve success</p>
      <Button
        size="sm"
        onClick={() =>
          taskDetail.open(
            isAssignedToMeView
              ? {
                  assignee_id: user.id,
                }
              : undefined,
          )
        }
      >
        Add New Task
      </Button>
    </div>
  );
}
