import { useQuery } from "@tanstack/react-query";

import { getDashboardComponent } from "../gen/axios/dashboardsController";

export const DASHBOARD_COMPONENTS_QUERY_KEY = {
  all: (days: number, systemName: string) => [
    "dashboard_components",
    days,
    systemName,
  ],
} as const;

export function useDashboardComponent(
  systemName: string,
  workspaceId: number,
  days: number,
) {
  return useQuery({
    queryKey: DASHBOARD_COMPONENTS_QUERY_KEY.all(days, systemName),
    queryFn: () =>
      getDashboardComponent(systemName, workspaceId, {
        params: { days },
      }),
    enabled: !!workspaceId,
  });
}
