import { Button } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { useHistory } from "react-router-dom";

import { RecordVersion } from "@/api";
import AddNewSectionButton from "@/components/shared/AddNewSectionButton";
import ExportWorkspacePopup from "@/components/shared/export-workspace-popup/ExportWorkspacePopup";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import { useFilters } from "@/hooks/useFilters";
import { useTableData } from "@/hooks/useTableData";

import { useLoading } from "../../../hooks/useLoading";
import ImportBulk from "../../dashboard/ImportBulk";
import Loading from "../../Loading";
import CreateCTACW from "../shared/CreateCTACW";
import CreateFirstCTA from "../shared/CreateFirstCTA";
import NewSectionTag from "../shared/NewSectionTag";
import SectionDragContext from "../shared/SectionDragContext";
import ShareLink from "../shared/ShareLink";
import Table from "../Table";
import ComplaintsSection from "./ComplaintsSection";

type Props = {
  defaultScreen?: string;
};

const SCREENS = {
  Active: {
    label: "Active",
    url: "/modules/complaints",
  },
  Completed: {
    label: "Completed",
    url: "/modules/complaints/completed",
  },
};

function DefaultComplaints({ defaultScreen }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [currentScreen, setCurrentScreen] = useState(defaultScreen);
  const [showAddNewSection, setShowAddNewSection] = useState(false);

  // Variables
  const { visibleFields } = mainStore.fields;
  const { data } = mainStore.complaints;
  const recordVersions = mainStore.recordVersions.list;
  const sectionTags = mainStore.sectionTags.orderedList;
  const { tableName, isSupport, isIW, workspaceID } = mainStore.context;
  const { canManageSections } = mainStore.userPermissions;

  // @ts-expect-error TS(2339) FIXME: Property 'module_workspace_id' does not exist on t... Remove this comment to see the full error message
  const moduleWorkspaceID = data?.module_workspace_id;

  // Hooks
  const history = useHistory();
  const loading = useLoading(visibleFields);

  useTableData();

  const { filtersTrigger, filtersViewEnabled, filtersContent } = useFilters({
    fields: visibleFields,
  });

  useEffect(() => {
    if (
      mainStore.sectionTags.current &&
      Object.keys(mainStore.sectionTags.current).length !== 0
    ) {
      setShowAddNewSection(false);
    }
  }, [mainStore.sectionTags.current]);

  useEffect(() => {
    mainStore.complaints.setDefaultPages(sectionTags);
  }, [sectionTags]);

  useEffect(() => {
    mainStore.complaints.setLastTab(currentScreen);
  }, [currentScreen]);

  const addNewSection = () => {
    setShowAddNewSection((prevState) => !prevState);
  };

  const onSectionChange = (
    recordVersionId: RecordVersion,
    sectionId: number,
  ) => {
    mainStore.complaints.updateSection(recordVersionId, sectionId);
  };

  const renderTabButton = (screen: string) => (
    <button
      key={screen}
      className={classNames({ active: currentScreen === screen })}
      onClick={() => handleScreenChange(screen)}
      data-testid={`marketing-tab-${screen}-trigger`}
    >
      {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
      {SCREENS[screen].label}
    </button>
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'newScreen' implicitly has an 'any' type... Remove this comment to see the full error message
  function handleScreenChange(newScreen) {
    mainStore.recordVersions.resetList();
    mainStore.complaints.cleanup();
    mainStore.complaints.index({
      workspaceID,
      tab: newScreen,
      tableName: tableName || undefined,
    });
    setCurrentScreen(newScreen);
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    history.push(`/workspaces/${workspaceID}${SCREENS[newScreen].url}`);
  }

  function renderCTA() {
    return workspaceID && isIW ? (
      <CreateFirstCTA subject="Complaint" />
    ) : (
      <CreateCTACW subject="Complaint" />
    );
  }

  async function createNewComplaint() {
    const recordVersion = await mainStore.complaints.create(
      tableName,
      undefined,
    );
    if (
      location.pathname === `/workspaces/${workspaceID}/modules/complaints` &&
      recordVersion
    ) {
      history.push(
        `/workspaces/${workspaceID}/modules/complaints/${recordVersion.id}`,
      );
    }
  }

  if (loading) {
    return <Loading loadingLayout="table" showTableHeader />;
  }

  return (
    <Table extraClassNames="obi-table">
      <>
        <div className={classNames("table-header-wrap")}>
          <div className="switch-table-wrap">
            <div className="buttons-block" data-testid="marketing-main-tabs">
              {Object.keys(SCREENS).map(renderTabButton)}
            </div>
          </div>
          <div className="import-export-buttons-container">
            <ExportWorkspacePopup
              trigger={
                <Button
                  LeftIcon={PiDownloadSimpleBold}
                  size="md"
                  color="tertiary"
                >
                  Export
                </Button>
              }
              moduleIdentifier="complaints"
              successMessage="Complaints are preparing to be exported"
            />
            <ShareLink moduleIdentifier="complaints" />
            <ImportBulk topLevelSection />
            {filtersTrigger}
          </div>
        </div>
        {filtersViewEnabled && (
          <div className="filters-wrap">
            <div className="switch-table-wrap" />
            {filtersContent}
          </div>
        )}
        {currentScreen === SCREENS.Active.label && (
          <AddRecordHeader
            addRecord={createNewComplaint}
            recordName="Complaint"
            canAddSection
            addSection={addNewSection}
          />
        )}
        <div
          className="table-list-wrap complaints-list-wrap"
          data-testid="complaints-table"
        >
          <SectionDragContext onSectionChange={onSectionChange}>
            <ComplaintsSection
              isDropdownOpenProp
              moduleWorkspaceID={moduleWorkspaceID}
              currentScreen={currentScreen}
              recordVersions={recordVersions.filter(
                (recordVersion) => !recordVersion.section_tag_id,
              )}
              pageID={0}
            />

            <div className="complaints-section">
              {mainStore.sectionTags.orderedList.map((sectionTag) => {
                const filteredRecordVersions = recordVersions.filter(
                  (recordVersion) =>
                    recordVersion.section_tag_id === sectionTag.id,
                );

                return (
                  <ComplaintsSection
                    key={sectionTag.id}
                    recordVersions={filteredRecordVersions}
                    sectionTag={sectionTag}
                    isDropdownOpenProp
                    moduleWorkspaceID={moduleWorkspaceID}
                    currentScreen={currentScreen}
                    pageID={sectionTag.id}
                  />
                );
              })}
            </div>

            <div className="complaints-add-margin" />

            {showAddNewSection && (
              <NewSectionTag moduleWorkspaceID={moduleWorkspaceID} />
            )}
            {canManageSections && (
              <div className="tw-sticky tw-left-[25px] tw-ml-[25px] tw-mt-8">
                <AddNewSectionButton onClick={addNewSection} />
              </div>
            )}

            {!isSupport && recordVersions.length < 3 && renderCTA()}
          </SectionDragContext>
        </div>
      </>
    </Table>
  );
}

export default observer(DefaultComplaints);
