import { PreviewTableColumn } from "@themis/ui";
import { PiTextAlignLeftBold } from "react-icons/pi";

import { LinkedRecord } from "@/api";

export const RELATED_ISSUE_COLUMNS: PreviewTableColumn<LinkedRecord>[] = [
  {
    key: "name",
    title: "Name",
    type: "locked",
    width: 200,
    Icon: PiTextAlignLeftBold,
  },
] as const;
