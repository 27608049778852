import {
  Query,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import {
  CreateFindingMutationRequest,
  ListRecordFindingsQueryResponse,
  UpdateFindingMutationRequest,
} from "@/api";
import {
  createFinding,
  deleteFinding,
  listRecordFindings,
  showFinding,
  unlinkFindingRecord,
  updateFinding,
  updateFindingStatusClose,
  updateFindingStatusOpen,
} from "@/api/gen/axios/findingsController";

export const FINDING_QUERY_KEYS = {
  all: ["findings"],
  lists: () => [...FINDING_QUERY_KEYS.all, "list"] as const,
  listRecordFindings: ({
    workspaceId,
    recordId,
  }: {
    workspaceId: number;
    recordId: number;
  }) => [...FINDING_QUERY_KEYS.lists(), { workspaceId, recordId }],
  details: () => [...FINDING_QUERY_KEYS.all, "detail"],
  detail: (findingId: number) => [...FINDING_QUERY_KEYS.details(), findingId],
} as const;

export function useRecordFindings(workspaceId: number, recordId: number) {
  return useQuery({
    queryKey: FINDING_QUERY_KEYS.listRecordFindings({ workspaceId, recordId }),
    queryFn: () => listRecordFindings(recordId),
    enabled: !!recordId && !!workspaceId,
  });
}

export function useFinding({ findingId }: { findingId: number }) {
  return useQuery({
    queryKey: FINDING_QUERY_KEYS.detail(findingId),
    queryFn: () => showFinding(findingId),
    enabled: !!findingId,
  });
}

export function useCreateFinding({
  workspaceId,
  recordId,
  onSuccess = () => {},
  onError = () => {},
}: {
  workspaceId: number;
  recordId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (finding: CreateFindingMutationRequest) =>
      createFinding(finding),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: FINDING_QUERY_KEYS.listRecordFindings({
          workspaceId,
          recordId,
        }),
      });
      onSuccess();
    },
    onError,
  });
}

export function useUpdateFinding({
  findingId,
  onSuccess = () => {},
  onError = () => {},
}: {
  findingId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (finding: UpdateFindingMutationRequest) =>
      updateFinding(findingId, finding),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: FINDING_QUERY_KEYS.detail(findingId),
      });
      onSuccess();
    },
    onError,
  });
}

export function useDeleteFinding({
  workspaceId,
  recordId,
  findingId,
  onSuccess = () => {},
  onError = () => {},
}: {
  workspaceId: number;
  recordId?: number;
  findingId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteFinding(findingId),
    onSuccess: () => {
      onSuccess();

      if (recordId) {
        queryClient.invalidateQueries({
          type: "all",
          queryKey: FINDING_QUERY_KEYS.listRecordFindings({
            workspaceId,
            recordId,
          }),
        });

        return;
      }

      queryClient.invalidateQueries({
        refetchType: "all",
        queryKey: FINDING_QUERY_KEYS.lists(),
        predicate: (query) => {
          return Boolean(
            (
              query as Query<ListRecordFindingsQueryResponse>
            ).state.data?.data.some((finding) => finding.id === findingId),
          );
        },
      });
    },
    onError,
  });
}

export function useCloseFinding({
  workspaceId,
  recordId,
  findingId,
  onSuccess = () => {},
  onError = () => {},
}: {
  workspaceId: number;
  recordId: number;
  findingId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => updateFindingStatusClose(findingId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: FINDING_QUERY_KEYS.listRecordFindings({
          workspaceId,
          recordId,
        }),
      });
      onSuccess();
    },
    onError,
  });
}

export function useOpenFinding({
  workspaceId,
  recordId,
  findingId,
  onSuccess = () => {},
  onError = () => {},
}: {
  workspaceId: number;
  recordId: number;
  findingId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => updateFindingStatusOpen(findingId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: FINDING_QUERY_KEYS.listRecordFindings({
          workspaceId,
          recordId,
        }),
      });
      onSuccess();
    },
    onError,
  });
}

export function useUnlinkFindingRecord({
  workspaceId,
  recordId,
  linkedRecordId,
  findingId,
  onSuccess = () => {},
  onError = () => {},
}: {
  workspaceId: number;
  recordId: number;
  linkedRecordId: number;
  findingId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => unlinkFindingRecord(findingId, linkedRecordId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: FINDING_QUERY_KEYS.listRecordFindings({
          workspaceId,
          recordId,
        }),
      });
      onSuccess();
    },
    onError,
  });
}
