var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import "./custom-theme.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { PiArrowDownBold, PiArrowUpBold, PiDotsSixVerticalBold, } from "react-icons/pi";
import AddColumnButton from "./AddColumnButton";
import AttachmentCell from "./Cells/AttachmentCell";
import ButtonCell from "./Cells/ButtonCell";
import CheckboxCell from "./Cells/CheckboxCell";
import DatePickerCell from "./Cells/DatePickerCell";
import IconButtonCell from "./Cells/IconButtonCell";
import IdentifierCellEditor from "./Cells/IdentifierCellEditor";
import IdentifierCellRenderer from "./Cells/IdentifierCellRenderer";
import ImagePickerCell from "./Cells/ImagePickerCell";
import LinkEditorCell from "./Cells/LinkEditorCell";
import LinkRenderCell from "./Cells/LinkRenderCell";
import SelectCell from "./Cells/SelectCell";
import TextEditorCell from "./Cells/TextEditorCell";
import TextRenderCell from "./Cells/TextRenderCell";
export function Table(_a) {
    var columns = _a.columns, rows = _a.rows, _b = _a.width, width = _b === void 0 ? "800px" : _b, _c = _a.rowHeight, rowHeight = _c === void 0 ? 40 : _c, _d = _a.resizableColumns, resizableColumns = _d === void 0 ? true : _d, _e = _a.singleClickEdit, singleClickEdit = _e === void 0 ? true : _e, columnDef = _a.columnDef, _f = _a.onChange, onChange = _f === void 0 ? function () { } : _f;
    var components = useMemo(function () { return ({
        addColumnButton: AddColumnButton,
        textEditorCell: TextEditorCell,
        textRenderCell: TextRenderCell,
        buttonCell: ButtonCell,
        checkboxCell: CheckboxCell,
        selectCell: SelectCell,
        identifierCellEditor: IdentifierCellEditor,
        identifierCellRenderer: IdentifierCellRenderer,
        imagePickerCell: ImagePickerCell,
        datePickerCell: DatePickerCell,
        iconButtonCell: IconButtonCell,
        attachmentCell: AttachmentCell,
        linkRenderCell: LinkRenderCell,
        linkEditorCell: LinkEditorCell,
    }); }, []);
    var defaultColDef = useMemo(function () {
        return __assign({ resizable: resizableColumns, cellClassRules: {
                "tw-group/cell": function () { return true; },
                "drag-column": function (params) {
                    var _a;
                    return params && ((_a = params.colDef) === null || _a === void 0 ? void 0 : _a.rowDrag);
                },
            }, headerClass: function (params) {
                var colDef = params.colDef;
                return colDef && colDef.rowDrag ? "drag-column" : "header-cell";
            } }, columnDef);
    }, [resizableColumns, columnDef]);
    var autoSizeStrategy = useMemo(function () {
        return {
            type: "fitGridWidth",
        };
    }, []);
    var rowClassRules = {
        "tw-group/row": function () { return true; }, // Apply 'group' class to all rows
    };
    return (React.createElement("div", { id: "myGrid", className: "custom-theme tw-w-[".concat(width || "800px", "]") },
        React.createElement(AgGridReact, { getRowId: function (row) { return row.data.id; }, reactiveCustomComponents: true, autoSizeStrategy: autoSizeStrategy, components: components, columnDefs: columns, rowData: rows, domLayout: "autoHeight", rowHeight: rowHeight || 40, rowDragManaged: true, 
            // @ts-expect-error - TS doesn't know about the column props
            defaultColDef: defaultColDef, rowClassRules: rowClassRules, onCellValueChanged: function (row) {
                onChange(row.data);
            }, gridOptions: {
                icons: {
                    rowDrag: function () { return renderToStaticMarkup(React.createElement(PiDotsSixVerticalBold, null)); },
                    sortAscending: function () { return renderToStaticMarkup(React.createElement(PiArrowUpBold, null)); },
                    sortDescending: function () { return renderToStaticMarkup(React.createElement(PiArrowDownBold, null)); },
                },
                singleClickEdit: singleClickEdit,
            } })));
}
