import { observer } from "mobx-react";
import React from "react";

import { NavTabs } from "@/components/nav-tab";
import { useMainStore } from "@/contexts/Store";
import { patternToRoute } from "@/utils/routing";

import {
  kriCompanyLibraryUrl,
  kriInputUrl,
  kriListUrl,
  kriSummaryUrl,
  kriThemisLibraryUrl,
} from "../urlPaths";

const KeyRiskIndicatorsTabs = () => {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  const tabs = [
    {
      label: "KRIs",
      link: patternToRoute(kriListUrl, { workspace_id: workspaceID }),
    },
    {
      label: "Input",
      link: patternToRoute(kriInputUrl, { workspace_id: workspaceID }),
      tooltipContent:
        "Your hub for editing and viewing input data for all Key Risk Indicators.",
    },
    {
      label: "Summary",
      link: patternToRoute(kriSummaryUrl, { workspace_id: workspaceID }),
      tooltipContent:
        "Visually represents how each KRI fares against your predefined thresholds.",
    },
    {
      label: "Company KRI Library",
      link: patternToRoute(kriCompanyLibraryUrl, { workspace_id: workspaceID }),
      tooltipContent:
        "KRI templates applicable across all workspaces. Create new templates, or customize Themis provided templates to tailor them to your specific business requirements.",
    },
    {
      label: "Themis KRI Library",
      link: patternToRoute(kriThemisLibraryUrl, { workspace_id: workspaceID }),
      tooltipContent:
        "Industry-standard KRIs curated by compliance experts at Themis. Use these as a benchmark to enhance your risk management strategy and copy them to your Company Library for further customization.",
    },
  ];

  return <NavTabs tabs={tabs} />;
};

export default observer(KeyRiskIndicatorsTabs);
