import { Label, PreviewTable, useToast } from "@themis/ui";
import React from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { CreateFindingRequest, UpdateFindingRequest } from "@/api";
import {
  useCreateFinding,
  useFinding,
  useUpdateFinding,
} from "@/api/queries/findings";
import { ErrorContainer } from "@/components/ErrorContainer";
import { InfoPreviewSection } from "@/components/InfoPreviewSection";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import Loading from "@/components/Loading";
import { FindingActiveTag } from "@/features/findings/components/FindingActiveTag";
import {
  FindingsThemisModuleIdentifier,
  getFindingsListPath,
} from "@/features/findings/pages";

import FindingInfoForm, {
  FindingInfoSchema,
} from "../../components/FindingInfo/FindingInfoForm";
import { RelatedIssueActionsCell } from "../../components/RelatedIssuesTable/RelatedIssueActionsCell";
import { RELATED_ISSUE_COLUMNS } from "../../config/relatedIssuesTable";

function FormContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-min-w-96 tw-flex-col tw-gap-8 tw-px-32">
      {children}
    </div>
  );
}

function StatusContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-bg-neutral-50 tw-px-2.5 tw-py-1.5">
      {children}
    </div>
  );
}

function FindingInfoContainer({ children }: { children: React.ReactNode }) {
  return <div className="tw-flex tw-flex-col tw-gap-2">{children}</div>;
}

export function FindingDetails({
  recordId,
  isIssueManagementModuleAdded,
  moduleIdentifier,
}: {
  recordId: number | undefined;
  isIssueManagementModuleAdded: boolean;
  moduleIdentifier: FindingsThemisModuleIdentifier;
}) {
  const toast = useToast();
  const history = useHistory();
  const { workspace_id, record_version_id, findingId } = useParams<{
    workspace_id: string;
    record_version_id: string;
    findingId: string;
  }>();

  const isNewFinding = !Number.isInteger(Number(findingId));

  const {
    data: finding,
    isLoading,
    error,
  } = useFinding({
    findingId: Number(findingId),
  });

  const findingsListPath = generatePath(getFindingsListPath(moduleIdentifier), {
    workspace_id,
    record_version_id,
  });

  const { mutateAsync: createFinding } = useCreateFinding({
    workspaceId: Number(workspace_id),
    recordId: Number(recordId),
  });

  const { mutateAsync: updateFinding } = useUpdateFinding({
    findingId: Number(findingId),
  });

  async function handleCreateFinding(values: FindingInfoSchema) {
    try {
      const requestBody: CreateFindingRequest = {
        ...values,
        record_id: Number(recordId),
      };

      await createFinding(requestBody);

      history.push(findingsListPath);

      toast({
        content: `Finding "${values.name}" has been added!`,
        variant: "success",
      });
    } catch {
      toast({
        content: "Something went wrong. Could not create finding.",
        variant: "error",
      });
    }
  }

  async function handleUpdateFinding(values: FindingInfoSchema) {
    try {
      const requestBody: UpdateFindingRequest = {
        ...values,
      };

      await updateFinding(requestBody);

      history.push(findingsListPath);

      toast({
        content: `Finding "${values.name}" has been updated!`,
        variant: "success",
      });
    } catch {
      toast({
        content: "Something went wrong. Could not update finding.",
        variant: "error",
      });
    }
  }

  function handleSubmitForm(values: FindingInfoSchema) {
    if (!finding) {
      handleCreateFinding(values);
    } else {
      handleUpdateFinding(values);
    }
  }

  if (error || isLoading) {
    return (
      <PageContent>
        {isLoading ? (
          <Loading loadingLayout="small-table" />
        ) : (
          <ErrorContainer backButtonProps={{ linkTo: findingsListPath }}>
            Could not load finding details
          </ErrorContainer>
        )}
      </PageContent>
    );
  }

  return (
    <div className="tw-h-[calc(100%_-_48px)]">
      <PageLayout>
        <PageContent>
          <FormContainer>
            <div className="tw-flex tw-flex-row tw-gap-2">
              {!isNewFinding && (
                <StatusContainer>
                  <Label className="tw-font-semibold tw-text-neutral-500">
                    Record
                  </Label>
                  <p className="tw-font-semibold tw-text-secondary-300">
                    {finding?.data.record_name || "N/A"}
                  </p>
                </StatusContainer>
              )}
              <StatusContainer>
                <Label className="tw-font-semibold tw-text-neutral-500">
                  Status
                </Label>
                <FindingActiveTag status={finding?.data.status} />
              </StatusContainer>
            </div>
            <FindingInfoContainer>
              <h2 className="tw-text-lg tw-font-semibold tw-text-neutral-500">
                Finding Details
              </h2>
              <FindingInfoForm
                defaultValues={finding?.data}
                onSubmit={handleSubmitForm}
              />
            </FindingInfoContainer>
            <InfoPreviewSection
              title="Related Issue Management Records"
              countDescription="Issues"
              count={finding?.data.linked_records.length}
              content={
                <PreviewTable
                  responsive
                  columns={RELATED_ISSUE_COLUMNS}
                  rows={finding?.data.linked_records}
                  rowActions={(linkedRecord) => (
                    <RelatedIssueActionsCell
                      id={linkedRecord.id}
                      recordVersionId={linkedRecord.latest_record_version_id}
                      name={linkedRecord.name}
                      url={linkedRecord.url}
                      isIssueManagementModuleAdded={
                        isIssueManagementModuleAdded
                      }
                    />
                  )}
                />
              }
            />
          </FormContainer>
        </PageContent>
      </PageLayout>
    </div>
  );
}
