import classNames from "classnames";
import { kebabCase } from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { NavLink, useParams, useRouteMatch } from "react-router-dom";

import CommentsSlideMenu from "@/components/table/shared/comments/CommentsSlideMenu";
import { useMainStore } from "@/contexts/Store";
import {
  ModuleIdentifier,
  TopLevelModule,
} from "@/stores/types/module-workspaces-types";

type Props = {
  moduleIdentifier: ModuleIdentifier;
  recordVersionID: number;
  children?: React.ReactNode;
  showActiveDetailLink?: boolean;
  tab?: string;
  settingsExtraClassNames?: string;
};

const activeModuleIdentifiersForDocuments: ModuleIdentifier[] = ["audits"];

function DetailNav({
  moduleIdentifier,
  recordVersionID,
  showActiveDetailLink,
  children,
  tab,
  settingsExtraClassNames,
}: Props) {
  const mainStore = useMainStore();

  const {
    parent_record_version_id,
    moduleIdentifier: moduleIdentifierFromPath,
  } = useParams<{
    moduleIdentifier: string;
    parent_record_version_id: string;
  }>();

  const disabledModules: ModuleIdentifier[] = [
    "qa_tests_development",
    "qa_procedures",
    "qa_findings",
    "risk_assessment",
    "samples",
  ];
  const { workspaceID } = mainStore.context;
  const shouldRenderDetailNav = !disabledModules.includes(moduleIdentifier);
  const isIssueManagement =
    moduleIdentifier === "issue_management" &&
    !(moduleIdentifierFromPath === "action-plans"); // for IM we have to render more tabs (Action Plans / Controls)
  const isRiskRegister = moduleIdentifier === "risk_register"; // for IM we have to render more tabs (Action Plans / Controls)
  const isAudits = moduleIdentifier === "audits";
  const isComplaints = moduleIdentifier === "complaints";
  const isActionPlan =
    moduleIdentifier === "action_plans" ||
    moduleIdentifierFromPath === "action-plans"; // for Action Plans we have another URL, because it is a 'child' module, we need to take into account parent_record_version_id
  const isChangeManagement =
    moduleIdentifier === TopLevelModule.CHANGE_MANAGEMENT; // Change Management URIs 'change-management' can be different than the module identifier 'new-product-approval'
  const moduleIdentifierPath = kebabCase(moduleIdentifier);
  const [auditLink, detailLink] = setLinksUrls();
  const isControlMapping = moduleIdentifier === "control_mapping";

  // @ts-expect-error TS(7006) FIXME: Parameter '_' implicitly has an 'any' type.
  const isDetailLinkActive = (_, location) =>
    showActiveDetailLink || location.pathname === detailLink;

  const hideComments = Boolean(
    useRouteMatch({
      path: [
        "/workspaces/:workspace_id/modules/issue-management/:record_version_id/action-plans/",
        "/workspaces/:workspace_id/modules/issue-management/:record_version_id/audit_trail",
        "/workspaces/:workspace_id/modules/issue-management/:parent_record_version_id/action-plans/:record_version_id/audit_trail",
      ],
      exact: true,
    }),
  );

  const recordVersion =
    mainStore.recordVersions.list.find((r) => r.id === recordVersionID) ||
    mainStore.recordVersions.current;

  // funcs
  function setLinksUrls() {
    if (isActionPlan) {
      return [
        `/workspaces/${workspaceID}/modules/issue-management/${parent_record_version_id}/${moduleIdentifierFromPath}/${recordVersionID}/audit_trail`,
        `/workspaces/${workspaceID}/modules/issue-management/${parent_record_version_id}/${moduleIdentifierFromPath}/${recordVersionID}`,
      ];
    }

    if (isChangeManagement) {
      return [
        `/workspaces/${workspaceID}/modules/change-management/${recordVersionID}/audit_trail`,
        `/workspaces/${workspaceID}/modules/change-management/${recordVersionID}`,
      ];
    }

    if (tab) {
      return [
        `/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${kebabCase(
          tab.toLowerCase(),
        )}/${recordVersionID}/audit_trail`,
        `/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${kebabCase(
          tab.toLowerCase(),
        )}/${recordVersionID}`,
      ];
    }

    return [
      `/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/audit_trail`,
      `/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}`,
    ];
  }

  return (
    shouldRenderDetailNav && (
      <div
        className={classNames("settings-links-wrap", {
          [settingsExtraClassNames || ""]: settingsExtraClassNames,
        })}
      >
        <ul>
          <li className="detail-nav-link">
            <NavLink exact to={detailLink} isActive={isDetailLinkActive}>
              Record View
            </NavLink>
          </li>
          {isControlMapping && (
            <li>
              <NavLink
                to={`/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/module_assessments`}
              >
                Control Assessment
              </NavLink>
            </li>
          )}
          {activeModuleIdentifiersForDocuments.includes(moduleIdentifier) && (
            <li>
              <NavLink
                to={`/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/documents`}
              >
                Documents
              </NavLink>
            </li>
          )}
          {isRiskRegister && (
            <li>
              <NavLink
                to={`/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/version-history`}
              >
                Version History
              </NavLink>
            </li>
          )}
          {isIssueManagement && (
            <li>
              <NavLink
                to={`/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/action-plans`}
              >
                Action Plans
              </NavLink>
            </li>
          )}
          {(isIssueManagement || isRiskRegister) && (
            <li>
              <NavLink
                to={`/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/controls`}
              >
                Module Controls
              </NavLink>
            </li>
          )}
          {isIssueManagement && (
            <li>
              <NavLink
                to={`/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/operational-controls`}
              >
                Operational Controls
              </NavLink>
            </li>
          )}
          {isIssueManagement && (
            <li>
              <NavLink
                to={`/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/related-risks`}
              >
                Related Risks
              </NavLink>
            </li>
          )}
          {(isAudits || isComplaints) && (
            <li>
              <NavLink
                to={`/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/findings`}
              >
                Findings
              </NavLink>
            </li>
          )}
          <li className="detail-nav-link">
            <NavLink to={auditLink}>Activity</NavLink>
          </li>
        </ul>
        {/* possibility for modules to add something else to the same row, like table actions (import/export/send/etc)  */}
        {children}
        {!hideComments && (
          <CommentsSlideMenu
            renderCommentsButton
            recordID={recordVersion?.record_id}
            globalID={recordVersion?.global_id}
            uncompletedCommentsCount={recordVersion?.uncompleted_comments_count}
            taskableType="Record"
          />
        )}
      </div>
    )
  );
}

export default observer(DetailNav);
