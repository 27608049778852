import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListTodosPathParams,
  ListTodosQueryResponse,
} from "../../models/ListTodos";

/**
 * @description List todos
 * @summary List todos
 * @link /companies/:company_id/todos
 */
export async function listTodos(
  companyId: ListTodosPathParams["company_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListTodosQueryResponse>["data"]> {
  const res = await client<ListTodosQueryResponse>({
    method: "get",
    url: `/companies/${companyId}/todos`,
    ...options,
  });
  return res.data;
}
