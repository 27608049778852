import React, { ReactNode } from "react";
import { Control } from "react-hook-form";

import { TodoItemsFormValues, TodosFieldsTypes } from "../types";
import TextField from "./text-field/TextField";

type Props = {
  control: Control<TodoItemsFormValues>;
  fieldData: TodosFieldsTypes;
  isCompleted: boolean;
};

function TodoItemsRowFieldRenderer({ control, fieldData, isCompleted }: Props) {
  const width = fieldData.width || 800;
  const fieldComponents: { [key: string]: ReactNode } = {
    name: (
      <TextField
        width={width}
        name="name"
        control={control}
        locked={isCompleted}
      />
    ),
  };

  const selectedComponent = fieldComponents[fieldData.field_name];

  return (
    selectedComponent || (
      <li className="locked-cell pointer-events-none" style={{ width }}>
        <div className="cell-content">N/A</div>
      </li>
    )
  );
}

export default TodoItemsRowFieldRenderer;
