import { debounce } from "lodash";
import React, { useMemo, useRef } from "react";
import { Popover, PopoverAnchor, PopoverContent } from "../../Popover/Popover";
function TextRenderCell(_a) {
    var value = _a.value, valueFormatted = _a.valueFormatted;
    var textRef = useRef(null);
    var _b = React.useState(false), isHovering = _b[0], setIsHovering = _b[1];
    var isTextOverflowing = useMemo(function () {
        var textElement = textRef.current;
        return textElement
            ? textElement.scrollWidth > textElement.clientWidth
            : false;
    }, [value, textRef.current]);
    var debouncedHandleMouseEnter = debounce(function () {
        setIsHovering(true);
    }, 500);
    var handleOnMouseLeave = function () {
        debouncedHandleMouseEnter.cancel();
        setIsHovering(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: textRef, onMouseEnter: debouncedHandleMouseEnter, onMouseLeave: handleOnMouseLeave, className: "tw-flex tw-h-full tw-w-full tw-items-center tw-overflow-hidden" },
            React.createElement("div", { className: " tw-px-2 tw-font-sans tw-font-medium tw-text-neutral-500 read-only:tw-text-neutral-300" }, valueFormatted || value)),
        React.createElement(Popover, { open: isHovering && isTextOverflowing },
            React.createElement(PopoverAnchor, { virtualRef: textRef }),
            React.createElement(PopoverContent, { align: "center", side: "bottom", onEscapeKeyDown: function () { return setIsHovering(false); } },
                React.createElement("div", { className: "tw-max-w-[250px] tw-p-2 tw-text-sm tw-text-neutral-500" }, value)))));
}
export default TextRenderCell;
