import "./answer-review.scss";

import { Button, IconButton } from "@themis/ui";
import { uniq } from "lodash";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { PiCheckBold, PiPencilSimpleLineBold } from "react-icons/pi";
import Popup from "reactjs-popup";

import { AnswersAPI } from "@/api/legacy/risk-assessment";
import { userColors } from "@/components/constants";
import { Flex, Icon, MiniTag, Typography } from "@/components/Elements";
import { formatDate, stringToDate } from "@/components/helpers/DateFormatters";
import ModalWrapper from "@/components/shared/ModalWrapper";
import CommentsSlideMenu from "@/components/table/shared/comments/CommentsSlideMenu";
import UsersCircle from "@/components/table/shared/UsersCircle";
import { COLOR } from "@/config/theme";
import { useMainStore } from "@/contexts/Store";
import { RiskMethodology } from "@/features/risk-assessment";
import ActionCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ActionCell";
import ContentCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ContentCell";
import GenericInputCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/GenericInputCell";
import GenericTextAreaCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/GenericTextAreaCell";
import ListCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ListCell/ListCell";
import TableCell from "@/features/risk-assessment/components/Table/GenericTable/TableCell";
import TableRow from "@/features/risk-assessment/components/Table/GenericTable/TableRow";
import { RISK_TYPE_OPTIONS } from "@/features/risk-assessment/config";

import placeholderPlus from "../../../../../images/table-image/icon/plus-placeholder.svg";
import placeholderUser from "../../../../../images/table-image/icon/user-placeholder.svg";
import { BaseQuestion, QuestionRead } from "../../../types/questionnaire";
import { AnswerRead } from "../../../types/questionnaire-form";
import { RiskArea } from "../../../types/risk-area";
import LongTextTableCell from "../../Table/GenericTable/LongTextTableCell";
import QuestionnaireBuilderUserAssignment from "../QuestionnaireBuilderUserAssignment/QuestionnaireBuilderUserAssignment";

export type AnswerReviewProps = {
  riskAreas: RiskArea[];
  question: QuestionRead;
  riskMethodology: RiskMethodology;
  groupAssigneeIDs: number[];
  updateAnswer: (answer: AnswerRead, question: QuestionRead) => void;
  rowIndex: number;
  AnswerCell: JSX.Element;
  RiskRatingCell: JSX.Element;
  order: string;
  isEditable: boolean;
  onChangeAssignees: (
    question: BaseQuestion & { id: number },
    user_ids: number[],
  ) => void | Promise<void>;
  onChangeApproval: (
    questionID: number,
    answerID: number,
    isApproved: boolean,
  ) => void | Promise<void>;
  showRedFlag?: boolean;
  isInternalQuestionnaire: boolean;
};

const ReviewTag = ({
  isEmpty,
  isApproved,
}: {
  isEmpty: boolean;
  isApproved: boolean;
}) => {
  if (isEmpty) {
    return <MiniTag theme="cherry" label="No Reviewers" />;
  }

  if (isApproved) {
    return <MiniTag theme="turquoise" label="Review Complete" />;
  }

  return <MiniTag theme="purple" label="Needs Review" />;
};

const AnswerReview = (props: AnswerReviewProps) => {
  const mainStore = useMainStore();
  const { isAdmin } = mainStore.context;

  const review = props.question.answer?.answer_review;
  const [editMode, setEditMode] = useState(false);
  const [override, setOverride] = useState(review?.score?.toString());
  const [rationale, setRationale] = useState(review?.reason);
  const [isAssignmentPopupOpen, setIsAssignmentPopupOpen] = useState(false);

  const riskType = RISK_TYPE_OPTIONS.find(
    (option) => option.id === props.question.risk_type,
  );
  const riskArea = props.riskAreas.find(
    (ra) => ra.id === props.question.risk_area_id,
  );

  const approvals = props.question.answer?.approved_by || [];

  const assignees = uniq(
    props.question.assignments
      ?.map((assignment) => assignment.user_id)
      .concat(props.groupAssigneeIDs)
      .concat(approvals.map((approval) => approval.user_id) || []),
  )
    .map((userID) =>
      mainStore.users.allUsers.find((user) => user.id === userID),
    )
    .filter(Boolean);
  const assignmentCellUserElements = assignees.map((user) => {
    return approvals.some((approval) => approval.user_id === user?.id) ? (
      <div className="users-circle--approved" key={user!.id}>
        <UsersCircle
          user={user}
          showFullName={false}
          additionalStyles={
            approvals.some((approval) => approval.user_id === user?.id)
              ? {
                  backgroundColor: COLOR.extrasLightCyan,
                  color: COLOR.extrasTeal,
                }
              : {
                  backgroundColor: userColors[user!.icon_color_index!],
                }
          }
        />
      </div>
    ) : (
      <UsersCircle
        key={user!.id}
        user={user}
        showFullName={false}
        additionalStyles={{
          backgroundColor: userColors[user!.icon_color_index!],
        }}
      />
    );
  });

  const currentUserID = mainStore.users.user!.id!;
  const isCurrentUserApprover = assignees.some((a) => a?.id === currentUserID);
  const canOverrideAnswer =
    isAdmin ||
    !props.isInternalQuestionnaire ||
    isCurrentUserApprover ||
    assignees.length === 0;
  const canChangeReviewers = isAdmin || !props.isInternalQuestionnaire;
  const isApprovedByCurrentUser = Boolean(
    props.question.answer?.approved_by?.some(
      (approval) => approval.user_id === currentUserID,
    ),
  );
  const isQuestionFullyApproved = assignees.every(
    (assignee) =>
      props.question.answer?.approved_by?.some(
        (approval) => approval.user_id === assignee!.id,
      ),
  );

  const save = async () => {
    try {
      const answer = await AnswersAPI.review(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
        mainStore.context.workspaceID,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        props.question.answer.id,
        {
          // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          score: parseInt(override),
          id: props.question.answer?.answer_review?.id,
          reason: rationale,
        },
      );
      props.updateAnswer(answer, props.question);
      setEditMode(false);
      mainStore.toast.setInfoText("Answer overridden!", true);
    } catch (err) {
      mainStore.toast.setErrorFromResponse(err);
    }
  };

  const cancel = () => {
    setOverride(review?.score?.toString());
    setRationale(review?.reason);
    setEditMode(false);
  };

  function onChangeApproval() {
    props.onChangeApproval(
      props.question.id,
      props.question.answer!.id!,
      !isApprovedByCurrentUser,
    );
  }

  const reviewComponent = canChangeReviewers && (
    <Flex fullWidth columnGap={5}>
      {[...Array(3)].map((_, id) => (
        <img key={id} src={placeholderUser} alt="placeholder-user" />
      ))}
      <img src={placeholderPlus} alt="placeholder-user" />
    </Flex>
  );

  return (
    <TableRow rowIndex={props.rowIndex}>
      <ActionCell>
        <CommentsSlideMenu
          SubHeader={() => (
            <div className="section-commentary-subheader">
              {props.question.text}
            </div>
          )}
          taskableType={"Question"}
          recordID={props.question.id}
          uncompletedCommentsCount={props.question.uncompleted_comments_count}
          title="Summary"
          hideEmptyState
        />
      </ActionCell>
      <ContentCell
        isFirstDataCell
        content={
          <ReviewTag
            isEmpty={assignees.length === 0}
            isApproved={isQuestionFullyApproved}
          />
        }
      />
      <TableCell
        isEditable={canChangeReviewers}
        onClick={() => setIsAssignmentPopupOpen(true)}
      >
        <Popup
          position={["bottom left", "top left"]}
          trigger={
            <div style={{ display: "flex", width: "100%" }}>
              {assignmentCellUserElements.length > 0
                ? assignmentCellUserElements
                : reviewComponent}
            </div>
          }
          on={["hover"]}
          open={isAssignmentPopupOpen}
          onClose={() => setIsAssignmentPopupOpen(false)}
          disabled={!canChangeReviewers}
          keepTooltipInside
        >
          {props.isEditable &&
          (isAssignmentPopupOpen || assignees.length === 0) ? (
            <QuestionnaireBuilderUserAssignment
              selectedIDs={props.question.assignments.map((a) => a.user_id)}
              setSelectedIDs={(ids: number[]) =>
                props.onChangeAssignees(props.question, ids)
              }
            />
          ) : (
            <ModalWrapper className="answer-review__approvals-modal">
              <Typography
                label="Approvals"
                color="brandingDimViolet"
                weight="semiBold"
              />
              <Flex column rowGap={3}>
                {approvals.map((approval) => {
                  const user = mainStore.users.allUsers.find(
                    (u) => u.id === approval.user_id,
                  );

                  if (!user) {
                    return null;
                  }

                  return (
                    <Flex
                      key={user?.id}
                      justifySpaceBetween
                      alignCenter
                      fullWidth
                    >
                      <Flex columnGap={5}>
                        <UsersCircle
                          user={user}
                          showFullName={false}
                          additionalStyles={{
                            backgroundColor: userColors[user.icon_color_index!],
                          }}
                        />
                        <Flex column rowGap={0}>
                          <Typography
                            label={user.full_name}
                            color="generalMidnightDark"
                            size="sm"
                          />
                          <Typography
                            size="sm"
                            label={formatDate(
                              stringToDate(approval.approved_at),
                            )}
                          />
                        </Flex>
                      </Flex>
                      <MiniTag theme="blue" label="Approved" />
                    </Flex>
                  );
                })}
                {assignees
                  .filter(
                    (assignee) =>
                      !approvals.some(
                        (approval) => approval.user_id === assignee?.id,
                      ),
                  )
                  .map((assignee) => {
                    return (
                      <Flex
                        key={assignee?.id}
                        fullWidth
                        alignCenter
                        justifySpaceBetween
                      >
                        <Flex columnGap={1} alignCenter>
                          <UsersCircle
                            user={assignee}
                            showFullName={false}
                            additionalStyles={{
                              backgroundColor:
                                userColors[assignee!.icon_color_index!],
                            }}
                          />
                          <Typography
                            label={assignee?.full_name}
                            color="generalMidnightDark"
                            size="sm"
                          />
                        </Flex>
                        <MiniTag theme="orange" label="Pending" />
                      </Flex>
                    );
                  })}
              </Flex>
            </ModalWrapper>
          )}
        </Popup>
      </TableCell>
      {props.AnswerCell}
      <LongTextTableCell
        endIcon={
          props.showRedFlag && (
            <Icon
              size="lg"
              name="flagSolid"
              color="generalError"
              className="content-cell-red-flag"
              data-tooltip-id="tooltip"
              data-tooltip-place="top"
              data-tooltip-content={
                "Response is red flagged based on questionnaire template."
              }
            />
          )
        }
      >
        {`${props.order}. ${props.question.text}`}
      </LongTextTableCell>
      {props.RiskRatingCell}
      <LongTextTableCell>{props.question.answer?.comment}</LongTextTableCell>
      <GenericInputCell
        value={override}
        onChange={setOverride}
        disabled={!editMode}
        type="number"
        min={props.riskMethodology.lower_range}
        max={props.riskMethodology.higher_range}
      />
      <GenericTextAreaCell
        value={rationale}
        onChange={setRationale}
        disabled={!editMode}
      />
      <LongTextTableCell>{riskArea?.name}</LongTextTableCell>
      <TableCell isLastDataCell>
        <MiniTag label={riskType?.name} theme={riskType?.theme} />
      </TableCell>
      {props.question.answer?.additional_files &&
        props.question.answer?.additional_files.length > 0 && (
          <ListCell>
            {(props.question.answer?.additional_files || []).map((document) => (
              <Typography key={document.id} label={document.file_name} />
            ))}
          </ListCell>
        )}
      {props.isEditable && (
        <ActionCell>
          {editMode ? (
            <>
              <Button color="tertiary" onClick={cancel}>
                Cancel
              </Button>
              <Button onClick={save}>Save</Button>
            </>
          ) : (
            <div className="tw-flex tw-min-w-[60px] tw-flex-row-reverse tw-gap-1">
              <IconButton
                className="editAnswerButton"
                color="tertiary"
                Icon={PiPencilSimpleLineBold}
                disabled={!canOverrideAnswer}
                onClick={() => setEditMode(true)}
                data-tooltip-left={
                  canOverrideAnswer
                    ? "Cannot override the final rating"
                    : "Override the final rating"
                }
                data-testid={`edit-question-${props.question.id}`}
              />
              {isCurrentUserApprover && (
                <IconButton
                  color={isApprovedByCurrentUser ? "tertiary" : "secondary"}
                  Icon={isApprovedByCurrentUser ? IoClose : PiCheckBold}
                  onClick={onChangeApproval}
                  data-tooltip-left="Toggle your review approval"
                  data-testid={`toggle-approval-${props.question.id}`}
                />
              )}
            </div>
          )}
        </ActionCell>
      )}
    </TableRow>
  );
};

export default observer(AnswerReview);
