import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  CreateAccountContractMutationRequest,
  type ListOfAccountContractsQueryParams,
  ListOfWorkspaceContractsQueryParams,
  UpdateContractMutationRequest,
} from "../gen";
import {
  createAccountContract,
  deleteContract,
  listOfAccountContracts,
  listOfWorkspaceContracts,
  showContract,
  updateContract,
} from "../gen/axios/contractsController";

export const CONTRACTS_QUERY_KEY = "contracts";

export function useAccountContracts(
  accountId: number,
  params?: ListOfAccountContractsQueryParams,
) {
  return useQuery({
    queryKey: [CONTRACTS_QUERY_KEY, { accountId, params }],
    queryFn: () => listOfAccountContracts(accountId, params),
    enabled: !!accountId,
  });
}

export function useWorkspaceContracts(
  workspaceId: number,
  params?: ListOfWorkspaceContractsQueryParams,
) {
  return useQuery({
    queryKey: [CONTRACTS_QUERY_KEY, { workspaceId, params }],
    queryFn: () => listOfWorkspaceContracts(workspaceId, params),
    enabled: !!workspaceId,
  });
}

export function useContract(contractId: number) {
  return useQuery({
    queryKey: [CONTRACTS_QUERY_KEY, { contractId }],
    queryFn: () => showContract(contractId),
    enabled: !!contractId,
  });
}

export function useCreateContract({
  accountId,
  onSuccess = () => {},
  onError = () => {},
}: {
  accountId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateAccountContractMutationRequest) =>
      createAccountContract(accountId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CONTRACTS_QUERY_KEY, { accountId }],
      });
      onSuccess();
    },
    onError,
  });
}

export function useUpdateContract({
  contractId,
  onSuccess = () => {},
  onError = () => {},
}: {
  contractId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateContractMutationRequest) =>
      updateContract(contractId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CONTRACTS_QUERY_KEY, { contractId }],
      });
      onSuccess();
    },
    onError,
  });
}

export function useDeleteContract({
  workspaceId,
  accountId,
  onSuccess = () => {},
  onError = () => {},
}: {
  workspaceId?: number;
  accountId?: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (contractId: number) => deleteContract(contractId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CONTRACTS_QUERY_KEY, { accountId, workspaceId }],
      });
      onSuccess();
    },
    onError,
  });
}
