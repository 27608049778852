var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as React from "react";
import { PiCheckBold } from "react-icons/pi";
import { cn } from "../../lib/utils";
var Checkbox = React.forwardRef(function (_a, ref) {
    var className = _a.className, _b = _a.color, color = _b === void 0 ? "black" : _b, _c = _a.rounded, rounded = _c === void 0 ? false : _c, _d = _a.size, size = _d === void 0 ? "lg" : _d, props = __rest(_a, ["className", "color", "rounded", "size"]);
    return (React.createElement(CheckboxPrimitive.Root, __assign({ ref: ref, className: cn("focus-visible:tw-ring-ring tw-peer tw-box-content tw-shrink-0 tw-overflow-hidden tw-rounded-sm tw-border-2 tw-border-solid tw-border-neutral-700 tw-p-0 tw-ring-primaryDim-200 focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-primaryDim-400 disabled:tw-cursor-not-allowed disabled:tw-opacity-50 data-[state=checked]:tw-text-neutral-500", { "tw-border-1 tw-rounded-full": rounded }, { "tw-border-primaryDim-300": color === "primary" }, { "tw-border-secondary-300": color === "secondary" }, { "tw-h-4 tw-w-4": size === "lg" }, { "tw-h-3 tw-w-3": size === "md" }, { "tw-h-2 tw-w-2": size === "sm" }, className) }, props),
        React.createElement("div", { className: cn({
                "tw-h-4 tw-w-4": size === "lg",
                "tw-h-3 tw-w-3": size === "md",
                "tw-h-2 tw-w-2": size === "sm",
            }) },
            React.createElement(CheckboxPrimitive.Indicator, { className: cn("tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-text-current", { "tw-bg-primaryDim-300": color === "primary" }, { "tw-bg-secondary-300": color === "secondary" }) },
                React.createElement(PiCheckBold, { className: cn("tw-font-semibold", {
                        "tw-h-3 tw-w-3": rounded || size === "md",
                        "tw-h-4 tw-w-4": size === "lg",
                        "tw-h-2 tw-w-2": size === "sm",
                        "tw-fill-neutral-25": color === "primary" || color === "secondary",
                    }) })))));
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;
export { Checkbox };
