import { MiniTag, MiniTagProps } from "@themis/ui";
import { camelCase, startCase } from "lodash";
import React from "react";

const colorMap: Record<string, MiniTagProps["color"]> = {
  Pending: "grey",
  Active: "fuchsia",
  Paused: "red",
  Inactive: "red",
  Unreviewed: "grey",
  Keep_Reviewing: "blue",
  False_Positive: "red",
  Remediated: "mint",
  Addressed: "fuchsia",
  Low: "green",
  Medium: "orange",
  High: "red",
  Website: "lavender",
  Social_Media: "lavender",
  Compliance: "lavender",
  Missing_Disclosures: "purple",
  Links_With_Issues: "grey",
  Need_Review: "red",
  Resolved: "green",
} as const;

export function StatusTag({
  status,
  color,
}: {
  status?: string | null;
  color?: MiniTagProps["color"] | null;
}) {
  if (!status) {
    return;
  }

  const manipulated_status = startCase(camelCase(status));

  if (manipulated_status === "") {
    return;
  }

  function getColorValue() {
    if (color) {
      return color;
    }
    return colorMap[manipulated_status.replace(/ /g, "_")];
  }

  const customColor = getColorValue();

  return (
    <MiniTag size="lg" color={customColor}>
      {startCase(camelCase(manipulated_status))}
    </MiniTag>
  );
}
