import { Select, SelectItem } from "@themis/ui";
import React from "react";

export function TimeFrameSelect({
  options,
  selectedValue,
  onSelect,
}: {
  options: SelectItem[];
  selectedValue: string;
  onSelect: (value: string) => void;
}) {
  return (
    <Select
      className="tw-w-[200px]"
      items={options}
      onSelect={onSelect}
      selected={selectedValue}
    />
  );
}
