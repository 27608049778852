import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import Button from "../../Elements/Button";
import Spinner from "../../table/shared/Spinner";

function ChangeManagementSectionSaveButton() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isSaving, setIsSaving] = useState(false);

  // Hooks
  const location = useLocation();
  const history = useHistory();

  // Variables
  const section_id = location.pathname.split("/");
  const sectionID = Number(section_id[section_id.length - 1]);

  const { moduleWorkspaceID, workspaceID } = mainStore.context;
  const sectionTag = mainStore.sectionTags.list?.find(
    (section) => section.id === sectionID,
  );
  // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type '{}'.
  const { title, details, notes, files } =
    mainStore.changeManagements.currentSectionTags;
  const isCreateMode =
    location.pathname ===
    `/workspaces/${workspaceID}/modules/change-management/add-new-approval`;
  const isEditMode = location.pathname.startsWith(
    `/workspaces/${workspaceID}/modules/change-management/edit-approval/`,
  );
  const disabled = !title || !details || isSaving;

  // Functions
  async function handleClick() {
    setIsSaving(true);

    const payload = {
      title,
      details,
      notes,
      files,
    };

    if (isCreateMode) {
      await mainStore.sectionTags.create(moduleWorkspaceID, payload);
      await mainStore.changeManagements.index({
        workspaceID,
      });

      history.goBack();
    }

    if (isEditMode) {
      const savedObject = await mainStore.sectionTags.update(
        moduleWorkspaceID,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        sectionTag.id,
        payload,
      );

      if (savedObject) {
        await mainStore.toast.setInfoText("Change saved!");
        await mainStore.changeManagements.index({
          workspaceID,
        });

        setIsSaving(false);
      } else {
        mainStore.toast.setErrorText(
          "There is an error when change saved. Please try again.",
        );
        setIsSaving(false);
      }
    }
  }

  const nameButton = (
    <div className="content-saving">
      Save Change
      {isSaving && <Spinner />}
    </div>
  );

  return (
    <Button
      label={nameButton}
      className="table-button header-action"
      disabled={disabled}
      onClick={handleClick}
    />
  );
}

export default observer(ChangeManagementSectionSaveButton);
