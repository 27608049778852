import "./risk-assessment-tabs.scss";

import { observer } from "mobx-react";
import React, { PropsWithChildren, ReactNode } from "react";
import { generatePath, NavLink } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { ROUTES } from "../pages";
import ModuleTabs from "./ModuleTabs/ModuleTabs";

type Props = PropsWithChildren<{
  RightActionBar?: ReactNode;
}>;

function RiskAssessmentTabs(props: Props) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  return (
    <div className="risk-assessment-tabs">
      <ModuleTabs>
        <NavLink
          exact
          to={generatePath(ROUTES.DASHBOARD_PATH, {
            workspace_id: Number(workspaceID),
          })}
        >
          Overview
        </NavLink>
        <NavLink
          exact
          to={generatePath(ROUTES.PARTNERS_PATH, {
            workspace_id: Number(workspaceID),
          })}
        >
          Partners
        </NavLink>
        <NavLink
          isActive={(_, location) =>
            location.pathname.includes("modules/risk-assessment/templates/")
          }
          to={generatePath(ROUTES.QUESTIONNAIRE_TEMPLATE_PATH, {
            workspace_id: Number(workspaceID),
          })}
        >
          Templates
        </NavLink>
      </ModuleTabs>
      {props.RightActionBar}
    </div>
  );
}

export default observer(RiskAssessmentTabs);
