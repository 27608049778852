import "./styles.scss";

import classNames from "classnames";
import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

import { Flex, Typography } from "@/components/Elements";

type Tab = {
  label: string;
  link: string;
  tooltipContent?: string;
  tooltipPlace?: "top" | "bottom" | "left" | "right";
};

export type NavTabsProps = {
  tabs: Tab[];
};

export const NavTabs = ({ tabs }: NavTabsProps) => (
  <Flex alignCenter columnGap={4}>
    {tabs.map((tab) => (
      <NavTab key={tab.link} tab={tab} />
    ))}
  </Flex>
);

type NavTabProps = {
  tab: Tab;
};

const NavTab = ({ tab }: NavTabProps) => {
  const active = useRouteMatch({ path: tab.link, exact: true });

  return (
    <Link
      to={tab.link}
      data-tooltip-id="tooltip"
      data-tooltip-place={tab.tooltipPlace || "bottom"}
      data-tooltip-content={tab.tooltipContent}
      className={classNames("nav-tab", { "nav-tab--active": active })}
    >
      <Typography
        label={tab.label}
        size="sm"
        color={active ? "generalWhite" : "generalLightBlue"}
      />
    </Link>
  );
};
