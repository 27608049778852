import { PreviewTable } from "@themis/ui";
import React from "react";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";

import { useContacts } from "@/api/queries/contacts";
import Loading from "@/components/Loading";

import { ACCOUNT_DETAILS_PATH, ACCOUNT_ID_PARAM } from "..";
import { ErrorContainer } from "../../../../components/ErrorContainer";
import { CONTACTS_COLUMNS } from "../../config/contactsTable";

function AccountContacts() {
  const { accountId, workspace_id } = useParams<{
    workspace_id: string;
    [ACCOUNT_ID_PARAM]: string;
  }>();
  const { url } = useRouteMatch();

  const { data, isPending, isError } = useContacts(Number(accountId));

  if (isPending) {
    return <Loading loadingLayout="table" />;
  }

  if (isError) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath(ACCOUNT_DETAILS_PATH, {
            workspace_id,
            accountId,
          }),
        }}
      >
        Could not load account contacts.
      </ErrorContainer>
    );
  }

  return (
    <PreviewTable
      responsive
      addRow={{
        text: "Add Contact",
        linkTo: (location) => ({
          pathname: `${url}/new`,
          state: { from: location.pathname },
        }),
      }}
      columns={CONTACTS_COLUMNS}
      rows={data.contacts}
    />
  );
}

export { AccountContacts };
