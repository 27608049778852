import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";

import { Todo } from "@/api";
import { UsersCircle } from "@/components/users-circle";
import LongTextField from "@/features/vendor-due-diligence-contracts/components/long-text-field/LongTextField";
import { User } from "@/stores/types/user-types";

import { TodosFieldsTypes, TodosFormValues } from "../types";
import TableCell from "./table-cell/TableCell";
import TodoStatusTag from "./TodoStatusTag";

type Props = {
  todo: Todo;
  fieldData: TodosFieldsTypes;
  keyForColor?: string;
  createdBy?: User;
};

function TodosRowFieldRenderer({
  todo,
  fieldData,
  keyForColor,
  createdBy,
}: Props) {
  const width = "20%";
  const minWidth = 240;

  const { control } = useForm<TodosFormValues>({
    defaultValues: {
      name: todo.name,
      description: todo.description,
    },
  });

  const fieldComponents: { [key: string]: ReactNode } = {
    name: (
      <LongTextField
        width={width}
        minWidth={minWidth}
        name="name"
        control={control}
        locked
      />
    ),
    description: (
      <LongTextField
        width={width}
        minWidth={minWidth}
        name="description"
        control={control}
        locked
      />
    ),
    status: (
      <TableCell width={width} minWidth={minWidth} locked>
        <TodoStatusTag
          value={todo.status || "unknown"}
          keyForColor={keyForColor || "blue"}
        />
      </TableCell>
    ),
    created_by: (
      <TableCell width={width} minWidth={minWidth} locked>
        <div className="tw-flex tw-h-[32px] tw-items-center">
          <UsersCircle
            initials={createdBy?.initials}
            iconColorIndex={createdBy?.icon_color_index}
          />
          <p className="tw-pl-2 tw-leading-relaxed tw-text-neutral-300">
            {createdBy?.full_name}
          </p>
        </div>
      </TableCell>
    ),
    related_tasks: (
      <TableCell width={width} minWidth={minWidth} locked>
        {todo.todo_items?.filter((v) => v.completed).length || 0}/
        {todo.todo_items?.length || 0} Tasks
      </TableCell>
    ),
  };

  const selectedComponent = fieldComponents[fieldData.field_name];

  return (
    selectedComponent || (
      <li
        className="locked-cell pointer-events-none"
        style={{ width, minWidth }}
      >
        <div className="cell-content">N/A</div>
      </li>
    )
  );
}

export default TodosRowFieldRenderer;
