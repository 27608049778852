import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateChildAccountMutationRequest,
  CreateChildAccountMutationResponse,
  CreateChildAccountPathParams,
} from "../../models/CreateChildAccount";

/**
 * @description Create child account
 * @summary Create child account
 * @link /accounts/:account_id/child_accounts
 */
export async function createChildAccount(
  accountId: CreateChildAccountPathParams["account_id"],
  data: CreateChildAccountMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateChildAccountMutationResponse>["data"]> {
  const res = await client<
    CreateChildAccountMutationResponse,
    CreateChildAccountMutationRequest
  >({
    method: "post",
    url: `/accounts/${accountId}/child_accounts`,
    data,
    ...options,
  });
  return res.data;
}
