export type FeatureFlag = {
  flipper_id: string;
  features: { [feature: string]: { enabled: boolean } };
};

type FeatureGateKey =
  | "boolean"
  | "actors"
  | "percentage_of_actors"
  | "percentage_of_time"
  | "groups";

type FeatureGate = {
  key: FeatureGateKey;
  name: string;
  value: string[];
};

export type FeatureFlagResponse = {
  features: {
    gates: FeatureGate[];
    key: FEATURE_FLAG_ID;
    state: FEATURE_STATE;
  }[];
};

export type FeatureFlagActors = {
  companyIds: number[];
  workspaceIds: number[];
  userIds: number[];
};

export type FeatureFlagActorMap = {
  [key in FEATURE_FLAG_ID]?: FeatureFlagActors | boolean;
};

export enum FEATURE_STATE {
  ON = "on",
  OFF = "off",
  CONDITIONAL = "conditional",
}

export enum FEATURE_FLAG_ID {
  APP_PROJECTS = "APP-projects",
  GRO_PARTNER_QUESTIONNAIRES = "GRO-risk-assessment-partner-questionnaires",
  GRO_UNLIMITED_VENDORS = "GRO-unlimited-vendors",
  COM_PUBLIC_API = "COM-public-api",
  COM_SHARE_POINT = "COM-share-point",
  COM_CHECKLIST = "COM-checklist",
  COM_WEBSITE_MONITORING = "COM-website-monitoring",
  SELF_QUESTIONNAIRES_EXPORT = "SELF-questionnaires-export",
  GENG_TRAINING_MODULE_REVAMP = "GENG-training-module-revamp",
  SELF_AUTO_UNLOCK = "SELF-auto-unlock",
  GENG_NEW_DASHBOARD_WIDGETS = "GENG-new-dashboard-widgets",
}

export enum ACTOR_TYPE {
  USER = "User",
  WORKSPACE = "Workspace",
  COMPANY = "Company",
}
