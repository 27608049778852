import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  Tabs,
  TabsList,
  TabsTrigger,
} from "@themis/ui";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { PiArchiveBold, PiDotsThreeBold } from "react-icons/pi";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import { useMainStore } from "@/contexts/Store";
import ExportButton from "@/features/misc/ExportButton";

import { MY_TASKS_ROUTES } from ".";
import StatusFilterButton from "../components/StatusFilterButton";
import { TasksTable } from "../components/table/TasksTable";
import {
  allTableColumns,
  assignedToMeTableColumns,
  assignedToOthersTableColumns,
  createdByMeTableColumns,
} from "../config/myTasksTableConfig";
import { TaskStatus } from "../config/status";
import { FilterOperand } from "../hooks/useFilterSort";
import { useMyTasksFilterSort } from "../hooks/useMyTasksFilterSort";

function MyTasksPage() {
  const mainStore = useMainStore();
  const { activeWorkspace } = mainStore.context;
  const { pathname, search } = useLocation();
  const history = useHistory();

  const isAssignedToMeView = pathname === MY_TASKS_ROUTES.assignedToMeView;

  const {
    taskDetail,
    users: { user },
  } = useMainStore();
  const { filters, addFilter, sorting, setSorting, removeFilter } =
    useMyTasksFilterSort();

  useEffect(() => {
    if (activeWorkspace) {
      // Internal WSs show tasks in all WSs, CW show only tasks related to that WS
      if (activeWorkspace.is_internal) {
        removeFilter("workspace_id");
      } else {
        addFilter("workspace_id", {
          operand: FilterOperand.ANY,
          value: [String(activeWorkspace.id)],
        });
      }
    }

    if (!filters?.status) {
      addFilter("status", {
        operand: FilterOperand.ANY,
        value: [
          TaskStatus["Not Started"].value,
          TaskStatus["In Progress"].value,
          TaskStatus.Done.value,
        ],
      });
    }

    if (!sorting) {
      setSorting({
        columnKey: "due_date",
        direction: "asc",
      });
    }

    if (pathname === MY_TASKS_ROUTES.assignedToMeView) {
      addFilter("assignee_id", {
        operand: FilterOperand.ANY,
        value: [String(user.id)],
      });
      removeFilter("created_by_id");
    } else if (pathname === MY_TASKS_ROUTES.createdByMeView) {
      addFilter("created_by_id", {
        operand: FilterOperand.ANY,
        value: [String(user.id)],
      });
      removeFilter("assignee_id");
    } else if (pathname === MY_TASKS_ROUTES.assignedToOthersView) {
      addFilter("created_by_id", {
        operand: FilterOperand.ANY,
        value: [String(user.id)],
      });
      addFilter("assignee_id", {
        operand: FilterOperand.NOT,
        value: [String(user.id)],
      });
    } else if (pathname === MY_TASKS_ROUTES.allView) {
      // remove all filters that are non-user applied ie. applied by navigating between tabs, note we don't use clearAllFilters because it also removes filtering for archived tasks
      removeFilter("created_by_id");
      removeFilter("assignee_id");
    }
  }, [location.pathname, activeWorkspace?.id]);

  return (
    <PageLayout>
      <Header
        title="My Tasks"
        HeaderOptions={
          <>
            <Button
              size="sm"
              onClick={() =>
                taskDetail.open(
                  isAssignedToMeView
                    ? {
                        assignee_id: user.id,
                        collaborator_ids: [user.id!],
                      }
                    : undefined,
                )
              }
            >
              Add New Task
            </Button>
            <ExportButton referenceType={"tasks"} />
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <IconButton Icon={PiDotsThreeBold} color="tertiary" />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={() => history.push(MY_TASKS_ROUTES.archived)}
                  className="tw-flex tw-items-center tw-gap-2"
                >
                  <PiArchiveBold />
                  View Archived Tasks
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        }
      />
      <Tabs
        className="tw-flex tw-items-center tw-bg-neutral-25 tw-px-6"
        onValueChange={(url) => history.push({ pathname: url, search })}
        value={pathname}
      >
        <TabsList>
          <TabsTrigger value={MY_TASKS_ROUTES.assignedToMeView}>
            Assigned to me
          </TabsTrigger>
          <TabsTrigger value={MY_TASKS_ROUTES.createdByMeView}>
            Created by me
          </TabsTrigger>
          <TabsTrigger value={MY_TASKS_ROUTES.assignedToOthersView}>
            Assigned to others
          </TabsTrigger>
          <TabsTrigger value={MY_TASKS_ROUTES.allView}>All</TabsTrigger>
        </TabsList>
        <StatusFilterButton />
      </Tabs>
      <PageContent>
        <Switch>
          <Route path={MY_TASKS_ROUTES.assignedToMeView}>
            <TasksTable tableColumns={assignedToMeTableColumns} />
          </Route>
          <Route path={MY_TASKS_ROUTES.createdByMeView}>
            <TasksTable tableColumns={createdByMeTableColumns} />
          </Route>
          <Route path={MY_TASKS_ROUTES.assignedToOthersView}>
            <TasksTable tableColumns={assignedToOthersTableColumns} />
          </Route>
          <Route path={MY_TASKS_ROUTES.allView}>
            <TasksTable tableColumns={allTableColumns} />
          </Route>
        </Switch>
      </PageContent>
    </PageLayout>
  );
}

export default observer(MyTasksPage);
