import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import UsersSelect from "../../cell-type/users-select/UsersSelect";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
};

function MultipleApproversPopup({ recordVersion }: Props) {
  // States

  // Import MobX stores
  const mainStore = useMainStore();
  const approversIds = mainStore.avroSchemas.valueForField(
    "approvers",
    recordVersion?.data,
  );

  // Variables

  // Rendering
  return (
    <UsersSelect
      tableName="Default"
      columnTitle="Approvers"
      fieldName="approvers"
      recordVersionID={recordVersion?.id}
      selectedIDs={approversIds}
      singleSelection={false}
      width={130}
      hasErrors={false}
      reviews={recordVersion?.reviews}
      isUserEditable={false}
      isCreativeViewApproversList
    />
  );
}

export default observer(MultipleApproversPopup);
