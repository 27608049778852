import { Tabs, TabsContent, TabsList, TabsTrigger } from "@themis/ui";
import { observer } from "mobx-react";
import React from "react";

import IssuesByRisk from "@/components/charts/widgets/WorkspaceOverview/IssuesByRisk";
import IssuesBySource from "@/components/charts/widgets/WorkspaceOverview/IssuesBySource";
import IssuesWithDueDatesApproachingStat from "@/components/charts/widgets/WorkspaceOverview/IssuesWithDueDatesApproachingStat";
import LibraryRecordByStages from "@/components/charts/widgets/WorkspaceOverview/LibraryRecordByStages";
import LibraryRecordsWithDueDatesApproaching from "@/components/charts/widgets/WorkspaceOverview/LibraryRecordsWithDueDatesApproaching";
import WorkDistributionByModules from "@/components/charts/widgets/WorkspaceOverview/WorkDistributionByModules";
import WorkflowsWithDueDatesApproaching from "@/components/charts/widgets/WorkspaceOverview/WorkflowsWithDueDatesApproaching";
import { TimeFrameSelect } from "@/components/TimeFrameSelect";
import { useMainStore } from "@/contexts/Store";
import { useIsFeatureEnabled } from "@/hooks/useIsFeatureEnabled";
import { useSearchParams } from "@/hooks/useSearchParams";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

import { ActionItems } from "../../../components/home/action-items/ActionItems";
import HomeContent from "../../../components/home/content/HomeContent";
import AnnouncementHeader from "../../../components/home/header/AnnouncementHeader";
import HomeHeader from "../../../components/home/header/HomeHeader";

export type HomePageSearchParams = {
  time_frame?: string;
};

const DEFAULT_TIME_FRAME = "30" as const;

const TIME_FRAME_OPTIONS = [
  { value: "1", label: "1 Day" },
  { value: "7", label: "1 Week" },
  { value: "30", label: "1 Month" },
  { value: "90", label: "3 Months" },
  { value: "180", label: "6 Months" },
  { value: "365", label: "1 Year" },
];

function HomePage() {
  const { users, announcements } = useMainStore();
  const [tabName, setTabName] = React.useState("my_work_tab_trigger");
  const isOutlookRetroEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_NEW_DASHBOARD_WIDGETS,
  );

  const [searchParams, setSearchParams] =
    useSearchParams<HomePageSearchParams>();

  const selectedTimeFrame = searchParams.time_frame || DEFAULT_TIME_FRAME;
  const selectedTimeFrameNumber = Number(selectedTimeFrame);

  function handleTimeFrameSelect(value: string) {
    setSearchParams({ ...searchParams, time_frame: value }, true);
  }

  if (isOutlookRetroEnabled === undefined) {
    return null;
  }

  return (
    <div className="dashboard-content">
      <div className="dashboard-content-wrap home-page">
        {isOutlookRetroEnabled ? (
          <div className="tw-flex tw-w-screen tw-flex-col tw-px-6 tw-py-2">
            <div className="tw-text-base tw-font-semibold tw-text-neutral-500">
              Good Morning, {users.user?.full_name}
            </div>
            <Tabs
              value={tabName}
              onValueChange={setTabName}
              className=" tw-border-0"
            >
              <TabsList className="tw-h-[34px] tw-gap-4 tw-rounded-none tw-border-0 tw-border-b-2 tw-border-solid tw-border-primaryDim-100 tw-pb-0">
                <TabsTrigger
                  value="my_work_tab_trigger"
                  className="tw-rounded-none tw-p-0 tw-font-medium tw-text-neutral-300 hover:tw-bg-primaryDim-25  data-[state=active]:tw-border-0 data-[state=active]:tw-border-b-2  data-[state=active]:tw-border-solid data-[state=active]:tw-border-primaryDim-300 data-[state=active]:tw-bg-neutral-25 data-[state=active]:tw-text-primaryDim-300"
                >
                  My Work
                </TabsTrigger>
                <TabsTrigger
                  value="company_outlook_tab_trigger"
                  className="tw-rounded-none tw-p-0 tw-font-medium tw-text-neutral-300 hover:tw-bg-primaryDim-25  data-[state=active]:tw-border-0 data-[state=active]:tw-border-b-2  data-[state=active]:tw-border-solid data-[state=active]:tw-border-primaryDim-300 data-[state=active]:tw-bg-neutral-25 data-[state=active]:tw-text-primaryDim-300"
                >
                  Company Outlook
                </TabsTrigger>
                <TabsTrigger
                  value="company_retrospective_tab_trigger"
                  className="tw-rounded-none tw-p-0 tw-font-medium tw-text-neutral-300 hover:tw-bg-primaryDim-25  data-[state=active]:tw-border-0 data-[state=active]:tw-border-b-2  data-[state=active]:tw-border-solid data-[state=active]:tw-border-primaryDim-300 data-[state=active]:tw-bg-neutral-25 data-[state=active]:tw-text-primaryDim-300"
                >
                  Company Retrospective
                </TabsTrigger>
              </TabsList>
              <TabsContent value="my_work_tab_trigger">
                <>
                  <div
                    className="home-page__main-panel"
                    data-testid="home-header"
                  >
                    <HomeContent />
                  </div>
                  <ActionItems />
                </>
              </TabsContent>
              <TabsContent value="company_outlook_tab_trigger">
                <div className="tw-flex tw-w-full tw-flex-col tw-gap-[14px]">
                  <header className="tw-flex tw-items-center tw-justify-between tw-self-stretch">
                    <h2 className="tw-text-base tw-font-semibold tw-text-neutral-500">
                      Workspace Overview
                    </h2>
                    <TimeFrameSelect
                      options={TIME_FRAME_OPTIONS}
                      selectedValue={selectedTimeFrame}
                      onSelect={handleTimeFrameSelect}
                    />
                  </header>
                  <div className="tw-flex tw-flex-wrap tw-justify-center tw-gap-4 tw-pb-5">
                    <LibraryRecordByStages
                      timeFrame={selectedTimeFrameNumber}
                    />
                    <LibraryRecordsWithDueDatesApproaching
                      timeFrame={selectedTimeFrameNumber}
                    />
                    <WorkflowsWithDueDatesApproaching
                      timeFrame={selectedTimeFrameNumber}
                    />
                    <WorkDistributionByModules
                      timeFrame={selectedTimeFrameNumber}
                    />
                    <IssuesBySource timeFrame={selectedTimeFrameNumber} />
                    <IssuesByRisk timeFrame={selectedTimeFrameNumber} />
                    <IssuesWithDueDatesApproachingStat
                      timeFrame={selectedTimeFrameNumber}
                    />
                  </div>
                </div>
              </TabsContent>
            </Tabs>
          </div>
        ) : (
          <>
            <div className="home-page__main-panel" data-testid="home-header">
              {announcements.announcement.forHeader ? (
                <AnnouncementHeader />
              ) : (
                <HomeHeader />
              )}
              <HomeContent />
            </div>
            <ActionItems />
          </>
        )}
      </div>
    </div>
  );
}

export default observer(HomePage);
