import classNames from "classnames";
import { observer } from "mobx-react";
import Pagination from "rc-pagination";
import React, { useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import { NavLink, useHistory } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import { useFilters } from "@/hooks/useFilters";
import { useTableData } from "@/hooks/useTableData";
import { IndexParams } from "@/stores/types/module-store-types";

import { useLoading } from "../../../hooks/useLoading";
import { useSortingAndPagination } from "../../../hooks/useSortingAndPagination";
import ExportBulk from "../../dashboard/ExportBulk";
import ImportBulk from "../../dashboard/ImportBulk";
import { Icon } from "../../Elements";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import Loading from "../../Loading";
import MenuActions from "../menu-actions/MenuActions";
import { customColumns } from "../shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";
import ShareLink from "../shared/ShareLink";
import TableMenu from "../shared/tableMenu/TableMenu";
import Table from "../Table";

function CustomerSupportComplaints() {
  // Import MobX stores
  const mainStore = useMainStore();
  useTableData();

  // State
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectMode, setSelectMode] = useState(false);

  // Variables
  const { visibleFields } = mainStore.fields;
  const recordVersions = mainStore.recordVersions.list;
  const { isSupport, isCustomerSupport, tableName, workspaceID } =
    mainStore.context;
  const { data, page } = mainStore.customerSupport;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const arrowLeftImage = (
    <Icon name="chevronLeft" color="generalMidnightDark" />
  );
  const arrowRightImage = (
    <Icon name="chevronRight" color="generalMidnightDark" />
  );

  // Hooks
  const history = useHistory();
  const loading = useLoading(visibleFields);

  const { filtersTrigger, filtersViewEnabled, filtersContent } = useFilters({
    fields: visibleFields,
  });

  const { createColumn, renameColumn, deleteColumn, AddColumnButton } =
    customColumns({});

  const {
    sortByFieldName,
    sortByDirection,
    setSortByFieldName,
    setSortByDirection,
    setSortBy,
  } = useSortingAndPagination({ store: mainStore.customerSupport, page });

  const onPageChange = async (newPage: number) => {
    const params: IndexParams = {};
    params.workspaceID = workspaceID;
    params.page = newPage;
    params.tableName = tableName || undefined;

    if (sortByFieldName) {
      params.sortParams = {
        field_name: sortByFieldName,
        direction: sortByDirection || "ASC",
      };
    }

    await mainStore.customerSupport.index(params);
  };

  async function createNewComplaints() {
    if (selectMode) {
      return;
    }

    if (tableName) {
      const recordVersion = await mainStore.customerSupport.create(tableName);
      history.push(
        `/workspaces/${workspaceID}/modules/customer-support/${recordVersion.id}`,
      );
    }
  }

  function resetMenuActions() {
    setShowCheckbox(false);
    setSelectMode(false);
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  }

  const renderFields = visibleFields.map((field) => {
    return (
      <ModuleTableColumn
        key={field.name}
        field={field}
        setSortBy={setSortBy}
        // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        sortDirection={field.name === sortByFieldName ? sortByDirection : ""}
        setSortByDirection={setSortByDirection}
        setSortByFieldName={setSortByFieldName}
        onDelete={deleteColumn}
        onRename={renameColumn}
      />
    );
  });

  const renderRows = recordVersions.map((recordVersion) => {
    const status = mainStore.avroSchemas.firstValueForField(
      "status",
      recordVersion.data,
    );
    const customerSupportStatus = mainStore.avroSchemas.firstValueForField(
      "customer_support_status",
      recordVersion.data,
    );

    const isClosed = status === "closed";
    const isComplete = customerSupportStatus === "complete";
    const isLocked = isClosed || isComplete;

    return (
      <ModuleRecordVersion
        key={recordVersion.id}
        recordVersion={recordVersion}
        fields={visibleFields}
        tableID={recordVersion.table_id}
        tableName={recordVersion.table_name}
        currentTableName={recordVersion.table_name}
        moduleIdentifier="customer_support"
        isArchivedRow={isLocked}
        isComplaintClosed={isClosed}
        inSelectMenuActionMode={selectMode}
      />
    );
  });

  const renderTabs = () => (
    <>
      <NavLink
        exact
        to={`/workspaces/${workspaceID}/modules/customer-support/support`}
      >
        Customer Support
      </NavLink>
      {!isSupport && !isCustomerSupport && (
        <NavLink
          exact
          to={`/workspaces/${workspaceID}/modules/customer-support/compliance`}
          data-testid="compliance-link"
        >
          Compliance
        </NavLink>
      )}
    </>
  );

  return (
    <Table extraClassNames="obi-table">
      {loading && <Loading loadingLayout="table" showTableHeader />}
      {!loading && (
        <>
          <div
            className={classNames("table-header-wrap", {
              "header-with-filters": filtersViewEnabled,
            })}
          >
            <div className="switch-complaints-wrap">{renderTabs()}</div>
            <div className="import-export-buttons-container">
              <ExportBulk />
              <ShareLink moduleIdentifier="customer_support" />
              <ImportBulk />
              {filtersTrigger}
            </div>
          </div>
          {filtersViewEnabled && (
            <div className="filters-wrap">
              <div className="switch-table-wrap" />
              {filtersContent}
            </div>
          )}

          <AddRecordHeader
            recordName={getRecordName(
              "customer_support",
              moduleWorkspaces,
              true,
            )}
            addRecord={createNewComplaints}
          />
          <div
            className="table-list-wrap complaints-list-wrap"
            data-testid="complaints-table"
          >
            <div className="dropdown-table">
              <div className="list-title-table">
                <ul>
                  <div className="list-column-wrap">
                    <div className="title-first-block table-column-reorder">
                      {visibleFields.length > 0 && (
                        <TableMenu
                          setShowCheckbox={setShowCheckbox}
                          resetMenuActions={resetMenuActions}
                          selectMode={selectMode}
                          setSelectMode={setSelectMode}
                        />
                      )}
                    </div>
                    {showCheckbox && selectMode ? (
                      <div>
                        <MenuActions resetMenuActions={resetMenuActions} />
                      </div>
                    ) : (
                      <div className="list-title-table-wrap">
                        {renderFields}
                        <span className="stretch-cell" />
                      </div>
                    )}
                  </div>
                  {!(showCheckbox && selectMode) && (
                    <AddColumnButton onAdd={createColumn} />
                  )}
                </ul>
              </div>

              {renderRows}
              <div
                className="tw-sticky tw-left-[19px] tw-ml-[19px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
                onClick={createNewComplaints}
                data-testid="add-new-record-button"
              >
                <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
                <div className="tw-neutral-300 tw-text-sm">
                  {`Add ${getRecordName(
                    "customer_support",
                    moduleWorkspaces,
                    true,
                  )}...`}
                </div>
              </div>
            </div>
            <Pagination
              onChange={onPageChange}
              current={page}
              // @ts-expect-error TS(2339) FIXME: Property 'count' does not exist on type '{}'.
              total={data.count}
              prevIcon={arrowLeftImage}
              nextIcon={arrowRightImage}
              showPrevNextJumpers
            />
          </div>
        </>
      )}
    </Table>
  );
}

export default observer(CustomerSupportComplaints);
