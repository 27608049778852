import { Button, useToast } from "@themis/ui";
import React from "react";

import { useMonitoringGroupChildResultsUpdate } from "@/api/queries/websiteMonitoring";

export function MarkAllStatus({
  monitoringGroupAssetId,
  monitoringGroupAssetChildId,
}: {
  monitoringGroupAssetId: number;
  monitoringGroupAssetChildId: number;
}) {
  const toast = useToast();

  const { mutateAsync: updateResult } = useMonitoringGroupChildResultsUpdate({
    monitoringGroupAssetChildId: Number(monitoringGroupAssetChildId),
    monitoringGroupAssetId,
    onSuccess: () => {
      toast({ content: "Result saved successfully!", variant: "success" });
    },
    onError: () => {
      toast({
        content: "Something went wrong. Could not update result.",
        variant: "error",
      });
    },
  });

  async function handleSubmitForm() {
    await updateResult();
  }

  return (
    <Button
      className="risk-rating-top-button gray-button"
      color="transparent"
      onClick={() => handleSubmitForm()}
    >
      Mark rest as Remediated
    </Button>
  );
}
