import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { useMainStore } from "@/contexts/Store";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attachmentGroup?: any;
  disabled?: boolean;
};

function CreativeViewCaption({ attachmentGroup, disabled }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // state
  const [caption, setCaption] = useState("");
  const [contentEditableValue, setContentEditableValue] = useState("");

  // effects
  useEffect(() => {
    if (!attachmentGroup?.id) {
      return;
    }

    const value = attachmentGroup.caption || "";

    setCaption(value);
    setContentEditableValue(value);
  }, [attachmentGroup?.id]);

  // funcs
  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleCaptionChange(e) {
    const { innerText } = e.target;
    setCaption(innerText);
  }

  function handleSave() {
    mainStore.attachmentGroups.update({
      attachmentGroupID: attachmentGroup.id,
      params: { caption },
    });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleCaptionKeyPress(event) {
    if (event.keyCode !== 13) {
      return;
    }

    event.target.blur();
  }

  return (
    <section
      className="creative-view-caption"
      data-testid="creative-view-caption"
    >
      <div
        className="custom-textarea"
        onInput={handleCaptionChange}
        contentEditable={!disabled}
        suppressContentEditableWarning
        data-testid="creative-view-caption-area"
        onBlur={handleSave}
        onKeyDown={handleCaptionKeyPress}
      >
        {contentEditableValue}
      </div>
      {!caption && <span className="placeholder">Caption here...</span>}
    </section>
  );
}

CreativeViewCaption.defaultProps = {
  attachmentGroup: {},
  disabled: false,
};

export default observer(CreativeViewCaption);
