import { PreviewTable, PreviewTableColumn } from "@themis/ui";
import { MonitoringGroup } from "app/javascript/api/gen/models/MonitoringGroup";
import classNames from "classnames";
import { DataWithIdAndCustomFields } from "packages/ui/src/components/PreviewTable/types";
import React, { useState } from "react";

import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import SectionTag from "@/components/table/shared/SectionTag";
import { Section } from "@/stores/types/section-tags";

import arrowUp from "../../../images/table-image/icon/arrow-up.svg";
import { StatusTag } from "../components/StatusTag";
import { ViewButton } from "./ViewButton";

type Props<R extends DataWithIdAndCustomFields> = {
  isDropdownOpen?: boolean;
  sectionTag?: Section;
  columns: PreviewTableColumn<R>[];
  rows?: R[];
  monitoringGroup?: MonitoringGroup;
};

function MonitoringGroupSection<R extends DataWithIdAndCustomFields>({
  sectionTag,
  columns,
  rows = [],
  monitoringGroup,
  ...props
}: Props<R>) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(props.isDropdownOpen);
  const sectionTableID = `section-table-${sectionTag?.id || 0}`;

  const dropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      {monitoringGroup && (
        <div className="dropdown-title-wrap" data-testid="dropdown-section">
          <div
            className={classNames("dropdown-title", {
              "dropdown-open": isDropdownOpen,
            })}
          >
            <div className="dropdown-title-block">
              <div className="dropdown-title-click">
                {monitoringGroup?.group_name && (
                  <div className="tw-flex tw-justify-center tw-rounded-md">
                    <div className="dropdown-arrow" onClick={dropdownClick}>
                      <img src={arrowUp} alt="dropdown-arrow" />
                    </div>
                    <SectionTag
                      isEditMode={isEditMode}
                      setIsEditMode={setIsEditMode}
                      sectionTagID={monitoringGroup?.id}
                      sectionTagTitle={monitoringGroup?.group_name}
                    />
                    <div className="dropdown-public-block">
                      <StatusTag status={monitoringGroup?.group_status || ""} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {isDropdownOpen && (
        <div
          className="dropdown-table tw-z-30 tw-w-full"
          id={sectionTableID}
          data-testid="dropdown-table"
        >
          <PageLayout>
            <PageContent>
              <PreviewTable
                rows={rows}
                columns={columns}
                responsive={false}
                customCellClassName="tw-box-border tw-border-y tw-bg-neutral-25 tw-text-neutral-300 tw-border-r-0 tw-border-solid tw-border-neutral-100 tw-px-2.5 tw-py-1"
                rowActions={(asset) => (
                  <ViewButton monitoringGroupAssetId={asset.id} />
                )}
              />
            </PageContent>
          </PageLayout>
        </div>
      )}
    </>
  );
}

export default MonitoringGroupSection;
