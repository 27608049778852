import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DeleteTodoItemMutationResponse,
  DeleteTodoItemPathParams,
} from "../../models/DeleteTodoItem";

/**
 * @description Delete todo item
 * @summary Delete todo item
 * @link /todo_items/:id
 */
export async function deleteTodoItem(
  id: DeleteTodoItemPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DeleteTodoItemMutationResponse>["data"]> {
  const res = await client<DeleteTodoItemMutationResponse>({
    method: "delete",
    url: `/todo_items/${id}`,
    ...options,
  });
  return res.data;
}
