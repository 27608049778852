import {
  IconButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@themis/ui";
import React from "react";
import { PiLinkSimpleBold } from "react-icons/pi";

import NameCell from "./NameCell";

export function DisplayUrlLinkCell({
  title,
  url,
  id,
  type,
}: {
  title: string;
  url: string;
  id: number;
  type: string;
}) {
  return (
    <>
      {url && (
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <a target="_blank" rel="noopener noreferrer" href={url}>
              <IconButton
                Icon={PiLinkSimpleBold}
                size="lg"
                color="transparent"
                variant="vertical"
              />
            </a>
          </TooltipTrigger>
          <TooltipContent
            align="start"
            side="bottom"
            className="tw-absolute tw-max-h-48 tw-overflow-y-auto tw-font-semibold"
          >
            {url}
          </TooltipContent>
        </Tooltip>
      )}
      <p className="tw-w-full tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-pl-2 tw-text-sm tw-font-semibold">
        <NameCell id={id} title={title} type={type} />
      </p>
    </>
  );
}
