import React from "react";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { useParams } from "react-router-dom";

import { useDashboardComponent } from "@/api/queries/dashboardComponents";

export default function IssuesWithDueDatesApproachingStat({
  timeFrame,
}: {
  timeFrame: number;
}) {
  const { workspace: workspaceId } = useParams<{ workspace: string }>();
  const { data: widgetData } = useDashboardComponent(
    "issues_with_due_dates_approaching",
    Number(workspaceId),
    timeFrame,
  );

  return (
    <div className="tw-flex tw-h-[260px] tw-max-h-[260px] tw-w-full tw-max-w-[496px] tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Issues with Due Dates Approaching
        </div>
        <PiDownloadSimpleBold className="tw-h-4 tw-w-4 tw-cursor-pointer tw-fill-neutral-500" />
      </div>
      <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-2">
        <div className="tw-text-6xl tw-font-semibold tw-text-neutral-500">
          {widgetData?.data.data?.value}
        </div>
        <div className="tw-text-sm tw-font-medium tw-text-neutral-300">
          Issues Due Date
        </div>
      </div>
    </div>
  );
}
