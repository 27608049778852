import { observer } from "mobx-react";
import React, { useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

// @ts-expect-error TS(2307) FIXME: Cannot find module '../../../assets/docs/import/co... Remove this comment to see the full error message
import contactSampleFile from "../../../assets/docs/import/contact_csv_upload_sample.csv";
// @ts-expect-error TS(2307) FIXME: Cannot find module '../../../assets/docs/import/us... Remove this comment to see the full error message
import csvSampleFile from "../../../assets/docs/import/user_csv_upload_sample.csv";
import contactImportExampleImage from "../../images/contact-import-example.svg";
import downloadIcon from "../../images/table-image/icon/download-white.svg";
import uploadIcon from "../../images/table-image/icon/upload-icon.svg";
import userImportExampleImage from "../../images/user-import-example.svg";

type Props = {
  isUsers: boolean;
};

function ImportUsers({ isUsers }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  const { active_workspace: workspace } = mainStore.users.user;
  const [showPopup, setShowPopup] = useState(false);

  function onPopupOpen() {
    setShowPopup(true);
  }

  function onPopupClose() {
    setShowPopup(false);
  }

  function downloadTemplateCSV() {
    csvSampleFile.name;
    const link = document.createElement("a");
    link.href = isUsers ? csvSampleFile : contactSampleFile;
    link.download = `${isUsers ? "user" : "contact"}_import_sample.csv`;
    link.click();
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  async function processCSV(event) {
    const [file] = event.target.files;
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    await mainStore.users.importCsvFile(file, workspace.id, isUsers);
    onPopupClose();
  }

  const downloadTemplateButton = (
    <div>
      <button onClick={downloadTemplateCSV}>
        <img src={downloadIcon} alt="download-icon" />
        Download CSV Template
      </button>
    </div>
  );

  const directUploadProvider = (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <DirectUploadProvider
      render={() => (
        <div className="import-csv-file-select">
          <button>
            <img src={uploadIcon} alt="upload-icon" />
            Import CSV/XLSX File
          </button>
          <input
            type="file"
            className="cursor-pointer"
            onChange={(event) => processCSV(event)}
          />
        </div>
      )}
    />
  );

  return (
    <Popup
      position="bottom right"
      trigger={
        isUsers ? (
          <button
            name="import-users-button"
            className="import-button import-users-button"
          >
            Import Users
          </button>
        ) : (
          <button
            name="import-contacts-button"
            className="import-button import-contacts-button"
          >
            Import Contacts
          </button>
        )
      }
      open={showPopup}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      keepTooltipInside
      nested
    >
      <div className="table-dropdown import-dropdown">
        <div className="import-csv-section">
          <div className="import-csv-section-title">
            <div className="users-circle">1</div>
            <h3>Download CSV File</h3>
          </div>
          {downloadTemplateButton}
        </div>
        <div className="import-csv-section">
          <div className="import-csv-section-title">
            <div className="users-circle">2</div>
            <h3>Add / Edit info in CSV template</h3>
          </div>
          <p>
            Required fields are full name{isUsers ? ", email address" : ""} and
            department.
          </p>
          <div className="import-csv-blurb">
            For CSV/XLSX files, add column headings (ex: &quot;Full Name&quot;)
            as seen in the CSV template above & the provided example below.
          </div>
          <img
            src={isUsers ? userImportExampleImage : contactImportExampleImage}
            className="user-csv-table-example-image"
            alt="csv-example-image"
          />
        </div>
        <div className="import-csv-section">
          <div className="import-csv-section-title">
            <div className="users-circle">3</div>
            <h3>Upload CSV/XLSX File</h3>
          </div>
          {directUploadProvider}
        </div>
      </div>
    </Popup>
  );
}

export default observer(ImportUsers);
