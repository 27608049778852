import { Tabs, TabsList, TabsTrigger } from "@themis/ui";
import React from "react";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import {
  ACCOUNTS_LIST_PATH,
  ALL_CONTRACTS_PATH,
} from "@/features/accounts/pages/index";

interface AccountTabsProps {
  RightComponent?: React.ReactNode;
}

function AccountsHeader({ RightComponent }: AccountTabsProps) {
  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();
  const { pathname } = useLocation();
  const history = useHistory();

  function handleTabChange(value: string) {
    history.push(value);
  }

  return (
    <Tabs
      className={"tw-flex tw-items-center tw-bg-neutral-25 tw-px-6"}
      onValueChange={handleTabChange}
      value={pathname}
    >
      <TabsList>
        <TabsTrigger
          value={generatePath(ACCOUNTS_LIST_PATH, {
            workspace_id,
          })}
        >
          Accounts
        </TabsTrigger>
        <TabsTrigger
          value={generatePath(ALL_CONTRACTS_PATH, {
            workspace_id,
          })}
        >
          Contracts
        </TabsTrigger>
      </TabsList>
      {RightComponent}
    </Tabs>
  );
}

export default AccountsHeader;
