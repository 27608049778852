import { PieChart } from "@themis/ui";
import React, { useMemo } from "react";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { useParams } from "react-router-dom";

import { useDashboardComponent } from "@/api/queries/dashboardComponents";

const PIE_COLORS = [
  "#8F92F9",
  "#95D5F0",
  "#F042F0",
  "#9C001A",
  "#FDB932",
  "#474DEB",
  "#FFBEC9",
  "#AA75F0",
];
export default function LibraryRecordsWithDueDatesApproaching({
  timeFrame,
}: {
  timeFrame: number;
}) {
  const { workspace: workspaceId } = useParams<{ workspace: string }>();
  const { data: widgetData } = useDashboardComponent(
    "library_records_with_due_dates_approaching",
    Number(workspaceId),
    timeFrame,
  );

  const mutated = useMemo(() => {
    const keys = Object.keys(widgetData?.data?.data || {});
    if (keys.length === 0) {
      return [];
    }
    return keys.map((key, index) => ({
      name:
        key === "policy"
          ? "Policies"
          : key
              .replace(/_/gi, " ")
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase(),
              ),
      value: widgetData?.data?.data![key],
      color: PIE_COLORS[index],
    }));
  }, [widgetData]);

  const valueSum = mutated.reduce((acc, curr) => acc + curr.value, 0);

  return (
    <div className="tw-flex tw-h-[260px] tw-max-h-[260px] tw-w-full tw-max-w-[496px] tw-flex-col tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Library Records with Due Dates Approaching
        </div>
        <PiDownloadSimpleBold className="tw-h-4 tw-w-4 tw-cursor-pointer tw-fill-neutral-500" />
      </div>
      <div>
        <div className="tw-flex tw-h-[228px] tw-w-full tw-justify-evenly">
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
            <div className="tw-text-6xl tw-font-semibold tw-text-neutral-500">
              {valueSum}
            </div>
            <div className="tw-text-sm tw-font-medium tw-text-neutral-300">
              Record Due Dates
            </div>
          </div>
          <div className="tw-flex tw-flex-col">
            <PieChart data={mutated} />
            <div className="tw-text-sm tw-font-medium tw-text-neutral-300">
              % Library Record
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
