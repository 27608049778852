import "./todo-items.scss";

import React, { useEffect } from "react";
import { SubmitErrorHandler, useForm } from "react-hook-form";

import { CreateTodoItemRequest, todosService } from "@/api";
import { useMainStore } from "@/contexts/Store";

import TodoItemsDetailFieldRenderer from "../components/TodoItemsDetailFieldRenderer";
import { initialTodoItemsValues, todoItemFields } from "../constants";
import { TodoItemsFormValues } from "../types";

type Props = {
  refetch: VoidFunction;
  todoID: number;
};

function TodoItemsCreatedForm({ todoID, refetch }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const { control, handleSubmit, reset, watch, clearErrors, setError } =
    useForm<TodoItemsFormValues>({
      defaultValues: initialTodoItemsValues,
    });

  // Variables
  const nameField = watch("name");

  useEffect(() => {
    if (nameField) {
      clearErrors("name");
    }
  }, [nameField]);

  const onSubmit = async (data: TodoItemsFormValues) => {
    if (!nameField.trim()) {
      setError("name", {});
      mainStore.toast.setErrorText("The name field cannot be empty!");

      return;
    }

    const serializeData = {
      todo_item: {
        ...data,
        name: data.name!,
      },
    };

    try {
      await handleCreate(serializeData);
      refetch();
      reset();

      mainStore.toast.setInfoText("Checklist Item was successfully created");
    } catch (error) {
      mainStore.toast.setErrorText(
        "An error occurred. Could not create update",
      );
      window.console.log(error);
    }
  };

  // Functions
  const onInvalid: SubmitErrorHandler<TodoItemsFormValues> = () => {
    mainStore.toast.setErrorText(
      "There is something not right! Please check if all necessary fields have been filled properly!",
    );
  };

  function handleCreate(data: CreateTodoItemRequest) {
    return todosService.createTodoItem(todoID, data);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
      <div>
        <div className="section-row two-cols todo-item-detail-view">
          {todoItemFields.map((field) => {
            return (
              <TodoItemsDetailFieldRenderer
                control={control}
                fieldData={field}
                locked={false}
                key={field.field_name}
              />
            );
          })}
        </div>
      </div>
    </form>
  );
}

export default TodoItemsCreatedForm;
