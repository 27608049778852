import { StackedBarChart } from "@themis/ui";
import React, { useMemo } from "react";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { useParams } from "react-router-dom";

import { useDashboardComponent } from "@/api/queries/dashboardComponents";

export default function IssuesByRisk({ timeFrame }: { timeFrame: number }) {
  const { workspace: workspaceId } = useParams<{ workspace: string }>();
  const { data: widgetData } = useDashboardComponent(
    "issues_due_date_by_risk_levels",
    Number(workspaceId),
    timeFrame,
  );

  const mutated = useMemo(() => {
    if (!widgetData || !widgetData.data.data) {
      return {
        keys: [],
        data: [],
        colors: [],
      };
    }
    const mutatedData: {
      name: string;
      total: number;
      [key: string]: string | number;
    }[] = [];
    const ranges = Object.keys(widgetData.data.data);
    const keys: string[] = [];
    ranges.forEach((range) => {
      let total = 0;
      const sources = Object.keys(widgetData.data.data![range]);
      sources.forEach((source) => {
        if (keys.indexOf(source) === -1) {
          keys.push(source);
        }
        total += widgetData.data.data![range][source];
      });
      mutatedData.push({
        name: range,
        total,
        ...widgetData.data.data![range],
      });
    });

    return {
      keys,
      data: mutatedData,
      colors: [
        { key: "low", color: "#66AA22" },
        { key: "medium", color: "#FF9900" },
        { key: "high", color: "#EB2E4E" },
      ],
    };
  }, [widgetData]);

  return (
    <div className="tw-flex tw-h-[260px] tw-max-h-[260px] tw-w-full tw-max-w-[496px] tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Issues by Risk
        </div>
        <PiDownloadSimpleBold className="tw-h-4 tw-w-4 tw-cursor-pointer tw-fill-neutral-500" />
      </div>
      <div className="tw-flex tw-justify-center tw-gap-3">
        <div className="tw-flex tw-items-center tw-gap-[6px]">
          <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-green-3" />
          <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
            Low
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-gap-[6px]">
          <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-yellow-4" />
          <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
            Medium
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-gap-[6px]">
          <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-warning-300" />
          <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
            High
          </div>
        </div>
      </div>
      <div>
        <div className="tw-h-[212px] tw-w-full">
          <StackedBarChart
            wireKey="total"
            data={mutated.data}
            keys={mutated.keys}
            colors={mutated.colors}
          />
        </div>
      </div>
    </div>
  );
}
