import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { LOADING_DELAY } from "@/components/constants";
import ImportFromWS from "@/components/ImportFromWS/ImportFromWS";
import ModuleSummaryCharts from "@/components/reports/summaryReports/ModuleSummaryCharts";
import AddNewSectionButton from "@/components/shared/AddNewSectionButton";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import { useFilters } from "@/hooks/useFilters";
import { useScrollToError } from "@/hooks/useScrollToError";
import { useTableData } from "@/hooks/useTableData";

import lightbulb from "../../../images/table-image/icon/lightbulb-icon.svg";
import ExportBulk from "../../dashboard/ExportBulk";
import ImportBulk from "../../dashboard/ImportBulk";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import { getRepositionPayloadFromIndices } from "../../helpers/reordering";
import Loading from "../../Loading";
import SlideMenu from "../../slideMenu/SlideMenu";
import AttestationsList from "../policy/AttestationsList";
import EmailAttest from "../policy/EmailAttest";
import CWCreateFirstCTA from "../shared/create-first-cta/CWCreateFirstCTA";
import CreateFirstCTA from "../shared/CreateFirstCTA";
import NewSectionTag from "../shared/NewSectionTag";
import SectionDragContext from "../shared/SectionDragContext";
import Table from "../Table";
import RecordsSection from "./RecordsSection";

type Props = {
  archived?: boolean;
  isDragAndDrop?: boolean;
};

function Procedures({ archived, isDragAndDrop }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  useTableData();

  // Variables
  const { data } = mainStore.procedures;
  const moduleWorkspaceID = data?.module_workspace_id;
  const recordVersions = mainStore.recordVersions.list;
  const { tableName, workspaceID, isIW: isInternal } = mainStore.context;
  const { canManageSections } = mainStore.userPermissions;
  const databaseTableName =
    tableName === "ProceduresAttestation" ? "ProceduresFinalized" : tableName;
  const fields = mainStore.fields.list;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const recordNamePlural = getRecordName("procedures", moduleWorkspaces, false);
  const recordNamePluralLowerCase = recordNamePlural.toLowerCase();
  const { hasModuleWriteAccess } = mainStore.userPermissions;

  // State
  const [showAddNewSection, setShowAddNewSection] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isImportSlideoutOpen, setIsImportSlideoutOpen] = useState(false);

  // refs
  const timer = useRef();

  // Hooks
  const location = useLocation();
  useScrollToError({ block: "center" });

  const {
    filtersTrigger,
    filtersViewEnabled,
    filtersContent,
    setFiltersViewEnabled,
  } = useFilters({
    fields,
  });

  useEffect(() => {
    timer.current && clearTimeout(timer.current);

    // @ts-expect-error TS(2322) FIXME: Type 'Timeout' is not assignable to type 'undefine... Remove this comment to see the full error message
    timer.current = setTimeout(() => {
      setLoading(
        !fields ||
          (fields.length === 0 &&
            !location.pathname.endsWith(
              `/workspaces/${workspaceID}/modules/procedures/attestation`,
            )),
      );
    }, LOADING_DELAY);
  }, [fields]);

  // Hide NewSectionTag after successful creation
  useEffect(() => {
    if (
      mainStore.sectionTags.current &&
      Object.keys(mainStore.sectionTags.current).length !== 0
    ) {
      setShowAddNewSection(false);
    }
  }, [mainStore.sectionTags.current]);

  useEffect(() => {
    setButtonDisabled(recordVersions.length === 0);
  }, [recordVersions]);

  const addNewSection = () => {
    setShowAddNewSection(!showAddNewSection);
  };

  const showCTA =
    recordVersions.length < 3 &&
    location.pathname ===
      `/workspaces/${workspaceID}/modules/procedures/drafts`;

  const attestProceduresClick = () => {
    setShowCheckbox(true);
  };

  const attestProceduresClose = () => {
    setShowCheckbox(false);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionId' implicitly has an 'any... Remove this comment to see the full error message
  const onSectionChange = (recordVersionId, sectionId) => {
    mainStore.procedures.updateSection(recordVersionId, sectionId);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'sectionId' implicitly has an 'any' type... Remove this comment to see the full error message
  const getVersionsForStatusAndSection = (sectionId) =>
    recordVersions.filter((recordVersion) => {
      if (recordVersion.section_tag_id !== sectionId) {
        return false;
      }
      const statusValue = mainStore.avroSchemas.valueForField(
        "status",
        recordVersion.data,
      )?.[0];
      const completed = statusValue === "completed";
      return (
        (tableName === "Completed" && completed) || tableName !== "Completed"
      );
    });

  const onRecordMoved = async (
    // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionId' implicitly has an 'any... Remove this comment to see the full error message
    recordVersionId,
    // @ts-expect-error TS(7006) FIXME: Parameter 'sectionId' implicitly has an 'any' type... Remove this comment to see the full error message
    sectionId,
    // @ts-expect-error TS(7006) FIXME: Parameter 'sourceIndex' implicitly has an 'any' ty... Remove this comment to see the full error message
    sourceIndex,
    // @ts-expect-error TS(7006) FIXME: Parameter 'destinationIndex' implicitly has an 'an... Remove this comment to see the full error message
    destinationIndex,
  ) => {
    const relevantRecordVersions = getVersionsForStatusAndSection(sectionId);
    await mainStore.recordVersions.reorder(
      recordVersionId,
      getRepositionPayloadFromIndices(
        relevantRecordVersions,
        sourceIndex,
        destinationIndex,
      ),
    );
  };

  useEffect(() => {
    if (location.pathname) {
      attestProceduresClose();
    }
  }, [location.pathname]);

  const isFinalized = tableName === "ProceduresFinalized";
  const isDrafts = tableName === "ProceduresDrafts";

  return (
    <Table>
      {loading &&
        location.pathname ===
          `/workspaces/${workspaceID}/modules/procedures/drafts` && (
          <Loading loadingLayout="sections" showTableHeader />
        )}
      {loading &&
        location.pathname ===
          `/workspaces/${workspaceID}/modules/procedures/finalized` && (
          <Loading loadingLayout="table" showTableHeader />
        )}
      {!loading && (
        <SectionDragContext
          onSectionChange={onSectionChange}
          onRecordMoved={onRecordMoved}
        >
          <div className={classNames("table-header-wrap")}>
            <div className="switch-table-wrap">
              <ul>
                <li>
                  <NavLink
                    exact
                    to={`/workspaces/${workspaceID}/modules/procedures/drafts`}
                  >
                    Drafts
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to={`/workspaces/${workspaceID}/modules/procedures/finalized`}
                  >
                    Completed
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to={`/workspaces/${workspaceID}/modules/procedures/attestation`}
                  >
                    Attestations
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="import-export-buttons-container">
              <ExportBulk isExportFilesEnabled />
              {isDrafts && (
                <ImportBulk
                  topLevelSection
                  importFromWS
                  openImportSlideOut={() => setIsImportSlideoutOpen(true)}
                />
              )}
              {isFinalized && hasModuleWriteAccess && (
                <div className="table-export-block">
                  <SlideMenu
                    closeSlideMenu={attestProceduresClose}
                    trigger={
                      <button
                        className="table-button"
                        id="triggerId5"
                        disabled={buttonDisabled}
                        style={{ background: "#6D71F9", marginLeft: 8 }}
                        onClick={attestProceduresClick}
                      >
                        Send Email / Attestation
                      </button>
                    }
                  >
                    <EmailAttest
                      tableName={tableName}
                      moduleWorkspaceID={moduleWorkspaceID}
                      checkboxSelection
                    />
                  </SlideMenu>
                </div>
              )}
              {(isDrafts || isFinalized) && filtersTrigger}
            </div>
          </div>
          {isDrafts && (
            <AddRecordHeader
              recordName="Procedure"
              addRecord={() =>
                mainStore.procedures.create(Number(workspaceID), null)
              }
              addSection={addNewSection}
              canAddSection
            />
          )}
          {filtersViewEnabled && (
            <div className="filters-wrap">
              <div className="switch-table-wrap" />
              {filtersContent}
            </div>
          )}
          {isDrafts && moduleWorkspaceID && (
            <ModuleSummaryCharts
              recordVersions={recordVersions}
              moduleWorkspaceID={moduleWorkspaceID}
              filtersViewEnabled={filtersViewEnabled}
              setFiltersViewEnabled={setFiltersViewEnabled}
              moduleName="procedures"
            />
          )}
          <div
            className={classNames("table-list-wrap procedures-list-wrap", {
              finalized: isFinalized,
            })}
            data-testid="procedures-table"
          >
            {((recordVersions.length > 0 && isFinalized) || isDrafts) && (
              <>
                {/* top-level */}
                <RecordsSection
                  isDropdownOpen
                  tableName={tableName}
                  recordVersions={recordVersions.filter(
                    (recordVersion) => !recordVersion.section_tag_id,
                  )}
                  moduleWorkspaceID={moduleWorkspaceID}
                  archived={archived}
                  showCheckbox={showCheckbox}
                  // @ts-expect-error TS(2322) FIXME: Type '{ isDropdownOpen: true; tableName: "Procedur... Remove this comment to see the full error message
                  databaseTableName={databaseTableName}
                  isDragAndDrop={isDragAndDrop}
                />

                {mainStore.sectionTags.orderedList.map((sectionTag) => {
                  const filteredRecordVersions = recordVersions.filter(
                    (recordVersion) =>
                      recordVersion.section_tag_id === sectionTag.id,
                  );
                  return (
                    <RecordsSection
                      key={sectionTag.id}
                      isDropdownOpen
                      tableName={tableName}
                      recordVersions={filteredRecordVersions}
                      moduleWorkspaceID={moduleWorkspaceID}
                      archived={archived}
                      showCheckbox={showCheckbox}
                      // @ts-expect-error TS(2322) FIXME: Type '{ key: number | null; isDropdownOpen: true; ... Remove this comment to see the full error message
                      databaseTableName={databaseTableName}
                      isDragAndDrop={isDragAndDrop}
                      sectionTag={sectionTag}
                    />
                  );
                })}
              </>
            )}

            {showAddNewSection && (
              <NewSectionTag
                moduleWorkspaceID={moduleWorkspaceID}
                onCancel={() => setShowAddNewSection(false)}
              />
            )}

            {fields.length > 0 && isDrafts && canManageSections && (
              <div
                className="tw-sticky tw-left-[25px] tw-ml-[25px]"
                data-testid="add-procedures-section"
              >
                <AddNewSectionButton onClick={addNewSection} />
              </div>
            )}

            {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message */}
            {["ProceduresAttestation"].includes(tableName) && (
              <AttestationsList
                group="Procedures"
                title={recordNamePlural}
                // @ts-expect-error TS(2322) FIXME: Type '{ group: string; title: string; moduleWorksp... Remove this comment to see the full error message
                moduleWorkspaceID={moduleWorkspaceID}
              />
            )}

            {showCTA && !isInternal && (
              <CWCreateFirstCTA
                title={`Add / Share ${recordNamePlural}`}
                sentences={[
                  `Create ${recordNamePluralLowerCase} for the collaborative workspace by clicking "Add New Procedure" on the top to start.`,
                  `Shared ${recordNamePluralLowerCase} are visible here.`,
                ]}
              />
            )}

            {showCTA && isInternal && <CreateFirstCTA subject="Procedure" />}

            {recordVersions.length === 0 && isFinalized && (
              <div className="review-attested-policies">
                <h4>
                  <img src={lightbulb} alt="lightbulb-icon" />
                  Review your published {recordNamePluralLowerCase}
                </h4>
                <p>
                  Published {recordNamePluralLowerCase} are stored here. Publish
                  draft {recordNamePluralLowerCase} are visible here. These{" "}
                  {recordNamePluralLowerCase} can be shared with partners and
                  attested to by employees.
                </p>
              </div>
            )}
          </div>
        </SectionDragContext>
      )}
      {isImportSlideoutOpen && (
        <SlideMenu
          open={isImportSlideoutOpen}
          closeSlideMenu={() => setIsImportSlideoutOpen(false)}
        >
          <ImportFromWS
            closeSlideOut={() => setIsImportSlideoutOpen(false)}
            refetchData={() =>
              mainStore.procedures.index({
                workspaceID,
                tableName: "ProceduresDrafts",
              })
            }
          />
        </SlideMenu>
      )}
    </Table>
  );
}

export default observer(Procedures);
