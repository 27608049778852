import {
  ModuleIdentifier,
  TopLevelModuleIdentifier,
} from "@/stores/types/module-workspaces-types";

const topLevelModuleBasePaths: Record<TopLevelModuleIdentifier, string> = {
  audits: "/modules/audits",
  complaints: "/modules/complaints",
  conflicts_of_interest: "/modules/conflicts-of-interest",
  control_mapping: "/modules/control-mapping",
  customer_support: "/modules/customer-support",
  documents: "/modules/documents",
  finra: "/modules/finra",
  issue_management: "/modules/issue-management",
  marketing: "/modules/marketing",
  new_product_approval: "/modules/change-management",
  policy: "/modules/policy",
  procedures: "/modules/procedures",
  qa_tests_development: "/modules/qa-tests",
  risk_assessment: "/modules/risk-assessment",
  risk_register: "/modules/risk-register",
  training: "/modules/training",
  key_risk_indicators: "/modules/key-risk-indicators/list",
  vendor_due_diligence: "/modules/vendor-due-diligence/active",
  zendesk: "/modules/zendesk",
  website_monitoring: "/modules/website_monitoring",
  accounts: "/modules/accounts",
  checklists: "/todos",
};

const topLevelModuleMainDirectory: {
  [key in TopLevelModuleIdentifier]?: string;
} = {
  customer_support: "/support",
  finra: "/escalated",
  policy: "/drafts",
  procedures: "/drafts",
};

export function getBasePathFromModuleIdentifier(
  themisModuleIdentifier: ModuleIdentifier,
) {
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const modulePath = topLevelModuleBasePaths[themisModuleIdentifier];
  if (modulePath) {
    return modulePath;
  }

  window.console.warn(
    `UNKNOWN THEMIS MODULE in getPathFromModuleIdentifier.ts: ${themisModuleIdentifier}`,
  );
  return null;
}

export function getPathFromModuleIdentifier(
  themisModuleIdentifier: ModuleIdentifier,
) {
  const basePath = getBasePathFromModuleIdentifier(themisModuleIdentifier);
  if (!basePath) {
    return basePath;
  }

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const mainDirectory = topLevelModuleMainDirectory[themisModuleIdentifier];
  return `${basePath}${mainDirectory}`;
}
