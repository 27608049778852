import qs, { ParsedQs } from "qs";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export function useSearchParams<T extends ParsedQs>(): [
  T,
  (newSearchParams: T, replace?: boolean) => void,
] {
  const location = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => {
    return qs.parse(location.search, { ignoreQueryPrefix: true }) as T;
  }, [location.search]);

  const setSearchParams = useCallback(
    (newSearchParams: T, replace = false) => {
      const newStringifiedParams = qs.stringify(newSearchParams);

      if (replace) {
        history.replace({ search: newStringifiedParams });
      } else {
        history.push({ search: newStringifiedParams });
      }
    },
    [location.search],
  );

  return [searchParams, setSearchParams];
}
