import {
  ConfirmationPopup,
  IconButton,
  LinkIconButton,
  useToast,
} from "@themis/ui";
import React, { useState } from "react";
import { PiPencilSimpleLineBold, PiTrashSimpleBold } from "react-icons/pi";
import { generatePath, useParams } from "react-router-dom";

import { useDeleteAccount } from "@/api/queries/accounts";

import { ACCOUNT_DETAILS_PATH } from "../../pages";

export function AccountActionsCell({
  accountId,
  name,
}: {
  accountId: number;
  name: string;
}) {
  const toast = useToast();
  const { workspace_id } = useParams<{ workspace_id: string }>();

  const { mutate: deleteAccount } = useDeleteAccount({
    workspaceId: Number(workspace_id),
    onSuccess: () => {
      toast({
        content: "Account has been removed!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to remove account!",
        variant: "error",
      });
    },
  });

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  function handleDelete() {
    setIsConfirmationOpen(false);
    deleteAccount(accountId);
  }

  function handleClickDelete() {
    setIsConfirmationOpen(true);
  }

  function handleCancelDelete() {
    setIsConfirmationOpen(false);
  }

  return (
    <>
      <LinkIconButton
        aria-label="Edit Account"
        aria-describedby={`account-id-${accountId}`}
        to={(location) => ({
          pathname: generatePath(ACCOUNT_DETAILS_PATH, {
            workspace_id,
            accountId,
          }),
          state: { from: location.pathname },
        })}
        Icon={PiPencilSimpleLineBold}
        size="lg"
        color="tertiary"
      />
      <span className="tw-hidden" id={`account-id-${accountId}`}>
        Account Name {name} with ID {accountId}
      </span>
      <ConfirmationPopup
        anchor
        align="end"
        title="Delete Account"
        content="Actions are non-reversible. Are you sure you want to proceed?"
        open={isConfirmationOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
      >
        <IconButton
          aria-label="Delete Account"
          aria-describedby={`account-id-${accountId}`}
          Icon={PiTrashSimpleBold}
          size="lg"
          color="tertiary"
          onClick={handleClickDelete}
        />
      </ConfirmationPopup>
    </>
  );
}
