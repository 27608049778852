import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ImportThemisRecordsMutationRequest,
  ImportThemisRecordsMutationResponse,
  ImportThemisRecordsPathParams,
} from "../../models/ImportThemisRecords";

/**
 * @description Import ThemisRecords
 * @summary Import ThemisRecords
 * @link /companies/:company_id/:reference_type/import
 */
export async function importThemisRecords(
  companyId: ImportThemisRecordsPathParams["company_id"],
  referenceType: ImportThemisRecordsPathParams["reference_type"],
  data?: ImportThemisRecordsMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ImportThemisRecordsMutationResponse>["data"]> {
  const res = await client<
    ImportThemisRecordsMutationResponse,
    ImportThemisRecordsMutationRequest
  >({
    method: "post",
    url: `/companies/${companyId}/${referenceType}/import`,
    data,
    ...options,
  });
  return res.data;
}
