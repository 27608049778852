import { IconButton } from "@themis/ui";
import React from "react";
import { MdArrowForwardIos } from "react-icons/md";

import { useMonitoringSidebar } from "../providers/MonitoringSidebarProvider";

export default function ViewSlideButton({
  monitoringGroupResultId,
}: {
  monitoringGroupResultId: number;
}) {
  const { openSidebarWithId } = useMonitoringSidebar();

  return (
    <IconButton
      onClick={() => openSidebarWithId(monitoringGroupResultId)}
      Icon={MdArrowForwardIos}
      size="lg"
      color="transparent"
    />
  );
}
