import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CloneObjectMutationRequest,
  CloneObjectMutationResponse,
  CloneObjectPathParams,
} from "../../models/CloneObject";

/**
 * @description Clone an object that supports cloning. Supported objects are: tasks and projects.
 * @summary Clone an object
 * @link /:reference_type/:reference_id/clone
 */
export async function cloneObject(
  referenceType: CloneObjectPathParams["reference_type"],
  referenceId: CloneObjectPathParams["reference_id"],
  data?: CloneObjectMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CloneObjectMutationResponse>["data"]> {
  const res = await client<
    CloneObjectMutationResponse,
    CloneObjectMutationRequest
  >({
    method: "post",
    url: `/${referenceType}/${referenceId}/clone`,
    data,
    ...options,
  });
  return res.data;
}
