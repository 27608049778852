import { useQuery } from "@tanstack/react-query";

import { LinkedRecord, RecordVersion } from "../gen";
import api from "../legacy/api";

export const QA_TESTS_QUERY_KEY = "qa_tests";

export function useQaTests() {
  return useQuery({
    queryKey: [QA_TESTS_QUERY_KEY],
    queryFn: () =>
      api.get<{
        record_versions: (RecordVersion & {
          linked_records: LinkedRecord[];
        })[];
      }>("/qa_tests_developments"),
  });
}
