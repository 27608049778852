import {
  ACTOR_TYPE,
  FEATURE_FLAG_ID,
  FEATURE_STATE,
  FeatureFlagActorMap,
  FeatureFlagActors,
  FeatureFlagResponse,
} from "../types/feature-flag-types";

export function parseFeatureFlagResponse(response: FeatureFlagResponse) {
  const featureFlagMap: FeatureFlagActorMap = {};

  response.features.forEach((feature) => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const actors = feature.gates.find((gate) => gate.key === "actors").value;

    // Return boolean if state is 'on' or 'off'
    if (feature.state !== FEATURE_STATE.CONDITIONAL) {
      featureFlagMap[feature.key] = feature.state === FEATURE_STATE.ON;
      return;
    }

    // Return false if actors is empty list
    if (!actors.length) {
      featureFlagMap[feature.key] = false;
      return;
    }

    featureFlagMap[feature.key] = {
      userIds: [],
      companyIds: [],
      workspaceIds: [],
    };

    actors.forEach((actorValue) => {
      const [actorType, actorId] = actorValue.split(";") as [
        actorType: ACTOR_TYPE,
        actorId: string,
      ];

      if (!actorId) {
        return;
      }

      const featureFlagActors = featureFlagMap[
        feature.key
      ] as FeatureFlagActors;

      switch (actorType) {
        case ACTOR_TYPE.USER:
          featureFlagActors.userIds.push(Number(actorId));
          break;
        case ACTOR_TYPE.COMPANY:
          featureFlagActors.companyIds.push(Number(actorId));
          break;
        case ACTOR_TYPE.WORKSPACE:
          featureFlagActors.workspaceIds.push(Number(actorId));
          break;
        default:
          break;
      }
    });
  });

  Object.keys(featureFlagMap).forEach((featureKey) => {
    const typedFeatureKey = featureKey as FEATURE_FLAG_ID;
    const featureFlagActors = featureFlagMap[typedFeatureKey];
    // Return false if all actor id lists are empty
    if (
      typeof featureFlagMap[typedFeatureKey] !== "boolean" &&
      Object.values(featureFlagActors as FeatureFlagActorMap).every(
        (featureFlagActorsGroup) =>
          Array.isArray(featureFlagActorsGroup) &&
          featureFlagActorsGroup.length,
      )
    ) {
      featureFlagMap[typedFeatureKey] = false;
    }
  });
  return featureFlagMap;
}
