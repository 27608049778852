import classNames from "classnames";
import React from "react";

type Props = {
  width: number | string;
  locked?: boolean;
  pinned?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  questionnaires?: any[];
};

export default function Questionnaires({
  width,
  questionnaires,
  locked,
  pinned,
}: Props) {
  // Variables
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const totalCount = questionnaires.length;
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const completedCount = questionnaires.filter((questionnaire) =>
    // @ts-expect-error TS(7006) FIXME: Parameter 'cq' implicitly has an 'any' type.
    questionnaire.checklist_questions.every((cq) => cq.answer),
  ).length;

  return (
    <li
      style={{ width }}
      data-testid="questionnaires-cell"
      className={classNames("questionnaires-cell", {
        "cell-red": completedCount < totalCount,
        "locked-cell pointer-events-none": locked,
        pinned,
      })}
    >
      <div className="cell-content">
        {totalCount === completedCount && totalCount > 0
          ? "All Completed"
          : `${completedCount}/${totalCount} Completed`}
      </div>
    </li>
  );
}

Questionnaires.defaultProps = {
  width: 200,
  questionnaires: [],
};
