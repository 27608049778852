import { PreviewTableColumn } from "@themis/ui";
import { format, parseISO } from "date-fns";
import React from "react";

import { MonitoringGroupAssetChild } from "@/api";

import { DisplayUrlLinkCell } from "../components/DisplayUrlLinkCell";
import { StatusTag } from "../components/StatusTag";

export const MONITORING_GROUP_ASSET_CHILDREN_COLUMNS: PreviewTableColumn<MonitoringGroupAssetChild>[] =
  [
    {
      isRowStart: true,
      title: "Asset Child URL",
      type: "locked",
      width: 450,
      Component: (monitoring_group_asset) => (
        <DisplayUrlLinkCell
          title={monitoring_group_asset.data || ""}
          url={monitoring_group_asset.data || ""}
          id={monitoring_group_asset.id}
          type={"Child"}
        />
      ),
    },
    {
      title: "Status",
      type: "locked",
      width: 140,
      Component: (monitoring_group_asset) => (
        <StatusTag
          status={
            Number(monitoring_group_asset.child_unresolved_results_count) > 0
              ? "Need Review"
              : "Resolved"
          }
        />
      ),
    },
    {
      key: "child_grouped_results_count",
      title: "Total Findings",
      type: "locked",
      width: 130,
    },
    {
      key: "child_grouped_unresolved_results_count",
      title: "Unresolved Findings",
      type: "locked",
      width: 170,
    },
    {
      title: "Latest Scan Date",
      type: "locked",
      isRowEnd: true,
      width: 160,
      Component: (monitoring_group_asset) =>
        format(
          parseISO(
            monitoring_group_asset.monitoring_group_asset_child_updated_at ||
              "",
          ),
          "MM/dd/yyyy",
        ),
    },
  ] as const;
