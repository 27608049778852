import React from "react";
import { generatePath, useParams } from "react-router-dom";

import { Project } from "@/api";
import { useProjects } from "@/api/queries/projects";
import { Header } from "@/components/Layout/Header";
import { PageLayout } from "@/components/Layout/PageLayout";
import Loading from "@/components/Loading";
import mainStore from "@/stores/Main";

import ProjectsTable from "../components/ProjectsTable/ProjectsTable";
import { PROJECTS_LIST_PATH } from "./ProjectRoutes";

function ArchivedProjects() {
  const companyId = mainStore.companies.company?.id;
  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();

  const { data: projects, isPending } = useProjects(Number(companyId));

  if (isPending) {
    return <Loading loadingLayout="table" />;
  }

  const archivedProjects = projects?.data.filter(
    (project: Project) => !!project.archived_at,
  );

  return (
    <PageLayout>
      <Header
        title={
          <div className="tw-flex tw-items-center tw-gap-2">
            Archived Projects
          </div>
        }
        backButtonLinkTo={generatePath(PROJECTS_LIST_PATH, {
          workspace_id,
        })}
      />
      {archivedProjects?.length ? (
        <ProjectsTable
          projects={archivedProjects}
          workspace_id={workspace_id}
          companyId={companyId}
        />
      ) : (
        "No archived projects"
      )}
    </PageLayout>
  );
}

export default ArchivedProjects;
