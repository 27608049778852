import { MiniTag } from "@themis/ui";
import React from "react";

export const TaskStatus = {
  "Not Started": {
    value: "Not Started",
    label: "Not Started",
    Component: () => <MiniTag color="grey">Not Started</MiniTag>,
  },
  "In Progress": {
    value: "In Progress",
    label: "In Progress",
    Component: () => <MiniTag color="primary">In Progress</MiniTag>,
  },
  Done: {
    value: "Done",
    label: "Completed",
    Component: () => <MiniTag color="turquoise">Completed</MiniTag>,
  },
} as const;

export const TASK_STATUSES = Object.values(TaskStatus);
