import "react-dropdown/style.css";

import { observer } from "mobx-react";
import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import { useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import deleteIcon from "../../../images/table-image/icon/close-icon2.svg";
import DetailNav from "../../detailView/DetailNav";
import { Icon } from "../../Elements";
import ExportActivityEvents from "../../settings/ExportActivityEvents";
import ListSettings from "../settings/ListSettings";
import ListTitleSettings from "../settings/ListTitleSettings";
import Table from "../Table";

interface AuditTrailsParams {
  actionName?: string;
  page: number;
  moduleIdentifier: ModuleIdentifier;
  recordID: number;
}

type Props = {
  isRecordView?: boolean;
};

type ParamProps = {
  record_version_id: string;
  moduleIdentifier: ModuleIdentifier;
  tab?: string;
};

function AuditTrail({ isRecordView }: Props) {
  // Import Mobx stores
  const mainStore = useMainStore();

  const headers = [
    {
      field_name: "global_id",
      title: "Global ID",
      width: 200,
    },
    { field_name: "record_name", title: "Record Name", width: 200 },
    {
      field_name: "action",
      title: "Action",
      width: 200,
    },
    {
      field_name: "username",
      title: "User",
      width: 200,
    },
    {
      field_name: "audited_changes",
      title: "Activity",
      width: 400,
    },
    {
      field_name: "created_at",
      title: "Time",
      width: 200,
    },
  ];

  // Constants
  const { record_version_id, tab } = useParams<ParamProps>();
  const { themisModuleIdentifier: moduleIdentifier } = mainStore.context;
  const recordVersionFetch = mainStore.recordVersions.current;
  const recordVersionID = Number(record_version_id);
  const recordVersionFromStore = mainStore.recordVersions.list.find(
    (rv) => rv.id === recordVersionID,
  );

  const recordVersion = recordVersionFromStore || recordVersionFetch;
  const { moduleWorkspaceID } = mainStore.context;
  const recordID = recordVersion?.record?.id;
  const store = mainStore?.auditTrails;
  const { auditTrails, count, actions } = store;
  const hidePaginationBlock = auditTrails.length === 0;

  const arrowLeftImage = (
    <Icon name="chevronLeft" color="generalMidnightDark" />
  );
  const arrowRightImage = (
    <Icon name="chevronRight" color="generalMidnightDark" />
  );

  // State
  const [currentAction, setCurrentAction] = useState(null);
  const [page, setPage] = useState(1);

  // If Navigating directly to the audit trail page, we need to fetch the record version
  useEffect(() => {
    const fetchData = async () => {
      if (recordVersionFromStore) {
        return;
      }
      if (recordVersionFetch?.id === recordVersionID) {
        return;
      }
      if (recordVersionID) {
        await mainStore.recordVersions.fetch(recordVersionID);
      }
    };

    fetchData();
  }, [recordVersionID]);

  // Hooks
  useEffect(() => {
    if (recordID) {
      if (isRecordView) {
        store.fetchRecordAuditTrail({ recordID, actionName: currentAction });
      }
    }

    if (moduleIdentifier) {
      if (!isRecordView) {
        store.fetchModuleAuditTrail({
          moduleIdentifier,
          actionName: currentAction,
        });
      }
    }
  }, [recordID, currentAction, moduleIdentifier]);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'actionObject' implicitly has an 'any' t... Remove this comment to see the full error message
  const handleDropdownClick = (actionObject) => {
    setCurrentAction(actionObject.value);
  };

  const clearFilter = () => {
    setCurrentAction(null);
  };

  const renderHeaders = headers.map((header) => (
    <ListTitleSettings
      key={header.field_name}
      width={header.width}
      title={header.title}
      fieldName={header.field_name}
    />
  ));

  const renderRows = auditTrails?.map((auditRecord) => (
    <ListSettings
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
      key={auditRecord.id}
      headers={headers}
      data={auditRecord}
      model="AuditTrail"
    />
  ));

  // @ts-expect-error TS(7006) FIXME: Parameter 'newPage' implicitly has an 'any' type.
  const onPageChange = async (newPage) => {
    const params: AuditTrailsParams = {} as AuditTrailsParams;
    setPage(newPage);

    params.page = newPage;
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
    params.actionName = currentAction;

    if (isRecordView) {
      // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
      params.recordID = recordID;
      // @ts-expect-error TS(2345) FIXME: Argument of type 'AuditTrailsParams' is not assign... Remove this comment to see the full error message
      await store.fetchRecordAuditTrail(params);
    } else {
      params.moduleIdentifier = moduleIdentifier as ModuleIdentifier;
      // @ts-expect-error TS(2345) FIXME: Argument of type 'AuditTrailsParams' is not assign... Remove this comment to see the full error message
      await store.fetchModuleAuditTrail(params);
    }
  };

  return (
    <Table>
      <div
        className="settings-wrap company-users-settings-container audit-trail-container"
        data-testid="audit-trail"
      >
        {isRecordView && (
          <DetailNav
            moduleIdentifier={moduleIdentifier as ModuleIdentifier}
            recordVersionID={recordVersionID}
            tab={tab}
          />
        )}

        <div className="filter-header">
          <div className="filter-header-flex">
            <div className="filter-header-left">
              Filter By Action
              <Dropdown
                options={actions}
                onChange={handleDropdownClick}
                // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                value={currentAction}
              />
              <img src={deleteIcon} onClick={clearFilter} alt="delete-icon" />
            </div>
            <div className="filter-header-right">
              <ExportActivityEvents
                recordID={recordID}
                // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
                moduleWorkspaceID={moduleWorkspaceID}
                // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
                isRecordView={isRecordView}
              />
            </div>
          </div>
        </div>
        <div className="settings-content-wrap">
          <div className="list-title-table">
            <ul>
              <div className="list-title-table-wrap">
                {renderHeaders}
                <span className="stretch-cell" />
              </div>
              <div className="small-block-indent" />
            </ul>
          </div>
          {renderRows}
        </div>
        <div className="audit-trail-pagination">
          {hidePaginationBlock ? (
            ""
          ) : (
            <Pagination
              onChange={onPageChange}
              current={page}
              total={count}
              prevIcon={arrowLeftImage}
              nextIcon={arrowRightImage}
              showPrevNextJumpers
              pageSize={25}
            />
          )}
        </div>
      </div>
    </Table>
  );
}

AuditTrail.defaultProps = {
  isRecordView: false,
};

export default observer(AuditTrail);
