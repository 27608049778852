import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import {
  NavLink,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import Popup from "reactjs-popup";

import CommentsSlideMenu from "@/components/table/shared/comments/CommentsSlideMenu";

import ExportBulk from "../../../components/dashboard/ExportBulk";
import ImportBulk from "../../../components/dashboard/ImportBulk";
import { useMainStore } from "../../../contexts/Store";
import noticeNo from "../../../images/table-image/icon/notice-no.svg";

type Props = {
  isInternal?: boolean;
  recordVersionID?: string;
};

function TestDetailsHeader({ recordVersionID, isInternal }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  // Hooks
  const location = useLocation();

  const isRecordViewMatch = Boolean(
    useRouteMatch({
      path: `/workspaces/${workspaceID}/modules/qa-tests-development/${recordVersionID}`,
      exact: true,
    }),
  );

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [listProceduresSteps, setListProceduresSteps] = useState([]);

  const recordVersion =
    isRecordViewMatch &&
    (mainStore.recordVersions.list.find(
      (rv) => rv.id === Number(recordVersionID),
    ) ||
      mainStore.recordVersions.current);

  // Effects
  useEffect(() => {
    let mounted = true;

    if (recordVersionID) {
      (async () => {
        const stepsRecordVersions =
          await mainStore.qa.testProcedureSteps(recordVersionID);
        if (mounted) {
          setListProceduresSteps(stepsRecordVersions);
        }
      })();
    }

    return () => {
      mounted = false;
    };
  }, [recordVersionID, location]);

  useEffect(() => {
    if (location.pathname) {
      setShowPopup(false);
    }
  }, [location]);

  const clearRecordVersions = () => {
    mainStore.recordVersions.resetList();
  };

  // Variables
  const renderPopup = (
    <Popup
      position="bottom center"
      on="hover"
      trigger={<img src={noticeNo} alt="notice-no-icon" />}
      open={showPopup}
      onOpen={() => setShowPopup(true)}
      onClose={() => setShowPopup(false)}
      keepTooltipInside
      arrow={false}
      offsetY={8}
    >
      <div className="qa-procures-popup">Add New Procedures</div>
    </Popup>
  );

  return (
    <div
      className="table-header-wrap no-scroll test-details-header"
      data-testid="qa-test-details-header"
    >
      <div className="switch-table-wrap">
        <ul>
          <li>
            <NavLink
              onClick={clearRecordVersions}
              exact
              to={`/workspaces/${workspaceID}/modules/qa-tests-development/${recordVersionID}`}
            >
              Record View
            </NavLink>
          </li>
          {isInternal && (
            <li>
              <NavLink
                data-testid="qa-procedures-tab"
                exact
                onClick={clearRecordVersions}
                to={`/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/procedures`}
              >
                Procedure Steps{" "}
                {listProceduresSteps?.length === 0 &&
                  !location.pathname.endsWith("procedures") &&
                  renderPopup}
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              data-testid="qa-findings-tab"
              onClick={clearRecordVersions}
              to={`/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/findings`}
            >
              Findings
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/linked_documents`}
            >
              Documents
            </NavLink>
          </li>
          <li>
            <NavLink
              data-testid="samples-tab"
              exact
              onClick={clearRecordVersions}
              to={`/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/samples`}
            >
              Samples
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="import-export-buttons-container">
        <Switch>
          <Route
            exact
            path={[
              `/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/record`,
              `/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/procedures`,
              `/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/samples`,
            ]}
          >
            <>
              <ExportBulk
                parentRecordVersionID={Number(recordVersionID)}
                subModuleDownload
              />
              <ImportBulk
                subModuleDownload
                parentRecordVersionID={Number(recordVersionID)}
              />
            </>
          </Route>
          <Route
            exact
            path={`/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/linked_documents`}
          />
        </Switch>
        {isRecordViewMatch && recordVersion && (
          <CommentsSlideMenu
            renderCommentsButton
            globalID={recordVersion.global_id}
            recordID={recordVersion.record_id}
            uncompletedCommentsCount={recordVersion.uncompleted_comments_count}
            taskableType="Record"
          />
        )}
      </div>
    </div>
  );
}

export default observer(TestDetailsHeader);
