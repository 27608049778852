import { LinkButton, PreviewTable } from "@themis/ui";
import React from "react";
import { PiPlusBold } from "react-icons/pi";
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { useAccounts } from "@/api/queries/accounts";
import Loading from "@/components/Loading";
import { useSearchParams } from "@/hooks/useSearchParams";

import { ErrorContainer } from "../../../components/ErrorContainer";
import { ACCOUNT_COLUMNS } from "../config/accountsTable";
import { ACCOUNT_ROUTES } from "../pages";
import { AccountSearchParams } from "../pages/Accounts";
import { AccountCard } from "./AccountCard";

function CardsContainer({ children }: { children: React.ReactNode }) {
  return (
    <div
      data-testid="cards-container"
      className="tw-flex tw-flex-wrap tw-items-center tw-gap-4 tw-self-stretch tw-py-2"
    >
      {children}
    </div>
  );
}

export function AccountList() {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const [{ view }] = useSearchParams<AccountSearchParams>();
  const { isPending, data, isError } = useAccounts(Number(workspace_id));

  const accountsListView = view || "table";

  if (isPending) {
    return <Loading loadingLayout={accountsListView} />;
  }

  if (isError) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath("/workspaces/:workspace_id/home", {
            workspace_id,
          }),
        }}
      >
        Could not load accounts.
      </ErrorContainer>
    );
  }

  return accountsListView === "grid" ? (
    <>
      <CardsContainer>
        {data?.accounts?.map(({ id, logo, account_types, name, is_active }) => (
          <AccountCard
            linkTo={generatePath(`${url}${ACCOUNT_ROUTES.details}`, {
              accountId: id,
            })}
            key={id}
            id={id}
            isActive={is_active}
            logoURL={logo?.file_url}
            name={name}
            type={account_types[0]}
          />
        ))}
      </CardsContainer>
      <LinkButton
        to={generatePath(`${url}${ACCOUNT_ROUTES.details}`, {
          accountId: "new",
        })}
      >
        Add New Account
      </LinkButton>
    </>
  ) : (
    <>
      <PreviewTable rows={data?.accounts} columns={ACCOUNT_COLUMNS} />
      <div
        className="tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-4 tw-px-[7px] tw-py-[6px]"
        onClick={() =>
          history.push(
            generatePath(`${url}${ACCOUNT_ROUTES.details}`, {
              accountId: "new",
            }),
          )
        }
      >
        <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
        <div className="tw-neutral-300 tw-text-sm">Add Account...</div>
      </div>
    </>
  );
}
