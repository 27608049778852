import "./styles.scss";

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import {
  Button,
  Flex,
  IconButton,
  MiniTag,
  Typography,
} from "@/components/Elements";
import { useSwitchToSelectedCommentTab } from "@/components/settings/notificationsCenter/tasks/hooks/useSwitchToSelectedCommentTab";
import MutableTaskComment from "@/components/settings/notificationsCenter/tasks/MutableTaskComment";
import SubpageTabs from "@/components/subpage-tabs/SubPageTabs";
import { CommentTab } from "@/components/table/shared/comments/CommentsSlideMenu/types";
import UserMentionsInput from "@/components/table/shared/UserMentionsInput";
import { useMainStore } from "@/contexts/Store";
import {
  getTopLevelComments,
  isNotResolvedComment,
} from "@/stores/helpers/CommentsHelpers";
import {
  CommentTypeProperty,
  TaskableType,
} from "@/stores/types/comment-types";

export type SearchParamsProps = {
  comments_tab?: CommentTab;
  comment_id?: string;
  question_id?: string;
  template_id?: string;
  question_group_id?: string;
  questionnaire_id?: string;
};

type Props = {
  taskableType: TaskableType;
  globalID?: string;
  recordID?: number;
  /**
   * Will render comments button instead of MiniTag comment count trigger
   * (Used in Detail Pages)
   */
  SubHeader?: React.FC;
  title?: string;
  hideEmptyState?: boolean;
  searchParams: SearchParamsProps;
  closeSlideMenu?: () => void;
};

// incorrect/confusing naming
function CommentSlidebarContent({
  taskableType,
  globalID,
  recordID,
  SubHeader,
  title = "Comments",
  hideEmptyState,
  searchParams,
  closeSlideMenu,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { canAddComments } = mainStore.userPermissions;
  // const location = useLocation();

  const topLevelComments = getTopLevelComments(searchParams.comments_tab, {
    comments: mainStore.comments.comments,
    privateComments: mainStore.comments.privateComments,
  });

  // State
  const [commentInput, setCommentInput] = useState("");

  useSwitchToSelectedCommentTab(mainStore.comments.privateComments);

  useEffect(() => {
    indexComments();
  }, [recordID]);

  async function indexComments() {
    if (!recordID) {
      return;
    }

    let indexParams: null | CommentTypeProperty = null;

    if (taskableType === "Record") {
      indexParams = { record_id: recordID };
    } else if (taskableType === "BaseChecklist") {
      indexParams = { template_id: recordID };
    } else if (taskableType === "Question") {
      indexParams = { question_id: recordID };
    } else if (taskableType === "QuestionGroup") {
      indexParams = { question_group_id: recordID };
    } else {
      mainStore.toast.setErrorText("Something went wrong");
      return;
    }
    await mainStore.comments.index(indexParams);
  }

  async function handleSubmit() {
    if (!commentInput) {
      return;
    }

    await mainStore.comments.create({
      content: commentInput,
      private: searchParams.comments_tab === "internal",
    });
    setCommentInput("");
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleAddTask(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  }

  const sortingForCompleted = topLevelComments.slice().sort((a, b) => {
    if (a.author.id === b.author.id) {
      return b.created_at.localeCompare(a.created_at);
    }
    return a.author.id > b.author.id ? 1 : -1;
  });

  const renderEmptyState = !hideEmptyState && (
    <div className="no-column-wrap">
      <div className="no-column-block">
        <div className="no-column-procedure no-column-slide-menu">
          <div className="no-column-title">
            <h3>No Comments added yet</h3>
          </div>
          Type in the “Add new comment” field above and hit Enter to submit.
        </div>
      </div>
    </div>
  );

  return (
    <div className="comments-slide-menu">
      <div
        className="comments-slide-menu__record-header"
        data-testid="tasks-popup"
      >
        <Flex alignCenter columnGap={10}>
          <Typography
            type="h3"
            size="md"
            weight="bold"
            label={title}
            color="generalMidnightDark"
          />
          {globalID && (
            <MiniTag
              label={globalID}
              fontSize="xs"
              theme="navy"
              fontWeight="medium"
            />
          )}
        </Flex>
        <IconButton
          iconSize="md"
          size="sm"
          transparent
          icon="close"
          onClick={closeSlideMenu}
        />
      </div>
      {SubHeader && <SubHeader />}
      {mainStore.comments.showPrivateComments && (
        <SubpageTabs<{ comments_tab: CommentTab }>
          infoText={
            "Collaborative comments can be seen by all users with access to this workspace.\nInternal comments can only be seen by users with access to the internal workspace."
          }
          searchParamName="comments_tab"
          options={[
            {
              activeValue: "collaborative",
              label: "Collaborative",
              count:
                mainStore.comments.comments.filter(isNotResolvedComment).length,
            },
            {
              activeValue: "internal",
              label: "Internal",
              count:
                mainStore.comments.privateComments.filter(isNotResolvedComment)
                  .length,
            },
          ]}
        />
      )}
      <div className="comments-slide-menu__content">
        {canAddComments && (
          <>
            <div className="comments-slide-menu__header">
              <UserMentionsInput
                editable
                dataTestID="mentions-input"
                content={commentInput}
                placeholder={"Add a comment. Use @ to mention a user."}
                onChange={(event) => setCommentInput(event.target.value)}
                onKeyDown={handleAddTask}
                singleLine={false}
              />
            </div>
            <div className="comment_confirm_button">
              <Button
                label="Confirm"
                onClick={handleSubmit}
                disabled={commentInput.length === 0}
              />
            </div>
          </>
        )}
        {sortingForCompleted.map((task) => (
          <MutableTaskComment
            key={task.id}
            commentID={task.id}
            taskableType={taskableType}
          />
        ))}
        {!sortingForCompleted.length && renderEmptyState}
      </div>
    </div>
  );
}

export default observer(CommentSlidebarContent);
