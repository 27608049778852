var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import classNames from "classnames";
import React, { useRef } from "react";
import { PiArrowDown, PiArrowUp, PiPlusBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import { TableRow } from "./TableRow";
/**
 * @returns A container for the table that sets the width of the columns
 */
function TableContainer(_a) {
    var children = _a.children, columns = _a.columns, responsive = _a.responsive, hasRowActions = _a.hasRowActions;
    var columnWidths = columns.map(function (_a) {
        var width = _a.width, type = _a.type;
        if (type === "actions") {
            return "auto";
        }
        return "".concat(width).concat(responsive ? "fr" : "px");
    });
    if (hasRowActions) {
        columnWidths.push("auto");
    }
    return (React.createElement("div", { role: "grid", style: {
            gridTemplateColumns: columnWidths.join(" "),
        }, className: classNames("tw-grid tw-overflow-auto", {
            "tw-w-full": responsive,
        }) }, children));
}
function HeaderCellContainer(_a) {
    var children = _a.children;
    return (React.createElement("div", { className: "tw-sticky tw-top-0 tw-z-[10] tw-bg-neutral-25" }, children));
}
function TableHeaderCell(_a) {
    var children = _a.children, Icon = _a.Icon, isIdColumn = _a.isIdColumn, isRowStart = _a.isRowStart, isRowEnd = _a.isRowEnd, sortDirection = _a.sortDirection, onClick = _a.onClick;
    return (React.createElement(HeaderCellContainer, null,
        React.createElement("div", { onClick: onClick, className: classNames("tw-box-border tw-flex tw-h-8 tw-items-center tw-gap-1.5 tw-text-nowrap tw-px-2 tw-text-sm tw-font-medium", {
                "tw-border-l-solid tw-rounded-l tw-border-l": isRowStart,
                "tw-rounded-r tw-border-r": isRowEnd,
                "tw-mr-1 tw-rounded tw-bg-blue-1 tw-text-blue-4": isIdColumn,
                "tw-border-x-0 tw-border-y tw-border-solid tw-border-neutral-100 tw-bg-neutral-25 tw-text-neutral-300": !isIdColumn,
                "tw-cursor-pointer hover:tw-bg-neutral-50": onClick,
            }) },
            Icon && React.createElement(Icon, { className: "tw-h-4 tw-w-4" }),
            children,
            sortDirection === "asc" && (React.createElement(PiArrowUp, { className: "tw-ml-auto tw-h-4 tw-w-4" })),
            sortDirection === "desc" && (React.createElement(PiArrowDown, { className: "tw-ml-auto tw-h-4 tw-w-4" })))));
}
/**
 * @returns A gap between the last header and the right side of the table
 */
var LastHeaderGap = React.forwardRef(function (_, ref) {
    return (React.createElement("div", { ref: ref, className: "tw-sticky tw-right-0 tw-top-0 tw-z-10 tw-bg-neutral-25" }));
});
LastHeaderGap.displayName = "LastHeaderGap";
/**
 * @returns A gap between the header and the first row
 */
function GridRowGap() {
    return React.createElement("div", { className: "tw-col-span-full tw-h-1 tw-bg-neutral-25" });
}
var HeaderBottomGap = React.forwardRef(function (_, ref) {
    return (React.createElement("div", { ref: ref, className: "tw-sticky tw-top-8 tw-z-10 tw-col-span-full tw-h-1 tw-bg-neutral-25" }));
});
HeaderBottomGap.displayName = "HeaderBottomGap";
export function PreviewTable(_a) {
    var addRow = _a.addRow, columns = _a.columns, columnIconOverrides = _a.columnIconOverrides, customFieldColumnIconOverrides = _a.customFieldColumnIconOverrides, componentOverrides = _a.componentOverrides, customFieldComponentOverrides = _a.customFieldComponentOverrides, responsive = _a.responsive, _b = _a.rows, rows = _b === void 0 ? [] : _b, rowActions = _a.rowActions, sort = _a.sort, _c = _a.onSortChange, onSortChange = _c === void 0 ? function () { } : _c, _d = _a.headerDisplay, headerDisplay = _d === void 0 ? true : _d, customCellClassName = _a.customCellClassName, customRowClassName = _a.customRowClassName;
    var lastHeaderGapRef = useRef(null);
    var headerBottomGapRef = useRef(null);
    var orderAwareColumns = columns.map(function (column, index) {
        if (column.type === "actions" || column.type === "id") {
            return column;
        }
        var isRowStart = false;
        if (index === 0) {
            isRowStart = true;
        }
        else if (index === 1 && columns[0].type === "id") {
            isRowStart = true;
        }
        var isRowEnd = false;
        if (index === columns.length - 1) {
            isRowEnd = true;
        }
        else if (index === columns.length - 2 &&
            columns[columns.length - 1].type === "actions") {
            isRowEnd = true;
        }
        return __assign(__assign({}, column), { isRowStart: isRowStart, isRowEnd: isRowEnd });
    });
    function handleSortChange(columnKey) {
        if (!columnKey) {
            return;
        }
        if (sort === undefined || sort.columnKey !== columnKey) {
            onSortChange({ columnKey: columnKey, direction: "asc" });
            return;
        }
        onSortChange(sort.direction === "asc" ? { columnKey: columnKey, direction: "desc" } : undefined);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(TableContainer, { columns: orderAwareColumns, responsive: responsive, hasRowActions: !!rowActions },
            headerDisplay && (React.createElement(React.Fragment, null,
                orderAwareColumns.map(function (_a) {
                    var title = _a.title, isRowStart = _a.isRowStart, isRowEnd = _a.isRowEnd, type = _a.type, Icon = _a.Icon, key = _a.key, isCustomColumn = _a.isCustomColumn;
                    function getIcon() {
                        if (isCustomColumn && key) {
                            var customFieldColumnIconOverride = customFieldColumnIconOverrides === null || customFieldColumnIconOverrides === void 0 ? void 0 : customFieldColumnIconOverrides[key];
                            return customFieldColumnIconOverride || Icon;
                        }
                        var columnIconOverride = columnIconOverrides === null || columnIconOverrides === void 0 ? void 0 : columnIconOverrides[key];
                        return columnIconOverride || Icon;
                    }
                    return type === "actions" ? (React.createElement(LastHeaderGap, { key: "actions", ref: lastHeaderGapRef })) : (React.createElement(TableHeaderCell, { key: title, Icon: getIcon(), isIdColumn: type === "id", isRowStart: isRowStart, isRowEnd: isRowEnd, sortDirection: sort !== undefined && sort.columnKey === key
                            ? sort.direction
                            : undefined, onClick: key && (function () { return handleSortChange(key); }) }, title));
                }),
                rowActions && React.createElement(LastHeaderGap, { ref: lastHeaderGapRef }),
                React.createElement(HeaderBottomGap, { ref: headerBottomGapRef }))),
            rows.map(function (row, index) { return (React.createElement(React.Fragment, { key: row.id },
                Boolean(index || addRow) && React.createElement(GridRowGap, null),
                React.createElement(TableRow, { row: row, rowActions: rowActions, columns: orderAwareColumns, customCellClassName: customCellClassName, customRowClassName: customRowClassName, componentOverrides: componentOverrides, customFieldComponentOverrides: customFieldComponentOverrides }))); })),
        addRow && (React.createElement(Link, { to: addRow.linkTo, className: "tw-cursor-auto tw-text-inherit" },
            React.createElement("div", { className: "tw-sticky tw-left-0 tw-ml-0 tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]", "data-testid": "add-new-record-button" },
                React.createElement(PiPlusBold, { className: "tw-h-[18px] tw-w-[18px]" }),
                React.createElement("div", { className: "tw-neutral-300 tw-text-sm" },
                    addRow.text,
                    "..."))))));
}
