import "./KRIModule.scss";

import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router-dom";

import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import DetailView from "@/components/detailView/DetailView";
import DashboardContent from "@/components/shared/DashboardContent";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import HeadSelect from "@/components/table/shared/HeadSelect";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";

import { kriListUrl } from "../urlPaths";

const KRIDetailsPage = ({ recordName }: { recordName: string }) => {
  const history = useHistory();

  return (
    <DashboardContent>
      <DashboardHeader
        title={`${recordName} Detail`}
        onBackClick={() => history.push(kriListUrl)}
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<HeadSelect />}
      />
      <DashboardContentWrapper>
        <DetailView moduleIdentifier={TopLevelModule.KEY_RISK_INDICATORS} />
      </DashboardContentWrapper>
    </DashboardContent>
  );
};

export default observer(KRIDetailsPage);
