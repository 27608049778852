import { MiniTag } from "@themis/ui";
import React from "react";

export function ActiveTag({
  isActive,
  ...props
}: { isActive: boolean } & React.ComponentPropsWithRef<"div">) {
  return (
    <MiniTag {...props} color={isActive ? "primary" : "red"}>
      {isActive ? "Active" : "Inactive"}
    </MiniTag>
  );
}
