import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DeleteTodoMutationResponse,
  DeleteTodoPathParams,
} from "../../models/DeleteTodo";

/**
 * @description Delete todo
 * @summary Delete todo
 * @link /todos/:id
 */
export async function deleteTodo(
  id: DeleteTodoPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DeleteTodoMutationResponse>["data"]> {
  const res = await client<DeleteTodoMutationResponse>({
    method: "delete",
    url: `/todos/${id}`,
    ...options,
  });
  return res.data;
}
