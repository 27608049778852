import { observer } from "mobx-react";
import React from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import DashboardContent from "@/components/shared/DashboardContent";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import FavoriteButton from "@/features/misc/favorites/FavoriteButton";
import mainStore from "@/stores/Main";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

import HeadSelect from "../../../components/table/shared/HeadSelect";
import TodosDetailView from "./TodosDetailView";
import TodosIndex from "./TodosIndex";

function Todos() {
  const history = useHistory();
  const location = useLocation();
  const isTodosIndex = location.pathname === "/todos";
  const checklistFeatureEnabled = mainStore.featureFlags.getIsEnabled(
    FEATURE_FLAG_ID.COM_CHECKLIST,
  );

  const todoComponents = (
    <>
      <DashboardHeader
        title={"Checklists"}
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<HeadSelect />}
        onBackClick={isTodosIndex ? () => history.push("/todos") : undefined}
        passedFavButton={
          <FavoriteButton
            passedModuleWorkspaceID={
              mainStore.moduleWorkspaces.list.find(
                (mw) => mw.themis_module.identifier === "checklists",
              )?.id
            }
          />
        }
      />
      <DashboardContent>
        <Switch>
          <Route path="/todos/add-new-checklist">
            <DashboardContentWrapper>
              <TodosDetailView />
            </DashboardContentWrapper>
          </Route>
          <Route path="/todos/:id">
            <DashboardContentWrapper>
              <TodosDetailView />
            </DashboardContentWrapper>
          </Route>
          <Route path="/todos">
            <DashboardContentWrapper>
              <TodosIndex />
            </DashboardContentWrapper>
          </Route>
        </Switch>
      </DashboardContent>
    </>
  );

  const todos = checklistFeatureEnabled ? todoComponents : null;

  return todos;
}

export default observer(Todos);
