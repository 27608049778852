import { PreviewTableColumn } from "@themis/ui";
import { format, parseISO } from "date-fns";
import React from "react";

import { MonitoringGroupAsset } from "@/api";

import { DisplayUrlLinkCell } from "../components/DisplayUrlLinkCell";
import { StatusTag } from "../components/StatusTag";

export const MONITORING_GROUP_ASSET_COLUMNS: PreviewTableColumn<MonitoringGroupAsset>[] =
  [
    {
      isRowStart: true,
      title: "Asset / Job Name",
      type: "locked",
      width: 250,
      Component: (monitoring_group_asset) => (
        <DisplayUrlLinkCell
          title={monitoring_group_asset.asset_name || ""}
          url={monitoring_group_asset.data || ""}
          id={monitoring_group_asset.id}
          type={"Asset"}
        />
      ),
    },
    {
      title: "Status (Generated)",
      type: "locked",
      width: 175,
      Component: (monitoring_group_asset) => (
        <StatusTag status={monitoring_group_asset.asset_status || ""} />
      ),
    },
    {
      key: "asset_type",
      title: "Asset Type",
      type: "locked",
      width: 200,
      Component: (monitoring_group_asset) => (
        <StatusTag status={monitoring_group_asset.asset_type || ""} />
      ),
    },
    {
      title: "Latest Update",
      type: "locked",
      isRowEnd: true,
      width: 200,
      Component: (monitoring_group_asset) =>
        format(
          parseISO(
            monitoring_group_asset.monitoring_group_asset_updated_at || "",
          ),
          "MM/dd/yyyy",
        ),
    },
    {
      key: "asset_children_results_count",
      title: "Total Findings",
      type: "locked",
      width: 200,
    },
    {
      key: "asset_children_unresolved_results_count",
      title: "Unresolved Findings",
      type: "locked",
      width: 200,
    },
    {
      title: "Created Date",
      type: "locked",
      isRowEnd: true,
      width: 200,
      Component: (monitoring_group_asset) =>
        format(
          parseISO(
            monitoring_group_asset.monitoring_group_asset_created_at || "",
          ),
          "MM/dd/yyyy",
        ),
    },
  ] as const;
