import classNames from "classnames";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import { RecordVersion } from "@/api";
import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import {
  dashboardDisplayNames,
  mapUrlToDashboard,
} from "@/components/reports/common/MetricsPage/constants";
import {
  Dashboards,
  IssueManagementTabs as Tabs,
} from "@/components/reports/common/MetricsPage/types";
import {
  activeIssuesUrl,
  allIssuesUrl,
  completedIssuesUrl,
  createdDateDashboardUrl,
  metricsDashboardUrl,
  overdueDashboardUrl,
  statusDashboardUrl,
  timeToCloseDashboardUrl,
} from "@/components/reports/common/MetricsPage/urlPaths";
import SingleSelectField from "@/components/reports/common/SingleSelectField";
import IssueManagementMetrics from "@/components/reports/issueManagement/IssueManagementMetrics";
import DashboardContent from "@/components/shared/DashboardContent";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import { useMainStore } from "@/contexts/Store";
import useDragAndDropRecords from "@/hooks/useDragAndDropRecords";
import usePrevious from "@/hooks/usePrevious";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";
import { pathWithoutWorkspacePrefix } from "@/utils/routing";

import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import HeadSelect from "../shared/HeadSelect";
import SectionDragContext from "../shared/SectionDragContext";
import SectionPage from "../shared/SectionPage";

const SCREENS = {
  [Tabs.ALL]: {
    label: Tabs.ALL,
    url: allIssuesUrl,
  },
  [Tabs.ACTIVE]: {
    label: Tabs.ACTIVE,
    url: activeIssuesUrl,
  },
  [Tabs.COMPLETED]: {
    label: Tabs.COMPLETED,
    url: completedIssuesUrl,
  },
  [Tabs.METRICS]: {
    label: Tabs.METRICS,
    url: statusDashboardUrl,
  },
};

function IssueManagement() {
  const mainStore = useMainStore();
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const workspaceId = Number(workspace_id);
  const history = useHistory();
  const location = useLocation();
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const { groupFieldName } = mainStore.reports;
  const { canViewReports } = mainStore.userPermissions;
  const tabs = canViewReports
    ? Object.keys(SCREENS)
    : Object.keys(SCREENS).filter((tab) => tab !== Tabs.METRICS);
  const prevLocation = usePrevious(location.pathname);

  const { onSectionChange, onRecordMoved } = useDragAndDropRecords({
    themisIdentifier: TopLevelModule.ISSUE_MANAGEMENT,
    mainStore,
  });

  useEffect(() => {
    if (mainStore.fields.allGroupableFields.length === 0 && workspaceId) {
      mainStore.fields.getAllFieldsByDataType({
        workspaceIDs: [workspaceId],
        identifier: TopLevelModule.ISSUE_MANAGEMENT,
      });
    }
  }, [workspaceId, mainStore.fields.allGroupableFields]);

  const tabName = useMemo(() => {
    const relativePath = location.pathname.replace(
      `/workspaces/${workspaceId}`,
      "",
    );
    switch (relativePath) {
      case statusDashboardUrl:
      case createdDateDashboardUrl:
      case overdueDashboardUrl:
      case timeToCloseDashboardUrl:
      case metricsDashboardUrl:
        return Tabs.METRICS;
      case allIssuesUrl:
        return Tabs.ALL;
      case activeIssuesUrl:
        return Tabs.ACTIVE;
      case completedIssuesUrl:
        return Tabs.COMPLETED;
      default:
        return Tabs.ALL;
    }
  }, [location.pathname]);

  useEffect(() => {
    mainStore.issueManagement.setLastTab(tabName);
  }, [tabName]);

  useEffect(() => {
    return () => {
      mainStore.issueManagement.abort();
    };
  }, []);

  useEffect(() => {
    const routeWithoutWorkspacePrefix = pathWithoutWorkspacePrefix(
      location.pathname,
    );
    if (
      Object.keys(mapUrlToDashboard).includes(routeWithoutWorkspacePrefix) &&
      prevLocation !== location.pathname
    ) {
      mainStore.reports.setGroupFieldName(
        mapUrlToDashboard[routeWithoutWorkspacePrefix],
      );
    }
  }, [location.pathname]);

  function createNewIssue(sectionTagID?: number | null) {
    const tableId = mainStore.context.tableID;

    if (!tableId) {
      return;
    }

    const searchParams = new URLSearchParams({
      ...(sectionTagID ? { section_tag_id: String(sectionTagID) } : {}),
      table_id: String(tableId),
    }).toString();

    history.push(
      `/workspaces/${workspaceId}/modules/issue-management/new-record?${searchParams}`,
    );
  }

  const minDateFunction = (columnTitle: string, rv: RecordVersion) => {
    if (columnTitle !== "Due Date") {
      return null;
    }

    const actionPlansDates = rv.record.action_plans.reduce<number[]>(
      (dates, { target_completion_date }) => {
        return target_completion_date
          ? [...dates, dayjs(target_completion_date).valueOf()]
          : dates;
      },
      [],
    );

    return actionPlansDates.length
      ? dayjs(Math.max(...actionPlansDates)).toDate()
      : null;
  };

  const maxDateFunction = (columnTitle: string) => {
    if (columnTitle === "Date Identified") {
      return new Date();
    }
    return null;
  };

  const handleChangeGroupFieldName = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
    (option) => {
      switch (option) {
        case dashboardDisplayNames[Dashboards.STATUS]:
          history.push(`/workspaces/${workspaceId}${statusDashboardUrl}`);
          break;
        case dashboardDisplayNames[Dashboards.CREATED_DATE]:
          history.push(`/workspaces/${workspaceId}${createdDateDashboardUrl}`);
          break;
        case dashboardDisplayNames[Dashboards.OVERDUE]:
          history.push(`/workspaces/${workspaceId}${overdueDashboardUrl}`);
          break;
        case dashboardDisplayNames[Dashboards.TIME_TO_CLOSE]:
          history.push(`/workspaces/${workspaceId}${timeToCloseDashboardUrl}`);
          break;
        default:
          break;
      }
    },
    [history],
  );

  const handleClickTab = useCallback(
    (wID: number, screen: Tabs) => {
      history.push(`/workspaces/${wID}${SCREENS[screen].url}`);
    },
    [history],
  );

  const Tab = useMemo(
    () => (
      <div className="issue-management-tabs-container">
        <div className="buttons-block" data-testid="main-tabs">
          {tabs.map((screen) => {
            return (
              <button
                key={screen}
                className={classNames({ active: tabName === screen })}
                onClick={() => handleClickTab(workspaceId!, screen as Tabs)}
                data-testid={`issue-management-tab-${screen}-trigger`}
              >
                {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
                {SCREENS[screen].label}
              </button>
            );
          })}
        </div>
        <div>
          {tabName === Tabs.METRICS && (
            <div>
              <SingleSelectField
                value={dashboardDisplayNames[groupFieldName]}
                options={Object.values(dashboardDisplayNames)}
                handleChange={handleChangeGroupFieldName}
                canSelectSameOption={false}
                testId="dashboard-select"
              />
            </div>
          )}
        </div>
      </div>
    ),
    [tabName, groupFieldName, mainStore.fields.allGroupableFields, workspaceId],
  );

  const renderPage = useCallback(
    (screen: Tabs) => (
      <SectionDragContext
        onSectionChange={onSectionChange}
        onRecordMoved={onRecordMoved}
      >
        <SectionPage
          isDraggable
          isTopLevelSectionAdded
          archived={screen === SCREENS.Completed.label}
          approvalFieldName=""
          CTASubject="Issue"
          moduleIdentifier="issue_management"
          recordName={getRecordName("issue_management", moduleWorkspaces, true)}
          tabs={Tab}
          createNewRecord={createNewIssue}
          selectedTab={screen}
          paginated
          archivedWord="closed"
          minDateFunction={minDateFunction}
          maxDateFunction={maxDateFunction}
        />
      </SectionDragContext>
    ),
    [tabName],
  );

  return (
    <>
      <DashboardHeader
        title="Issue Management"
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<HeadSelect />}
      />
      <DashboardContent>
        <Switch>
          <Route path={`/workspaces/:workspace_id${allIssuesUrl}`}>
            <DashboardContentWrapper>
              {renderPage(SCREENS.All.label)}
            </DashboardContentWrapper>
          </Route>

          <Route path={`/workspaces/:workspace_id${activeIssuesUrl}`}>
            <DashboardContentWrapper>
              {renderPage(SCREENS.Active.label)}
            </DashboardContentWrapper>
          </Route>
          <Route path={`/workspaces/:workspace_id${completedIssuesUrl}`}>
            <DashboardContentWrapper>
              {renderPage(SCREENS.Completed.label)}
            </DashboardContentWrapper>
          </Route>

          <Route path={`/workspaces/:workspace_id${metricsDashboardUrl}`}>
            <div className="table-header-wrap im-tab-container">{Tab}</div>
            <DashboardContentWrapper>
              <IssueManagementMetrics />
              <div style={{ height: "40px" }} />
            </DashboardContentWrapper>
          </Route>
        </Switch>
      </DashboardContent>
    </>
  );
}

export default observer(IssueManagement);
