import "./styles.scss";

import React from "react";

import { Flex } from "@/components/Elements";
import { NavTabs, NavTabsProps } from "@/components/nav-tab";

export function NavTabContainer(props: NavTabsProps) {
  return (
    <Flex justifySpaceBetween alignCenter className="nav-tab-container">
      <NavTabs tabs={props.tabs} />
    </Flex>
  );
}
