import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { ModifyAccountRequest } from "../gen";
import {
  createChildAccount,
  listChildAccounts,
} from "../gen/axios/childAccountsController";

const CHILD_ACCOUNTS_QUERY_KEY = "childAccounts";

export function useChildAccounts(accountId: number) {
  return useQuery({
    queryKey: [CHILD_ACCOUNTS_QUERY_KEY, { accountId }],
    queryFn: () => listChildAccounts(accountId),
    enabled: !!accountId,
  });
}

export function useCreateChildAccount({
  accountId,
  onSuccess = () => {},
  onError = () => {},
}: {
  accountId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (account: ModifyAccountRequest) =>
      createChildAccount(accountId, { account }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CHILD_ACCOUNTS_QUERY_KEY, { accountId }],
      });
      onSuccess();
    },
    onError,
  });
}
