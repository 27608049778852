import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateTodoItemMutationRequest,
  UpdateTodoItemMutationResponse,
  UpdateTodoItemPathParams,
} from "../../models/UpdateTodoItem";

/**
 * @description Update todo item
 * @summary Update todo item
 * @link /todo_items/:id
 */
export async function updateTodoItem(
  id: UpdateTodoItemPathParams["id"],
  data: UpdateTodoItemMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateTodoItemMutationResponse>["data"]> {
  const res = await client<
    UpdateTodoItemMutationResponse,
    UpdateTodoItemMutationRequest
  >({
    method: "put",
    url: `/todo_items/${id}`,
    data,
    ...options,
  });
  return res.data;
}
