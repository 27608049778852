import { Label, useToast } from "@themis/ui";
import React from "react";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import { ModifyAccountRequest } from "@/api";
import { useAccount } from "@/api/queries/accounts";
import { useCreateChildAccount } from "@/api/queries/childAccounts";
import { InfoPreviewSection } from "@/components/InfoPreviewSection";

import { ACCOUNT_DETAILS_PATH } from "..";
import {
  AccountInfoForm,
  AccountInfoSchema,
} from "../../components/AccountInfo/AccountInfoForm";
import { ActiveTag } from "../../components/ActiveTag";
import regex from "../../config/regex";
import { AccountLocationState } from "../AccountDetails";

function FormContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-min-w-96 tw-flex-col tw-gap-8 tw-px-32">
      {children}
    </div>
  );
}

function StatusContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-bg-neutral-50 tw-px-2.5 tw-py-1.5">
      {children}
    </div>
  );
}

function AccountInfoContainer({ children }: { children: React.ReactNode }) {
  return <div className="tw-flex tw-flex-col tw-gap-2">{children}</div>;
}

export function NewChildAccountInfo() {
  const toast = useToast();
  const history = useHistory();
  const location = useLocation<AccountLocationState>();
  const { workspace_id, accountId } = useParams<{
    workspace_id: string;
    accountId: string;
  }>();

  const { data: parentAccountData } = useAccount({
    workspaceId: Number(workspace_id),
    accountId: Number(accountId),
  });

  const { mutateAsync: createChildAccount } = useCreateChildAccount({
    accountId: Number(accountId),
  });

  /**
   * Redirect to the previous page (account details page or child accounts list page)
   * after saving the child account.
   * If there is no previous page, redirect to the account details page.
   */
  const locationAfterSave =
    location.state?.from ||
    generatePath(ACCOUNT_DETAILS_PATH, {
      workspace_id,
      accountId,
    });

  const handleCreateChildAccount = async (
    newAccountData: ModifyAccountRequest,
  ) => {
    try {
      await createChildAccount(newAccountData);

      history.push(locationAfterSave);

      toast({
        content: `New Sub Account "${newAccountData.name}" has been added!`,
        variant: "success",
      });
    } catch {
      toast({
        content: "Something went wrong. Could not create sub account.",
        variant: "error",
      });
    }
  };

  const handleSubmitForm = async (values: AccountInfoSchema) => {
    const newAccountData = {
      ...values,
      // API takes array of types but we only allow a single selection
      account_types: values.account_types ? [values.account_types] : undefined,
    };

    // API only accepts signed_id provided by direct upload so we remove logo from payload if its not
    if (regex.URL.test(String(newAccountData.logo)) || !newAccountData.logo) {
      delete newAccountData.logo;
    }

    handleCreateChildAccount(newAccountData);
  };

  return (
    <FormContainer>
      <div className="tw-flex tw-flex-row tw-gap-2">
        <StatusContainer>
          <Label className="tw-font-semibold tw-text-neutral-500">Status</Label>
          <ActiveTag
            isActive={Boolean(parentAccountData?.account?.is_active)}
          />
        </StatusContainer>
        <StatusContainer>
          <Label className="tw-font-semibold tw-text-neutral-500">
            Parent Account
          </Label>
          <p className="tw-text-neutral-300">
            {parentAccountData?.account.name || ""}
          </p>
        </StatusContainer>
      </div>
      <AccountInfoContainer>
        <h2 className="tw-text-lg tw-font-semibold tw-text-neutral-500">
          Account Info
        </h2>
        <AccountInfoForm onSubmit={handleSubmitForm} />
      </AccountInfoContainer>
      <InfoPreviewSection title="Contacts" disabled />
      <InfoPreviewSection title="Contracts" disabled />
      <InfoPreviewSection
        title="Sub Accounts"
        disabled
        countDescription="Account"
      />
      <InfoPreviewSection title="Documents" disabled />
    </FormContainer>
  );
}
