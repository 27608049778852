import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import ArchivedProjects from "./ArchivedProjects";
import Project from "./Project";
import Projects from "./Projects";

export const PROJECT_ID_PARAM = "project_id";

export const PROJECTS_ROUTES = {
  base: "/projects",
  details: "/:project_id",
  tasks: "/:project_id/tasks",
  archived: "/archived",
} as const;

export const PROJECTS_LIST_PATH =
  `/workspaces/:workspace_id${PROJECTS_ROUTES.base}` as const;
export const PROJECT_DETAILS_PATH =
  `${PROJECTS_LIST_PATH}${PROJECTS_ROUTES.details}` as const;
export const PROJECT_TASKS_PATH =
  `${PROJECTS_LIST_PATH}${PROJECTS_ROUTES.tasks}` as const;
export const PROJECTS_ARCHIVE_PATH =
  `${PROJECTS_LIST_PATH}${PROJECTS_ROUTES.archived}` as const;

export default function ProjectRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}${PROJECTS_ROUTES.archived}`}>
        <ArchivedProjects />
      </Route>
      <Route path={`${path}${PROJECTS_ROUTES.details}`}>
        <Project />
      </Route>
      <Route path={`${path}${PROJECTS_ROUTES.tasks}`}>
        <Project />
      </Route>
      <Route exact path={path}>
        <Projects />
      </Route>
    </Switch>
  );
}
