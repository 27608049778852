import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListChildAccountsPathParams,
  ListChildAccountsQueryResponse,
} from "../../models/ListChildAccounts";

/**
 * @description List child accounts
 * @summary List child accounts
 * @link /accounts/:account_id/child_accounts
 */
export async function listChildAccounts(
  accountId: ListChildAccountsPathParams["account_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListChildAccountsQueryResponse>["data"]> {
  const res = await client<ListChildAccountsQueryResponse>({
    method: "get",
    url: `/accounts/${accountId}/child_accounts`,
    ...options,
  });
  return res.data;
}
