import { TextInput } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import { PiXBold } from "react-icons/pi";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";

import ExportBulk from "@/components/dashboard/ExportBulk";
import ImportBulk from "@/components/dashboard/ImportBulk";
import { Icon } from "@/components/Elements";
import ImportFromWS from "@/components/ImportFromWS/ImportFromWS";
import ModuleSummaryCharts from "@/components/reports/summaryReports/ModuleSummaryCharts";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import { useFilters } from "@/hooks/useFilters";
import { useLoading } from "@/hooks/useLoading";
import { useSortingAndPagination } from "@/hooks/useSortingAndPagination";
import { useTableData } from "@/hooks/useTableData";
import { arrayToQueryString, useUpdateFilter } from "@/hooks/useUpdateFilter";
import { IndexParams } from "@/stores/types/module-store-types";
import { Folder } from "@/stores/types/section-tags";

import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import { getRepositionPayloadFromIndices } from "../../helpers/reordering";
import Loading from "../../Loading";
import SlideMenu from "../../slideMenu/SlideMenu";
import MenuActions from "../menu-actions/MenuActions";
import CWCreateFirstCTA from "../shared/create-first-cta/CWCreateFirstCTA";
import CreateFirstCTA from "../shared/CreateFirstCTA";
import { customColumns } from "../shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";
import ReorderableTable from "../shared/ReorderableTable";
import SectionDragContext from "../shared/SectionDragContext";
import TableMenu from "../shared/tableMenu/TableMenu";
import Table from "../Table";
import Folders from "./Folders";

const DOCUMENTS_SEARCH_FIELDS_MANE: string[] = ["document", "name"] as const;

function Documents() {
  // Import MobX stores
  const mainStore = useMainStore();

  useTableData();

  // State
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [showFolderCreatePoup, setShowCreatePopup] = useState(false);
  const [selectMode, setSelectMode] = useState(false);
  const [isImportSlideoutOpen, setIsImportSlideoutOpen] = useState(false);
  const [folder, setFolder] = useState<Folder | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [preparedSearch, setPreparedSearch] = useState(false);
  const [showTabs, setShowTabs] = useState(false);
  const [lastVisitedFolder, setLastVisitedFolder] = useState<number | null>(
    null,
  );

  // Variables
  const { visibleFields } = mainStore.fields;
  const { list: recordVersions } = mainStore.recordVersions;
  const { isIW, workspaceID, moduleWorkspaceID } = mainStore.context;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const { currentFilterURL } = mainStore.filters;
  const { page, count } = mainStore.documents;

  // Hooks
  const { currentFilters } = useUpdateFilter();
  const { folder_id } = useParams<{ folder_id: string }>();
  const loading =
    useLoading(visibleFields) ||
    visibleFields.length === 0 ||
    (Boolean(folder_id) && Boolean(!folder));
  const folderID = Number(folder_id);
  const history = useHistory();
  const location = useLocation();
  const isSearch = location.pathname.includes("search");
  const folderPath = `/workspaces/${workspaceID}/modules/documents`;
  const searchMode = isSearch || showTabs;
  const isSearchResultZero = searchMode && recordVersions.length === 0;
  const searchSentences = !isSearch
    ? "Don't see what you're looking for? Search all documents."
    : "";
  const isFolder = () => {
    return (
      location.pathname === folderPath ||
      location.pathname.includes(`${folderPath}/folder/`)
    );
  };

  const params: IndexParams = {
    workspaceID,
    tableFilters: currentFilters,
    folderID: folderID || undefined,
  };

  const {
    filtersTrigger,
    filtersViewEnabled,
    filtersContent,
    setFiltersViewEnabled,
  } = useFilters({
    fields: visibleFields,
  });

  useEffect(() => {
    (async () => {
      mainStore.documents.setFolderID(folderID);
      if (folderID) {
        setLastVisitedFolder(folderID);
        setFolder(await mainStore.sectionTags.show(folderID));
      } else {
        setFolder(null);
      }
    })();
  }, [folderID]);

  useEffect(() => {
    mainStore.filters.setCurrentFilterURL(
      currentFilters.length ? arrayToQueryString(currentFilters) : "",
    );
  }, []);

  useEffect(() => {
    history.replace(`${location.pathname}?${currentFilterURL}`);
  }, [location.pathname]);

  useEffect(() => {
    if (showTabs) {
      if (preparedSearch) {
        params.searchParams = {
          key: searchValue,
          fields_name: DOCUMENTS_SEARCH_FIELDS_MANE,
        };
      }

      if (isSearch) {
        mainStore.documents.search(params);
      } else {
        mainStore.documents.index(params);
      }
    }
  }, [isSearch, showTabs, preparedSearch]);

  const { createColumn, renameColumn, deleteColumn, AddColumnButton } =
    customColumns({});

  const {
    sortByFieldName,
    sortByDirection,
    setSortByFieldName,
    setSortByDirection,
  } = useSortingAndPagination({
    store: mainStore.documents,
    folderID,
  });

  // Functions
  const handleCreateNewDocument = async () => {
    if (workspaceID) {
      await mainStore.documents.create({ section_tag_id: folder?.id });
    }
  };

  const resetMenuActions = () => {
    setShowCheckbox(false);
    setSelectMode(false);
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionId' implicitly has an 'any... Remove this comment to see the full error message
  const onSectionChange = (recordVersionId, sectionId) => {
    mainStore.documents.move({
      folderID: sectionId,
      recordVersionID: recordVersionId,
    });
  };

  const onRecordMovedWithinSection = (
    // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionId' implicitly has an 'any... Remove this comment to see the full error message
    recordVersionId,
    // @ts-expect-error TS(7006) FIXME: Parameter 'sectionId' implicitly has an 'any' type... Remove this comment to see the full error message
    sectionId,
    // @ts-expect-error TS(7006) FIXME: Parameter 'sourceIndex' implicitly has an 'any' ty... Remove this comment to see the full error message
    sourceIndex,
    // @ts-expect-error TS(7006) FIXME: Parameter 'destinationIndex' implicitly has an 'an... Remove this comment to see the full error message
    destinationIndex,
  ) => {
    const relevantRecordVersions = mainStore.recordVersions.list.filter(
      (recordVersion) => {
        if (!sectionId) {
          return !recordVersion.section_tag_id;
        }

        return recordVersion.section_tag_id === sectionId;
      },
    );
    mainStore.recordVersions.reorder(
      recordVersionId,
      getRepositionPayloadFromIndices(
        relevantRecordVersions,
        sourceIndex,
        destinationIndex,
      ),
    );
  };

  const renderFields = visibleFields.map((field) => {
    return (
      <ModuleTableColumn
        key={field.name}
        field={field}
        // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        sortDirection={field.name === sortByFieldName ? sortByDirection : ""}
        setSortByDirection={setSortByDirection}
        setSortByFieldName={setSortByFieldName}
        onDelete={deleteColumn}
        onRename={renameColumn}
      />
    );
  });

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersion' implicitly has an 'any' ... Remove this comment to see the full error message
  const renderRow = (recordVersion, index) => {
    const status = mainStore.avroSchemas.firstValueForField(
      "status",
      recordVersion?.data,
    );

    return (
      <ModuleRecordVersion
        key={recordVersion.id}
        fields={visibleFields}
        recordVersion={recordVersion}
        moduleWorkspaceID={moduleWorkspaceID || 0}
        tableID={recordVersion.table_id}
        tableName={recordVersion.table_name}
        moduleIdentifier="documents"
        isDraggable={!isSearch}
        isSorted={!!sortByFieldName}
        order={index}
        isLockedRow={status === "published"}
        inSelectMenuActionMode={selectMode}
      />
    );
  };
  const onSearchChange = async (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      if (!searchValue) {
        return clearSearch();
      }
      setPreparedSearch(true);
      setShowTabs(true);

      params.searchParams = {
        key: searchValue,
        fields_name: DOCUMENTS_SEARCH_FIELDS_MANE,
      };

      if (isSearch) {
        await mainStore.documents.search(params);
      } else {
        await mainStore.documents.index(params);
      }
    }
  };

  const clearSearch = async () => {
    setSearchValue("");
    setPreparedSearch(true);
    setShowTabs(false);

    params.searchParams = undefined;

    if (isSearch) {
      await mainStore.documents.search(params);
    } else {
      await mainStore.documents.index(params);
    }
  };

  const onPageChange = async (newPage: number) => {
    params.page = newPage;
    if (searchValue) {
      params.searchParams = {
        key: searchValue,
        fields_name: DOCUMENTS_SEARCH_FIELDS_MANE,
      };
    }

    await mainStore.documents.search(params);
  };

  const documentsTab = lastVisitedFolder
    ? `/workspaces/${workspaceID}/modules/documents/folder/${lastVisitedFolder}`
    : `/workspaces/${workspaceID}/modules/documents`;

  const renderTabs = () => (
    <>
      <NavLink to={documentsTab} isActive={isFolder}>
        Documents
      </NavLink>
      <NavLink
        exact
        to={`/workspaces/${workspaceID}/modules/documents/search`}
        data-testid="all-documents-tab"
      >
        All Documents
      </NavLink>
    </>
  );

  const arrowLeftImage = (
    <Icon name="chevronLeft" color="generalMidnightDark" />
  );
  const arrowRightImage = (
    <Icon name="chevronRight" color="generalMidnightDark" />
  );

  return (
    <Table>
      {loading && <Loading loadingLayout="table" showTableHeader={false} />}
      {!loading && (
        <>
          <div className={classNames("table-header-wrap")}>
            {searchMode && (
              <div className="switch-table-wrap tw-min-w-56">
                {renderTabs()}
              </div>
            )}
            <div className="import-export-buttons-container">
              <div
                className="tw-relative tw-w-[300px]"
                data-testid="documents-search"
              >
                <TextInput
                  name="search"
                  placeholder="Search document"
                  onKeyDown={(e) =>
                    onSearchChange(e as React.KeyboardEvent<HTMLInputElement>)
                  }
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                  className="tw-pr-[25px]"
                />
                {searchValue !== "" && (
                  <PiXBold
                    className="tw-absolute tw-right-0 tw-top-[10px] tw-mr-[10px]"
                    onClick={() => clearSearch()}
                    data-testid="documents-clear-search"
                  />
                )}
              </div>
              {!isSearch && (
                <>
                  <ExportBulk isExportFilesEnabled />
                  <ImportBulk
                    importFromWS
                    openImportSlideOut={() => setIsImportSlideoutOpen(true)}
                    currentSection={folder?.id}
                  />
                  {filtersTrigger}
                </>
              )}
            </div>
          </div>
          <AddRecordHeader
            recordName="Document"
            addRecord={handleCreateNewDocument}
            canAddSection
            addSection={() => setShowCreatePopup(!showFolderCreatePoup)}
            sectionName="Folder"
          />
          <SectionDragContext
            onRecordMoved={onRecordMovedWithinSection}
            onSectionChange={onSectionChange}
          >
            {filtersViewEnabled && (
              <div className="filters-wrap">
                <div className="switch-table-wrap" />
                {filtersContent}
              </div>
            )}
            {!isSearch && moduleWorkspaceID && (
              <ModuleSummaryCharts
                recordVersions={recordVersions}
                moduleWorkspaceID={moduleWorkspaceID}
                sectionTagID={folderID}
                filtersViewEnabled={filtersViewEnabled}
                setFiltersViewEnabled={setFiltersViewEnabled}
                moduleName="documents"
              />
            )}
            {!isSearch && showTabs && (
              <div
                className="library-explainer tw-m-auto tw-mt-3 tw-w-1/3 tw-max-w-fit"
                data-testid="search-all-tips"
              >
                Don&apos;t see what you&apos;re looking for?
                <span className="tw-font-bold"> Search all documents.</span>
              </div>
            )}
            <div
              className="table-list-wrap documents-content draggable-documents-content"
              data-testid="documents-content"
            >
              <div className="dropdown-table">
                <div
                  className={classNames("list-title-table", {
                    "tw-pl-9": isSearch,
                  })}
                >
                  <ul>
                    <div className="list-column-wrap">
                      {!isSearch && (
                        <div className="title-first-block table-column-reorder">
                          {visibleFields?.length > 0 && !isSearchResultZero && (
                            <TableMenu
                              setShowCheckbox={setShowCheckbox}
                              resetMenuActions={resetMenuActions}
                              selectMode={selectMode}
                              setSelectMode={setSelectMode}
                            />
                          )}
                        </div>
                      )}
                      {showCheckbox && selectMode ? (
                        <div>
                          <MenuActions resetMenuActions={resetMenuActions} />
                        </div>
                      ) : (
                        <div className="list-title-table-wrap">
                          {!isSearchResultZero && renderFields}
                          <span className="stretch-cell" />
                        </div>
                      )}
                    </div>
                    {!isSearchResultZero && (
                      <AddColumnButton onAdd={createColumn} />
                    )}
                  </ul>
                </div>

                {!isSearchResultZero && (
                  <ReorderableTable
                    recordVersions={recordVersions}
                    isSorted={!!sortByFieldName}
                    renderer={renderRow}
                    // @ts-expect-error TS(2322) FIXME: Type 'Folder | null' is not assignable to type 'Se... Remove this comment to see the full error message
                    sectionTag={folder}
                    newRowName={getRecordName(
                      "documents",
                      moduleWorkspaces,
                      true,
                    )}
                    onAddNewRow={
                      !searchMode ? handleCreateNewDocument : undefined
                    }
                  />
                )}
              </div>

              {isSearch && !isSearchResultZero && (
                <Pagination
                  onChange={onPageChange}
                  current={page}
                  total={count}
                  prevIcon={arrowLeftImage}
                  nextIcon={arrowRightImage}
                  showPrevNextJumpers
                  pageSize={25}
                  aria-label={`Page ${page}`}
                />
              )}

              {recordVersions.length < 3 && isIW && !searchMode && (
                <CreateFirstCTA subject="Document" />
              )}

              {recordVersions.length < 3 && !isIW && !searchMode && (
                <CWCreateFirstCTA
                  title="Add / Share Documents"
                  sentences={[
                    'Create Documents for the collaborative workspace by clicking "Add New Document" on the top to start.',
                    "Shared documents are visible here.",
                  ]}
                />
              )}

              {isSearchResultZero && (
                <CWCreateFirstCTA
                  title="No results found"
                  sentences={[searchSentences]}
                />
              )}

              {!searchMode && moduleWorkspaceID && (
                <Folders
                  createFolderPopupFromParent={showFolderCreatePoup}
                  moduleWorkspaceID={moduleWorkspaceID}
                  folderID={folder?.id}
                />
              )}
            </div>
          </SectionDragContext>
        </>
      )}
      {isImportSlideoutOpen && (
        <SlideMenu
          open={isImportSlideoutOpen}
          closeSlideMenu={() => setIsImportSlideoutOpen(false)}
        >
          <ImportFromWS
            closeSlideOut={() => setIsImportSlideoutOpen(false)}
            refetchData={() =>
              mainStore.documents.index({
                workspaceID,
              })
            }
            showFolderIcon
          />
        </SlideMenu>
      )}
    </Table>
  );
}

export default observer(Documents);
