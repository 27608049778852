import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ShowAccountPathParams,
  ShowAccountQueryResponse,
} from "../../models/ShowAccount";

/**
 * @description Account with related data
 * @summary account
 * @link /workspaces/:workspace_id/accounts/:id
 */
export async function showAccount(
  workspaceId: ShowAccountPathParams["workspace_id"],
  id: ShowAccountPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ShowAccountQueryResponse>["data"]> {
  const res = await client<ShowAccountQueryResponse>({
    method: "get",
    url: `/workspaces/${workspaceId}/accounts/${id}`,
    ...options,
  });
  return res.data;
}
