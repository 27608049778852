import { PreviewTable } from "@themis/ui";
import React from "react";
import { useParams } from "react-router-dom";

import { useQaTests } from "@/api/queries/qaTests";
import { InfoPreviewSection } from "@/components/InfoPreviewSection";
import { RelatedIssueActionsCell } from "@/features/findings/components/RelatedIssuesTable/RelatedIssueActionsCell";
import { RELATED_ISSUE_COLUMNS } from "@/features/findings/config/relatedIssuesTable";

export function PreviewRelatedIssueManagementRecords({
  isIssueManagementModuleAdded,
}: {
  isIssueManagementModuleAdded: boolean;
}) {
  const { record_version_id } = useParams<{ record_version_id: string }>();
  const recordVersionId = Number(record_version_id);
  const { data: qaTests, isPending, isError } = useQaTests();

  if (isPending || isError) {
    return null;
  }

  const linkedIssueManagementRecords = qaTests.data.record_versions.find(
    ({ id }) => id === recordVersionId,
  )?.linked_records;

  if (!linkedIssueManagementRecords) {
    return null;
  }

  return (
    <InfoPreviewSection
      title="Related Issue Management Records"
      countDescription="Issue"
      count={linkedIssueManagementRecords.length}
      content={
        Boolean(linkedIssueManagementRecords.length) && (
          <PreviewTable
            responsive
            columns={RELATED_ISSUE_COLUMNS}
            rows={linkedIssueManagementRecords}
            rowActions={(linkedRecord) => (
              <RelatedIssueActionsCell
                id={linkedRecord.id}
                recordVersionId={linkedRecord.latest_record_version_id}
                name={linkedRecord.name}
                url={linkedRecord.url}
                isIssueManagementModuleAdded={isIssueManagementModuleAdded}
              />
            )}
          />
        )
      }
    />
  );
}
