import React, { useEffect } from "react";
import { PiPlusBold } from "react-icons/pi";
import { useHistory } from "react-router-dom";

import { todosService } from "@/api";
import LoadingWrapper from "@/components/loading-wrapper";
import ListTitleSettings from "@/components/table/settings/ListTitleSettings";
import CWCreateFirstCTA from "@/components/table/shared/create-first-cta/CWCreateFirstCTA";
import Table from "@/components/table/Table";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import TodosRow from "@/features/todos/components/TodosRow";
import { useLoadableData } from "@/hooks/useLoadableData";
import mainStore from "@/stores/Main";
import { User } from "@/stores/types/user-types";

import { todoFields } from "../constants";

function TodosIndex() {
  const [users, setUsers] = React.useState<User[]>([]);

  const history = useHistory();
  const { companyID, activeWorkspace } = mainStore.context;

  const todoList = useLoadableData({
    request: () => todosService.listTodos(companyID as number),
    onError: () => "Error. Please refresh to try again.",
  });

  useEffect(() => {
    const getUsers = async () => {
      const activeWorkspaceID = activeWorkspace?.id;
      if (activeWorkspaceID) {
        const usersData = await mainStore.users.indexForSettings({
          workspaceID: activeWorkspaceID,
        });
        setUsers(usersData);
      }
    };

    if (users.length === 0 && mainStore.users.users.length === 0) {
      getUsers();
    } else {
      setUsers(mainStore.users.users);
    }
  }, [activeWorkspace]);

  const todoListData = todoList.data?.data;

  // Components
  function RenderHeader() {
    return (
      <div className="list-title-table">
        <ul>
          <div className="list-column-wrap">
            <div className="list-title-table-wrap">
              {todoFields.map((field) => (
                <ListTitleSettings
                  key={field.field_name}
                  title={field.display_name}
                  width={"20%"}
                  minWidth={240}
                />
              ))}
              <span className="stretch-cell" />
            </div>
          </div>
          <div className="vendor-contracts__table-title-indent" />
        </ul>
      </div>
    );
  }

  function addNewTodo() {
    history.push(`/todos/add-new-checklist`);
  }

  return (
    <Table>
      <LoadingWrapper
        loading={!todoListData && todoList.loading}
        errorText={todoList.errorText}
        loadingLayout="table"
      >
        <AddRecordHeader addRecord={addNewTodo} recordName="Checklist" />
        <div className="vendor-contracts table-list-wrap">
          {todoListData && todoListData?.length > 0 && <RenderHeader />}

          {todoList.data?.data?.map((todo) => {
            return (
              <TodosRow
                key={todo.id}
                todo={todo}
                refetch={todoList.refetch}
                users={users}
              />
            );
          })}
          <div
            className="tw-sticky tw-left-[0px] tw-ml-[0px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
            onClick={addNewTodo}
            data-testid="add-new-record-button"
          >
            <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
            <div className="tw-neutral-300 tw-text-sm">Add Checklist...</div>
          </div>
          {todoListData && todoListData?.length === 0 && (
            <div className="tw-mt-[5rem]">
              <CWCreateFirstCTA
                title="Add Checklist"
                sentences={[
                  'Create Checklist for the internal workspace by clicking "Add New Checklist" above to start.',
                ]}
              />
            </div>
          )}
        </div>
      </LoadingWrapper>
    </Table>
  );
}

export default TodosIndex;
